import { getUserToken, putMeApi } from "../api/users.ts";
import { clearUserForErrorLogging } from "./error-utils";
import { isBrowser } from "./miscellaneous-util";
import { cognitoLogout } from "./user-auth-provider";

export const getViewAsUserId = () =>
  (isBrowser() && window.localStorage.getItem("view-as-user")) || "";

export const isTokenAvailable = () => {
  if (isBrowser()) {
    return !!window.localStorage.getItem("token");
  }
  return false;
};

export const getCsrfToken = async () => {
  const userIndex = await getUserToken();
  const { csrfToken } = userIndex;
  if (isBrowser()) {
    window.localStorage.setItem("token", csrfToken);
  }
  return csrfToken;
};

export const logout = (redirect = "/login") => {
  cognitoLogout().finally(() => {
    clearUserForErrorLogging();
    if (redirect) {
      window.location.href = redirect;
    }
  });
};

export const updateUserLastLoginTime = () => {
  return putMeApi({
    lastLoginTime: new Date(),
  });
};
