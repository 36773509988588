import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 16.2 15.5"
      xmlSpace="preserve"
      {...props}
    >
      <g
        id="prefix__Group_6302"
        transform="translate(1350.053 912.162)"
        opacity={0.4}
      >
        <path
          className="icon-main"
          d="M-1336.5-896.7h-9.8c-.1 0-.3-.1-.4-.1-.1-.1-.1-.2-.1-.4v-10.2c0-.3.2-.5.5-.5s.5.2.5.5v9.7h8.8v-9.7c0-.3.2-.5.5-.5s.5.2.5.5v10.2c0 .3-.2.5-.5.5z"
          id="prefix__Path_2381"
        />
        <path
          className="icon-main"
          d="M-1334.3-908.3h-14.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h14.2c.3 0 .5.2.5.5s-.2.5-.5.5z"
          id="prefix__Path_2382"
        />
        <path
          className="icon-main"
          d="M-1338.5-908.3c-.3 0-.5-.2-.5-.5v-1.7h-.1c-.1-.1-.2-.1-.3-.1h-3.7c-.1 0-.1.1-.2.1-.1.1-.2.2-.2.3v1.4c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-1.5-.1c.1-.3.2-.6.5-.8.2-.2.4-.4.7-.4h3.9c.3 0 .6.1.9.3.2.1.4.4.5.7v1.8c0 .3-.2.5-.5.5z"
          id="prefix__Path_2383"
        />
        <path
          className="icon-main"
          d="M-1344-905.4h1v6.1h-1z"
          id="prefix__Path_2384"
        />
        <path
          className="icon-main"
          d="M-1341.9-905.4h1v6.1h-1z"
          id="prefix__Path_2385"
        />
        <path
          className="icon-main"
          d="M-1339.8-905.4h1v6.1h-1z"
          id="prefix__Path_2386"
        />
      </g>
      <g id="prefix__Group_6303" transform="translate(1349 911.636)">
        <path
          className="icon-main"
          d="M-1336.5-896.7h-9.8c-.1 0-.3-.1-.4-.1-.1-.1-.1-.2-.1-.4v-10.2c0-.3.2-.5.5-.5s.5.2.5.5v9.7h8.8v-9.7c0-.3.2-.5.5-.5s.5.2.5.5v10.2c0 .3-.2.5-.5.5z"
          id="prefix__Path_2381-2"
        />
        <path
          className="icon-main"
          d="M-1334.3-908.3h-14.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h14.2c.3 0 .5.2.5.5s-.2.5-.5.5z"
          id="prefix__Path_2382-2"
        />
        <path
          className="icon-main"
          d="M-1338.5-908.3c-.3 0-.5-.2-.5-.5v-1.7h-.1c-.1-.1-.2-.1-.3-.1h-3.7c-.1 0-.1.1-.2.1-.1.1-.2.2-.2.3v1.4c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-1.5-.1c.1-.3.2-.6.5-.8.2-.2.4-.4.7-.4h3.9c.3 0 .6.1.9.3.2.1.4.4.5.7v1.8c0 .3-.2.5-.5.5z"
          id="prefix__Path_2383-2"
        />
        <path
          className="icon-main"
          d="M-1344-905.4h1v6.1h-1z"
          id="prefix__Path_2384-2"
        />
        <path
          className="icon-main"
          d="M-1341.9-905.4h1v6.1h-1z"
          id="prefix__Path_2385-2"
        />
        <path
          className="icon-main"
          d="M-1339.8-905.4h1v6.1h-1z"
          id="prefix__Path_2386-2"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
