import sumBy from "lodash/sumBy";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import mergeWith from "lodash/mergeWith";
import { by, isNotEmptyObject } from "./object-util";
import { sumArrays } from "./expensesUtils";
import { getCurrentMonth } from "./calendar-util";

const propertyHasLinkedAccounts = (property) => !!property.accountIds;

const calculateTotalPropertyDebtData = (propertyDebtList = []) => {
  const debtHistories = propertyDebtList.map(by("history"));
  return {
    currentDebt: sumBy(propertyDebtList, by("currentDebt")),
    monthlyRepayment: sumBy(propertyDebtList, by("monthlyRepayment")),
    history: mergeWith(
      {},
      ...debtHistories,
      (debtAmount = 0, srcDebtAmount = 0) => debtAmount + srcDebtAmount
    ),
  };
};

const buildDebtHistory = (linkedAccountBalanceHistories = []) => {
  const yyyyMMRegex = /\d{4}-\d{2}/;
  return mapValues(
    groupBy(linkedAccountBalanceHistories, (balanceHistory) => {
      return balanceHistory.date.match(yyyyMMRegex)[0];
    }),
    (balanceHistories) =>
      sumBy(balanceHistories, (balanceHistory) => balanceHistory.balance || 0)
  );
};

const calculatePropertyDebtList = (
  propertyList = [],
  propertyLoanAccounts = [],
  balanceHistories = [],
  propertyLoanPaymentTransactions = []
) => {
  const balanceHistoriesByAccountId = keyBy(balanceHistories, by("accountId"));
  const propertyLoanPaymentTransactionsByAccountId = keyBy(
    propertyLoanPaymentTransactions,
    by("accountId")
  );
  const propertyLoanAccountsByAccountId = keyBy(
    propertyLoanAccounts,
    by("accountId")
  );
  const propertyDebtData = propertyList
    .filter(propertyHasLinkedAccounts)
    .map((property) => {
      const { accountIds = "", propertyId = "" } = property;
      const linkedAccountIds = accountIds ? accountIds.split(",") : [];
      const linkedAccounts = linkedAccountIds.map(
        (linkedAccountId) =>
          propertyLoanAccountsByAccountId[linkedAccountId] || {}
      );

      const linkedAccountBalanceHistories = linkedAccountIds.flatMap(
        (accountId) =>
          balanceHistoriesByAccountId[accountId]?.balanceHistory || []
      );

      const monthlyRepayment = sumBy(linkedAccounts, (account) => {
        const paymentTransactionsInThisAccount =
          propertyLoanPaymentTransactionsByAccountId[account.accountId]
            ?.transactions || [];
        const totalPayment = sumBy(
          paymentTransactionsInThisAccount,
          by("amount", 0)
        );
        return Math.abs(totalPayment);
      });

      return {
        currentDebt: sumBy(linkedAccounts, by("balance", 0)),
        monthlyRepayment,
        propertyId,
        history: buildDebtHistory(linkedAccountBalanceHistories),
      };
    });
  return propertyDebtData;
};

const calculatePropertyEstimateHistory = (propertyList = []) => {
  const totalEstimateHistory = propertyList.reduce(
    (res, property) => {
      [1, 3, 5].forEach((year) => {
        res[year].time = res[year].time.concat(
          property?.propertyEstimateHistory?.[year]?.time || []
        );
        res[year].value = sumArrays(
          res[year].value,
          property?.propertyEstimateHistory?.[year]?.value || []
        );
      });
      return res;
    },
    {
      1: { time: [], value: [] },
      3: { time: [], value: [] },
      5: { time: [], value: [] },
    }
  );
  // Clear duplicate year data
  [1, 3, 5].forEach((year) => {
    totalEstimateHistory[year].time = [
      ...new Set(totalEstimateHistory[year].time),
    ];
  });
  return totalEstimateHistory;
};

const getTimeFilter = (time) => {
  switch (time) {
    case "one-year":
      return 1;
    case "three-year":
      return 3;
    case "five-year":
      return 5;
    default:
      return 0;
  }
};

const buildFilteredPropertyChart = (chartData = {}, timeFilter = "") => {
  if (isNotEmptyObject(chartData)) {
    const selectedChartByFilter =
      chartData[getTimeFilter(timeFilter === "all" ? "five-year" : timeFilter)];
    const { time = [], value = [] } = selectedChartByFilter;
    const data = value.map((element, index) => {
      return {
        x: new Date(time[index]),
        y: element,
      };
    });
    const reverseChartData = [].concat(data).sort((a, b) => {
      return new Date(a.x) - new Date(b.x);
    });
    return reverseChartData;
  }
  return [];
};

const buildSuburbFilteredData = (chartData = [], timeFilter = "") => {
  if (getTimeFilter(timeFilter) > 0) {
    return chartData.filter((chartItem) => {
      return (
        new Date(chartItem.x) >=
        new Date(getCurrentMonth(getTimeFilter(timeFilter)))
      );
    });
  }
  return chartData;
};

export {
  getTimeFilter,
  buildFilteredPropertyChart,
  buildSuburbFilteredData,
  calculatePropertyDebtList,
  calculateTotalPropertyDebtData,
  calculatePropertyEstimateHistory,
};
