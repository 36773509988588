export const INSTITUTION_MOCKUP_DATA = {
  data: [
    {
      id: 16441,
      name: "Dag Site",
      logo: "https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16441_1_2.SVG",
      favicon: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
      baseUrl: "http://64.14.28.129/dag/index.do",
    },
  ],
};

export const INSTITUTION_ACCOUNT_MOCKUP_DATA = {
  data: [
    {
      id: "10524900",
      institutionId: "16441",
      isAllowToUpdate: true,
      additionalStatus: "AVAILABLE_DATA_RETRIEVED",
    },
  ],
};
