import { useSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteUser,
  getMeApi,
  getUserConfirmation,
  putMeApi,
  updateUserAssociate,
  updateUserConfirmation,
} from "../api/users";
import { ConfirmationStatus, UpdateUserInfo } from "../types/user.types";
import { setUserForErrorLogging } from "../utils/error-utils";
import {
  cognitoChangePassword,
  isAuthenticatedUser,
} from "../utils/user-auth-provider";
import { useQueryInvalidationWithNotification } from "./util.hooks";

export const useIsLoggedIn = () => {
  const { data: isLoggedIn } = useQuery("isLoggedIn", isAuthenticatedUser, {
    retry: false,
  });
  return !!isLoggedIn;
};

export const useUpdateUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useMutation(
    (updateUserInfo: UpdateUserInfo) => putMeApi(updateUserInfo),
    {
      onSuccess: () => {
        invalidateQueries("myInfo");
        enqueueSnackbar("Your information has been updated", {
          variant: "success",
        });
      },
    }
  );
};

export const useUserConfimationData = (id: string) => {
  return useQuery(["confirmationData", id], () => getUserConfirmation(id), {
    retry: 0,
  });
};

export const useUpdateConfirmationData = () => {
  return useMutation(
    (updateUserConfirmationData: { id: string; status: ConfirmationStatus }) =>
      updateUserConfirmation(updateUserConfirmationData.id, {
        status: updateUserConfirmationData.status,
      })
  );
};

export const useMyInfo = (options = { pending: false }) => {
  const isLoggedIn = useIsLoggedIn();
  return useQuery("myInfo", getMeApi, {
    enabled: options.pending ? false : isLoggedIn,
    retry: false,
    onSuccess: (user) => {
      setUserForErrorLogging(user.id);
    },
  });
};

export const useUpdateUserAssociate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, params }: { id: string; params: Record<string, unknown> }) =>
      updateUserAssociate(id, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("staffList");
      },
    }
  );
};

export const useClearQueryCache = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.clear();
  };
};

export const useDeleteAccount = ({ onSuccess }) => {
  return useMutation(deleteUser, {
    onSuccess,
  });
};

export const useChangePassword = ({ onSuccess, onError }) => {
  return useMutation(
    ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) => cognitoChangePassword(currentPassword, newPassword),
    {
      onSuccess,
      onError,
    }
  );
};
