import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function SvgComponent(props, { fill = primaryColor }) {
  return (
    <svg width={16.184} height={16.756} viewBox="0 0 16.184 16.756" {...props}>
      <g data-name="Group 4003" fill="none">
        <path
          data-name="Path 1405"
          d="M1.252 9.732s.318 5.458 2.963 5.41 5.229.068 7.529 0 3.689-5.943 3.689-5.943H.749"
          stroke="#323a45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          data-name="Path 1406"
          d="M12.838 14.543v2.21"
          stroke="#323a45"
          strokeWidth={1.5}
        />
        <path
          data-name="Path 1407"
          d="M3.041 14.543v2.213"
          stroke="#323a45"
          strokeWidth={1.5}
        />
        <path
          data-name="Path 1408"
          d="M1.184 8.836v-4.6c0-1.94 3.456-2.471 3.456-2.471l.119-.062 1.9-.956s1.873.36 1.438 1.341-2.35 2.576-3.179 2.576-.14-2.576-.14-2.576"
          stroke="#323a45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path data-name="Path 1409" d="M8.341 7.301h.953" stroke={fill} />
        <path data-name="Path 1410" d="M9.746 7.301h.953" stroke={fill} />
        <path data-name="Path 1411" d="M11.379 7.301h.953" stroke={fill} />
        <path data-name="Path 1412" d="M9.746 5.667h.953" stroke={fill} />
        <path data-name="Path 1413" d="M8.341 5.667h.953" stroke={fill} />
        <path data-name="Path 1414" d="M8.341 4.035h.953" stroke={fill} />
        <path data-name="Path 1415" d="M6.709 7.301h.953" stroke={fill} />
        <path data-name="Path 1416" d="M6.709 5.667h.953" stroke={fill} />
      </g>
    </svg>
  );
}

export default SvgComponent;
