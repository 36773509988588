import React, { useMemo } from "react";

import { useCompanyInfo } from "../hooks/common.hooks";

const Footer = () => {
  const { data: companyInfo = {} } = useCompanyInfo();
  const {
    financialServicesLicenseNumber = "",
    creditLicenseNumber = "",
    companyNumber = "",
    businessName = "",
    businessPhoneNumber = "",
    name = "",
    customerSupportEmail = "",
  } = companyInfo;
  const companyFooterDetails = useMemo(() => {
    if (
      companyNumber &&
      (businessName || name) &&
      (creditLicenseNumber || financialServicesLicenseNumber)
    )
      return `${businessName || name} | ACN ${companyNumber} | ${
        creditLicenseNumber
          ? `Australian Credit License Number ${creditLicenseNumber}`
          : `Australian Financial Services License Number ${financialServicesLicenseNumber}`
      }`;
    return "WEALTHX | ACN 640 789 813";
  }, [
    businessName,
    companyNumber,
    creditLicenseNumber,
    financialServicesLicenseNumber,
    name,
  ]);
  return (
    <footer className="footer-custom text-white">
      <div className="footer-container">
        <div className="second_footer_content">
          <div className="second_footer_left">
            <p>ALL APPLICATIONS ARE SUBJECT TO ELIGIBILITY AND ASSESSMENT</p>
            <p>{companyFooterDetails}</p>
          </div>
          <div className="second_footer_right">
            <p>
              <b>Call</b>&nbsp;&nbsp; {businessPhoneNumber || "(02) 89 732 732"}
            </p>
            <p>
              <b>Email</b>&nbsp;&nbsp;{" "}
              {customerSupportEmail || "clint@wealthx.au"}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
