const checkRefreshPropertyIdInList = (refreshId, propertyList) => {
  return propertyList.find((item) => item.propertyId === refreshId);
};

const processingEstimateHistory = (resultList, item) => {
  const resultProcessing = resultList.map((propertyItem) => {
    const { propertyId = "" } = propertyItem;
    const findPriceHistory = item.find(
      (itemDetail) => itemDetail?.[propertyId]
    );
    return {
      ...propertyItem,
      propertyEstimateHistory: findPriceHistory?.[propertyId] || {},
    };
  });
  return resultProcessing;
};

const processingNearbyImageResult = (resultList, nearbyImageResult) => {
  const resultProcessing = resultList.map((result) => {
    const { comparableSales = [] } = result;
    const comparableItem = comparableSales.map((propertyItem) => {
      const { propertyId = "" } = propertyItem;
      const temp = nearbyImageResult.find(
        (propertyImage) => propertyImage[propertyId]
      );
      return {
        ...propertyItem,
        images: temp ? temp[propertyId] : [],
      };
    });
    return { ...result, comparableSales: comparableItem };
    // }
  });
  return resultProcessing;
};

const processingPropertyList = (
  refreshPropertyId,
  propertyResult,
  propertyList
) => {
  if (refreshPropertyId) {
    // Check refreshId available or not. If not will save all property to the Property List
    const checkRefreshIdInResult = checkRefreshPropertyIdInList(
      refreshPropertyId,
      propertyResult
    );
    // Check refreshId available on the result or not. If not the result has been removed from the list
    if (checkRefreshIdInResult) {
      // Check refreshId available in the Property list or not
      const checkRefreshIdInPropertyList = checkRefreshPropertyIdInList(
        refreshPropertyId,
        propertyList
      );
      // refreshId available in the property list => Action: Edit Property
      if (checkRefreshIdInPropertyList) {
        const propertyListArray = propertyList.map((item) => {
          const { propertyId } = item;
          if (propertyId === refreshPropertyId) {
            const resultData = { ...item, ...checkRefreshIdInResult };
            return resultData;
          }
          return item;
        });

        return propertyListArray;
      }
      // refreshId is not available in the property list => Action: Add new property
      const propertyListArray = propertyList.concat(checkRefreshIdInResult);
      return propertyListArray;
    }
    // refreshId is not available in the property response list => Action: Remove property
    const propertyListArray = propertyList.filter((item) => {
      const { propertyId } = item;
      return propertyId !== refreshPropertyId;
    });
    return propertyListArray;
  }
  // refresh id not in the Property result => first time get property list
  return propertyResult;
};

export {
  processingPropertyList,
  processingEstimateHistory,
  processingNearbyImageResult,
};
