import { useSnackbar } from "notistack";
import { useContext } from "react";
import { useMutation, useQueries, useQuery } from "react-query";

import {
  acceptJointAccountInvitation,
  createJointAccount,
  deleteJointAccount,
  deleteJointAccountConnection,
  getJointAccount,
  getJointAccountAggregationData,
  getJointAccountBalanceHistory,
  getJointAccountBankAccountHistory,
  getJointAccountConnection,
  getJointAccountDebtTransaction,
  getJointAccountPropertiesPriceHistory,
  inviteMemberToJointAccount,
  updateJointAccount,
  updateJointAccountConnection,
  validateInvitationToken,
} from "../api/joint-account";
import JointAccountContext from "../context/joint-account-context";
import { useMyInfo } from "./user.hooks.ts";
import { useQueryInvalidationWithNotification } from "./util.hooks";

export const useCreateJointAccount = () => {
  return useMutation((data) => createJointAccount(data));
};

export const useDeleteJointAccount = () => {
  const { enqueueSnackbar } = useSnackbar();
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useMutation((id) => deleteJointAccount(id), {
    onSuccess: () => {
      invalidateQueries("jointAccountList");
      enqueueSnackbar("Delete Joint Account successfully", {
        variant: "success",
      });
    },
  });
};
export const useDeleteJointAccountConnection = () => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useMutation((id) => deleteJointAccountConnection(id), {
    onSuccess: () => {
      invalidateQueries("jointAccountList");
    },
  });
};
export const useUpdateJointAccountConnection = () => {
  const { enqueueSnackbar } = useSnackbar();
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useMutation(
    ({ id, params }) => updateJointAccountConnection(id, params),
    {
      onSuccess: () => {
        invalidateQueries("jointAccountList");
        enqueueSnackbar("Update successfully", {
          variant: "success",
        });
      },
    }
  );
};

export const useUpdateJointAccount = () => {
  const { enqueueSnackbar } = useSnackbar();
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useMutation((data) => updateJointAccount(data.id, data.params), {
    onSuccess: () => {
      invalidateQueries("jointAccountList");
      enqueueSnackbar("Update Joint Account successfully", {
        variant: "success",
      });
    },
  });
};

export const useInviteMemberToJointAccount = () => {
  return useMutation((data) =>
    inviteMemberToJointAccount(data.id, data.params)
  );
};

export const useJointAccountList = () => {
  return useQuery("jointAccountList", getJointAccount);
};

export const useSelectedJointAccountDetails = () => {
  const { selectedJointAccount } = useContext(JointAccountContext);
  const { data: jointAccountList } = useJointAccountList();
  return jointAccountList?.find(
    (jointAccount) => jointAccount.id === selectedJointAccount
  );
};

export const useJointAccountAggregationData = (id) => {
  return useQuery(
    ["jointAccountAggregation", id],
    () => getJointAccountAggregationData(id),
    { enabled: Boolean(id) }
  );
};

export const useJointAccountBankAccountHistory = (id, financeHistoryParams) => {
  return useQuery(
    ["jointAccountAggregationFinanceHistory", id, financeHistoryParams],
    () => getJointAccountBankAccountHistory(id, financeHistoryParams),
    { enabled: Boolean(id) }
  );
};

export const useJointAccountPropertiesPriceHistory = (id) => {
  return useQuery(
    ["jointAccountPropertiesPriceHistory", id],
    () => getJointAccountPropertiesPriceHistory(id),
    { enabled: Boolean(id) }
  );
};

export const useSelectedJointAccountPropertiesPriceHistory = () => {
  const { selectedJointAccount = "" } = useContext(JointAccountContext);
  return useJointAccountPropertiesPriceHistory(selectedJointAccount);
};

export const useSelectedJointAccountAggregationData = () => {
  const { selectedJointAccount = "" } = useContext(JointAccountContext);
  return useJointAccountAggregationData(selectedJointAccount);
};

export const useSelectedJointAccountBankAccountHistory = (
  financeHistoryParams
) => {
  const { selectedJointAccount = "" } = useContext(JointAccountContext);
  return useJointAccountBankAccountHistory(
    selectedJointAccount,
    financeHistoryParams
  );
};

export const useJointAccountDebtTransaction = (
  id,
  { accountIds = [], direction, limit, keyword, from, to }
) => {
  const {
    data: jointAccountAggregationData,
  } = useSelectedJointAccountAggregationData();
  return useQueries(
    accountIds.map((accountId) => {
      const externalId = jointAccountAggregationData?.bankAccounts?.data?.find(
        (account) => accountId === account.id
      )?.externalId;
      return {
        queryKey: [
          "jointAccountDebtTransaction",
          id,
          {
            accountId,
            direction,
            limit,
            keyword,
            from,
            to,
            externalId: jointAccountAggregationData?.bankAccounts?.data?.find(
              (account) => accountId === account.id
            )?.externalId,
          },
        ],
        queryFn: () =>
          getJointAccountDebtTransaction(id, {
            accountId,
            externalId: jointAccountAggregationData?.bankAccounts?.data?.find(
              (account) => accountId === account.id
            )?.externalId,
            direction,
            limit,
            keyword,
            from,
            to,
          }),
        select: (response) => ({
          accountId: accountId.toString(),
          balanceHistory: response.data,
        }),
        enabled: Boolean(id) && Boolean(externalId),
      };
    })
  );
};

export const useSelectedJointAccountDebtTransaction = (
  debtTransactionParams
) => {
  const { selectedJointAccount = "" } = useContext(JointAccountContext);
  return useJointAccountDebtTransaction(
    selectedJointAccount,
    debtTransactionParams
  );
};
export const useValidateInvitationToken = (token) => {
  return useQuery(
    "jointAccountInvitationToken",
    () => validateInvitationToken({ params: { token } }),
    { enabled: Boolean(token) }
  );
};

export const useAcceptJointAccountInvitation = () => {
  return useMutation((data) => acceptJointAccountInvitation(data));
};

export const useGetJointAccountConnection = (id) => {
  return useQuery(
    ["jointAccountConnection", id],
    () => getJointAccountConnection(id),
    {
      enabled: Boolean(id),
      retry: 0,
    }
  );
};

const useJointAccountById = (jointAccountId) => {
  const { data: jointAccountList } = useJointAccountList();
  const jointAccountDetails = jointAccountList.find(
    (jointAccount) => jointAccount?.id === jointAccountId
  );
  return jointAccountDetails;
};

export const useJointAccountConnectionDetails = (jointAccountId) => {
  const { data: myInfo } = useMyInfo();
  const jointAccountDetails = useJointAccountById(jointAccountId);
  return jointAccountDetails.connections.find(
    (connection) => connection.email === myInfo.email
  );
};

export const useIsJointAccountOwner = (jointAccountId) => {
  const { data: myInfo } = useMyInfo();
  const jointAccountDetails = useJointAccountById(jointAccountId);
  return myInfo?.id === jointAccountDetails.ownerId;
};

export const useJointAccountBalanceHistory = (
  id,
  { accountIds = [], from, to, interval = "M" }
) => {
  const {
    data: jointAccountAggregationData,
  } = useSelectedJointAccountAggregationData();

  return useQueries(
    accountIds.map((accountId) => {
      const externalId = jointAccountAggregationData?.bankAccounts?.data?.find(
        (account) => accountId === account.id
      )?.externalId;
      return {
        queryKey: [
          "jointAccountBalanceHistory",
          id,
          {
            accountId,
            from,
            to,
            interval,
            externalId,
          },
        ],
        queryFn: () =>
          getJointAccountBalanceHistory(id, {
            accountId,
            from,
            to,
            interval,
            externalId,
          }),
        select: (response) => ({
          accountId: accountId.toString(),
          balanceHistory: response.data,
        }),
        enabled: Boolean(id) && Boolean(externalId),
      };
    })
  );
};
export const useSelectedJointAccountBalanceHistory = (balanceHistoryParams) => {
  const { selectedJointAccount = "" } = useContext(JointAccountContext);
  return useJointAccountBalanceHistory(
    selectedJointAccount,
    balanceHistoryParams
  );
};
