export const SERVICES_PROVIDER_MOCKUP_DATA = {
  AMC: {
    logo: "https://m2.connective.com.au/images/AMC_logo_l.jpg",
    longName: "AAMC",
  },
  ADEC: {
    logo: "https://m2.connective.com.au/images/ADEC_logo_l.jpg",
    longName: "Adelaide Bank (Commercial)",
  },
  ALZ: {
    logo: "https://m2.connective.com.au/images/ALZ_logo_l.jpg",
    longName: "Allianz Insurance",
  },
  AMP: {
    logo: "https://m2.connective.com.au/images/AMP_logo_l.jpg",
    longName: "AMP",
  },
  AXXA: {
    logo: "https://m2.connective.com.au/images/AXXA_logo_l.jpg",
    longName: "Angle Finance",
  },
  ANZ: {
    logo: "https://m2.connective.com.au/images/ANZ_logo_l.jpg",
    longName: "ANZ",
  },
  ESA: {
    logo: "https://m2.connective.com.au/images/ESA_logo_l.jpg",
    longName: "ANZ (Asset Finance)",
  },
  ANZC: {
    logo: "https://m2.connective.com.au/images/ANZC_logo_l.jpg",
    longName: "ANZ (Commercial)",
  },
  ABD: {
    logo: "https://m2.connective.com.au/images/ABD_logo_l.jpg",
    longName: "Aussie Bonds",
  },
  ALI: {
    logo: "https://m2.connective.com.au/images/ALI_logo_l.jpg",
    longName: "Australian Life Insurance",
  },
  AMB: {
    logo: "https://m2.connective.com.au/images/AMB_logo_l.jpg",
    longName: "Australian Military Bank",
  },
  YMHA: {
    logo: "https://m2.connective.com.au/images/YMHA_logo_l.jpg",
    longName: "Australian Motorcycle and Marine Finance",
  },
  WBA: {
    logo: "https://m2.connective.com.au/images/WBA_logo_l.jpg",
    longName: "Auswide Bank",
  },
  AFS: {
    logo: "https://m2.connective.com.au/images/AFS_logo_l.jpg",
    longName: "Automotive Financial Services",
  },
  BNJO: {
    logo: "https://m2.connective.com.au/images/BNJO_logo_l.jpg",
    longName: "Banjo",
  },
  BOC: {
    logo: "https://m2.connective.com.au/images/BOC_logo_l.jpg",
    longName: "Bank of China",
  },
  BOM: {
    logo: "https://m2.connective.com.au/images/BOM_logo_l.jpg",
    longName: "Bank of Melbourne",
  },
  BOMC: {
    logo: "https://m2.connective.com.au/images/BOMC_logo_l.jpg",
    longName: "Bank of Melbourne (Commercial)",
  },
  BQL: {
    logo: "https://m2.connective.com.au/images/BQL_logo_l.jpg",
    longName: "Bank of Queensland",
  },
  BOQ: {
    logo: "https://m2.connective.com.au/images/BOQ_logo_l.jpg",
    longName: "Bank of Queensland (Asset Finance)",
  },
  BOQC: {
    logo: "https://m2.connective.com.au/images/BOQC_logo_l.jpg",
    longName: "Bank of Queensland (Commercial)",
  },
  BSA: {
    logo: "https://m2.connective.com.au/images/BSA_logo_l.jpg",
    longName: "Bank SA",
  },
  BSAC: {
    logo: "https://m2.connective.com.au/images/BSAC_logo_l.jpg",
    longName: "Bank SA (Commercial)",
  },
  BWS: {
    logo: "https://m2.connective.com.au/images/BWS_logo_l.jpg",
    longName: "Bankwest",
  },
  IDEC: {
    logo: "https://m2.connective.com.au/images/IDEC_logo_l.jpg",
    longName: "Better Choice (Commercial)",
  },
  IDE: {
    logo: "https://m2.connective.com.au/images/IDE_logo_l.jpg",
    longName: "Better Choice Home Loans",
  },
  BMM: {
    logo: "https://m2.connective.com.au/images/BMM_logo_l.jpg",
    longName: "Better Mortgage Management",
  },
  BMMC: {
    logo: "https://m2.connective.com.au/images/BMMC_logo_l.jpg",
    longName: "Better Mortgage Management (Commercial)",
  },
  BLB: {
    logo: "https://m2.connective.com.au/images/BLB_logo_l.jpg",
    longName: "Bluebay Home Loans",
  },
  BFS: {
    logo: "https://m2.connective.com.au/images/BFS_logo_l.jpg",
    longName: "Branded Financial Services",
  },
  CFI: {
    logo: "https://m2.connective.com.au/images/CFI_logo_l.jpg",
    longName: "Capital Finance (Asset Finance)",
  },
  CFF: {
    logo: "https://m2.connective.com.au/images/CFF_logo_l.jpg",
    longName: "Cashflow Finance",
  },
  CIT: {
    logo: "https://m2.connective.com.au/images/CIT_logo_l.jpg",
    longName: "Citibank",
  },
  CFG: {
    logo: "https://m2.connective.com.au/images/CFG_logo_l.jpg",
    longName: "Classic Funding Group",
  },
  CBA: {
    logo: "https://m2.connective.com.au/images/CBA_logo_l.jpg",
    longName: "Commonwealth Bank",
  },
  CBF: {
    logo: "https://m2.connective.com.au/images/CBF_logo_l.jpg",
    longName: "Commonwealth Bank (Asset Finance)",
  },
  CBAC: {
    logo: "https://m2.connective.com.au/images/CBAC_logo_l.jpg",
    longName: "Commonwealth Bank (Commercial)",
  },
  CHLR: {
    logo: "https://m2.connective.com.au/images/CHLR_logo_l.jpg",
    longName: "Connective Advance",
  },
  CRTT: {
    logo: "https://m2.connective.com.au/images/CRTT_logo_l.jpg",
    longName: "Connective Advance by Thinktank (Commercial)",
  },
  CAPE: {
    logo: "https://m2.connective.com.au/images/CAPE_logo_l.jpg",
    longName: "Connective Asset Finance",
  },
  CCO: {
    logo: "https://m2.connective.com.au/images/CCO_logo_l.jpg",
    longName: "Connective Compliance Calculator",
  },
  CHLE: {
    logo: "https://m2.connective.com.au/images/CHLE_logo_l.jpg",
    longName: "Connective Elevate",
  },
  CHLA: {
    logo: "https://m2.connective.com.au/images/CHLA_logo_l.jpg",
    longName: "Connective Essentials",
  },
  CHLAB: {
    logo: "https://m2.connective.com.au/images/CHLAB_logo_l.jpg",
    longName: "Connective Select",
  },
  CHLS: {
    logo: "https://m2.connective.com.au/images/CHLS_logo_l.jpg",
    longName: "Connective Solutions",
  },
  CHLSC: {
    logo: "https://m2.connective.com.au/images/CHLSC_logo_l.jpg",
    longName: "Connective Solutions (Commercial)",
  },
  DAB: {
    logo: "https://m2.connective.com.au/images/DAB_logo_l.jpg",
    longName: "Deposit Assure",
  },
  DPW: {
    logo: "https://m2.connective.com.au/images/DPW_logo_l.jpg",
    longName: "Deposit Power (Deposit Bonds)",
  },
  ELP: {
    logo: "https://m2.connective.com.au/images/ELP_logo_l.jpg",
    longName: "earlypay",
  },
  FONC: {
    logo: "https://m2.connective.com.au/images/FONC_logo_l.jpg",
    longName: "Finance One (Commercial)",
  },
  FON: {
    logo: "https://m2.connective.com.au/images/FON_logo_l.jpg",
    longName: "Finance One (Consumer)",
  },
  FMB: {
    logo: "https://m2.connective.com.au/images/FMB_logo_l.jpg",
    longName: "Firefighters Mutual Bank",
  },
  TNT: {
    logo: "https://m2.connective.com.au/images/TNT_logo_l.jpg",
    longName: "Firstmac",
  },
  TNTA: {
    logo: "https://m2.connective.com.au/images/TNTA_logo_l.jpg",
    longName: "Firstmac (Asset Finance)",
  },
  FLXA: {
    logo: "https://m2.connective.com.au/images/FLXA_logo_l.jpg",
    longName: "flexicommercial Pty Ltd",
  },
  GCU: {
    logo: "https://m2.connective.com.au/images/GCU_logo_l.jpg",
    longName: "Gateway Bank",
  },
  GWBC: {
    logo: "https://m2.connective.com.au/images/GWBC_logo_l.jpg",
    longName: "Gateway Bank (Commercial)",
  },
  GWF: {
    logo: "https://m2.connective.com.au/images/GWF_logo_l.jpg",
    longName: "Genworth (LMI)",
  },
  GAF: {
    logo: "https://m2.connective.com.au/images/GAF_logo_l.jpg",
    longName: "Grow Funding Pty Ltd",
  },
  HPB: {
    logo: "https://m2.connective.com.au/images/HPB_logo_l.jpg",
    longName: "Health Professionals Bank",
  },
  HBS: {
    logo: "https://m2.connective.com.au/images/HBS_logo_l.jpg",
    longName: "Heritage Bank",
  },
  HRBC: {
    logo: "https://m2.connective.com.au/images/HRBC_logo_l.jpg",
    longName: "Heritage Bank (Commercial)",
  },
  HST: {
    logo: "https://m2.connective.com.au/images/HST_logo_l.jpg",
    longName: "HomeStart Finance",
  },
  ICF: {
    logo: "https://m2.connective.com.au/images/ICF_logo_l.jpg",
    longName: "IC Financial",
  },
  IMB: {
    logo: "https://m2.connective.com.au/images/IMB_logo_l.jpg",
    longName: "IMB Bank (Commercial)",
  },
  ING: {
    logo: "https://m2.connective.com.au/images/ING_logo_l.jpg",
    longName: "ING",
  },
  INGC: {
    logo: "https://m2.connective.com.au/images/INGC_logo_l.jpg",
    longName: "ING (Commercial)",
  },
  INI: {
    logo: "https://m2.connective.com.au/images/INI_logo_l.jpg",
    longName: "Intellitrain",
  },
  ICG: {
    logo: "https://m2.connective.com.au/images/ICG_logo_l.jpg",
    longName: "Iron Capital Holdings Pty Ltd",
  },
  ICGN2: {
    logo: "https://m2.connective.com.au/images/ICGN2_logo_l.jpg",
    longName: "Iron Capital No.2 Pty Ltd",
  },
  JUDOCF: {
    logo: "https://m2.connective.com.au/images/JUDOCF_logo_l.jpg",
    longName: "Judo Bank (Asset Finance)",
  },
  JUDO: {
    logo: "https://m2.connective.com.au/images/JUDO_logo_l.jpg",
    longName: "Judo Bank (Commercial)",
  },
  KHL: {
    logo: "https://m2.connective.com.au/images/KHL_logo_l.jpg",
    longName: "Keystart Home Loans",
  },
  LHL: {
    logo: "https://m2.connective.com.au/images/LHL_logo_l.jpg",
    longName: "La Trobe Financial",
  },
  LHLC: {
    logo: "https://m2.connective.com.au/images/LHLC_logo_l.jpg",
    longName: "La Trobe Financial (Comm)",
  },
  GEP: {
    logo: "https://m2.connective.com.au/images/GEP_logo_l.jpg",
    longName: "Latitude Financial Services",
  },
  LLB: {
    logo: "https://m2.connective.com.au/images/LLB_logo_l.jpg",
    longName: "lawlab",
  },
  LIFE: {
    logo: "https://m2.connective.com.au/images/LIFE_logo_l.jpg",
    longName: "Lifebroker",
  },
  LOA: {
    logo: "https://m2.connective.com.au/images/LOA_logo_l.jpg",
    longName: "Loanave",
  },
  LUMI: {
    logo: "https://m2.connective.com.au/images/LUMI_logo_l.jpg",
    longName: "Lumi Financial Management Pty Ltd",
  },
  MKM: {
    logo: "https://m2.connective.com.au/images/MKM_logo_l.jpg",
    longName: "MA Money",
  },
  MCM: {
    logo: "https://m2.connective.com.au/images/MCM_logo_l.jpg",
    longName: "Macquarie Bank",
  },
  MCMC: {
    logo: "https://m2.connective.com.au/images/MCMC_logo_l.jpg",
    longName: "Macquarie Bank (Commercial)",
  },
  MCL: {
    logo: "https://m2.connective.com.au/images/MCL_logo_l.jpg",
    longName: "Macquarie Leasing (Commercial)",
  },
  MCLC: {
    logo: "https://m2.connective.com.au/images/MCLC_logo_l.jpg",
    longName: "Macquarie Leasing (Consumer)",
  },
  MEB: {
    logo: "https://m2.connective.com.au/images/MEB_logo_l.jpg",
    longName: "ME Bank",
  },
  MEF: {
    logo: "https://m2.connective.com.au/images/MEF_logo_l.jpg",
    longName: "Medfin",
  },
  META: {
    logo: "https://m2.connective.com.au/images/META_logo_l.jpg",
    longName: "Metro (Asset Finance)",
  },
  MONY3: {
    logo: "https://m2.connective.com.au/images/MONY3_logo_l.jpg",
    longName: "money3 Corporation Ltd",
  },
  MSB: {
    logo: "https://m2.connective.com.au/images/MSB_logo_l.jpg",
    longName: "MyState",
  },
  HSL: {
    logo: "https://m2.connective.com.au/images/HSL_logo_l.jpg",
    longName: "NAB",
  },
  NABA: {
    logo: "https://m2.connective.com.au/images/NABA_logo_l.jpg",
    longName: "NAB (Asset Finance)",
  },
  NABC: {
    logo: "https://m2.connective.com.au/images/NABC_logo_l.jpg",
    longName: "NAB (Commercial)",
  },
  NPBS: {
    logo: "https://m2.connective.com.au/images/NPBS_logo_l.jpg",
    longName: "Newcastle Permanent Building Society",
  },
  NOWF: {
    logo: "https://m2.connective.com.au/images/NOWF_logo_l.jpg",
    longName: "NOW Finance",
  },
  OND: {
    logo: "https://m2.connective.com.au/images/OND_logo_l.jpg",
    longName: "OnDeck Capital",
  },
  POL: {
    logo: "https://m2.connective.com.au/images/POL_logo_l.jpg",
    longName: "P & N Bank",
  },
  PAM: {
    logo: "https://m2.connective.com.au/images/PAM_logo_l.jpg",
    longName: "Paramount Mortgage Services",
  },
  PAMC: {
    logo: "https://m2.connective.com.au/images/PAMC_logo_l.jpg",
    longName: "Paramount Mortgage Services (Commercial)",
  },
  PEPA: {
    logo: "https://m2.connective.com.au/images/PEPA_logo_l.jpg",
    longName: "Pepper (Asset Finance)",
  },
  PEP: {
    logo: "https://m2.connective.com.au/images/PEP_logo_l.jpg",
    longName: "Pepper Money",
  },
  PEPC: {
    logo: "https://m2.connective.com.au/images/PEPC_logo_l.jpg",
    longName: "Pepper Money (Commercial)",
  },
  RSTA: {
    logo: "https://m2.connective.com.au/images/RSTA_logo_l.jpg",
    longName: "Plenti (Asset Finance)",
  },
  PSPA: {
    logo: "https://m2.connective.com.au/images/PSPA_logo_l.jpg",
    longName: "Prospa (Asset Finance)",
  },
  QBE: {
    logo: "https://m2.connective.com.au/images/QBE_logo_l.jpg",
    longName: "QBE (LMI)",
  },
  QUAF: {
    logo: "https://m2.connective.com.au/images/QUAF_logo_l.jpg",
    longName: "QuickAF",
  },
  RDZD: {
    logo: "https://m2.connective.com.au/images/RDZD_logo_l.jpg",
    longName: "RedZed",
  },
  RDZDC: {
    logo: "https://m2.connective.com.au/images/RDZDC_logo_l.jpg",
    longName: "RedZed (Commercial)",
  },
  HLL: {
    logo: "https://m2.connective.com.au/images/HLL_logo_l.jpg",
    longName: "Resimac",
  },
  HLLA: {
    logo: "https://m2.connective.com.au/images/HLLA_logo_l.jpg",
    longName: "Resimac (Asset Finance)",
  },
  SPBF: {
    logo: "https://m2.connective.com.au/images/SPBF_logo_l.jpg",
    longName: "Scottish Pacific Business Finance",
  },
  SELF: {
    logo: "https://m2.connective.com.au/images/SELF_logo_l.jpg",
    longName: "Selfco Leasing",
  },
  GET: {
    logo: "https://m2.connective.com.au/images/GET_logo_l.jpg",
    longName: "Shift",
  },
  SGB: {
    logo: "https://m2.connective.com.au/images/SGB_logo_l.jpg",
    longName: "St George Bank",
  },
  SGBC: {
    logo: "https://m2.connective.com.au/images/SGBC_logo_l.jpg",
    longName: "St George Bank (Commercial)",
  },
  SCM: {
    logo: "https://m2.connective.com.au/images/SCM_logo_l.jpg",
    longName: "Suncorp Bank",
  },
  SCMC: {
    logo: "https://m2.connective.com.au/images/SCMC_logo_l.jpg",
    longName: "Suncorp Bank (Commercial)",
  },
  SCMCF: {
    logo: "https://m2.connective.com.au/images/SCMCF_logo_l.jpg",
    longName: "Suncorp Metway Advances Corporation Pty Ltd",
  },
  TMB: {
    logo: "https://m2.connective.com.au/images/TMB_logo_l.jpg",
    longName: "Teachers Mutual Bank",
  },
  THMN: {
    logo: "https://m2.connective.com.au/images/THMN_logo_l.jpg",
    longName: "Thornmoney",
  },
  TBL: {
    logo: "https://m2.connective.com.au/images/TBL_logo_l.jpg",
    longName: "Trailblazer Finance",
  },
  TBLC: {
    logo: "https://m2.connective.com.au/images/TBLC_logo_l.jpg",
    longName: "Trailblazer Finance (Commercial)",
  },
  "864H": {
    logo: "https://m2.connective.com.au/images/864H_logo_l.jpg",
    longName: "Ubank",
  },
  UNI: {
    logo: "https://m2.connective.com.au/images/UNI_logo_l.jpg",
    longName: "UniBank",
  },
  VMY: {
    logo: "https://m2.connective.com.au/images/VMY_logo_l.jpg",
    longName: "Virgin Money",
  },
  WAD: {
    logo: "https://m2.connective.com.au/images/WAD_logo_l.jpg",
    longName: "Waddle Debtor Finance",
  },
  WBP: {
    logo: "https://m2.connective.com.au/images/WBP_logo_l.jpg",
    longName: "WBP Property Group",
  },
  WPC: {
    logo: "https://m2.connective.com.au/images/WPC_logo_l.jpg",
    longName: "Westpac",
  },
  WPCC: {
    logo: "https://m2.connective.com.au/images/WPCC_logo_l.jpg",
    longName: "Westpac (Commercial)",
  },
  WPE: {
    logo: "https://m2.connective.com.au/images/WPE_logo_l.jpg",
    longName: "Westpac Equipment Finance",
  },
  WISR: {
    logo: "https://m2.connective.com.au/images/WISR_logo_l.jpg",
    longName: "WISR Finance Pty Ltd",
  },
  SPTA: {
    logo: "https://m2.connective.com.au/images/SPTA_logo_l.jpg",
    longName: "Zip Business",
  },
};
