import * as React from "react";

function SvgProperty(props) {
  return (
    <svg
      id="property_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 41.7 41.7"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.property_svg__st0{fill:none;stroke:#fff;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round}.property_svg__st1{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}`}
      </style>
      <g id="property_svg__Group_3665" transform="translate(0 46.994)">
        <path
          id="property_svg__Path_1216"
          className="property_svg__st0"
          d="M2.9-7h35v-22.2h-35V-7z"
        />
        <path
          id="property_svg__Path_1217"
          className="property_svg__st0"
          d="M23.6-14.8h9.8v-10h-9.8v10z"
        />
        <path
          id="property_svg__Path_1218"
          className="property_svg__st1"
          d="M23.6-19.3h9.8v-1.4h-9.8v1.4z"
        />
        <path
          id="property_svg__Path_1219"
          className="property_svg__st1"
          d="M27.5-24.7v9.8h1.4v-9.8h-1.4z"
        />
        <path
          id="property_svg__Path_1220"
          d="M23.7-45.2l18 12h-36l18-12z"
          fillRule="evenodd"
          clipRule="evenodd"
          className="icon-fill"
        />
        <path
          id="property_svg__Path_1221"
          className="property_svg__st0"
          d="M20.2-45.2l19.4 12.5H.8l19.4-12.5z"
        />
        <path
          id="property_svg__Path_1222"
          className="property_svg__st0"
          d="M9.3-42.4H2.5v8.3L9.4-39l-.1-3.4z"
        />
      </g>
    </svg>
  );
}

export default SvgProperty;
