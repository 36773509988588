import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.965}
      height={20.575}
      viewBox="0 0 21.965 20.575"
      {...props}
    >
      <g data-name="Group 6111">
        <path
          data-name="Path 2223"
          d="M.5 8.834l7.453 3.668 12.151-12z"
          fill="none"
          stroke={primaryColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          data-name="Path 2224"
          d="M7.898 12.503l3.8 7.573 3.968-9.357L19.961.591z"
          fill="none"
          stroke={primaryColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          data-name="Path 2283"
          d="M9.898 12.501l3.8 7.573 3.968-9.357L21.961.589z"
          fill={primaryColor}
          opacity={0.396}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
