import * as React from "react";

const Volunteer = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M1.5 11h4v11h-4zM16.5 3.2c.6-.7 1.7-1.2 2.7-1.2 1.8 0 3.3 1.5 3.3 3.3 0 2.3-2.9 4.9-6 7.7-3.1-2.8-6-5.4-6-7.7 0-1.9 1.4-3.3 3.3-3.3 1 0 2 .5 2.7 1.2zM20.5 17h-7l-2.1-.7.3-.9 1.8.7h2.8c.7 0 1.2-.5 1.2-1.2 0-.5-.3-.9-.8-1.1L9.5 11h-2v9l7 2 8-3c0-1.1-.9-2-2-2z" />
  </svg>
);

export default Volunteer;
