import * as React from "react";

function SvgComponent({ fill = "36ff99" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={7.3}
      height={9}
      viewBox="0 0 7.3 9"
      overflow="visible"
    >
      <style>
        {`.prefix__st0{fill:none;stroke:${fill};stroke-width:2;stroke-linecap:round}`}
      </style>
      <g id="prefix__Group_5780_1_" transform="translate(-1436.908 -364.592)">
        <path
          id="prefix__Line_188_1_"
          className="prefix__st0"
          d="M1437.9 365.6l5.3 3.5"
        />
        <path
          id="prefix__Line_189_1_"
          className="prefix__st0"
          d="M1437.9 372.6l5.3-3.5"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
