import * as React from "react";

function SvgDashboard() {
  return (
    <svg
      id="dashboard_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 45 45"
      xmlSpace="preserve"
    >
      <style>
        {`.dashboard_svg__st0{fill:none}.dashboard_svg__st1{fill:#fff}`}
      </style>
      <g id="dashboard_svg__Group_3738" transform="translate(-50 -514.018)">
        <g id="dashboard_svg__Path_1237">
          <path
            className="dashboard_svg__st0"
            d="M2.6 5.2h16.7c1.4 0 2.6 1.2 2.6 2.6V36c0 1.4-1.2 2.6-2.6 2.6H2.6C1.2 38.6 0 37.4 0 36V7.7c0-1.4 1.2-2.5 2.6-2.5z"
            transform="translate(50 515.281)"
          />
          <path
            className="dashboard_svg__st1"
            d="M2.6 7.2c-.3 0-.6.2-.6.5V36c0 .3.3.6.6.6h16.7c.3 0 .6-.3.6-.6V7.7c0-.3-.3-.6-.6-.6H2.6m0-1.9h16.7c1.4 0 2.6 1.2 2.6 2.6V36c0 1.4-1.2 2.6-2.6 2.6H2.6C1.2 38.6 0 37.4 0 36V7.7c0-1.4 1.2-2.5 2.6-2.5z"
            transform="translate(50 515.281)"
          />
        </g>
        <path
          id="dashboard_svg__Rectangle_515"
          className="icon-fill"
          d="M81.6 519.2H93c1.1 0 2 .9 2 2v8.9c0 1.1-.9 2-2 2H81.6c-1.1 0-2-.9-2-2v-8.9c0-1.1.9-2 2-2z"
        />
        <g id="dashboard_svg__Path_1239">
          <path
            className="dashboard_svg__st0"
            d="M2.6 5.2h12.9c1.4 0 2.6 1.1 2.6 2.6V18c0 1.4-1.2 2.6-2.6 2.6H2.6C1.2 20.6 0 19.4 0 18V7.7c0-1.4 1.2-2.5 2.6-2.5z"
            transform="translate(75.711 515.281)"
          />
          <path
            className="dashboard_svg__st1"
            d="M2.6 7.2c-.3 0-.6.2-.6.5V18c0 .3.3.6.6.6h12.9c.3 0 .6-.3.6-.6V7.7c0-.3-.3-.6-.6-.6H2.6m0-1.9h12.9c1.4 0 2.6 1.2 2.6 2.6V18c0 1.4-1.2 2.6-2.6 2.6H2.6C1.2 20.6 0 19.4 0 18V7.7c0-1.4 1.2-2.5 2.6-2.5z"
            transform="translate(75.711 515.281)"
          />
        </g>
        <g id="dashboard_svg__Path_1238">
          <path
            className="dashboard_svg__st0"
            d="M2.6 5.2h12.9c1.4 0 2.6 1.1 2.6 2.6V18c0 1.4-1.2 2.6-2.6 2.6H2.6C1.2 20.6 0 19.4 0 18V7.7c0-1.4 1.2-2.5 2.6-2.5z"
            transform="translate(75.711 533.279)"
          />
          <path
            className="dashboard_svg__st1"
            d="M2.6 7.2c-.3 0-.6.2-.6.5V18c0 .3.3.6.6.6h12.9c.3 0 .6-.3.6-.6V7.7c0-.3-.3-.6-.6-.6H2.6m0-1.9h12.9c1.4 0 2.6 1.2 2.6 2.6V18c0 1.4-1.2 2.6-2.6 2.6H2.6C1.2 20.6 0 19.4 0 18V7.7c0-1.4 1.2-2.5 2.6-2.5z"
            transform="translate(75.711 533.279)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgDashboard;
