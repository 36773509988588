import * as Sentry from "@sentry/gatsby";

export const setUserForErrorLogging = (userId) => {
  Sentry.setUser({ id: userId });
};

export const clearUserForErrorLogging = () => {
  Sentry.configureScope((scope) => scope.setUser(null));
};

export const captureException = (exception) => {
  Sentry.captureException(exception);
};

export const captureMessage = (message) => {
  Sentry.captureMessage(message);
};
