import * as React from "react";

function SvgFinance(props) {
  return (
    <svg
      id="finance_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 43 43"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.finance_svg__st0{fill:none;stroke:#fff;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round}.finance_svg__st1{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}`}
      </style>
      <g id="finance_svg__Group_3663" transform="translate(-70.25 -902.244)">
        <g id="finance_svg__Group_3621" transform="translate(71 902.994)">
          <path
            id="finance_svg__Path_1202"
            className="finance_svg__st0"
            d="M2.5 40.8h35.3v-5.6H2.5v5.6z"
          />
          <path
            id="finance_svg__Path_1203"
            className="finance_svg__st1"
            d="M4 34.8h2V14.6H4v20.2z"
          />
          <path
            id="finance_svg__Path_1204"
            className="finance_svg__st1"
            d="M9.1 34.8h2V14.6h-2v20.2z"
          />
          <path
            id="finance_svg__Path_1205"
            className="finance_svg__st1"
            d="M16.7 34.8h2V14.6h-2v20.2z"
          />
          <path
            id="finance_svg__Path_1206"
            className="finance_svg__st1"
            d="M21.8 34.8h2V14.6h-2v20.2z"
          />
          <path
            id="finance_svg__Path_1207"
            className="finance_svg__st1"
            d="M29 34.8h2V14.6h-2v20.2z"
          />
          <path
            id="finance_svg__Path_1208"
            className="finance_svg__st1"
            d="M34.2 34.8h2V14.6h-2v20.2z"
          />
          <path
            id="finance_svg__Path_1209"
            d="M24 .7l18.3 13.9H5.7L24 .7z"
            fillRule="evenodd"
            clipRule="evenodd"
            className="icon-fill"
          />
          <path
            id="finance_svg__Path_1210"
            className="finance_svg__st0"
            d="M19.9.7l19.9 14.4H0L19.9.7z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgFinance;
