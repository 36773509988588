export const get = (url) => {
  const iframe = document.getElementById("dummyframe");
  if (!iframe) {
    document.body.insertAdjacentHTML(
      "beforeend",
      '<iframe name="dummyframe" id="dummyframe" style="display: none;"></iframe>'
    );
  }

  const form = document.createElement("form");
  form.setAttribute("hidden", true);
  form.setAttribute("method", "GET");
  form.setAttribute("action", url);
  form.setAttribute("target", "dummyframe");

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};
