import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function SvgComponent(props, { fill = primaryColor }) {
  return (
    <svg width={17.713} height={15.083} viewBox="0 0 17.713 15.083" {...props}>
      <g data-name="Group 4004" fill="none" strokeWidth={1.5}>
        <path
          data-name="Path 1417"
          d="M2.701 12.506c3.788.021 9.309-.041 12.393 0S14.219.718 12.851.754s-5.2-.017-7.476 0-6.463 11.733-2.674 11.752z"
          stroke="#323a45"
        />
        <path data-name="Path 1419" d="M6.819 9.425h3.422" stroke="#323a45" />
        <path
          data-name="Path 1420"
          d="M12.245 8.976h2.238v1.564h-2.238z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          data-name="Path 1421"
          d="M2.587 5.093h12.65l.751-.812h.975v1.555H15.59"
          stroke="#323a45"
        />
        <path
          data-name="Path 1422"
          d="M1.723 5.831l.752-.742-.752-.812H.749v1.555h1.372"
          stroke="#323a45"
        />
        <path
          data-name="Path 1423"
          d="M12.596 12.745s.645 1.671 1.307 1.583 1.42-1.583 1.42-1.583"
          stroke="#323a45"
        />
        <path
          data-name="Path 1424"
          d="M2.938 12.745s.645 1.671 1.307 1.583 1.42-1.583 1.42-1.583"
          stroke="#323a45"
        />
        <path
          data-name="Path 1425"
          d="M3.392 8.976H5.63v1.564H3.392z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
