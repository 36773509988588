export const SCENARIOS_MOCKUP_DATA = [
  {
    id: "e2ef4af3-2a1f-4a0e-94d7-0aa7ffdff18c",
    scenarioObject: {
      features: [],
      loanType: "standard-variable",
      userName: "Experity  Hau Nguyen 123  ",
      applicant: 1,
      dependants: 0,
      lvrSetting: {
        lvr: 75,
        monthlyRent: 0,
        estimateIncome: 54797,
        estimateExpenses: 30232,
        estimateProperty: 5085558,
        cashTowardProperty: 1271390,
      },
      propertyId: "",
      propertyType: "Residential",
      scenarioType: "buy-a-house",
    },
    externalReferences: {
      bcId: "664295ee-793d-4d44-803e-b183568eca46",
      opportunity: "",
      borrowCapacityHistory: {
        "2021-06": 5659255.239999999,
        "2021-08": 5497379.637999999,
        "2021-10": 3427865.8580000005,
        "2021-12": 8515828.67,
        "2022-02": 12607478.698,
        "2022-04": 201764.12199999997,
      },
      maxBuyingPowerListHistory: {
        "2021-06": 7746801.13,
        "2021-08": 7564004.46,
        "2021-10": 5159002.470000001,
        "2021-12": 10668813.41,
        "2022-02": 15289157.3,
        "2022-04": 1478211.71,
      },
    },
    opportunityId: "",
    updatedAt: "2022-11-23T04:24:32.000Z",
    createdAt: "2022-05-11T10:04:19.000Z",
    dateSubmit: null,
    buyingPower: 1458224,
    mainUserId: "97532bed-44d1-49b3-83e3-874e3b8cadac",
  },
];
