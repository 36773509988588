import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useMemo } from "react";
import { USER_ROLE, loanTypes, scenarioTypes } from "../../commons/enum";
import DataLoadingContext from "../../context/data-loading-context";
import {
  useCompanyInfo,
  useCompanyInfoById,
  useFinanceSummary,
} from "../../hooks/common.hooks";
import { useCreateScenarioMutation } from "../../hooks/scenario.hooks";
import { useMyInfo } from "../../hooks/user.hooks.ts";
import { convertToInt } from "../../utils/convert-number";
import { navigateToDomainWithLocalStorageData } from "../../utils/domain-navigation";
import { getAllValueLocationStorage } from "../../utils/locastorage-utils";
import { calculateLvr } from "../../utils/scenario-utils";
import { checkIsDemoClient } from "../../utils";

export const buildCreateScenarioRequest = (
  userSummary,
  pendingToCreateScenarios
) => {
  const {
    buyingGoal,
    homeloan,
    firstName,
    scenarioType,
    monthlyRent = 0,
  } = pendingToCreateScenarios;
  const cashDeposit = userSummary?.cashDeposit || 0;
  const cashTowardProperty = convertToInt(cashDeposit * 0.8);
  const lvr = calculateLvr(buyingGoal, cashTowardProperty);

  const scenarioFilter = {
    userName: `${process.env.SCENARIO_PREFIX} ${firstName}`,
    scenarioType: scenarioType || scenarioTypes.BUY_A_HOUSE,
    applicant: 1,
    dependants: 0,
    lvrSetting: {
      estimateProperty: buyingGoal,
      // If homeloan available, we will calculate Cashtowards equal buying goal - homeloan, else we will use cashTowardProperty
      cashTowardProperty: homeloan ? buyingGoal - homeloan : cashTowardProperty,
      monthlyRent,
      lvr,
    },
    propertyId: "",
    loanType: loanTypes.STANDARD_VARIABLE,
    propertyType: "Residential",
    features: [],
  };
  return scenarioFilter;
};

/**
 * DashboardLogicWrapper is a centralized place for dashboard-wide logic.
 * It is placed inside Layout's contexts to make it easier to use `useContext`
 */
export const DashboardLogicWrapper = ({ children }) => {
  const {
    shouldCreatePendingScenario,
    pendingToCreateScenarios,
    setAppState,
  } = useContext(DataLoadingContext);
  const { data: myInfo, isSuccess: isGetMyInfoSuccess } = useMyInfo();
  const {
    data: companyInfo,
    isSuccess: isGetCompanyInfoSuccess,
  } = useCompanyInfo();
  const {
    data: companyInfoById,
    isSuccess: isGetCompanyInfoByIdSuccess,
  } = useCompanyInfoById(myInfo?.companyId);

  const summary = useFinanceSummary();
  const isDemoClient = checkIsDemoClient();
  const createScenarioMutation = useCreateScenarioMutation();
  const { closeSnackbar } = useSnackbar();

  // create any pending scenario from signup screen
  useEffect(() => {
    if (shouldCreatePendingScenario && pendingToCreateScenarios.length > 0) {
      pendingToCreateScenarios.forEach((scenarioItem) =>
        createScenarioMutation.mutate(
          buildCreateScenarioRequest(summary, scenarioItem)
        )
      );
      setAppState({
        pendingToCreateScenarios: [],
        shouldCreatePendingScenario: false,
      });
      closeSnackbar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCreatePendingScenario, pendingToCreateScenarios]);
  const isGettingDataSuccess = useMemo(() => {
    return (
      isGetCompanyInfoByIdSuccess &&
      isGetMyInfoSuccess &&
      isGetCompanyInfoSuccess
    );
  }, [
    isGetCompanyInfoByIdSuccess,
    isGetMyInfoSuccess,
    isGetCompanyInfoSuccess,
  ]);

  const isUserBelongToCompany = useMemo(() => {
    return isGettingDataSuccess ? companyInfo?.id === myInfo?.companyId : false;
  }, [isGettingDataSuccess, companyInfo?.id, myInfo?.companyId]);

  if (!isGettingDataSuccess) return null;

  if (
    myInfo.userRole === USER_ROLE.ADMIN ||
    isUserBelongToCompany ||
    isDemoClient
  ) {
    return <div className="my-account-container">{children}</div>;
  }

  const localStorageData = getAllValueLocationStorage();

  navigateToDomainWithLocalStorageData(
    companyInfoById?.domain,
    localStorageData
  );

  return null;
};
