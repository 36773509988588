import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={39}
      height={39}
      viewBox="0 0 39 39"
      {...props}
    >
      <g data-name="Group 6239">
        <g
          data-name="Group 6238"
          transform="translate(8.806 9.435)"
          fill={primaryColor}
        >
          <rect
            data-name="Rectangle 1070"
            width={4.161}
            height={22.396}
            rx={2.081}
            transform="rotate(35.02 8.82 27.97)"
          />
          <rect
            data-name="Rectangle 1071"
            width={4.162}
            height={12.861}
            rx={2.081}
            transform="rotate(144.99 2.498 10.874)"
          />
        </g>
        <g
          data-name="Ellipse 293"
          fill="none"
          stroke={primaryColor}
          strokeWidth={3}
        >
          <circle cx={19.5} cy={19.5} r={19.5} stroke="none" />
          <circle cx={19.5} cy={19.5} r={18} />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
