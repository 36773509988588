import * as React from "react";

const SelfImprovement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <circle cx={12} cy={6} r={2} />
    <path d="M21 16v-2c-2.2 0-4.2-1-5.6-2.7l-1.3-1.6c-.4-.4-1-.7-1.6-.7h-1.1c-.6 0-1.1.3-1.5.7l-1.3 1.6C7.2 13 5.2 14 3 14v2c2.8 0 5.2-1.2 7-3.2V15l-3.9 1.5c-.7.3-1.1 1-1.1 1.7 0 1 .8 1.8 1.8 1.8H9v-.5c0-1.4 1.1-2.5 2.5-2.5h3c.3 0 .5.2.5.5s-.2.5-.5.5h-3c-.8 0-1.5.7-1.5 1.5v.5h7.2c1 0 1.8-.8 1.8-1.8 0-.7-.5-1.4-1.1-1.7L14 15v-2.2c1.8 2 4.2 3.2 7 3.2z" />
  </svg>
);

export default SelfImprovement;
