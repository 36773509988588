export const trackingGoogleTagEvent = (
  trackingEvent,
  userData,
  conversion = {}
) => {
  window?.dataLayer?.push({
    event: trackingEvent,
    conversion: {
      ...conversion,
      action: trackingEvent,
    },
    customer: {
      email: userData?.email || "",
      mobile: userData?.phoneNumber || "",
      firstname: userData?.firstName || "",
      lastname: userData?.lastName || "",
      city: userData?.city || "",
      country: userData?.country || "",
      region: userData?.region || "",
      acceptsmarketing: "acceptsmarketing",
    },
  });
};
