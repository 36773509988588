export const COMPANY_INFO_MOCKUP_DATA = {
  id: "148b4a5e-b8e2-4725-bff0-6aab09650209",
  name: "WealthX",
  businessName: null,
  companyNumber: "13123124",
  customerSupportEmail: "support@wealthx.au",
  businessPhoneNumber: "09999999999",
  financialServicesLicenseNumber: "414141",
  creditLicenseNumber: "",
  isDefault: false,
  defaultBrokerId: "1aac82c9-b632-4f4f-924f-73af99e3ccfa",
  rootAdminId: "1aac82c9-b632-4f4f-924f-73af99e3ccfa",
  themeId: "7fc9a785-17b8-4fae-a937-ba4cba61c503",
  domain: "kkms",
  status: "APPROVED",
  type: "INTERNAL",
  createdAt: "2022-10-13T05:07:29.000Z",
  updatedAt: "2022-10-13T05:20:59.000Z",
  deletedAt: null,
  adminDetails: {
    id: "1aac82c9-b632-4f4f-924f-73af99e3ccfa",
    staffCode: "3EWVSJ",
    introductionVideoUrl: "",
    activeCampaignContactListId: null,
    contact: null,
    calendlyUrl: "",
    imageUrl:
      "https://d1rndq5v7rs3ry.cloudfront.net/27fb7865-b0c9-4458-8623-286865bf3dfa-clint-support-page-.png",
    bottomImageUrl: null,
    userId: "b5010b57-fe89-44ab-9659-c59537fdfe30",
    companyId: "148b4a5e-b8e2-4725-bff0-6aab09650209",
    createdAt: "2022-10-13T05:07:30.000Z",
    updatedAt: "2022-10-13T05:07:30.000Z",
    brokerIntegrationSetting: null,
    staffRoles: [
      {
        id: "acd0ce2a-d246-4165-99dd-389282619840",
        staffId: "1aac82c9-b632-4f4f-924f-73af99e3ccfa",
        role: "BROKER",
        companyId: "148b4a5e-b8e2-4725-bff0-6aab09650209",
        createdAt: "2022-10-13T05:07:30.000Z",
        updatedAt: "2022-10-13T05:07:30.000Z",
      },
      {
        id: "8a85d688-126d-41d1-b6f2-ba543d6c6438",
        staffId: "1aac82c9-b632-4f4f-924f-73af99e3ccfa",
        role: "COMPANY_ADMIN",
        companyId: "148b4a5e-b8e2-4725-bff0-6aab09650209",
        createdAt: "2022-10-13T05:07:30.000Z",
        updatedAt: "2022-10-13T05:07:30.000Z",
      },
    ],
    user: {
      firstName: "WealthX",
      lastName: "Support",
      userRole: "STAFF",
      phoneNumber: "+61902440202",
      email: "advisor@wealthx.au",
    },
  },
  theme: {
    id: "7fc9a785-17b8-4fae-a937-ba4cba61c503",
    primaryColor: "#33FF99",
    secondaryColor: "#131C24",
    // logoUrl:
    //   "https://upload.wikimedia.org/wikipedia/commons/7/7c/Techcombank_logo.png",
    // iconUrl: "https://mentori.vn/upload/recruitment/tfy1627116645.png",
    // logoDarkUrl:
    //   "https://upload.wikimedia.org/wikipedia/commons/7/7c/Techcombank_logo.png",
    iconDarkUrl: null,
    createdAt: "2022-10-13T05:07:29.000Z",
    updatedAt: "2022-10-13T05:46:34.000Z",
    deletedAt: null,
  },
  isCompanyHasConnectiveToken: true,
};
