import { Auth } from "aws-amplify";

export const cognitoLogin = (username, password) => {
  return Auth.signIn(
    username, // registerInfo.email,
    password // registerInfo.password,
  );
};

export const currentAuthenticatedUser = () => {
  return Auth.currentAuthenticatedUser();
};

export const isAuthenticatedUser = () => {
  return currentAuthenticatedUser()
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const cognitoLogout = () => {
  return Auth.signOut();
};

export const cognitoChangePassword = (oldPassword, newPassword) => {
  return Auth.currentAuthenticatedUser().then((user) => {
    return Auth.changePassword(user, oldPassword, newPassword);
  });
};

export const cognitoForgotPassword = (email) => {
  return Auth.forgotPassword(email);
};

export const cognitoForgotPasswordSubmit = (email, otp, newPassword) => {
  return Auth.forgotPasswordSubmit(email, otp, newPassword);
};

export const currentSession = () => {
  return Auth.currentSession();
};
