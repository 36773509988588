import React from "react";
import NumberFormat from "react-number-format";

const MenuLeftItemWValue = ({ icon, name, value, className = "" }) => {
  return (
    <div className={`ml-transaction-body ${className}`}>
      {icon && (
        <div className="ml-icon-container">
          <img alt={name} src={icon} />
        </div>
      )}
      <div className="ml-transaction-value">
        <div>{name}</div>
        <div className="ml-bank-value">
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </div>
      </div>
    </div>
  );
};

export default MenuLeftItemWValue;
