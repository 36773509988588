import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function BackArrow({ fill = primaryColor }) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 29.5 17.4"
      xmlSpace="preserve"
    >
      <style>
        {`.back-arrow-line{fill:none;stroke:${fill};stroke-width:2;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="prefix__Group_6119" transform="translate(1 1.413)">
        <g id="prefix__Group_6116" transform="translate(0 1)" opacity={0.4}>
          <path
            id="prefix__Path_2284"
            fill="none"
            className="back-arrow-line"
            d="M25.5 7H0l7 6.6"
          />
          <path
            id="prefix__Path_2285"
            fill="none"
            className="back-arrow-line"
            d="M25.5 6.6H0L7 0"
          />
        </g>
        <g id="prefix__Group_6117" transform="translate(2)">
          <path
            id="prefix__Path_2284-2"
            fill="none"
            className="back-arrow-line"
            d="M25.5 7H0l7 6.6"
          />
          <path
            id="prefix__Path_2285-2"
            fill="none"
            className="back-arrow-line"
            d="M25.5 6.6H0L7 0"
          />
        </g>
      </g>
    </svg>
  );
}

export default BackArrow;
