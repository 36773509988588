import { ACCOUNT_CATEGORY } from "../commons/enum";
import { convertToFloat } from "./convert-number";

export const calculateAccountsTotalBalance = (accounts) => {
  const totalBalance = accounts.reduce((result, account = {}) => {
    const { balance = "0" } = account;
    const balanceFloat = convertToFloat(balance);
    return result + balanceFloat;
  }, 0);
  return totalBalance || 0;
};

export const getAccountCategoryTitle = (category) => {
  switch (category) {
    case ACCOUNT_CATEGORY.ASSETS:
      return "Linked Accounts";
    case ACCOUNT_CATEGORY.ASSETS_NOT_SHARES_AND_SUPERS:
      return "Linked Accounts";
    case ACCOUNT_CATEGORY.CREDIT:
      return "Credit Cards";
    case ACCOUNT_CATEGORY.LOAN:
      return "Loans";
    case ACCOUNT_CATEGORY.SHARE:
      return "Share Trading";
    case ACCOUNT_CATEGORY.SUPER:
      return "Superannuation";
    case ACCOUNT_CATEGORY.PROPERTY_LOAN:
      return "Property Loans";
    case ACCOUNT_CATEGORY.PERSONAL_LOAN:
      return "Personal Loans";
    case ACCOUNT_CATEGORY.OTHERS:
      return "Others";
    default:
      return "";
  }
};
