import React from "react";
import ReactPlayer from "react-player";
import { useCompanyLogo } from "../hooks";
import videoBackground from "../videos/landing-background.mp4";

const SignupBanner = () => {
  const { lightLogo } = useCompanyLogo();
  return (
    <div className="signup-banner">
      {lightLogo && (
        <div className="banner-wraper">
          <div className="banner-container">
            <img src={lightLogo} alt="" />
          </div>
        </div>
      )}
      {videoBackground && (
        <ReactPlayer
          playing
          loop
          muted
          url={videoBackground}
          height="100%"
          width="100%"
          config={{
            file: {
              attributes: {
                preload: "auto",
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default SignupBanner;
