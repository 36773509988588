import axiosClient from "./axiosClient";
import { staffAssociatedInfo } from "../types/staff.types";
export const getStaffList = () => {
  return axiosClient.get<any, staffAssociatedInfo[]>(
    "/user-associate/staffList"
  );
};

export const getStaffInfo = (staffCode) => {
  const url = `/brokers/staffInfoByCode?code=${staffCode}`;
  return axiosClient.get(url);
};

export const getStaffInfoByReferralCode = (referralCode) => {
  const url = `/brokers/referral?code=${referralCode}`;
  return axiosClient.get(url);
};
