import { endOfMonth, format, startOfMonth, sub } from "date-fns";
import {
  getCurrentMonthWithDay,
  getFromToLastXDays,
} from "../../utils/calendar-util";
import { getcompanyDomain } from "../../utils/get-params-value";
import { generateRandomInteger } from "../../utils/math-util";
import {
  ACCOUNT_WITH_INSTITUTION_MOCKUP_DATA,
  BANK_ACCOUNT_MOCKUP_DATA,
  CATEGORIZED_ACCOUNTS,
  COMPANY_INFO_MOCKUP_DATA,
  HISTORY_120_DAYS_MOCKUP_DATA,
  HISTORY_30_DAYS_MOCKUP_DATA,
  HISTORY_60_DAYS_MOCKUP_DATA,
  HISTORY_ALL_DAYS_MOCKUP_DATA,
  HISTORY_LOAN_120_DAYS_MOCKUP_DATA,
  HISTORY_LOAN_30_DAYS_MOCKUP_DATA,
  HISTORY_LOAN_60_DAYS_MOCKUP_DATA,
  HISTORY_LOAN_ALL_DAYS_MOCKUP_DATA,
  INCOME_EXPENSE_SUMMARY_BY_MONTH_2_IN_12_MONTHS_MOCKUP_DATA,
  INCOME_EXPENSE_SUMMARY_BY_MONTH_2_IN_3_MONTHS_MOCKUP_DATA,
  INCOME_EXPENSE_SUMMARY_BY_MONTH_3_IN_12_MONTHS_MOCKUP_DATA,
  INSTITUTION_ACCOUNT_MOCKUP_DATA,
  INSTITUTION_MOCKUP_DATA,
  PRODUCT_LIST_MOCKUP_DATA,
  PROPERTIES_INDICES_MOCKUP_DATA,
  PROPERTIES_MOCKUP_DATA,
  PROPERTIES_NEARBY_DETAILS_MOCKUP_DATA,
  PROPERTIES_PRICE_HISTORY_MOCKUP_DATA,
  SCENARIOS_MOCKUP_DATA,
  SCENARIO_BUYING_POWER_HISTORY_MOCKUP_DATA,
  SERVICES_PROVIDER_MOCKUP_DATA,
  STAFF_LIST_MOCKUP_DATA,
  USER_INFO_MOCKUP_DATA,
  STAFF_INFO_MOCKUP_DATA,
} from "../demo-mockup";
import {
  generateCashBalanceLastXDays,
  generateLoanTransactionLastXDays,
  getMonthsListLastXDays,
} from "./generate-mockup-data-utils";
import { ACCOUNT_CATEGORY } from "../../commons/enum";

const FINANCE_HISTORY_DAY_FORMAT = "yyyy-MM-dd";

const { queryClient } = require("../../utils/react-query-util");

const prepareMarketIndicesParams = () => {
  const MAXIMUM_INDICES_YEARS = 15;
  const propertyIndicesParams = PROPERTIES_MOCKUP_DATA?.reduce(
    (result, item) => {
      const { addressDetails = {}, propertyType = "" } = item;
      const { locality = "", postcode = "" } = addressDetails;
      const level = `${locality}-${postcode}`;

      return {
        ...result,
        level: [...new Set([...result.level, level])],
        propertyType: [...new Set([...result.propertyType, propertyType])],
      };
    },
    {
      region: "Locality",
      interval: "monthly",
      start: getCurrentMonthWithDay(MAXIMUM_INDICES_YEARS),
      end: getCurrentMonthWithDay(),
      propertyType: [],
      stat: ["price", "marketscore"],
      level: [],
    }
  );
  return propertyIndicesParams;
};

export const updateThemeAfterSetMockupData = () => {
  const pageLayout = document.documentElement;
  pageLayout.style.setProperty(
    "--secondaryColor",
    `${COMPANY_INFO_MOCKUP_DATA?.theme?.secondaryColor || "#162029"}`
  );
  pageLayout.style.setProperty(
    "--primaryColor",
    `${COMPANY_INFO_MOCKUP_DATA?.theme?.primaryColor || "#04f73d"}`
  );
};

const prepareCashBalanceMockup = () => {
  const propertyLoanAccounts = [
    ...(CATEGORIZED_ACCOUNTS?.[ACCOUNT_CATEGORY.PROPERTY_LOAN] || []),
    ...(CATEGORIZED_ACCOUNTS?.[ACCOUNT_CATEGORY.PERSONAL_LOAN] || []),
    ...(CATEGORIZED_ACCOUNTS?.[ACCOUNT_CATEGORY.LOAN] || []),
  ];
  const propertyLoanIds =
    propertyLoanAccounts?.map(({ accountId }) => accountId) || [];
  const toDate = new Date();
  const fromDate = sub(toDate, { years: 15 });
  return {
    accountIds: propertyLoanIds,
    fromDate: format(fromDate, "yyyy-MM-dd"),
    toDate: format(toDate, "yyyy-MM-dd"),
    data: { data: [] },
  };
};

const preparePropertyLoanTransaction = () => {
  const propertyLoanAccounts = [
    ...(CATEGORIZED_ACCOUNTS?.[ACCOUNT_CATEGORY.PROPERTY_LOAN] || []),
    ...(CATEGORIZED_ACCOUNTS?.[ACCOUNT_CATEGORY.PERSONAL_LOAN] || []),
    ...(CATEGORIZED_ACCOUNTS?.[ACCOUNT_CATEGORY.LOAN] || []),
  ];
  const propertyLoanIds =
    propertyLoanAccounts?.map(({ accountId }) => accountId) || [];

  const startLastMonthDate = startOfMonth(sub(new Date(), { months: 1 }));
  const endLastMonthDate = endOfMonth(startLastMonthDate);
  return {
    accountIds: propertyLoanIds,
    fromDate: format(startLastMonthDate, "yyyy-MM-dd"),
    toDate: format(endLastMonthDate, "yyyy-MM-dd"),
    data: { data: [] },
  };
};

const prepareBankAccountHistoryMockup = () => {
  const bankAccountList = [
    { accountId: "", isLoanAccount: false, includeTransactions: true },
    { accountId: "", isLoanAccount: false },
  ];
  const bankAccounts = BANK_ACCOUNT_MOCKUP_DATA.data.map((bank) => ({
    accountId: bank.id,
    isLoanAccount: !bank.isAsset,
    balance: bank.balance,
  }));
  const historyBankData = [
    {
      days: 7,
      data: HISTORY_30_DAYS_MOCKUP_DATA,
    },
    { days: 14, data: HISTORY_60_DAYS_MOCKUP_DATA },
    { days: 30, data: HISTORY_120_DAYS_MOCKUP_DATA },
    { days: 730, data: HISTORY_ALL_DAYS_MOCKUP_DATA },
  ];
  const historyLoanData = [
    {
      days: 7,
      data: HISTORY_LOAN_30_DAYS_MOCKUP_DATA,
    },
    { days: 14, data: HISTORY_LOAN_60_DAYS_MOCKUP_DATA },
    { days: 30, data: HISTORY_LOAN_120_DAYS_MOCKUP_DATA },
    { days: 730, data: HISTORY_LOAN_ALL_DAYS_MOCKUP_DATA },
  ];
  const bankAccountHistoryMockup = [
    ...bankAccountList,
    ...bankAccounts,
  ].flatMap((bankAccount) => {
    const historyData = bankAccount.isLoanAccount
      ? historyLoanData
      : historyBankData;
    return historyData.map((history) => {
      const { from: fromDate, to: toDate } = getFromToLastXDays(history.days);
      const accountCashBalance = generateCashBalanceLastXDays(
        history.days,
        bankAccount.isLoanAccount
      );
      const accountTransaction = bankAccount.isLoanAccount
        ? generateLoanTransactionLastXDays(history.days, bankAccount.balance)
        : history.data.transactions;
      return {
        data: {
          ...history.data,
          cashBalances: accountCashBalance,
          transactions: accountTransaction,
        },
        queryData: {
          externalId: "",
          from: format(fromDate, FINANCE_HISTORY_DAY_FORMAT),
          to: format(toDate, FINANCE_HISTORY_DAY_FORMAT),
          accountId: bankAccount.accountId,
          includeTransactions:
            bankAccount?.includeTransactions || Boolean(bankAccount.accountId),
        },
        isLoanAccount: bankAccount.isLoanAccount,
      };
    });
  });
  return bankAccountHistoryMockup;
};

const prepareScenarioHistoryMockup = () => {
  const monthList = getMonthsListLastXDays(365);
  const formattedMonthList = monthList.map((month) => format(month, "yyyy-MM"));
  const buyingPowerHistory = formattedMonthList.reduce(
    (previousValue, month) => {
      return {
        ...previousValue,
        [month]: generateRandomInteger(1000000, 4000000),
      };
    },
    {}
  );

  const maxBuyingPowerList = formattedMonthList.reduce(
    (previousValue, month) => {
      return {
        ...previousValue,
        [month]: generateRandomInteger(1000000, 4000000),
      };
    },
    {}
  );

  return {
    ...SCENARIO_BUYING_POWER_HISTORY_MOCKUP_DATA,
    borrowCapacityList: buyingPowerHistory,
    maxBuyingPowerList,
  };
};

const prepareIncomeExpenseMockup = () => {
  const incomeExpenseRawMockup = [
    {
      data: INCOME_EXPENSE_SUMMARY_BY_MONTH_2_IN_12_MONTHS_MOCKUP_DATA,
      numberOfLookBackMonth: 2,
    },
    {
      data: INCOME_EXPENSE_SUMMARY_BY_MONTH_2_IN_3_MONTHS_MOCKUP_DATA,
      numberOfLookBackMonth: 2,
      months: 1,
    },
    {
      data: INCOME_EXPENSE_SUMMARY_BY_MONTH_3_IN_12_MONTHS_MOCKUP_DATA,
      numberOfLookBackMonth: 3,
    },
  ];
  return incomeExpenseRawMockup.map((financeData) => {
    const toDate = endOfMonth(sub(new Date(), { months: 1 }));
    const fromDate = startOfMonth(
      sub(toDate, {
        months:
          (financeData?.months || new Date().getMonth()) +
          financeData.numberOfLookBackMonth -
          1,
      })
    );
    return {
      ...financeData,
      fromDate: format(fromDate, FINANCE_HISTORY_DAY_FORMAT),
      toDate: format(toDate, FINANCE_HISTORY_DAY_FORMAT),
    };
  });
};

export const setQueryDataForDemo = () => {
  const companyDomain = getcompanyDomain();
  queryClient.setQueryData("jointAccountList", []);
  queryClient.setQueryData("accountList", BANK_ACCOUNT_MOCKUP_DATA);
  queryClient.setQueryData("propertyList", PROPERTIES_MOCKUP_DATA);
  queryClient.setQueryData(
    ["propertyPriceHistory", PROPERTIES_MOCKUP_DATA],
    PROPERTIES_PRICE_HISTORY_MOCKUP_DATA
  );
  queryClient.setQueryData(
    ["propertyNearbyDetail", PROPERTIES_MOCKUP_DATA],
    PROPERTIES_NEARBY_DETAILS_MOCKUP_DATA
  );
  queryClient.setQueryData("propertyList", PROPERTIES_MOCKUP_DATA);
  queryClient.setQueryData("institutionList", INSTITUTION_MOCKUP_DATA);
  queryClient.setQueryData("isLoggedIn", true);
  queryClient.setQueryData("myInfo", USER_INFO_MOCKUP_DATA);
  queryClient.setQueryData(
    ["brokerInfo", USER_INFO_MOCKUP_DATA.staffCode],
    STAFF_INFO_MOCKUP_DATA
  );
  queryClient.setQueryData(
    ["companyInfo", companyDomain],
    COMPANY_INFO_MOCKUP_DATA
  );
  queryClient.setQueryData(
    ["companyInfo", USER_INFO_MOCKUP_DATA.companyId],
    COMPANY_INFO_MOCKUP_DATA
  );

  queryClient.setQueryData(
    ["marketIndices", prepareMarketIndicesParams()],
    PROPERTIES_INDICES_MOCKUP_DATA
  );
  queryClient.setQueryData(
    "institutionAccounts",
    INSTITUTION_ACCOUNT_MOCKUP_DATA
  );
  queryClient.setQueryData(
    ["accountList", INSTITUTION_ACCOUNT_MOCKUP_DATA.data[0].id],
    ACCOUNT_WITH_INSTITUTION_MOCKUP_DATA
  );
  queryClient.setQueryData("staffList", STAFF_LIST_MOCKUP_DATA);
  // Scenario mockup data
  const scenarioHistoryMockup = prepareScenarioHistoryMockup();
  queryClient.setQueryData("scenarioList", SCENARIOS_MOCKUP_DATA);
  queryClient.setQueryData(
    ["scenarioProductList", SCENARIOS_MOCKUP_DATA[0].id],
    PRODUCT_LIST_MOCKUP_DATA
  );
  queryClient.setQueryData(
    ["buyingPowerHistoryByScenario", SCENARIOS_MOCKUP_DATA[0].id],
    scenarioHistoryMockup
  );
  queryClient.setQueryData("serviceProviders", SERVICES_PROVIDER_MOCKUP_DATA);
  // incomesExpensesSummaryByMonth
  const incomeExpenseMockup = prepareIncomeExpenseMockup();
  incomeExpenseMockup.forEach((financeData) => {
    queryClient.setQueryData(
      [
        "incomesExpensesSummaryByMonth",
        financeData.fromDate,
        financeData.toDate,
        financeData.numberOfLookBackMonth,
      ],
      financeData.data
    );
  });
  // bank account history
  const bankAccountHistoryMockup = prepareBankAccountHistoryMockup();
  bankAccountHistoryMockup.forEach((financeData) => {
    queryClient.setQueryData(
      ["financeHistory", financeData.queryData],
      financeData.data
    );
  });
  // cash balance
  const cashBalanceMockup = prepareCashBalanceMockup();
  cashBalanceMockup.accountIds.forEach((account) => {
    queryClient.setQueryData(
      [
        "balanceHistory",
        account,
        cashBalanceMockup.fromDate,
        cashBalanceMockup.toDate,
        "M",
      ],
      cashBalanceMockup.data
    );
  });
  // Property Loan Transaction
  const propertyLoanTransaction = preparePropertyLoanTransaction();
  propertyLoanTransaction.accountIds.forEach((account) => {
    queryClient.setQueryData(
      [
        "transactions",
        {
          accountId: account,
          direction: "credit",
          limit: undefined,
          keyword: "payment",
          from: propertyLoanTransaction.fromDate,
          to: propertyLoanTransaction.toDate,
        },
      ],
      cashBalanceMockup.data
    );
  });
};
