import { getHostName } from "../api/axiosClient";

export const navigateToDomainWithLocalStorageData = (
  domain,
  localStorageData
) => {
  const isDevelopmentEnv =
    process.env.ACTIVE_ENV === "development" ||
    process.env.NODE_ENV === "development";
  const subdomainURL = `http://${domain}.app.${getHostName()}${
    isDevelopmentEnv ? ":8001" : ""
  }/?data=${encodeURIComponent(JSON.stringify(localStorageData))}`;
  // Maintaining authentication information

  window.location.href = subdomainURL;
  localStorage.clear();
};
