import axiosClient from "./axiosClient";

export const createJointAccount = (params) => {
  const url = "/joint-account";
  return axiosClient.post(url, params);
};

export const getJointAccount = () => {
  const url = "/joint-account/all";
  return axiosClient.get(url);
};

export const getJointAccountConnection = (id) => {
  const url = `/joint-account/connection/${id}`;
  return axiosClient.get(url);
};

export const getJointAccountAggregationData = (id) => {
  const url = `/joint-account/${id}/aggregationData`;
  return axiosClient.get(url);
};
export const getJointAccountPropertiesPriceHistory = (id) => {
  const url = `/joint-account/${id}/propertiesPriceHistory`;
  return axiosClient.get(url);
};
export const getJointAccountBankAccountHistory = (id, params) => {
  const url = `/joint-account/${id}/accountHistory`;
  return axiosClient.get(url, { params });
};

export const getJointAccountBalanceHistory = (id, params) => {
  const url = `/joint-account/${id}/balanceHistory`;
  return axiosClient.get(url, { params });
};
export const getJointAccountDebtTransaction = (id, params) => {
  const url = `/joint-account/${id}/debtTransaction`;
  return axiosClient.get(url, { params });
};

export const updateJointAccount = (id, params) => {
  const url = `/joint-account/${id}`;
  return axiosClient.put(url, params);
};

export const inviteMemberToJointAccount = (id, params) => {
  const url = `/joint-account/${id}/invite`;
  return axiosClient.post(url, params);
};

export const validateInvitationToken = (params) => {
  const url = `/joint-account/validate-token`;
  return axiosClient.get(url, params);
};

export const acceptJointAccountInvitation = (params) => {
  const url = `/joint-account/accept-invitation`;
  return axiosClient.post(url, params);
};

export const deleteJointAccount = (id) => {
  const url = `/joint-account/${id}`;
  return axiosClient.delete(url);
};
export const deleteJointAccountConnection = (id) => {
  const url = `/joint-account/connection/${id}`;
  return axiosClient.delete(url);
};
export const updateJointAccountConnection = (id, params) => {
  const url = `/joint-account/connection/${id}`;
  return axiosClient.put(url, params);
};
