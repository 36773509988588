import { UpdateUserInfo, GetUserInfo } from "../types/user.types";
import axiosClient from "./axiosClient";

const refreshToken = () => {
  const url = "/users/refresh";
  return axiosClient.post(url);
};

const deleteUser = () => {
  const url = "/users/deleteAccount";
  return axiosClient.delete(url);
};

const getUserToken = () => {
  const url = "/users/index";
  return axiosClient.get(url);
};

export const getUserConfirmation = (confirmationId) => {
  const url = "/user-confirmation/" + confirmationId;
  return axiosClient.get(url);
};

export const updateUserConfirmation = (confirmationId, params) => {
  const url = "/user-confirmation/" + confirmationId;
  return axiosClient.post(url, params);
};

export const updateUserAssociate = (
  id: string,
  params: Record<string, unknown>
) => {
  return axiosClient.put(`/user-associate/${id}`, params);
};

const sentInvitation = (params) => {
  const url = "/users/invitation";
  return axiosClient.post(url, params);
};

const updateContact = () => {
  const url = "/users/updateContact";
  return axiosClient.post(url);
};

const getNameByReferral = (referralPath, referralCode) => {
  const url = `/referral/${referralPath}/${referralCode}`;
  return axiosClient.get(url);
};

const changeReferralURL = (referralPath, referralCode) => {
  const url = `/referral/${referralCode}`;
  return axiosClient.get(url, referralPath);
};

const preLoginApi = (params) => {
  const url = "/users/preLogin";
  return axiosClient.post(url, params);
};

const connectUserApi = () => {
  const url = "/users/connect";
  return axiosClient.post(url);
};

const reconnectUserApi = () => {
  const url = "/users/reconnect";
  return axiosClient.post(url);
};

const createUserApi = (params) => {
  const url = "/users/register";
  return axiosClient.post(url, params);
};

const confirmUserForgotPasswordOnProvider = (params) => {
  const url = "/users/forgotPassword";
  return axiosClient.post(url, params);
};

const getMeApi = () => {
  const url = "/users/me";
  return axiosClient.get<any, GetUserInfo>(url);
};

const putMeApi = (params) => {
  const url = "/users/me";
  return axiosClient.put<any, UpdateUserInfo>(url, params);
};
const changePasswordApi = (params) => {
  const url = "/users/me/password";
  return axiosClient.put(url, params);
};

const resentEmailVerificationApi = (params) => {
  const url = "/users/regenerateEmailVerification";
  return axiosClient.post(url, params);
};
const fotgotPasswordApi = (params) => {
  const url = "/users/requestRecoverPassword";
  return axiosClient.post(url, params);
};
const checkRecoveryPasswordCode = (params) => {
  const url = "/users/checkRecoveryPasswordCode";
  return axiosClient.get(url, params);
};
const newPasswordRecoveryApi = (params) => {
  const url = "/users/inputNewPasswordRecovery";
  return axiosClient.post(url, params);
};
const generateSMSVerification = (id, email) => {
  const url = `/users/generateSMSVerification/${id}/${email}`;
  return axiosClient.get(url);
};
const validateEmail = (id) => {
  const url = `/users/validate/email/${id}`;
  return axiosClient.get(url);
};
const validatePhoneNumber = (id) => {
  const url = `/users/validate/phoneNumber/${id}`;
  return axiosClient.get(url);
};

const resendSMSVerification = (phoneNumber, email) => {
  const url = `/users/regenerateSMSVerification/${phoneNumber}/${email}`;
  return axiosClient.get(url);
};

const verifyPhoneNumber = (codeId, id, email) => {
  const url = `/users/verifyPhoneNumber/${codeId}/${id}/${email}`;
  return axiosClient.get(url);
};

export {
  deleteUser,
  checkRecoveryPasswordCode,
  changeReferralURL,
  resendSMSVerification,
  updateContact,
  sentInvitation,
  refreshToken,
  getUserToken,
  getNameByReferral,
  verifyPhoneNumber,
  validateEmail,
  validatePhoneNumber,
  generateSMSVerification,
  fotgotPasswordApi,
  newPasswordRecoveryApi,
  putMeApi,
  changePasswordApi,
  getMeApi,
  preLoginApi,
  connectUserApi,
  createUserApi,
  reconnectUserApi,
  resentEmailVerificationApi,
  confirmUserForgotPasswordOnProvider,
};
