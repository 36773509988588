import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 41.6 34.5"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.prefix__st0{stroke-linejoin:round}.prefix__st0,.prefix__st1,.prefix__st2{fill:none;stroke:#131c24;stroke-width:3;stroke-linecap:round}.prefix__st2{stroke:${primaryColor};stroke-linejoin:round;stroke-miterlimit:10}`}
      </style>
      <g id="prefix__Group_6153" transform="translate(-857.22 -491.717)">
        <g id="prefix__Group_5267" transform="translate(832.084 192.283)">
          <g id="prefix__Group_5263" transform="translate(26.636 301.555)">
            <path
              id="prefix__Path_1432"
              className="prefix__st0"
              d="M0 18.5V7.6h32l-7.3 8"
            />
            <path
              id="prefix__Path_1433"
              className="prefix__st0"
              d="M23.9 0l8 7.6"
            />
          </g>
          <g id="prefix__Group_5264" transform="rotate(180 32.598 165.895)">
            <path
              id="prefix__Path_1432-2"
              className="prefix__st2"
              d="M0 18.5V7.6h32l-7.3 8"
            />
            <path
              id="prefix__Path_1433-2"
              className="prefix__st2"
              d="M23.9 0l8 7.6"
            />
          </g>
          <g id="prefix__Group_5265" transform="rotate(180 30.641 165.243)">
            <path
              id="prefix__Path_1432-3"
              className="prefix__st0"
              d="M0 18.5V7.6h32l-7.3 8"
            />
            <path
              id="prefix__Path_1433-3"
              className="prefix__st0"
              d="M23.9 0l8 7.6"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
