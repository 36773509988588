/* eslint-disable no-param-reassign */
import { endOfMonth, startOfMonth, sub } from "date-fns";
import isNil from "lodash/isNil";
import { useContext, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import {
  deleteProperty,
  getProperty,
  getPropertyIncides,
  getPropertyNearbyDetail,
  getPropertyPriceHistory,
  postProperty,
  putProperty,
  searchProperty,
} from "../api/property";
import { ACCOUNT_CATEGORY, EVENT_TRACKING } from "../commons/enum";
import { calculateTotalMarketIndices } from "../components/Layout/calculate-total-market-indices";
import {
  processingEstimateHistory,
  processingNearbyImageResult,
} from "../components/Layout/get-property";
import JointAccountContext from "../context/joint-account-context";
import {
  calculatePropertyDebtList,
  calculatePropertyEstimateHistory,
  calculateTotalPropertyDebtData,
} from "../utils/calculate-property-data-util";
import { getCurrentMonthWithDay } from "../utils/calendar-util";
import { trackingGoogleTagEvent } from "../utils/google-tag-manager-tracking";
import { by } from "../utils/object-util";
import { useBalanceHistories, useLinkedBankAccountData } from "./account.hooks";
import {
  useSelectedJointAccountAggregationData,
  useSelectedJointAccountPropertiesPriceHistory,
} from "./joint-account.hooks";
import { useTransactions } from "./transaction.hooks";
import { useIsLoggedIn, useMyInfo } from "./user.hooks.ts";
import { useQueryInvalidationWithNotification } from "./util.hooks";

export const useIndividualPropertyList = () => {
  const isLoggedIn = useIsLoggedIn();
  return useQuery(["propertyList"], getProperty, {
    enabled: isLoggedIn,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const usePropertyList = () => {
  const { selectedJointAccount } = useContext(JointAccountContext);
  const { data: jointAccountData } = useSelectedJointAccountAggregationData(
    selectedJointAccount
  );
  const individualPropertyList = useIndividualPropertyList();
  if (selectedJointAccount) {
    return { data: jointAccountData?.properties };
  }
  return individualPropertyList;
};

export const useIndividualPropertyPriceHistory = () => {
  const { selectedJointAccount } = useContext(JointAccountContext);
  const { data: propertyList } = usePropertyList();
  const queryKey = ["propertyPriceHistory", propertyList];
  return useQuery(queryKey, getPropertyPriceHistory, {
    enabled:
      !selectedJointAccount && !!(propertyList && propertyList.length > 0),
  });
};

export const usePropertyPriceHistory = () => {
  const { selectedJointAccount } = useContext(JointAccountContext);
  const {
    data: jointAccountData,
  } = useSelectedJointAccountPropertiesPriceHistory(selectedJointAccount);
  const individualPropertyList = useIndividualPropertyPriceHistory();
  if (selectedJointAccount) {
    return { data: jointAccountData };
  }
  return individualPropertyList;
};

export const usePropertyNearbyDetail = () => {
  const { data: propertyList } = usePropertyList();
  const queryKey = ["propertyNearbyDetail", propertyList];
  return useQuery(queryKey, getPropertyNearbyDetail, {
    enabled: !!(propertyList && propertyList.length > 0),
  });
};

export const useDetailPropertyList = () => {
  const { data: propertyList } = usePropertyList();
  const { data: propertyPriceHistory } = usePropertyPriceHistory();
  const { data: propertyNearbyDetail } = usePropertyNearbyDetail();

  return useMemo(() => {
    let detailPropertyList = propertyList;

    if (propertyPriceHistory) {
      detailPropertyList = processingEstimateHistory(
        detailPropertyList,
        propertyPriceHistory
      );
    }
    if (propertyNearbyDetail) {
      detailPropertyList = processingNearbyImageResult(
        detailPropertyList,
        propertyNearbyDetail
      );
    }

    return detailPropertyList;
  }, [propertyList, propertyPriceHistory, propertyNearbyDetail]);
};

export const useMarketIndices = (maximumYear = 15) => {
  const { data: propertyList } = usePropertyList();
  const propertyIndicesParams = propertyList?.reduce(
    (result, item) => {
      const { addressDetails = {}, propertyType = "" } = item;
      const { locality = "", postcode = "" } = addressDetails;
      const level = `${locality}-${postcode}`;
      result = {
        ...result,
        propertyType: [...new Set([...result.propertyType, propertyType])],
      };
      result = { ...result, level: [...new Set([...result.level, level])] };
      return result;
    },
    {
      region: "Locality",
      interval: "monthly",
      start: getCurrentMonthWithDay(maximumYear),
      end: getCurrentMonthWithDay(),
      propertyType: [],
      stat: ["price", "marketscore"],
      level: [],
    }
  );
  const queryParams = {
    params: propertyIndicesParams,
  };

  return useQuery(
    ["marketIndices", propertyIndicesParams],
    () => {
      return getPropertyIncides(queryParams);
    },
    {
      enabled: !!(propertyList && propertyList.length > 0),
    }
  );
};

export const useTotalMarketIndices = () => {
  const { data: propertyList } = usePropertyList();
  const { data: marketIndices } = useMarketIndices();
  return useMemo(() => {
    const [update, saleHistoryChart] = calculateTotalMarketIndices(
      marketIndices,
      propertyList
    );
    if (update && propertyList?.length > 0) {
      return saleHistoryChart;
    }
    return [];
  }, [propertyList, marketIndices]);
};

export const usePropertyDebtList = () => {
  const propertyList = useDetailPropertyList();
  const categorizedAccounts = useLinkedBankAccountData();
  const propertyLoanAccounts = useMemo(() => {
    return [
      ...(categorizedAccounts?.[ACCOUNT_CATEGORY.PROPERTY_LOAN] || []),
      ...(categorizedAccounts?.[ACCOUNT_CATEGORY.PERSONAL_LOAN] || []),
      ...(categorizedAccounts?.[ACCOUNT_CATEGORY.LOAN] || []),
    ];
  }, [categorizedAccounts]);
  const propertyLoanIds =
    [...new Set(propertyLoanAccounts)]?.map(({ accountId }) => accountId) || [];
  const toDate = new Date();
  const fromDate = sub(toDate, { years: 15 });
  const balanceHistoryQueries = useBalanceHistories({
    accountIds: propertyLoanIds,
    from: fromDate,
    to: toDate,
  });
  const balanceHistories = useMemo(
    () =>
      balanceHistoryQueries?.filter(({ data }) => !isNil(data)).map(by("data")),
    [balanceHistoryQueries]
  );

  const startLastMonthDate = startOfMonth(sub(toDate, { months: 1 }));
  const endLastMonthDate = endOfMonth(startLastMonthDate);
  const propertyLoanPaymentTransactionQueries = useTransactions({
    accountIds: propertyLoanIds,
    direction: "credit",
    from: startLastMonthDate,
    to: endLastMonthDate,
    keyword: "payment",
  });
  const propertyLoanPaymentTransactions = useMemo(
    () =>
      propertyLoanPaymentTransactionQueries
        ?.filter(({ data }) => !isNil(data))
        .map(by("data")),
    [propertyLoanPaymentTransactionQueries]
  );

  return useMemo(
    () =>
      propertyList?.length > 0
        ? calculatePropertyDebtList(
            propertyList,
            propertyLoanAccounts,
            balanceHistories,
            propertyLoanPaymentTransactions
          )
        : [],
    [
      propertyList,
      propertyLoanAccounts,
      balanceHistories,
      propertyLoanPaymentTransactions,
    ]
  );
};

export const useAddPropertyMutation = () => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  const { data: userInfo } = useMyInfo();
  return useMutation(
    (data) => {
      return postProperty(data);
    },
    {
      onSuccess: (property) => {
        const {
          addressDetails: { address, locality, state, postcode },
          estimate,
        } = property;
        const propertyConversion = {
          property: {
            street_address: address,
            suburb: locality,
            city: state,
            zip: postcode,
            valuation: estimate,
          },
        };
        trackingGoogleTagEvent(
          EVENT_TRACKING.PROPERTY_VALUATION,
          userInfo,
          propertyConversion
        );
        invalidateQueries("propertyList");
      },
    }
  );
};

export const useUpdatePropertyMutation = () => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useMutation(
    (data) => {
      const { id, params } = data;
      return putProperty(params, id);
    },
    {
      onSuccess: () => {
        invalidateQueries("propertyList");
      },
    }
  );
};
export const useDeletePropertyMutation = () => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useMutation(deleteProperty, {
    onSuccess: () => {
      invalidateQueries("propertyList");
    },
  });
};

export const useSearchAddressMutation = () => {
  return useMutation((data) => {
    return searchProperty(data);
  });
};

export const useTotalPropertyDebtData = () => {
  const propertyDebtList = usePropertyDebtList();
  return useMemo(() => calculateTotalPropertyDebtData(propertyDebtList), [
    propertyDebtList,
  ]);
};

export const useTotalPropertyDetail = () => {
  const propertyList = useDetailPropertyList();
  return useMemo(() => calculatePropertyEstimateHistory(propertyList), [
    propertyList,
  ]);
};
