/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

export const getAllValueLocationStorage = () => {
  const localStorageData = {};
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    const value = localStorage.getItem(key);
    localStorageData[key] = value;
  }
  return localStorageData;
};
