import get from "lodash/get";

const isNotEmptyObject = (value) => {
  return !(
    value &&
    Object.keys(value).length === 0 &&
    value.constructor === Object
  );
};

/**
 * Create a property picker for an object
 * @param {string, array} path
 * @param {any} defaultValue
 * @returns picker
 */
const by = (path, defaultValue) => (object) => get(object, path, defaultValue);

export { isNotEmptyObject, by };
