import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={33.277}
      height={28.48}
      viewBox="0 0 33.277 28.48"
      {...props}
    >
      <g data-name="Group 6153">
        <g data-name="Group 6121">
          <path
            data-name="Path 2287"
            d="M11.194 21.501s-4.209 1.469-6.941 0a5.518 5.518 0 01-3.217-6.064c.236-2.682 2.235-5.3 5.038-5.262 1.494-.959-1.74-9.393 10.752-9.172s9.135 8.729 9.135 8.729 4.135-.881 5.514 2.063c.535 1.135 1.61 6.355-.229 8.395-2.039 2.275-7.654 1.313-7.654 1.313"
            fill="none"
            stroke={primaryColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
        </g>
        <g
          data-name="Group 6117"
          fill="none"
          stroke={primaryColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path data-name="Path 2284" d="M17.477 27.48v-17l-6.552 4.679" />
          <path data-name="Path 2285" d="M17.477 27.48v-17l6.555 4.679" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
