import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import React, { useContext, useState } from "react";
import { useJointAccountList } from "../../../hooks/joint-account.hooks";
import { Swap } from "../../../svgs";
import JointAccountContext from "../../../context/joint-account-context";

const SwitchAccount = () => {
  const { data: jointAccounts = [] } = useJointAccountList();
  const [isExpanded, setIsExpanded] = useState(false);
  const { selectedJointAccount, setSelectedJointAccount } = useContext(
    JointAccountContext
  );
  const handleSelectAccount = (id) => {
    setSelectedJointAccount(id);
  };
  return (
    <Accordion
      className="switch-account"
      square
      expanded={isExpanded}
      onChange={() => {
        setIsExpanded(!isExpanded);
      }}
    >
      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
        <div className="ml-avatar-name">
          <div className="ml-items-body">
            <div className="ml-icon-container">
              <Swap />
            </div>
            <div className="ml-transaction-value">Accounts</div>
            <ExpandMoreIcon type="expandIcon" />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className="switch-account__item-container">
        {[{ id: "", name: "My Account" }, ...jointAccounts].map(
          (jointAccount) => (
            <div
              className={classNames("switch-account-item", {
                selected: selectedJointAccount === jointAccount.id,
              })}
              onClick={() => handleSelectAccount(jointAccount.id)}
              key={jointAccount.id}
            >
              {jointAccount.name}
            </div>
          )
        )}
      </AccordionDetails>
    </Accordion>
  );
};

SwitchAccount.propTypes = {};

export default SwitchAccount;
