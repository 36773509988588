import * as React from "react";

function SharingReferral(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13.135}
      height={17.197}
      viewBox="0 0 13.135 17.197"
      {...props}
    >
      <g data-name="Group 3726">
        <path
          data-name="Path 1241"
          d="M0 1.052v14.363h2.85L6.43 1.052z"
          className="icon-fill"
        />
        <path
          data-name="Path 1240"
          d="M10.736 16.448h1.648V7.604l-2.652-3.24V.751H1.019v14.436h8.713v-2.6L5.744 8.731 7.48 7.12l2.074 2.15 1.182 1.227"
          fill="none"
          stroke="#fff"
          strokeWidth={1.5}
        />
        <path
          data-name="Line 23"
          fill="none"
          stroke="#fff"
          strokeWidth={1.5}
          d="M9.983 4.543v4.784"
        />
      </g>
    </svg>
  );
}

export default SharingReferral;
