import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AtmIcon from "@material-ui/icons/Atm";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import BuildIcon from "@material-ui/icons/Build";
import BusinessIcon from "@material-ui/icons/Business";
import ChildFriendlyIcon from "@material-ui/icons/ChildFriendly";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import FlightIcon from "@material-ui/icons/Flight";
import HeadsetIcon from "@material-ui/icons/Headset";
import KingBedIcon from "@material-ui/icons/KingBed";
import LanguageIcon from "@material-ui/icons/Language";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoreIcon from "@material-ui/icons/More";
import PetsIcon from "@material-ui/icons/Pets";
import PhoneIcon from "@material-ui/icons/Phone";
import ReceiptIcon from "@material-ui/icons/Receipt";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import SatelliteIcon from "@material-ui/icons/Satellite";
import SchoolIcon from "@material-ui/icons/School";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import WorkIcon from "@material-ui/icons/Work";
import React from "react";
import NewsPaper from "../svgs/NewsPaper";
import NightLife from "../svgs/NightLife";
import SelfImprovement from "../svgs/SelfImprovement";
import Surfing from "../svgs/Surfing";
import Volunteer from "../svgs/Volunteer";
import {
  getLastMonths,
  monthFormater as monthFormatter,
} from "./calendar-util";
import { convertTextToKebabCase } from "./text-format";

export const getByPathOrDefault = (obj = {}, keyPath = [], defaultVal) => {
  let result = obj;

  for (let i = 0; i < keyPath.length; i += 1) {
    result = result[keyPath[i]];
    if (!result) {
      return defaultVal;
    }
  }

  return result || defaultVal;
};

export const sumArray = (arr) => arr.reduce((x, y) => x + y, 0);
export const sumArrays = (...arrays) => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) =>
    arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0)
  );
};

export const getDataLast3Months = (data) => {
  if (data) {
    const { changeHistory = [] } = data;
    return changeHistory.reduce((result, item) => {
      if (
        getLastMonths(new Date(), 3).includes(
          monthFormatter(new Date(item.date))
        )
      ) {
        return result + parseInt(item.amount, 10);
      }
      return result;
    }, 0);
  }
  return 0;
};

const getExpensesCategoryType = (category) => {
  switch (convertTextToKebabCase(category)) {
    case "automotive-expenses":
    case "asoline-fuel":
      return "Automotive Expenses";
    case "advertising":
    case "business-miscellaneous":
    case "office-maintenance":
    case "office-supplies":
    case "online-services":
    case "postage-shipping":
    case "printing":
      return "Business Services";
    case "education":
      return "Education Expenses";
    case "groceries":
    case "restaurants-dining":
      return "Food Expenses";
    case "charitable-giving":
      return "Giving";
    case "healthcare-medical":
      return "Healthcare";
    case "cable-satellite-services":
    case "home-improvement":
    case "home-maintenance":
    case "loans":
    case "mortgages":
    case "rent":
    case "telephone-services":
    case "utilities":
      return "Home Expenses";

    case "insurance":
      return "Insurance";

    case "atm-cash-withdrawals":
    case "checks":
    case "other-bill":
    case "other-expenses":
    case "service-charges-fees":
    case "wages-paid":
      return "Miscellaneous Expenses";

    case "child-dependent-expenses":
    case "clothing-shoes":
    case "dues-and-subscriptions":
    case "electronics":
    case "entertainment":
    case "general-merchandise":
    case "gifts":
    case "hobbies":
    case "personal-care":
    case "pet-care":
      return "Personal & Family";

    case "retirement-contributions":
      return "Retirement Savings";

    case "taxes":
      return "Taxes";
    case "travel":
      return "Travel";

    default:
      return "Other";
  }
};

export const getExpensesCategoryColor = (category) => {
  switch (getExpensesCategoryType(category)) {
    case "Automotive Expenses":
      return "#ff5a5a";
    case "Business Services":
      return "#5affec";
    case "Education Expenses":
      return "#5aff8d";
    case "Food Expenses":
      return "#c65aff";
    case "Giving":
      return "#5a67ff";
    case "Healthcare":
      return "#ff5a5a";
    case "Home Expenses":
      return "#5affec";
    case "Insurance":
      return "#ff5a5a";
    case "Miscellaneous Expenses":
      return "#be655c";
    case "Personal & Family":
      return "#ECFF5A";
    case "Retirement Savings":
      return "#803cff";
    case "Taxes":
      return "#264e76";
    case "Travel":
      return "#5390ce";
    default:
      return "#A0A3A7";
  }
};

export const getExpensesCategoryIcon = (category) => {
  switch (convertTextToKebabCase(category)) {
    case "automotive-expenses":
      return <DirectionsCarIcon />;
    case "asoline-fuel":
      return <LocalGasStationIcon />;
    case "advertising":
      return <NewsPaper />;
    case "business-miscellaneous":
      return <WorkIcon />;
    case "office-maintenance":
      return <BusinessIcon />;
    case "office-supplies":
      return <LocalPrintshopIcon />;
    case "online-services":
      return <LanguageIcon />;
    case "postage-shipping":
      return <LocalShippingIcon />;
    case "printing":
      return <LocalPrintshopIcon />;
    case "education":
      return <SchoolIcon />;
    case "groceries":
      return <LocalGroceryStoreIcon />;
    case "restaurants-dining":
      return <RestaurantIcon />;
    case "charitable-giving":
      return <Volunteer />;
    case "healthcare-medical":
      return <LocalHospitalIcon />;
    case "cable-satellite-services":
      return <SatelliteIcon />;
    case "home-improvement":
      return <BuildIcon />;
    case "home-maintenance":
      return <BuildIcon />;
    case "loans":
      return <AccountBalanceWalletIcon />;
    case "mortgages":
      return <AccountBalanceWalletIcon />;
    case "rent":
      return <KingBedIcon />;
    case "telephone-services":
      return <PhoneIcon />;
    case "utilities":
      return <WbIncandescentIcon />;

    case "insurance":
      return <VerifiedUserIcon />;

    case "atm-cash-withdrawals":
      return <AtmIcon />;
    case "checks":
      return <LocalAtmIcon />;
    case "other-bill":
      return <ReceiptIcon />;
    case "other-expenses":
      return <ReceiptIcon />;
    case "service-charges-fees":
      return <MonetizationOnIcon />;
    case "wages-paid":
      return <AccountBalanceWalletIcon />;

    case "child-dependent-expenses":
      return <ChildFriendlyIcon />;
    case "clothing-shoes":
      return <LocalMallIcon />;
    case "dues-and-subscriptions":
      return <SubscriptionsIcon />;
    case "electronics":
      return <HeadsetIcon />;
    case "entertainment":
      return <NightLife />;
    case "general-merchandise":
    case "gifts":
      return <ShoppingBasketIcon />;
    case "hobbies":
      return <Surfing />;
    case "personal-care":
      return <SelfImprovement />;
    case "pet-care":
      return <PetsIcon />;

    case "retirement-contributions":
      return <BeachAccessIcon />;

    case "taxes":
      return <AccountBalanceIcon />;
    case "travel":
      return <FlightIcon />;

    default:
      return <MoreIcon />;
  }
};

export const expensesCategoryChartColor = (chartData = []) => {
  return chartData.map((chartItem) =>
    getExpensesCategoryColor(chartItem.category)
  );
};
export const expensesCategoryChartIcon = (chartData = []) => {
  return chartData.map((chartItem) =>
    getExpensesCategoryIcon(chartItem.category)
  );
};

export const toTop5ExpenseChartDataAndOther = (chart = []) => {
  const sortedData = chart.sort(
    ({ amount: amount1 }, { amount: amount2 }) => amount1 - amount2
  );
  const top5ChartData = sortedData.slice(0, 5);
  const otherChartData = sortedData.slice(5).reduce((result, item) => {
    return result + item.amount;
  }, 0);
  if (otherChartData) {
    return [
      ...top5ChartData,
      { category: "All Other", amount: otherChartData },
    ];
  }
  return top5ChartData;
};
