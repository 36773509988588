import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const MenuLeftItem = ({ title = "", icon, checked = false, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={classNames("ml-items-body", {
        selected: checked,
      })}
    >
      <div className="ml-icon-container">{icon}</div>
      <div className="ml-transaction-value">{title}</div>
    </div>
  );
};

MenuLeftItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  checked: PropTypes.bool,
};

export default MenuLeftItem;
