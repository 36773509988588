import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 17.2 17.2" {...props}>
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          className="icon-fill"
          d="M10.8.2H9.4l-.2 1.1c-.5.1-.9.2-1.3.4L7.1.8c-.4.2-.8.5-1.2.7l.4 1.1c-.3.3-.7.6-.9 1l-1.2-.4c-.2.4-.5.8-.7 1.3l.8.8c-.1.4-.2.8-.3 1.3l-1.1.2v1.4l1.1.3c.1.5.2.9.3 1.3l-.8.8c.2.4.5.8.7 1.2l1.1-.4c.3.4.6.7.9 1l-.4 1.1c.4.3.8.5 1.2.7l.8-.9c.4.2.9.3 1.3.4l.2 1.1h1.4l.2-1.1c.4-.1.9-.2 1.3-.4l.8.9c.4-.2.8-.4 1.2-.7l-.4-1.1c.3-.3.7-.6.9-1l1.1.4c.3-.4.5-.8.7-1.2l-.8-.8c.2-.4.3-.9.3-1.3l1.1-.2V6.9L16 6.7c-.1-.5-.2-.9-.3-1.3l.8-.8c-.2-.4-.4-.8-.7-1.2l-1.1.4c-.3-.4-.6-.7-.9-1l.4-1.1c-.3-.4-.7-.7-1.2-.9l-.8.9c-.4-.2-.9-.3-1.3-.4L10.8.2z"
        />
        <path
          d="M9.1.2H7.5l-.3 1.3c-.5.1-1 .2-1.5.4l-.9-1c-.5.3-.9.5-1.4.8L3.9 3c-.4.3-.8.7-1.1 1.1l-1.3-.4c-.3.4-.5.9-.8 1.4l1 .9c-.2.5-.3 1-.4 1.5L0 7.8v1.6l1.3.3c.1.5.2 1 .4 1.5l-1 .9c.2.5.5 1 .8 1.4l1.2-.4c.3.4.7.8 1.1 1.1l-.4 1.3c.4.3.9.6 1.4.8l.9-1c.5.2 1 .3 1.5.4l.3 1.3c.5.1 1.1.1 1.6 0l.3-1.3c.5-.1 1-.2 1.5-.4l.9 1c.5-.2.9-.5 1.4-.8l-.4-1.3c.4-.3.8-.7 1.1-1.1l1.2.4c.3-.4.6-.9.8-1.4l-1-.9c.2-.5.3-1 .4-1.5l1.3-.3c.1-.5.1-1.1 0-1.6l-1.3-.3c-.2-.5-.3-1-.5-1.5l1-.9c-.2-.5-.5-1-.8-1.4l-1.2.4c-.4-.4-.8-.7-1.2-1.1l.4-1.3c-.4-.3-.9-.6-1.4-.8l-.9 1c-.5-.2-1-.3-1.5-.4L9.1.2zm-.8 2.9c3 0 5.4 2.5 5.3 5.5 0 3-2.4 5.4-5.4 5.5-3 0-5.4-2.5-5.3-5.5 0-3 2.4-5.4 5.4-5.5z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
