export const STAFF_INFO_MOCKUP_DATA = {
  name: "Hau",
  introductionVideoUrl: "",
  staffCode: "AAAAAA",
  contact: null,
  email: "kmsfinancial@hautest.vn",
  phoneNumber: "0902880001",
  calendlyUrl: "",
  imageUrl: "",
  bottomImageUrl: null,
  staffRoles: [
    {
      id: "6c8e2698-462f-4974-b862-25828e494940",
      staffId: "1f686688-a998-4269-957d-c78d59d11dd8",
      role: "WEALTH_PLANNER",
      companyId: "7729586b-6e41-4ba5-be01-a4880e7796e1",
      createdAt: "2022-09-19T17:32:03.000Z",
      updatedAt: "2022-09-19T17:32:03.000Z",
    },
    {
      id: "d76b73cb-f1c6-4c8b-bc25-14b9189df0ed",
      staffId: "1f686688-a998-4269-957d-c78d59d11dd8",
      role: "COMPANY_ADMIN",
      companyId: "7729586b-6e41-4ba5-be01-a4880e7796e1",
      createdAt: "2022-09-19T17:32:03.000Z",
      updatedAt: "2022-09-19T17:32:03.000Z",
    },
  ],
};
