import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function Calendar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.269"
      height="36.043"
      viewBox="0 0 36.269 36.043"
      {...props}
    >
      <g id="Group_6134" data-name="Group 6134" transform="translate(0 0.5)">
        <g
          id="Rectangle_1644"
          data-name="Rectangle 1644"
          transform="translate(0 2.176)"
          fill="none"
          strokeWidth="1"
        >
          <rect
            x="0.5"
            y="0.5"
            width="35.269"
            height="32.367"
            rx="1.5"
            strokeWidth="1.25"
          />
        </g>
        <g
          id="Group_6128"
          data-name="Group 6128"
          transform="translate(4.352 13.782)"
        >
          <rect
            id="Rectangle_1645"
            data-name="Rectangle 1645"
            width="7.254"
            height="7.254"
            rx="1"
            transform="translate(1.451 1.451)"
            fill={primaryColor}
            opacity="0.4"
          />
          <g id="Rectangle_1647" data-name="Rectangle 1647" fill="none">
            <rect
              x="0.5"
              y="0.5"
              width="6.254"
              height="6.254"
              rx="0.5"
              strokeWidth="1.25"
            />
          </g>
        </g>
        <g
          id="Group_6129"
          data-name="Group 6129"
          transform="translate(14.508 13.782)"
        >
          <rect
            id="Rectangle_1645-2"
            data-name="Rectangle 1645"
            width="7.254"
            height="7.254"
            rx="1"
            transform="translate(1.451 1.451)"
            fill={primaryColor}
            opacity="0.4"
          />
          <g id="Rectangle_1647-2" data-name="Rectangle 1647" fill="none">
            <rect
              x="0.5"
              y="0.5"
              width="6.254"
              height="6.254"
              rx="0.5"
              strokeWidth="1.25"
            />
          </g>
        </g>
        <g
          id="Group_6130"
          data-name="Group 6130"
          transform="translate(24.663 13.782)"
        >
          <rect
            id="Rectangle_1645-3"
            data-name="Rectangle 1645"
            width="7.254"
            height="7.254"
            rx="1"
            transform="translate(1.451 1.451)"
            fill={primaryColor}
            opacity="0.4"
          />
          <g id="Rectangle_1647-3" data-name="Rectangle 1647" fill="none">
            <rect
              x="0.5"
              y="0.5"
              width="6.254"
              height="6.254"
              rx="0.5"
              strokeWidth="1.25"
            />
          </g>
        </g>
        <g
          id="Group_6131"
          data-name="Group 6131"
          transform="translate(24.663 23.937)"
        >
          <rect
            id="Rectangle_1645-4"
            data-name="Rectangle 1645"
            width="7.254"
            height="7.254"
            rx="1"
            transform="translate(1.451 1.451)"
            fill={primaryColor}
            opacity="0.4"
          />
          <g id="Rectangle_1647-4" data-name="Rectangle 1647" fill="none">
            <rect
              x="0.5"
              y="0.5"
              width="6.254"
              height="6.254"
              rx="0.5"
              strokeWidth="1.25"
            />
          </g>
        </g>
        <g
          id="Group_6132"
          data-name="Group 6132"
          transform="translate(14.508 23.937)"
        >
          <rect
            id="Rectangle_1645-5"
            data-name="Rectangle 1645"
            width="7.254"
            height="7.254"
            rx="1"
            transform="translate(1.451 1.451)"
            fill={primaryColor}
            opacity="0.4"
          />
          <g id="Rectangle_1647-5" data-name="Rectangle 1647" fill="none">
            <rect
              x="0.5"
              y="0.5"
              width="6.254"
              height="6.254"
              rx="0.5"
              strokeWidth="1.25"
            />
          </g>
        </g>
        <g
          id="Group_6133"
          data-name="Group 6133"
          transform="translate(4.352 23.937)"
        >
          <rect
            id="Rectangle_1645-6"
            data-name="Rectangle 1645"
            width="7.254"
            height="7.254"
            rx="1"
            transform="translate(1.451 1.451)"
            fill={primaryColor}
            opacity="0.4"
          />
          <g id="Rectangle_1647-6" data-name="Rectangle 1647" fill="none">
            <rect
              x="0.5"
              y="0.5"
              width="6.254"
              height="6.254"
              rx="0.5"
              strokeWidth="1.25"
            />
          </g>
        </g>
        <line
          id="Line_298"
          data-name="Line 298"
          x1="34.818"
          transform="translate(0.725 8.705)"
          fill="none"
          strokeWidth="1.25"
        />
        <line
          id="Line_299"
          data-name="Line 299"
          y2="5.352"
          transform="translate(9.43)"
          fill="none"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          id="Line_300"
          data-name="Line 300"
          y2="5.352"
          transform="translate(26.839)"
          fill="none"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default Calendar;
