import { eachDayOfInterval, format, eachMonthOfInterval } from "date-fns";
import { getFromToLastXDays } from "../../utils/calendar-util";
import { generateRandomInteger } from "../../utils/math-util";

const FINANCE_HISTORY_DAY_FORMAT = "yyyy-MM-dd";
const DEFAULT_LOAN_DEBIT = -1000;
const DEFAULT_LOAN_CREDIT = 5000;
const DEFAULT_LOAN_BALANCE = 500000;

const LOAN_CREDIT_TRANSACTION = {
  id: "17686847",
  status: "posted",
  description:
    "ONLINE  PXXXXXX1145 fixed repayments    MCCAFFERTY T TRANSFER CREDIT",
  amount: 700,
  account: "11118987",
  balance: 301513.64,
  direction: "credit",
  categoryId: "28",
  category: "Transfers",
  categoryType: "TRANSFER",
  detailCategoryId: "undefined",
  date: "2022-11-10",
  transactionDate: "2022-11-10",
  postDate: "2022-11-10",
};

const LOAN_DEBIT_TRANSACTION = {
  id: "17643262",
  status: "posted",
  description: "INTEREST CHARGED INTEREST CHARGED",
  amount: -653.49,
  account: "11118987",
  balance: 302638.16000000003,
  direction: "debit",
  categoryId: "24",
  category: "Service Charges/Fees",
  categoryType: "EXPENSE",
  detailCategoryId: "undefined",
  date: "2022-10-31",
  transactionDate: "2022-10-31",
  postDate: "2022-10-31",
};

export const getMonthsListLastXDays = (days) => {
  const { from: fromDate, to: toDate } = getFromToLastXDays(days);
  const monthList = eachMonthOfInterval({
    start: fromDate,
    end: toDate,
  });
  return monthList;
};

export const getDaysListLastXDays = (days, step = 1) => {
  const { from: fromDate, to: toDate } = getFromToLastXDays(days);
  const dayList = eachDayOfInterval(
    {
      start: fromDate,
      end: toDate,
    },
    { step }
  );
  return dayList;
};

const generateLoanTransaction = (day, balance, isDebit) => {
  const formattedDate = format(day, FINANCE_HISTORY_DAY_FORMAT);
  const id = generateRandomInteger(1000000, 9999999);
  const transaction = isDebit
    ? LOAN_DEBIT_TRANSACTION
    : LOAN_CREDIT_TRANSACTION;
  return {
    ...transaction,
    id,
    date: formattedDate,
    postDate: formattedDate,
    transactionDate: formattedDate,
    amount: isDebit ? DEFAULT_LOAN_DEBIT : DEFAULT_LOAN_CREDIT,
    balance,
    direction: isDebit ? "debit" : "credit",
  };
};

export const generateLoanTransactionLastXDays = (
  days,
  defaultLoanBanance = DEFAULT_LOAN_BALANCE
) => {
  const defaultStepDay = 15;
  const dayList = getDaysListLastXDays(days, defaultStepDay);
  let loanBalance = defaultLoanBanance;
  const loanTransaction = dayList
    .reverse((a, b) => new Date(a) - new Date(b))
    .map((day, index) => {
      if (index % 2) {
        loanBalance =
          index === 0 ? loanBalance : loanBalance + DEFAULT_LOAN_CREDIT;
        return generateLoanTransaction(day, loanBalance, true);
      }
      loanBalance =
        index === 0 ? loanBalance : loanBalance - DEFAULT_LOAN_DEBIT;

      return generateLoanTransaction(day, loanBalance, false);
    });
  return loanTransaction;
};

export const generateCashBalanceLastXDays = (days, isLoanData) => {
  const dayList = getDaysListLastXDays(days);
  const cashBalanceValueList = dayList
    .map(() => generateRandomInteger(500000, 1000000))
    .sort((a, b) => (isLoanData ? b - a : a - b));
  const cashBalanceList = dayList.map((day, index) => {
    return {
      time: format(day, FINANCE_HISTORY_DAY_FORMAT),
      amount: cashBalanceValueList[index],
    };
  });
  return cashBalanceList;
};
