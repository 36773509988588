import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import LoadingSpinner from "./loading-spinner";

const CustomButton = ({
  buttonType = "fill",
  fillDark = false,
  disabled = false,
  label = "",
  onClick = () => {},
  onKeyPress = () => {},
  isLoading = false,
  href = "",
  size = "medium",
}) => {
  return (
    <Button
      disabled={disabled}
      target="_blank"
      className={`custom-button-${buttonType}${fillDark ? "-dark" : ""}`}
      href={href}
      onClick={onClick}
      size={size}
      onKeyPress={onKeyPress}
    >
      {isLoading ? <LoadingSpinner /> : label}
    </Button>
  );
};

const CustomButtonOutLine = ({
  label = "",
  onClick = () => {},
  onKeyPress = () => {},
  href = "",
  disabled = false,
  isLoading = false,
  buttonType = "dark",
  size = "medium",
}) => {
  return (
    <Button
      disabled={disabled}
      target="_blank"
      className={`custom-button-${buttonType}`}
      href={href}
      size={size}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      {isLoading ? <LoadingSpinner /> : label}
    </Button>
  );
};

const CustomButtonWithIcon = ({
  label = "",
  onClick = () => {},
  onKeyPress = () => {},
  href = "",
  disabled = false,
  isLoading = false,
  buttonType = "dark",
  icon,
}) => {
  return (
    <Button
      disabled={disabled}
      target="_blank"
      className={`custom-button-${buttonType}`}
      href={href}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      {isLoading ? <LoadingSpinner /> : label}
      <div className="plus-icon">{icon}</div>
    </Button>
  );
};

CustomButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
};

CustomButtonOutLine.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
};

CustomButtonWithIcon.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
};
export { CustomButtonOutLine, CustomButtonWithIcon };
export default CustomButton;
