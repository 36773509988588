import { QueryClient, setLogger } from "react-query";
import { captureException, captureMessage } from "./error-utils";

setLogger({
  warn: (message) => {
    captureMessage(message);
  },
  error: (error) => {
    captureException(error);
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 2,
    },
  },
});
