import * as React from "react";

const NewsPaper = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="m22 3-1.7 1.7L18.7 3 17 4.7 15.3 3l-1.7 1.7L12 3l-1.7 1.7L8.7 3 7 4.7 5.3 3 3.7 4.7 2 3v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V3zM11 19H4v-6h7v6zm9 0h-7v-2h7v2zm0-4h-7v-2h7v2zm0-4H4V8h16v3z" />
  </svg>
);

export default NewsPaper;
