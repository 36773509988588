import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function NextArrow({ fill = primaryColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={29.105}
      height={17.379}
      viewBox="0 0 29.105 17.379"
    >
      <g
        data-name="Group 6152"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <g data-name="Group 6116" opacity={0.4}>
          <path
            data-name="Path 2284"
            fill="none"
            d="M2.623 9.413h25.482l-7.014 6.552"
          />
          <path
            data-name="Path 2285"
            fill="none"
            d="M2.623 8.971h25.482l-7.014-6.555"
          />
        </g>
        <g data-name="Group 6117">
          <path
            data-name="Path 2284"
            fill="none"
            d="M1 8.413h25.482l-7.014 6.552"
          />
          <path
            data-name="Path 2285"
            fill="none"
            d="M1 7.971h25.482l-7.014-6.555"
          />
        </g>
      </g>
    </svg>
  );
}

export default NextArrow;
