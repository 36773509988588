import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import { graphql, navigate, useStaticQuery } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import { useFinanceSummary, useIsTablet } from "../../hooks/common.hooks";
import { useSelectedJointAccountDetails } from "../../hooks/joint-account.hooks";
import { useMyInfo } from "../../hooks/user.hooks.ts";
import { primaryColor } from "../../scss/colors.scss";
import {
  Account,
  Dashboard,
  Finance,
  Logout,
  Property,
  SharingReferral,
  Super,
  Support,
  Transactions,
} from "../../svgs";
import { logout } from "../../utils/auth";
import MobileMenu from "./components/mobile-menu";
import SwitchAccount from "./components/switch-account";
import MenuLeftItemWValue from "./menuLeftItem";
import "./menu_left.scss";
import MenuLeftItem from "./menu_left_item";
import { getFullName } from "../../utils/user-data-util";

const Accordion = withStyles({
  root: {
    backgroundColor: "transparent",
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    color: "#fff",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  expanded: {
    "&:first-child": {
      marginBottom: 0,
    },
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    padding: 0,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "& svg": {
      transform: "rotate(0deg)",
      transition: "transform .2s ease-in-out",
    },
    margin: 0,
    "&$expanded": {
      margin: 0,
      "& svg": {
        transform: "rotate(180deg)",
      },
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    backgroundColor: "#fff",
    "& a.ml-items-body": {
      "&:hover": {
        backgroundColor: "#fff",
      },
      padding: "1rem",
      color: "#000",
    },
  },
}))(MuiAccordionDetails);

const sideBarMenuItem = [
  {
    icon: <Dashboard />,
    title: "Dashboard",
    href: "",
  },
  {
    icon: <Transactions />,
    title: "Transactions",
    href: "transactions",
  },
  {
    icon: <Finance />,
    title: "Finance",
    href: "finance/buying-power",
  },
  {
    icon: <Property />,
    title: "Property",
    href: "property",
  },
  {
    icon: <Super />,
    title: "Shares & Super",
    href: "shares-and-super",
  },
  {
    icon: <Support />,
    title: "Help & Support",
    href: "help-support",
  },
  {
    icon: <SharingReferral />,
    title: "Sharing & Referral",
    href: "sharing",
  },
  {
    icon: <Account />,
    title: "Account",
    href: "account",
  },
];

const MenuLeft = (props) => {
  const data = useStaticQuery(graphql`
    query {
      gitCommit(latest: { eq: true }) {
        hash
        date
      }
      gitTag(latest: { eq: true }) {
        name
      }
      gitBranch(current: { eq: true }) {
        name
      }
    }
  `);
  const { gitBranch, gitCommit: { date: gitDate = "" } = {} } = data;
  const gitTagName = gitBranch?.name || "";
  const summary = useFinanceSummary() || {};
  const { data: userInfo } = useMyInfo();
  const isTablet = useIsTablet();
  const [logoutExpanded, setLogoutExpanded] = useState(false);
  const selectedJointAcocuntDetails = useSelectedJointAccountDetails();
  const accountName = useMemo(() => {
    return selectedJointAcocuntDetails
      ? selectedJointAcocuntDetails.name
      : getFullName(userInfo);
  }, [userInfo, selectedJointAcocuntDetails]);
  const [checkedItem, setcheckedItem] = useState("");
  const [sidebarCollapse, setSidebarCollapse] = useState(true);

  const {
    netWealth = 0,
    income = 0,
    expenses = 0,
    borrowCapacity = 0,
    cashDeposit = 0,
    propertyPortfolio = 0,
    liabilities = 0,
    totalSharesAndSuper = 0,
  } = summary;
  const { location: { pathname = "" } = {} } = props;

  useEffect(() => {
    switch (true) {
      case pathname.indexOf("transactions") === 1:
        setcheckedItem("Transactions");
        break;
      case pathname.indexOf("finance") === 1:
        setcheckedItem("Finance");
        break;
      case pathname.indexOf("property") === 1:
        setcheckedItem("Property");
        break;
      case pathname.indexOf("shares-and-super") === 1:
        setcheckedItem("Shares & Super");
        break;
      case pathname.indexOf("bookappointment") === 1:
        setcheckedItem("Book an appointment");
        break;
      case pathname.indexOf("help-support") === 1:
        setcheckedItem("Help & Support");
        break;
      case pathname.indexOf("account") === 1:
        setcheckedItem("Account");
        break;
      case pathname.indexOf("sharing") === 1:
        setcheckedItem("Sharing & Referral");
        break;
      default:
        setcheckedItem("Dashboard");
        break;
    }
  }, [pathname]);

  useEffect(() => {
    setLogoutExpanded(false);
  }, [isTablet]);

  const handleLogoutExpanded = () => {
    setLogoutExpanded(!logoutExpanded);
  };
  const handleToggleSidebar = (status) => {
    if (status) {
      setLogoutExpanded(false);
    }
    setSidebarCollapse(status);
  };
  const handleLogout = () => {
    logout();
  };

  return (
    <div className="menu_left">
      <div
        className={classNames("desktop-menu", {
          collapsed: sidebarCollapse,
        })}
        onMouseLeave={() => handleToggleSidebar(true)}
        onMouseEnter={() => handleToggleSidebar(false)}
      >
        <div className="desktop-menu-container">
          <Accordion
            className="position-relative"
            square
            expanded={logoutExpanded}
            onChange={!sidebarCollapse ? handleLogoutExpanded : undefined}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <div className="ml-avatar-wraper">
                <div className="ml-avatar-name">
                  {sidebarCollapse ? accountName.match(/\b(\w)/g) : accountName}
                </div>
                <ExpandMoreIcon />
              </div>
            </AccordionSummary>
            <AccordionDetails className="logout-button-group">
              <MenuLeftItem
                icon={<Logout fillcolor={primaryColor} borderColor="#000" />}
                title="Logout"
                checked={false}
                onClick={handleLogout}
              />
              <SwitchAccount />
              <div className="code-version">
                <p className="m-0">Version: {gitTagName}</p>
                <p className="m-0">
                  Last Updated On: {new Date(gitDate).toLocaleString() || " "}
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="ml-transaction-container">
            <MenuLeftItemWValue
              name="Net Wealth"
              value={netWealth}
              className="net-wealth"
            />
          </div>
          <div className="ml-transaction-container">
            <MenuLeftItemWValue name="Income" value={income} />
            <MenuLeftItemWValue name="Expenses" value={expenses} />
            <MenuLeftItemWValue
              name="Borrowing Capacity*"
              value={borrowCapacity}
            />
            <MenuLeftItemWValue name="Cash Deposit" value={cashDeposit} />
            <MenuLeftItemWValue
              name="Property Portfolio"
              value={propertyPortfolio}
            />
            <MenuLeftItemWValue name="Liabilities" value={liabilities} />
            <MenuLeftItemWValue
              name="Shares & Super"
              value={totalSharesAndSuper}
            />
          </div>
          <div className="ml-items-group">
            <div className="ml-items-container">
              {sideBarMenuItem.map((sideBarItem) => (
                <MenuLeftItem
                  key={sideBarItem.title}
                  icon={sideBarItem.icon}
                  title={sideBarItem.title}
                  checked={checkedItem === sideBarItem.title}
                  onClick={() => navigate(sideBarItem.href)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {isTablet && (
        <MobileMenu
          sideBarMenuItem={sideBarMenuItem}
          name={accountName}
          handleLogout={handleLogout}
          checkedItem={checkedItem}
          isExpanded={logoutExpanded}
          handleIsExpanded={handleLogoutExpanded}
          data={{ income, cashDeposit, expenses }}
          gitVersion={{ gitTagName, gitDate }}
        />
      )}
    </div>
  );
};

export default React.memo(MenuLeft);
