import * as React from "react";

const NightLife = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        fill: "none",
      }}
      d="M24 24H0"
    />
    <path d="M1.5 4.5h14l-6 9v4h2v2h-6v-2h2v-4l-6-9zm9.1 4 1.4-2H5l1.4 2h4.2zm6.9-4h5v3h-3v9c0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3c.4 0 .7.1 1 .2V4.5z" />
  </svg>
);

export default NightLife;
