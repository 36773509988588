export const HISTORY_30_DAYS_MOCKUP_DATA = {
  cashBalances: [],
  totalIncome: 21982.58,
  incomes: [
    {
      time: "2022-10-15",
      amount: 1630.42,
    },
    {
      time: "2022-10-16",
      amount: 532.31,
    },
    {
      time: "2022-10-21",
      amount: 1831.76,
    },
    {
      time: "2022-10-23",
      amount: 1538.52,
    },
    {
      time: "2022-10-31",
      amount: 15065.82,
    },
    {
      time: "2022-11-14",
      amount: 1383.75,
    },
  ],
  incomeSources: [
    {
      source: "Refunds/Adjustments",
      amount: 1630.42,
    },
    {
      source: "Deposits",
      amount: 3536.0599999999995,
    },
    {
      source: "Consulting",
      amount: 1538.52,
    },
    {
      source: "Sales",
      amount: 65.82,
    },
    {
      source: "Salary",
      amount: 15000,
    },
    {
      source: "Services",
      amount: 211.76,
    },
  ],
  totalIncoming: 21982.58,
  totalExpense: -9815.02,
  expenses: [
    {
      time: "2022-10-16",
      amount: -2102.76,
    },
    {
      time: "2022-10-17",
      amount: -1375.44,
    },
    {
      time: "2022-10-20",
      amount: -537.45,
    },
    {
      time: "2022-10-26",
      amount: -746.39,
    },
    {
      time: "2022-11-03",
      amount: -785.71,
    },
    {
      time: "2022-11-04",
      amount: -957.65,
    },
    {
      time: "2022-11-11",
      amount: -838.4,
    },
    {
      time: "2022-11-13",
      amount: -855.7,
    },
    {
      time: "2022-11-14",
      amount: -1615.52,
    },
  ],
  expenseCategories: [
    {
      category: "Gifts",
      amount: -1233.79,
    },
    {
      category: "General Merchandise",
      amount: -754.23,
    },
    {
      category: "Insurance",
      amount: -912.34,
    },
    {
      category: "Entertainment",
      amount: -221.73,
    },
    {
      category: "Postage And Shipping",
      amount: -311.59,
    },
    {
      category: "Restaurants Dining",
      amount: -842.12,
    },
    {
      category: "Office Supplies",
      amount: -1323.16,
    },
    {
      category: "Rent",
      amount: -1584.79,
    },
    {
      category: "Healthcare Medical",
      amount: -957.65,
    },
    {
      category: "Dues And Subscriptions",
      amount: -855.7,
    },
    {
      category: "Advertising",
      amount: -817.92,
    },
  ],
  totalOutgoing: -9815.02,
  livingExpenseCategories: [
    {
      category: "Gifts",
      amount: -1233.79,
    },
    {
      category: "General Merchandise",
      amount: -754.23,
    },
    {
      category: "Insurance",
      amount: -912.34,
    },
    {
      category: "Entertainment",
      amount: -221.73,
    },
    {
      category: "Postage And Shipping",
      amount: -311.59,
    },
    {
      category: "Restaurants Dining",
      amount: -842.12,
    },
    {
      category: "Office Supplies",
      amount: -1323.16,
    },
    {
      category: "Rent",
      amount: -1584.79,
    },
    {
      category: "Healthcare Medical",
      amount: -957.65,
    },
    {
      category: "Dues And Subscriptions",
      amount: -855.7,
    },
    {
      category: "Advertising",
      amount: -817.92,
    },
  ],
  transactions: [
    {
      id: "634218141",
      status: "posted",
      description: "Gifts",
      amount: -436.19,
      account: "11409640",
      balance: 1002789.3199999998,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "9",
      category: "Gifts",
      categoryType: "EXPENSE",
      detailCategoryId: "1420",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218142",
      status: "posted",
      description: "General Merchandise",
      amount: -754.23,
      account: "11409640",
      balance: 1002035.0899999999,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "44",
      category: "General Merchandise",
      categoryType: "EXPENSE",
      detailCategoryId: "1306",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218143",
      status: "posted",
      description: "Deposits",
      amount: 532.31,
      account: "11409640",
      balance: 1002567.3999999999,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218144",
      status: "posted",
      description: "Insurance",
      amount: -912.34,
      account: "11409640",
      balance: 1001655.0599999999,
      direction: "debit",
      type: "PAYMENT",
      subType: "INSURANCE_PAYMENT",
      categoryId: "14",
      category: "Insurance",
      categoryType: "EXPENSE",
      detailCategoryId: "1512",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218138",
      status: "posted",
      description: "Entertainment",
      amount: -221.73,
      account: "11409640",
      balance: 1001433.33,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "7",
      category: "Entertainment",
      categoryType: "EXPENSE",
      detailCategoryId: "1352",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218139",
      status: "posted",
      description: "Postage/Shipping",
      amount: -311.59,
      account: "11409640",
      balance: 1001121.74,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "104",
      category: "Postage and Shipping",
      categoryType: "EXPENSE",
      detailCategoryId: "1561",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218140",
      status: "posted",
      description: "Restaurants/Dining",
      amount: -842.12,
      account: "11409640",
      balance: 1000279.62,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "22",
      category: "Restaurants/Dining",
      categoryType: "EXPENSE",
      detailCategoryId: "1589",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218137",
      status: "posted",
      description: "Office Supplies",
      amount: -537.45,
      account: "11409640",
      balance: 999742.17,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "45",
      category: "Office Supplies",
      categoryType: "EXPENSE",
      detailCategoryId: "1177",
      date: "2022-10-20",
      transactionDate: "2022-10-20",
      postDate: "2022-10-20",
    },
    {
      id: "634218136",
      status: "posted",
      description: "Deposits",
      amount: 1831.76,
      account: "11409640",
      balance: 1001573.93,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-10-21",
      transactionDate: "2022-10-21",
      postDate: "2022-10-21",
    },
    {
      id: "634218135",
      status: "posted",
      description: "Consulting",
      amount: 1538.52,
      account: "11409640",
      balance: 1003112.4500000001,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "98",
      category: "Services",
      categoryType: "INCOME",
      detailCategoryId: "1203",
      date: "2022-10-23",
      transactionDate: "2022-10-23",
      postDate: "2022-10-23",
    },
    {
      id: "634218134",
      status: "posted",
      description: "Rent",
      amount: -746.39,
      account: "11409640",
      balance: 1002366.06,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "21",
      category: "Rent",
      categoryType: "EXPENSE",
      detailCategoryId: "1165",
      date: "2022-10-26",
      transactionDate: "2022-10-26",
      postDate: "2022-10-26",
    },
    {
      id: "634218133",
      status: "posted",
      description: "Sales",
      amount: 65.82,
      account: "11409640",
      balance: 1002431.88,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DIRECT_DEPOSIT_SALARY",
      categoryId: "29",
      category: "Paychecks/Salary",
      categoryType: "INCOME",
      detailCategoryId: "1183",
      date: "2022-10-31",
      transactionDate: "2022-10-31",
      postDate: "2022-10-31",
    },
    {
      id: "634219516",
      status: "posted",
      description: "Salary",
      amount: 15000,
      account: "11409640",
      balance: 1017431.88,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DIRECT_DEPOSIT_SALARY",
      categoryId: "29",
      category: "Paychecks/Salary",
      categoryType: "INCOME",
      detailCategoryId: "1183",
      date: "2022-10-31",
      transactionDate: "2022-10-31",
      postDate: "2022-10-31",
    },
    {
      id: "634218132",
      status: "posted",
      description: "Office Supplies",
      amount: -785.71,
      account: "11409640",
      balance: 1016646.17,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "45",
      category: "Office Supplies",
      categoryType: "EXPENSE",
      detailCategoryId: "1177",
      date: "2022-11-03",
      transactionDate: "2022-11-03",
      postDate: "2022-11-03",
    },
    {
      id: "634218131",
      status: "posted",
      description: "Healthcare/Medical",
      amount: -957.65,
      account: "11409640",
      balance: 1015688.52,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "11",
      category: "Healthcare/Medical",
      categoryType: "EXPENSE",
      detailCategoryId: "1453",
      date: "2022-11-04",
      transactionDate: "2022-11-04",
      postDate: "2022-11-04",
    },
    {
      id: "634218130",
      status: "posted",
      description: "Rent",
      amount: -838.4,
      account: "11409640",
      balance: 1014850.12,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "21",
      category: "Rent",
      categoryType: "EXPENSE",
      detailCategoryId: "1165",
      date: "2022-11-11",
      transactionDate: "2022-11-11",
      postDate: "2022-11-11",
    },
    {
      id: "634218129",
      status: "posted",
      description: "Dues and Subscriptions",
      amount: -855.7,
      account: "11409640",
      balance: 1013994.42,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "108",
      category: "Dues and Subscriptions",
      categoryType: "EXPENSE",
      detailCategoryId: "1181",
      date: "2022-11-13",
      transactionDate: "2022-11-13",
      postDate: "2022-11-13",
    },
    {
      id: "634218125",
      status: "posted",
      description: "Deposits",
      amount: 1171.99,
      account: "11409640",
      balance: 1015166.41,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218126",
      status: "posted",
      description: "Services",
      amount: 211.76,
      account: "11409640",
      balance: 1015378.17,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "CREDIT",
      categoryId: "98",
      category: "Services",
      categoryType: "INCOME",
      detailCategoryId: "1203",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218127",
      status: "posted",
      description: "Advertising",
      amount: -817.92,
      account: "11409640",
      balance: 1014560.25,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "100",
      category: "Advertising",
      categoryType: "EXPENSE",
      detailCategoryId: "1514",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218128",
      status: "posted",
      description: "Gifts",
      amount: -797.6,
      account: "11409640",
      balance: 1013762.65,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "9",
      category: "Gifts",
      categoryType: "EXPENSE",
      detailCategoryId: "1420",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218124",
      status: "posted",
      description: "Refunds/Adjustments",
      amount: 749.16,
      account: "11409640",
      balance: 1014511.81,
      direction: "credit",
      type: "REFUND",
      subType: "REFUND",
      categoryId: "227",
      category: "Refunds/Adjustments",
      categoryType: "INCOME",
      detailCategoryId: "1188",
      date: "2022-11-15",
      transactionDate: "2022-11-15",
      postDate: "2022-11-15",
    },
  ],
};

export const HISTORY_60_DAYS_MOCKUP_DATA = {
  transactions: [
    {
      id: "634218141",
      status: "posted",
      description: "Gifts",
      amount: -436.19,
      account: "11409640",
      balance: 1002789.3199999998,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "9",
      category: "Gifts",
      categoryType: "EXPENSE",
      detailCategoryId: "1420",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218142",
      status: "posted",
      description: "General Merchandise",
      amount: -754.23,
      account: "11409640",
      balance: 1002035.0899999999,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "44",
      category: "General Merchandise",
      categoryType: "EXPENSE",
      detailCategoryId: "1306",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218143",
      status: "posted",
      description: "Deposits",
      amount: 532.31,
      account: "11409640",
      balance: 1002567.3999999999,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218144",
      status: "posted",
      description: "Insurance",
      amount: -912.34,
      account: "11409640",
      balance: 1001655.0599999999,
      direction: "debit",
      type: "PAYMENT",
      subType: "INSURANCE_PAYMENT",
      categoryId: "14",
      category: "Insurance",
      categoryType: "EXPENSE",
      detailCategoryId: "1512",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218138",
      status: "posted",
      description: "Entertainment",
      amount: -221.73,
      account: "11409640",
      balance: 1001433.33,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "7",
      category: "Entertainment",
      categoryType: "EXPENSE",
      detailCategoryId: "1352",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218139",
      status: "posted",
      description: "Postage/Shipping",
      amount: -311.59,
      account: "11409640",
      balance: 1001121.74,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "104",
      category: "Postage and Shipping",
      categoryType: "EXPENSE",
      detailCategoryId: "1561",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218140",
      status: "posted",
      description: "Restaurants/Dining",
      amount: -842.12,
      account: "11409640",
      balance: 1000279.62,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "22",
      category: "Restaurants/Dining",
      categoryType: "EXPENSE",
      detailCategoryId: "1589",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218137",
      status: "posted",
      description: "Office Supplies",
      amount: -537.45,
      account: "11409640",
      balance: 999742.17,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "45",
      category: "Office Supplies",
      categoryType: "EXPENSE",
      detailCategoryId: "1177",
      date: "2022-10-20",
      transactionDate: "2022-10-20",
      postDate: "2022-10-20",
    },
    {
      id: "634218136",
      status: "posted",
      description: "Deposits",
      amount: 1831.76,
      account: "11409640",
      balance: 1001573.93,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-10-21",
      transactionDate: "2022-10-21",
      postDate: "2022-10-21",
    },
    {
      id: "634218135",
      status: "posted",
      description: "Consulting",
      amount: 1538.52,
      account: "11409640",
      balance: 1003112.4500000001,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "98",
      category: "Services",
      categoryType: "INCOME",
      detailCategoryId: "1203",
      date: "2022-10-23",
      transactionDate: "2022-10-23",
      postDate: "2022-10-23",
    },
    {
      id: "634218134",
      status: "posted",
      description: "Rent",
      amount: -746.39,
      account: "11409640",
      balance: 1002366.06,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "21",
      category: "Rent",
      categoryType: "EXPENSE",
      detailCategoryId: "1165",
      date: "2022-10-26",
      transactionDate: "2022-10-26",
      postDate: "2022-10-26",
    },
    {
      id: "634218133",
      status: "posted",
      description: "Sales",
      amount: 65.82,
      account: "11409640",
      balance: 1002431.88,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DIRECT_DEPOSIT_SALARY",
      categoryId: "29",
      category: "Paychecks/Salary",
      categoryType: "INCOME",
      detailCategoryId: "1183",
      date: "2022-10-31",
      transactionDate: "2022-10-31",
      postDate: "2022-10-31",
    },
    {
      id: "634219516",
      status: "posted",
      description: "Salary",
      amount: 15000,
      account: "11409640",
      balance: 1017431.88,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DIRECT_DEPOSIT_SALARY",
      categoryId: "29",
      category: "Paychecks/Salary",
      categoryType: "INCOME",
      detailCategoryId: "1183",
      date: "2022-10-31",
      transactionDate: "2022-10-31",
      postDate: "2022-10-31",
    },
    {
      id: "634218132",
      status: "posted",
      description: "Office Supplies",
      amount: -785.71,
      account: "11409640",
      balance: 1016646.17,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "45",
      category: "Office Supplies",
      categoryType: "EXPENSE",
      detailCategoryId: "1177",
      date: "2022-11-03",
      transactionDate: "2022-11-03",
      postDate: "2022-11-03",
    },
    {
      id: "634218131",
      status: "posted",
      description: "Healthcare/Medical",
      amount: -957.65,
      account: "11409640",
      balance: 1015688.52,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "11",
      category: "Healthcare/Medical",
      categoryType: "EXPENSE",
      detailCategoryId: "1453",
      date: "2022-11-04",
      transactionDate: "2022-11-04",
      postDate: "2022-11-04",
    },
    {
      id: "634218130",
      status: "posted",
      description: "Rent",
      amount: -838.4,
      account: "11409640",
      balance: 1014850.12,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "21",
      category: "Rent",
      categoryType: "EXPENSE",
      detailCategoryId: "1165",
      date: "2022-11-11",
      transactionDate: "2022-11-11",
      postDate: "2022-11-11",
    },
    {
      id: "634218129",
      status: "posted",
      description: "Dues and Subscriptions",
      amount: -855.7,
      account: "11409640",
      balance: 1013994.42,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "108",
      category: "Dues and Subscriptions",
      categoryType: "EXPENSE",
      detailCategoryId: "1181",
      date: "2022-11-13",
      transactionDate: "2022-11-13",
      postDate: "2022-11-13",
    },
    {
      id: "634218125",
      status: "posted",
      description: "Deposits",
      amount: 1171.99,
      account: "11409640",
      balance: 1015166.41,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218126",
      status: "posted",
      description: "Services",
      amount: 211.76,
      account: "11409640",
      balance: 1015378.17,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "CREDIT",
      categoryId: "98",
      category: "Services",
      categoryType: "INCOME",
      detailCategoryId: "1203",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218127",
      status: "posted",
      description: "Advertising",
      amount: -817.92,
      account: "11409640",
      balance: 1014560.25,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "100",
      category: "Advertising",
      categoryType: "EXPENSE",
      detailCategoryId: "1514",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218128",
      status: "posted",
      description: "Gifts",
      amount: -797.6,
      account: "11409640",
      balance: 1013762.65,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "9",
      category: "Gifts",
      categoryType: "EXPENSE",
      detailCategoryId: "1420",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218124",
      status: "posted",
      description: "Refunds/Adjustments",
      amount: 749.16,
      account: "11409640",
      balance: 1014511.81,
      direction: "credit",
      type: "REFUND",
      subType: "REFUND",
      categoryId: "227",
      category: "Refunds/Adjustments",
      categoryType: "INCOME",
      detailCategoryId: "1188",
      date: "2022-11-15",
      transactionDate: "2022-11-15",
      postDate: "2022-11-15",
    },
  ],
  cashBalances: [],
  totalIncome: 48109.97,
  incomes: [
    {
      time: "2022-09-15",
      amount: 577.52,
    },
    {
      time: "2022-09-17",
      amount: 86.04,
    },
    {
      time: "2022-09-21",
      amount: 752.06,
    },
    {
      time: "2022-09-24",
      amount: 931.64,
    },
    {
      time: "2022-09-26",
      amount: 1959.4,
    },
    {
      time: "2022-09-30",
      amount: 15000,
    },
    {
      time: "2022-10-01",
      amount: 142.87,
    },
    {
      time: "2022-10-07",
      amount: 2249.2400000000002,
    },
    {
      time: "2022-10-08",
      amount: 1581.62,
    },
    {
      time: "2022-10-14",
      amount: 2847,
    },
    {
      time: "2022-10-15",
      amount: 1630.42,
    },
    {
      time: "2022-10-16",
      amount: 532.31,
    },
    {
      time: "2022-10-21",
      amount: 1831.76,
    },
    {
      time: "2022-10-23",
      amount: 1538.52,
    },
    {
      time: "2022-10-31",
      amount: 15065.82,
    },
    {
      time: "2022-11-14",
      amount: 1383.75,
    },
  ],
  incomeSources: [
    {
      source: "Rewards",
      amount: 2170.02,
    },
    {
      source: "Interest",
      amount: 752.06,
    },
    {
      source: "Services",
      amount: 1441.26,
    },
    {
      source: "Refunds/Adjustments",
      amount: 3434.83,
    },
    {
      source: "Salary",
      amount: 31879.66,
    },
    {
      source: "Retirement Income",
      amount: 1581.62,
    },
    {
      source: "Sales",
      amount: 1775.94,
    },
    {
      source: "Deposits",
      amount: 3536.0599999999995,
    },
    {
      source: "Consulting",
      amount: 1538.52,
    },
  ],
  totalIncoming: 48109.97,
  totalExpense: -15306.48,
  expenses: [
    {
      time: "2022-09-15",
      amount: -819.78,
    },
    {
      time: "2022-09-16",
      amount: -73.01,
    },
    {
      time: "2022-09-17",
      amount: -993,
    },
    {
      time: "2022-09-19",
      amount: -1044.99,
    },
    {
      time: "2022-09-21",
      amount: -419.78,
    },
    {
      time: "2022-09-22",
      amount: -841.01,
    },
    {
      time: "2022-10-10",
      amount: -537.36,
    },
    {
      time: "2022-10-11",
      amount: -261.29,
    },
    {
      time: "2022-10-14",
      amount: -501.24,
    },
    {
      time: "2022-10-16",
      amount: -2102.76,
    },
    {
      time: "2022-10-17",
      amount: -1375.44,
    },
    {
      time: "2022-10-20",
      amount: -537.45,
    },
    {
      time: "2022-10-26",
      amount: -746.39,
    },
    {
      time: "2022-11-03",
      amount: -785.71,
    },
    {
      time: "2022-11-04",
      amount: -957.65,
    },
    {
      time: "2022-11-11",
      amount: -838.4,
    },
    {
      time: "2022-11-13",
      amount: -855.7,
    },
    {
      time: "2022-11-14",
      amount: -1615.52,
    },
  ],
  expenseCategories: [
    {
      category: "Office Maintenance",
      amount: -819.78,
    },
    {
      category: "Automotive Expenses",
      amount: -73.01,
    },
    {
      category: "Utilities",
      amount: -993,
    },
    {
      category: "Hobbies",
      amount: -280.43,
    },
    {
      category: "Pets Pet Care",
      amount: -59.55,
    },
    {
      category: "Restaurants Dining",
      amount: -1547.13,
    },
    {
      category: "Home Improvement",
      amount: -957.14,
    },
    {
      category: "Healthcare Medical",
      amount: -1798.6599999999999,
    },
    {
      category: "Gasoline Fuel",
      amount: -261.29,
    },
    {
      category: "Personal Care",
      amount: -501.24,
    },
    {
      category: "Gifts",
      amount: -1233.79,
    },
    {
      category: "General Merchandise",
      amount: -754.23,
    },
    {
      category: "Insurance",
      amount: -912.34,
    },
    {
      category: "Entertainment",
      amount: -221.73,
    },
    {
      category: "Postage And Shipping",
      amount: -311.59,
    },
    {
      category: "Office Supplies",
      amount: -1323.16,
    },
    {
      category: "Rent",
      amount: -1584.79,
    },
    {
      category: "Dues And Subscriptions",
      amount: -855.7,
    },
    {
      category: "Advertising",
      amount: -817.92,
    },
  ],
  totalOutgoing: -15306.48,
  livingExpenseCategories: [
    {
      category: "Office Maintenance",
      amount: -819.78,
    },
    {
      category: "Automotive Expenses",
      amount: -73.01,
    },
    {
      category: "Utilities",
      amount: -993,
    },
    {
      category: "Hobbies",
      amount: -280.43,
    },
    {
      category: "Pets Pet Care",
      amount: -59.55,
    },
    {
      category: "Restaurants Dining",
      amount: -1547.13,
    },
    {
      category: "Home Improvement",
      amount: -957.14,
    },
    {
      category: "Healthcare Medical",
      amount: -1798.6599999999999,
    },
    {
      category: "Gasoline Fuel",
      amount: -261.29,
    },
    {
      category: "Personal Care",
      amount: -501.24,
    },
    {
      category: "Gifts",
      amount: -1233.79,
    },
    {
      category: "General Merchandise",
      amount: -754.23,
    },
    {
      category: "Insurance",
      amount: -912.34,
    },
    {
      category: "Entertainment",
      amount: -221.73,
    },
    {
      category: "Postage And Shipping",
      amount: -311.59,
    },
    {
      category: "Office Supplies",
      amount: -1323.16,
    },
    {
      category: "Rent",
      amount: -1584.79,
    },
    {
      category: "Dues And Subscriptions",
      amount: -855.7,
    },
    {
      category: "Advertising",
      amount: -817.92,
    },
  ],
};

export const HISTORY_120_DAYS_MOCKUP_DATA = {
  transactions: [
    {
      id: "634218141",
      status: "posted",
      description: "Gifts",
      amount: -436.19,
      account: "11409640",
      balance: 1002789.3199999998,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "9",
      category: "Gifts",
      categoryType: "EXPENSE",
      detailCategoryId: "1420",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218142",
      status: "posted",
      description: "General Merchandise",
      amount: -754.23,
      account: "11409640",
      balance: 1002035.0899999999,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "44",
      category: "General Merchandise",
      categoryType: "EXPENSE",
      detailCategoryId: "1306",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218143",
      status: "posted",
      description: "Deposits",
      amount: 532.31,
      account: "11409640",
      balance: 1002567.3999999999,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218144",
      status: "posted",
      description: "Insurance",
      amount: -912.34,
      account: "11409640",
      balance: 1001655.0599999999,
      direction: "debit",
      type: "PAYMENT",
      subType: "INSURANCE_PAYMENT",
      categoryId: "14",
      category: "Insurance",
      categoryType: "EXPENSE",
      detailCategoryId: "1512",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218138",
      status: "posted",
      description: "Entertainment",
      amount: -221.73,
      account: "11409640",
      balance: 1001433.33,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "7",
      category: "Entertainment",
      categoryType: "EXPENSE",
      detailCategoryId: "1352",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218139",
      status: "posted",
      description: "Postage/Shipping",
      amount: -311.59,
      account: "11409640",
      balance: 1001121.74,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "104",
      category: "Postage and Shipping",
      categoryType: "EXPENSE",
      detailCategoryId: "1561",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218140",
      status: "posted",
      description: "Restaurants/Dining",
      amount: -842.12,
      account: "11409640",
      balance: 1000279.62,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "22",
      category: "Restaurants/Dining",
      categoryType: "EXPENSE",
      detailCategoryId: "1589",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218137",
      status: "posted",
      description: "Office Supplies",
      amount: -537.45,
      account: "11409640",
      balance: 999742.17,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "45",
      category: "Office Supplies",
      categoryType: "EXPENSE",
      detailCategoryId: "1177",
      date: "2022-10-20",
      transactionDate: "2022-10-20",
      postDate: "2022-10-20",
    },
    {
      id: "634218136",
      status: "posted",
      description: "Deposits",
      amount: 1831.76,
      account: "11409640",
      balance: 1001573.93,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-10-21",
      transactionDate: "2022-10-21",
      postDate: "2022-10-21",
    },
    {
      id: "634218135",
      status: "posted",
      description: "Consulting",
      amount: 1538.52,
      account: "11409640",
      balance: 1003112.4500000001,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "98",
      category: "Services",
      categoryType: "INCOME",
      detailCategoryId: "1203",
      date: "2022-10-23",
      transactionDate: "2022-10-23",
      postDate: "2022-10-23",
    },
    {
      id: "634218134",
      status: "posted",
      description: "Rent",
      amount: -746.39,
      account: "11409640",
      balance: 1002366.06,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "21",
      category: "Rent",
      categoryType: "EXPENSE",
      detailCategoryId: "1165",
      date: "2022-10-26",
      transactionDate: "2022-10-26",
      postDate: "2022-10-26",
    },
    {
      id: "634218133",
      status: "posted",
      description: "Sales",
      amount: 65.82,
      account: "11409640",
      balance: 1002431.88,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DIRECT_DEPOSIT_SALARY",
      categoryId: "29",
      category: "Paychecks/Salary",
      categoryType: "INCOME",
      detailCategoryId: "1183",
      date: "2022-10-31",
      transactionDate: "2022-10-31",
      postDate: "2022-10-31",
    },
    {
      id: "634219516",
      status: "posted",
      description: "Salary",
      amount: 15000,
      account: "11409640",
      balance: 1017431.88,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DIRECT_DEPOSIT_SALARY",
      categoryId: "29",
      category: "Paychecks/Salary",
      categoryType: "INCOME",
      detailCategoryId: "1183",
      date: "2022-10-31",
      transactionDate: "2022-10-31",
      postDate: "2022-10-31",
    },
    {
      id: "634218132",
      status: "posted",
      description: "Office Supplies",
      amount: -785.71,
      account: "11409640",
      balance: 1016646.17,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "45",
      category: "Office Supplies",
      categoryType: "EXPENSE",
      detailCategoryId: "1177",
      date: "2022-11-03",
      transactionDate: "2022-11-03",
      postDate: "2022-11-03",
    },
    {
      id: "634218131",
      status: "posted",
      description: "Healthcare/Medical",
      amount: -957.65,
      account: "11409640",
      balance: 1015688.52,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "11",
      category: "Healthcare/Medical",
      categoryType: "EXPENSE",
      detailCategoryId: "1453",
      date: "2022-11-04",
      transactionDate: "2022-11-04",
      postDate: "2022-11-04",
    },
    {
      id: "634218130",
      status: "posted",
      description: "Rent",
      amount: -838.4,
      account: "11409640",
      balance: 1014850.12,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "21",
      category: "Rent",
      categoryType: "EXPENSE",
      detailCategoryId: "1165",
      date: "2022-11-11",
      transactionDate: "2022-11-11",
      postDate: "2022-11-11",
    },
    {
      id: "634218129",
      status: "posted",
      description: "Dues and Subscriptions",
      amount: -855.7,
      account: "11409640",
      balance: 1013994.42,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "108",
      category: "Dues and Subscriptions",
      categoryType: "EXPENSE",
      detailCategoryId: "1181",
      date: "2022-11-13",
      transactionDate: "2022-11-13",
      postDate: "2022-11-13",
    },
    {
      id: "634218125",
      status: "posted",
      description: "Deposits",
      amount: 1171.99,
      account: "11409640",
      balance: 1015166.41,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218126",
      status: "posted",
      description: "Services",
      amount: 211.76,
      account: "11409640",
      balance: 1015378.17,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "CREDIT",
      categoryId: "98",
      category: "Services",
      categoryType: "INCOME",
      detailCategoryId: "1203",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218127",
      status: "posted",
      description: "Advertising",
      amount: -817.92,
      account: "11409640",
      balance: 1014560.25,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "100",
      category: "Advertising",
      categoryType: "EXPENSE",
      detailCategoryId: "1514",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218128",
      status: "posted",
      description: "Gifts",
      amount: -797.6,
      account: "11409640",
      balance: 1013762.65,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "9",
      category: "Gifts",
      categoryType: "EXPENSE",
      detailCategoryId: "1420",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218124",
      status: "posted",
      description: "Refunds/Adjustments",
      amount: 749.16,
      account: "11409640",
      balance: 1014511.81,
      direction: "credit",
      type: "REFUND",
      subType: "REFUND",
      categoryId: "227",
      category: "Refunds/Adjustments",
      categoryType: "INCOME",
      detailCategoryId: "1188",
      date: "2022-11-15",
      transactionDate: "2022-11-15",
      postDate: "2022-11-15",
    },
  ],
  cashBalances: [],
  totalIncome: 98287.51999999999,
  incomes: [
    {
      time: "2022-07-19",
      amount: 3347.3599999999997,
    },
    {
      time: "2022-07-20",
      amount: 469.6,
    },
    {
      time: "2022-07-24",
      amount: 1384.53,
    },
    {
      time: "2022-07-25",
      amount: 1705.02,
    },
    {
      time: "2022-07-31",
      amount: 15000,
    },
    {
      time: "2022-08-02",
      amount: 3699.92,
    },
    {
      time: "2022-08-03",
      amount: 2721.24,
    },
    {
      time: "2022-08-07",
      amount: 1798.39,
    },
    {
      time: "2022-08-09",
      amount: 954.88,
    },
    {
      time: "2022-08-10",
      amount: 366.3,
    },
    {
      time: "2022-08-11",
      amount: 738.12,
    },
    {
      time: "2022-08-14",
      amount: 859.28,
    },
    {
      time: "2022-08-20",
      amount: 224.82,
    },
    {
      time: "2022-08-24",
      amount: 1596.45,
    },
    {
      time: "2022-08-31",
      amount: 15000,
    },
    {
      time: "2022-09-07",
      amount: 311.64,
    },
    {
      time: "2022-09-15",
      amount: 577.52,
    },
    {
      time: "2022-09-17",
      amount: 86.04,
    },
    {
      time: "2022-09-21",
      amount: 752.06,
    },
    {
      time: "2022-09-24",
      amount: 931.64,
    },
    {
      time: "2022-09-26",
      amount: 1959.4,
    },
    {
      time: "2022-09-30",
      amount: 15000,
    },
    {
      time: "2022-10-01",
      amount: 142.87,
    },
    {
      time: "2022-10-07",
      amount: 2249.2400000000002,
    },
    {
      time: "2022-10-08",
      amount: 1581.62,
    },
    {
      time: "2022-10-14",
      amount: 2847,
    },
    {
      time: "2022-10-15",
      amount: 1630.42,
    },
    {
      time: "2022-10-16",
      amount: 532.31,
    },
    {
      time: "2022-10-21",
      amount: 1831.76,
    },
    {
      time: "2022-10-23",
      amount: 1538.52,
    },
    {
      time: "2022-10-31",
      amount: 15065.82,
    },
    {
      time: "2022-11-14",
      amount: 1383.75,
    },
  ],
  incomeSources: [
    {
      source: "Interest",
      amount: 4121.129999999999,
    },
    {
      source: "Services",
      amount: 4391.09,
    },
    {
      source: "Expense Reimbursement",
      amount: 2121.56,
    },
    {
      source: "Deposits",
      amount: 5286.889999999999,
    },
    {
      source: "Salary",
      amount: 61879.66,
    },
    {
      source: "Refunds/Adjustments",
      amount: 10767.23,
    },
    {
      source: "Investment Income",
      amount: 172.75,
    },
    {
      source: "Sales",
      amount: 3294.11,
    },
    {
      source: "Rewards",
      amount: 3132.96,
    },
    {
      source: "Retirement Income",
      amount: 1581.62,
    },
    {
      source: "Consulting",
      amount: 1538.52,
    },
  ],
  totalIncoming: 98287.51999999999,
  totalExpense: -28540.03,
  expenses: [
    {
      time: "2022-07-17",
      amount: -543.31,
    },
    {
      time: "2022-07-19",
      amount: -762.65,
    },
    {
      time: "2022-07-21",
      amount: -807.28,
    },
    {
      time: "2022-07-22",
      amount: -1261.1299999999999,
    },
    {
      time: "2022-07-25",
      amount: -706.56,
    },
    {
      time: "2022-08-01",
      amount: -741.97,
    },
    {
      time: "2022-08-03",
      amount: -875.15,
    },
    {
      time: "2022-08-06",
      amount: -786.18,
    },
    {
      time: "2022-08-11",
      amount: -970.61,
    },
    {
      time: "2022-08-16",
      amount: -390.07,
    },
    {
      time: "2022-08-17",
      amount: -384.63,
    },
    {
      time: "2022-08-19",
      amount: -959.17,
    },
    {
      time: "2022-08-21",
      amount: -335.28,
    },
    {
      time: "2022-08-23",
      amount: -904,
    },
    {
      time: "2022-09-01",
      amount: -239.58,
    },
    {
      time: "2022-09-03",
      amount: -941.38,
    },
    {
      time: "2022-09-04",
      amount: -338.11,
    },
    {
      time: "2022-09-08",
      amount: -149.86,
    },
    {
      time: "2022-09-10",
      amount: -222.18,
    },
    {
      time: "2022-09-13",
      amount: -91.55,
    },
    {
      time: "2022-09-14",
      amount: -822.9,
    },
    {
      time: "2022-09-15",
      amount: -819.78,
    },
    {
      time: "2022-09-16",
      amount: -73.01,
    },
    {
      time: "2022-09-17",
      amount: -993,
    },
    {
      time: "2022-09-19",
      amount: -1044.99,
    },
    {
      time: "2022-09-21",
      amount: -419.78,
    },
    {
      time: "2022-09-22",
      amount: -841.01,
    },
    {
      time: "2022-10-10",
      amount: -537.36,
    },
    {
      time: "2022-10-11",
      amount: -261.29,
    },
    {
      time: "2022-10-14",
      amount: -501.24,
    },
    {
      time: "2022-10-16",
      amount: -2102.76,
    },
    {
      time: "2022-10-17",
      amount: -1375.44,
    },
    {
      time: "2022-10-20",
      amount: -537.45,
    },
    {
      time: "2022-10-26",
      amount: -746.39,
    },
    {
      time: "2022-11-03",
      amount: -785.71,
    },
    {
      time: "2022-11-04",
      amount: -957.65,
    },
    {
      time: "2022-11-11",
      amount: -838.4,
    },
    {
      time: "2022-11-13",
      amount: -855.7,
    },
    {
      time: "2022-11-14",
      amount: -1615.52,
    },
  ],
  expenseCategories: [
    {
      category: "Clothing Shoes",
      amount: -282.33,
    },
    {
      category: "Advertising",
      amount: -1785.46,
    },
    {
      category: "Postage And Shipping",
      amount: -1074.24,
    },
    {
      category: "Business Miscellaneous",
      amount: -807.28,
    },
    {
      category: "Insurance",
      amount: -2111.68,
    },
    {
      category: "Office Supplies",
      amount: -2091.21,
    },
    {
      category: "Office Maintenance",
      amount: -983.29,
    },
    {
      category: "Home Improvement",
      amount: -1699.1100000000001,
    },
    {
      category: "Entertainment",
      amount: -1319.06,
    },
    {
      category: "Cable Satellite Services",
      amount: -786.18,
    },
    {
      category: "Online Services",
      amount: -276.73,
    },
    {
      category: "Pets Pet Care",
      amount: -753.43,
    },
    {
      category: "Dues And Subscriptions",
      amount: -1245.77,
    },
    {
      category: "Automotive Expenses",
      amount: -1032.18,
    },
    {
      category: "Gasoline Fuel",
      amount: -1165.29,
    },
    {
      category: "Utilities",
      amount: -1232.58,
    },
    {
      category: "Restaurants Dining",
      amount: -3402.96,
    },
    {
      category: "Loans",
      amount: -338.11,
    },
    {
      category: "Hobbies",
      amount: -280.43,
    },
    {
      category: "Healthcare Medical",
      amount: -1798.6599999999999,
    },
    {
      category: "Personal Care",
      amount: -501.24,
    },
    {
      category: "Gifts",
      amount: -1233.79,
    },
    {
      category: "General Merchandise",
      amount: -754.23,
    },
    {
      category: "Rent",
      amount: -1584.79,
    },
  ],
  totalOutgoing: -28540.03,
  livingExpenseCategories: [
    {
      category: "Clothing Shoes",
      amount: -282.33,
    },
    {
      category: "Advertising",
      amount: -1785.46,
    },
    {
      category: "Postage And Shipping",
      amount: -1074.24,
    },
    {
      category: "Business Miscellaneous",
      amount: -807.28,
    },
    {
      category: "Insurance",
      amount: -2111.68,
    },
    {
      category: "Office Supplies",
      amount: -2091.21,
    },
    {
      category: "Office Maintenance",
      amount: -983.29,
    },
    {
      category: "Home Improvement",
      amount: -1699.1100000000001,
    },
    {
      category: "Entertainment",
      amount: -1319.06,
    },
    {
      category: "Cable Satellite Services",
      amount: -786.18,
    },
    {
      category: "Online Services",
      amount: -276.73,
    },
    {
      category: "Pets Pet Care",
      amount: -753.43,
    },
    {
      category: "Dues And Subscriptions",
      amount: -1245.77,
    },
    {
      category: "Automotive Expenses",
      amount: -1032.18,
    },
    {
      category: "Gasoline Fuel",
      amount: -1165.29,
    },
    {
      category: "Utilities",
      amount: -1232.58,
    },
    {
      category: "Restaurants Dining",
      amount: -3402.96,
    },
    {
      category: "Loans",
      amount: -338.11,
    },
    {
      category: "Hobbies",
      amount: -280.43,
    },
    {
      category: "Healthcare Medical",
      amount: -1798.6599999999999,
    },
    {
      category: "Personal Care",
      amount: -501.24,
    },
    {
      category: "Gifts",
      amount: -1233.79,
    },
    {
      category: "General Merchandise",
      amount: -754.23,
    },
    {
      category: "Rent",
      amount: -1584.79,
    },
  ],
};

export const HISTORY_ALL_DAYS_MOCKUP_DATA = {
  transactions: [
    {
      id: "634218141",
      status: "posted",
      description: "Gifts",
      amount: -436.19,
      account: "11409640",
      balance: 1002789.3199999998,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "9",
      category: "Gifts",
      categoryType: "EXPENSE",
      detailCategoryId: "1420",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218142",
      status: "posted",
      description: "General Merchandise",
      amount: -754.23,
      account: "11409640",
      balance: 1002035.0899999999,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "44",
      category: "General Merchandise",
      categoryType: "EXPENSE",
      detailCategoryId: "1306",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218143",
      status: "posted",
      description: "Deposits",
      amount: 532.31,
      account: "11409640",
      balance: 1002567.3999999999,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218144",
      status: "posted",
      description: "Insurance",
      amount: -912.34,
      account: "11409640",
      balance: 1001655.0599999999,
      direction: "debit",
      type: "PAYMENT",
      subType: "INSURANCE_PAYMENT",
      categoryId: "14",
      category: "Insurance",
      categoryType: "EXPENSE",
      detailCategoryId: "1512",
      date: "2022-10-16",
      transactionDate: "2022-10-16",
      postDate: "2022-10-16",
    },
    {
      id: "634218138",
      status: "posted",
      description: "Entertainment",
      amount: -221.73,
      account: "11409640",
      balance: 1001433.33,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "7",
      category: "Entertainment",
      categoryType: "EXPENSE",
      detailCategoryId: "1352",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218139",
      status: "posted",
      description: "Postage/Shipping",
      amount: -311.59,
      account: "11409640",
      balance: 1001121.74,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "104",
      category: "Postage and Shipping",
      categoryType: "EXPENSE",
      detailCategoryId: "1561",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218140",
      status: "posted",
      description: "Restaurants/Dining",
      amount: -842.12,
      account: "11409640",
      balance: 1000279.62,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "22",
      category: "Restaurants/Dining",
      categoryType: "EXPENSE",
      detailCategoryId: "1589",
      date: "2022-10-17",
      transactionDate: "2022-10-17",
      postDate: "2022-10-17",
    },
    {
      id: "634218137",
      status: "posted",
      description: "Office Supplies",
      amount: -537.45,
      account: "11409640",
      balance: 999742.17,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "45",
      category: "Office Supplies",
      categoryType: "EXPENSE",
      detailCategoryId: "1177",
      date: "2022-10-20",
      transactionDate: "2022-10-20",
      postDate: "2022-10-20",
    },
    {
      id: "634218136",
      status: "posted",
      description: "Deposits",
      amount: 1831.76,
      account: "11409640",
      balance: 1001573.93,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-10-21",
      transactionDate: "2022-10-21",
      postDate: "2022-10-21",
    },
    {
      id: "634218135",
      status: "posted",
      description: "Consulting",
      amount: 1538.52,
      account: "11409640",
      balance: 1003112.4500000001,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "98",
      category: "Services",
      categoryType: "INCOME",
      detailCategoryId: "1203",
      date: "2022-10-23",
      transactionDate: "2022-10-23",
      postDate: "2022-10-23",
    },
    {
      id: "634218134",
      status: "posted",
      description: "Rent",
      amount: -746.39,
      account: "11409640",
      balance: 1002366.06,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "21",
      category: "Rent",
      categoryType: "EXPENSE",
      detailCategoryId: "1165",
      date: "2022-10-26",
      transactionDate: "2022-10-26",
      postDate: "2022-10-26",
    },
    {
      id: "634218133",
      status: "posted",
      description: "Sales",
      amount: 65.82,
      account: "11409640",
      balance: 1002431.88,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DIRECT_DEPOSIT_SALARY",
      categoryId: "29",
      category: "Paychecks/Salary",
      categoryType: "INCOME",
      detailCategoryId: "1183",
      date: "2022-10-31",
      transactionDate: "2022-10-31",
      postDate: "2022-10-31",
    },
    {
      id: "634219516",
      status: "posted",
      description: "Salary",
      amount: 15000,
      account: "11409640",
      balance: 1017431.88,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DIRECT_DEPOSIT_SALARY",
      categoryId: "29",
      category: "Paychecks/Salary",
      categoryType: "INCOME",
      detailCategoryId: "1183",
      date: "2022-10-31",
      transactionDate: "2022-10-31",
      postDate: "2022-10-31",
    },
    {
      id: "634218132",
      status: "posted",
      description: "Office Supplies",
      amount: -785.71,
      account: "11409640",
      balance: 1016646.17,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "45",
      category: "Office Supplies",
      categoryType: "EXPENSE",
      detailCategoryId: "1177",
      date: "2022-11-03",
      transactionDate: "2022-11-03",
      postDate: "2022-11-03",
    },
    {
      id: "634218131",
      status: "posted",
      description: "Healthcare/Medical",
      amount: -957.65,
      account: "11409640",
      balance: 1015688.52,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "11",
      category: "Healthcare/Medical",
      categoryType: "EXPENSE",
      detailCategoryId: "1453",
      date: "2022-11-04",
      transactionDate: "2022-11-04",
      postDate: "2022-11-04",
    },
    {
      id: "634218130",
      status: "posted",
      description: "Rent",
      amount: -838.4,
      account: "11409640",
      balance: 1014850.12,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "21",
      category: "Rent",
      categoryType: "EXPENSE",
      detailCategoryId: "1165",
      date: "2022-11-11",
      transactionDate: "2022-11-11",
      postDate: "2022-11-11",
    },
    {
      id: "634218129",
      status: "posted",
      description: "Dues and Subscriptions",
      amount: -855.7,
      account: "11409640",
      balance: 1013994.42,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "108",
      category: "Dues and Subscriptions",
      categoryType: "EXPENSE",
      detailCategoryId: "1181",
      date: "2022-11-13",
      transactionDate: "2022-11-13",
      postDate: "2022-11-13",
    },
    {
      id: "634218125",
      status: "posted",
      description: "Deposits",
      amount: 1171.99,
      account: "11409640",
      balance: 1015166.41,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "DEPOSIT",
      categoryId: "27",
      category: "Deposits",
      categoryType: "INCOME",
      detailCategoryId: "1195",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218126",
      status: "posted",
      description: "Services",
      amount: 211.76,
      account: "11409640",
      balance: 1015378.17,
      direction: "credit",
      type: "DEPOSITS_CREDITS",
      subType: "CREDIT",
      categoryId: "98",
      category: "Services",
      categoryType: "INCOME",
      detailCategoryId: "1203",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218127",
      status: "posted",
      description: "Advertising",
      amount: -817.92,
      account: "11409640",
      balance: 1014560.25,
      direction: "debit",
      type: "PAYMENT",
      subType: "PAYMENT",
      categoryId: "100",
      category: "Advertising",
      categoryType: "EXPENSE",
      detailCategoryId: "1514",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218128",
      status: "posted",
      description: "Gifts",
      amount: -797.6,
      account: "11409640",
      balance: 1013762.65,
      direction: "debit",
      type: "PURCHASE",
      subType: "PURCHASE",
      categoryId: "9",
      category: "Gifts",
      categoryType: "EXPENSE",
      detailCategoryId: "1420",
      date: "2022-11-14",
      transactionDate: "2022-11-14",
      postDate: "2022-11-14",
    },
    {
      id: "634218124",
      status: "posted",
      description: "Refunds/Adjustments",
      amount: 749.16,
      account: "11409640",
      balance: 1014511.81,
      direction: "credit",
      type: "REFUND",
      subType: "REFUND",
      categoryId: "227",
      category: "Refunds/Adjustments",
      categoryType: "INCOME",
      detailCategoryId: "1188",
      date: "2022-11-15",
      transactionDate: "2022-11-15",
      postDate: "2022-11-15",
    },
  ],
  cashBalances: [],
  totalIncome: 580090.2600000004,
  incomes: [
    {
      time: "2020-12-31",
      amount: 15000,
    },
    {
      time: "2021-01-08",
      amount: 1371.96,
    },
    {
      time: "2021-01-11",
      amount: 309.41,
    },
    {
      time: "2021-01-13",
      amount: 192.75,
    },
    {
      time: "2021-01-15",
      amount: 1059.79,
    },
    {
      time: "2021-01-16",
      amount: 1690.82,
    },
    {
      time: "2021-01-17",
      amount: 1941.37,
    },
    {
      time: "2021-01-28",
      amount: 264.4,
    },
    {
      time: "2021-01-29",
      amount: 295.45,
    },
    {
      time: "2021-01-31",
      amount: 16008.84,
    },
    {
      time: "2021-02-02",
      amount: 1806.21,
    },
    {
      time: "2021-02-06",
      amount: 1129.59,
    },
    {
      time: "2021-02-09",
      amount: 68.11,
    },
    {
      time: "2021-02-13",
      amount: 848.35,
    },
    {
      time: "2021-02-17",
      amount: 1319.12,
    },
    {
      time: "2021-02-20",
      amount: 792.45,
    },
    {
      time: "2021-02-26",
      amount: 475.5,
    },
    {
      time: "2021-02-28",
      amount: 15000,
    },
    {
      time: "2021-03-04",
      amount: 1209.6,
    },
    {
      time: "2021-03-05",
      amount: 1842.87,
    },
    {
      time: "2021-03-11",
      amount: 374.17,
    },
    {
      time: "2021-03-12",
      amount: 1712.66,
    },
    {
      time: "2021-03-15",
      amount: 1281.11,
    },
    {
      time: "2021-03-16",
      amount: 1528.59,
    },
    {
      time: "2021-03-19",
      amount: 2138.1,
    },
    {
      time: "2021-03-26",
      amount: 559.13,
    },
    {
      time: "2021-03-27",
      amount: 1756.06,
    },
    {
      time: "2021-03-28",
      amount: 73.9,
    },
    {
      time: "2021-03-29",
      amount: 674.49,
    },
    {
      time: "2021-03-30",
      amount: 1160.88,
    },
    {
      time: "2021-03-31",
      amount: 15529.35,
    },
    {
      time: "2021-04-04",
      amount: 374.4,
    },
    {
      time: "2021-04-11",
      amount: 449.34000000000003,
    },
    {
      time: "2021-04-12",
      amount: 1706.07,
    },
    {
      time: "2021-04-16",
      amount: 1843.78,
    },
    {
      time: "2021-04-19",
      amount: 1799.17,
    },
    {
      time: "2021-04-27",
      amount: 1120.03,
    },
    {
      time: "2021-04-28",
      amount: 375.53,
    },
    {
      time: "2021-04-30",
      amount: 15000,
    },
    {
      time: "2021-05-03",
      amount: 930.57,
    },
    {
      time: "2021-05-04",
      amount: 824.92,
    },
    {
      time: "2021-05-07",
      amount: 1637.53,
    },
    {
      time: "2021-05-11",
      amount: 1275.95,
    },
    {
      time: "2021-05-12",
      amount: 664.04,
    },
    {
      time: "2021-05-17",
      amount: 20.7,
    },
    {
      time: "2021-05-21",
      amount: 1434.71,
    },
    {
      time: "2021-05-24",
      amount: 1816.78,
    },
    {
      time: "2021-05-26",
      amount: 44.15,
    },
    {
      time: "2021-05-28",
      amount: 957.68,
    },
    {
      time: "2021-05-31",
      amount: 16233.22,
    },
    {
      time: "2021-06-02",
      amount: 1146.28,
    },
    {
      time: "2021-06-04",
      amount: 2685.3900000000003,
    },
    {
      time: "2021-06-06",
      amount: 1574.12,
    },
    {
      time: "2021-06-12",
      amount: 971.49,
    },
    {
      time: "2021-06-15",
      amount: 406.73,
    },
    {
      time: "2021-06-16",
      amount: 1004.25,
    },
    {
      time: "2021-06-18",
      amount: 1608.24,
    },
    {
      time: "2021-06-24",
      amount: 155.04,
    },
    {
      time: "2021-06-25",
      amount: 1086.89,
    },
    {
      time: "2021-06-26",
      amount: 695.09,
    },
    {
      time: "2021-06-27",
      amount: 405.85,
    },
    {
      time: "2021-06-30",
      amount: 15000,
    },
    {
      time: "2021-07-10",
      amount: 746.76,
    },
    {
      time: "2021-07-11",
      amount: 755.5200000000001,
    },
    {
      time: "2021-07-14",
      amount: 1157.3,
    },
    {
      time: "2021-07-16",
      amount: 2975.73,
    },
    {
      time: "2021-07-19",
      amount: 66.56,
    },
    {
      time: "2021-07-21",
      amount: 576.28,
    },
    {
      time: "2021-07-22",
      amount: 1009.1,
    },
    {
      time: "2021-07-25",
      amount: 1079.25,
    },
    {
      time: "2021-07-26",
      amount: 1117.64,
    },
    {
      time: "2021-07-29",
      amount: 1873.84,
    },
    {
      time: "2021-07-31",
      amount: 15000,
    },
    {
      time: "2021-08-01",
      amount: 850.26,
    },
    {
      time: "2021-08-03",
      amount: 1165.19,
    },
    {
      time: "2021-08-05",
      amount: 836.99,
    },
    {
      time: "2021-08-15",
      amount: 63.06,
    },
    {
      time: "2021-08-19",
      amount: 1713.79,
    },
    {
      time: "2021-08-21",
      amount: 424.73,
    },
    {
      time: "2021-08-25",
      amount: 1823.51,
    },
    {
      time: "2021-08-31",
      amount: 15000,
    },
    {
      time: "2021-09-02",
      amount: 25.27,
    },
    {
      time: "2021-09-08",
      amount: 774.3,
    },
    {
      time: "2021-09-12",
      amount: 210.23,
    },
    {
      time: "2021-09-16",
      amount: 1582.61,
    },
    {
      time: "2021-09-19",
      amount: 1664.73,
    },
    {
      time: "2021-09-21",
      amount: 264.64,
    },
    {
      time: "2021-09-23",
      amount: 1954.64,
    },
    {
      time: "2021-09-25",
      amount: 3200.3599999999997,
    },
    {
      time: "2021-09-27",
      amount: 293.42,
    },
    {
      time: "2021-09-28",
      amount: 1419.38,
    },
    {
      time: "2021-09-30",
      amount: 17121.96,
    },
    {
      time: "2021-10-01",
      amount: 1033.81,
    },
    {
      time: "2021-10-04",
      amount: 241.59,
    },
    {
      time: "2021-10-07",
      amount: 1827.79,
    },
    {
      time: "2021-10-11",
      amount: 1936.48,
    },
    {
      time: "2021-10-24",
      amount: 633.28,
    },
    {
      time: "2021-10-31",
      amount: 15000,
    },
    {
      time: "2021-11-02",
      amount: 1725.02,
    },
    {
      time: "2021-11-05",
      amount: 1713.41,
    },
    {
      time: "2021-11-06",
      amount: 1933.8,
    },
    {
      time: "2021-11-10",
      amount: 345.38,
    },
    {
      time: "2021-11-17",
      amount: 1798.47,
    },
    {
      time: "2021-11-18",
      amount: 702.23,
    },
    {
      time: "2021-11-25",
      amount: 3458.13,
    },
    {
      time: "2021-11-26",
      amount: 966.95,
    },
    {
      time: "2021-11-28",
      amount: 3771.34,
    },
    {
      time: "2021-11-30",
      amount: 15000,
    },
    {
      time: "2021-12-09",
      amount: 1559.37,
    },
    {
      time: "2021-12-22",
      amount: 470.53,
    },
    {
      time: "2021-12-25",
      amount: 1171.04,
    },
    {
      time: "2021-12-29",
      amount: 763.53,
    },
    {
      time: "2021-12-30",
      amount: 1986,
    },
    {
      time: "2021-12-31",
      amount: 18194.5,
    },
    {
      time: "2022-01-03",
      amount: 449.91,
    },
    {
      time: "2022-01-04",
      amount: 789.22,
    },
    {
      time: "2022-01-09",
      amount: 1124.12,
    },
    {
      time: "2022-01-10",
      amount: 1312.37,
    },
    {
      time: "2022-01-11",
      amount: 1777.83,
    },
    {
      time: "2022-01-15",
      amount: 344.69,
    },
    {
      time: "2022-01-20",
      amount: 194.19,
    },
    {
      time: "2022-01-27",
      amount: 935.31,
    },
    {
      time: "2022-01-28",
      amount: 1819.55,
    },
    {
      time: "2022-01-31",
      amount: 16896.05,
    },
    {
      time: "2022-02-04",
      amount: 2591.17,
    },
    {
      time: "2022-02-05",
      amount: 1558.81,
    },
    {
      time: "2022-02-07",
      amount: 1063.19,
    },
    {
      time: "2022-02-14",
      amount: 1341.59,
    },
    {
      time: "2022-02-23",
      amount: 2589.65,
    },
    {
      time: "2022-02-25",
      amount: 364.33,
    },
    {
      time: "2022-02-26",
      amount: 1388.88,
    },
    {
      time: "2022-02-28",
      amount: 15000,
    },
    {
      time: "2022-03-01",
      amount: 975.37,
    },
    {
      time: "2022-03-05",
      amount: 557.66,
    },
    {
      time: "2022-03-07",
      amount: 131.32,
    },
    {
      time: "2022-03-09",
      amount: 2055.5,
    },
    {
      time: "2022-03-12",
      amount: 641.39,
    },
    {
      time: "2022-03-13",
      amount: 1458.11,
    },
    {
      time: "2022-03-20",
      amount: 282,
    },
    {
      time: "2022-03-23",
      amount: 1223.74,
    },
    {
      time: "2022-03-24",
      amount: 1288.58,
    },
    {
      time: "2022-03-28",
      amount: 1464.13,
    },
    {
      time: "2022-03-31",
      amount: 15000,
    },
    {
      time: "2022-04-04",
      amount: 156.05,
    },
    {
      time: "2022-04-05",
      amount: 1525.38,
    },
    {
      time: "2022-04-06",
      amount: 147.07,
    },
    {
      time: "2022-04-08",
      amount: 2410.72,
    },
    {
      time: "2022-04-09",
      amount: 408.69,
    },
    {
      time: "2022-04-11",
      amount: 342.82,
    },
    {
      time: "2022-04-12",
      amount: 1724.07,
    },
    {
      time: "2022-04-13",
      amount: 1687.28,
    },
    {
      time: "2022-04-15",
      amount: 1879,
    },
    {
      time: "2022-04-18",
      amount: 2535.5,
    },
    {
      time: "2022-04-21",
      amount: 1126.52,
    },
    {
      time: "2022-04-23",
      amount: 1409.8,
    },
    {
      time: "2022-04-26",
      amount: 3453.58,
    },
    {
      time: "2022-04-30",
      amount: 15484.81,
    },
    {
      time: "2022-05-01",
      amount: 317.21,
    },
    {
      time: "2022-05-04",
      amount: 963.53,
    },
    {
      time: "2022-05-05",
      amount: 747.88,
    },
    {
      time: "2022-05-14",
      amount: 144.95,
    },
    {
      time: "2022-05-17",
      amount: 412.23,
    },
    {
      time: "2022-05-18",
      amount: 1006.12,
    },
    {
      time: "2022-05-24",
      amount: 798.01,
    },
    {
      time: "2022-05-26",
      amount: 493.28,
    },
    {
      time: "2022-05-27",
      amount: 1040.94,
    },
    {
      time: "2022-05-28",
      amount: 373.57,
    },
    {
      time: "2022-05-31",
      amount: 15774.03,
    },
    {
      time: "2022-06-01",
      amount: 466.19,
    },
    {
      time: "2022-06-04",
      amount: 73.54,
    },
    {
      time: "2022-06-05",
      amount: 78.24,
    },
    {
      time: "2022-06-07",
      amount: 1575.5700000000002,
    },
    {
      time: "2022-06-08",
      amount: 2504.82,
    },
    {
      time: "2022-06-10",
      amount: 1324.2099999999998,
    },
    {
      time: "2022-06-15",
      amount: 364.97,
    },
    {
      time: "2022-06-16",
      amount: 1134,
    },
    {
      time: "2022-06-18",
      amount: 897.1,
    },
    {
      time: "2022-06-26",
      amount: 1120.92,
    },
    {
      time: "2022-06-30",
      amount: 16608.13,
    },
    {
      time: "2022-07-01",
      amount: 1568.96,
    },
    {
      time: "2022-07-06",
      amount: 985.56,
    },
    {
      time: "2022-07-07",
      amount: 1068.68,
    },
    {
      time: "2022-07-09",
      amount: 477.17,
    },
    {
      time: "2022-07-10",
      amount: 930.31,
    },
    {
      time: "2022-07-19",
      amount: 3347.3599999999997,
    },
    {
      time: "2022-07-20",
      amount: 469.6,
    },
    {
      time: "2022-07-24",
      amount: 1384.53,
    },
    {
      time: "2022-07-25",
      amount: 1705.02,
    },
    {
      time: "2022-07-31",
      amount: 15000,
    },
    {
      time: "2022-08-02",
      amount: 3699.92,
    },
    {
      time: "2022-08-03",
      amount: 2721.24,
    },
    {
      time: "2022-08-07",
      amount: 1798.39,
    },
    {
      time: "2022-08-09",
      amount: 954.88,
    },
    {
      time: "2022-08-10",
      amount: 366.3,
    },
    {
      time: "2022-08-11",
      amount: 738.12,
    },
    {
      time: "2022-08-14",
      amount: 859.28,
    },
    {
      time: "2022-08-20",
      amount: 224.82,
    },
    {
      time: "2022-08-24",
      amount: 1596.45,
    },
    {
      time: "2022-08-31",
      amount: 15000,
    },
    {
      time: "2022-09-07",
      amount: 311.64,
    },
    {
      time: "2022-09-15",
      amount: 577.52,
    },
    {
      time: "2022-09-17",
      amount: 86.04,
    },
    {
      time: "2022-09-21",
      amount: 752.06,
    },
    {
      time: "2022-09-24",
      amount: 931.64,
    },
    {
      time: "2022-09-26",
      amount: 1959.4,
    },
    {
      time: "2022-09-30",
      amount: 15000,
    },
    {
      time: "2022-10-01",
      amount: 142.87,
    },
    {
      time: "2022-10-07",
      amount: 2249.2400000000002,
    },
    {
      time: "2022-10-08",
      amount: 1581.62,
    },
    {
      time: "2022-10-14",
      amount: 2847,
    },
    {
      time: "2022-10-15",
      amount: 1630.42,
    },
    {
      time: "2022-10-16",
      amount: 532.31,
    },
    {
      time: "2022-10-21",
      amount: 1831.76,
    },
    {
      time: "2022-10-23",
      amount: 1538.52,
    },
    {
      time: "2022-10-31",
      amount: 15065.82,
    },
    {
      time: "2022-11-14",
      amount: 1383.75,
    },
  ],
  incomeSources: [
    {
      source: "Salary",
      amount: 355362.89999999997,
    },
    {
      source: "Other Income",
      amount: 18472.16,
    },
    {
      source: "Investment Income",
      amount: 19619.249999999993,
    },
    {
      source: "Consulting",
      amount: 16432.33,
    },
    {
      source: "Interest",
      amount: 19783.850000000002,
    },
    {
      source: "Services",
      amount: 24247.059999999998,
    },
    {
      source: "Deposits",
      amount: 19195.6,
    },
    {
      source: "Rewards",
      amount: 17062.64,
    },
    {
      source: "Refunds/Adjustments",
      amount: 23668.769999999997,
    },
    {
      source: "Expense Reimbursement",
      amount: 20911.049999999996,
    },
    {
      source: "Retirement Income",
      amount: 23722.4,
    },
    {
      source: "Sales",
      amount: 21612.249999999996,
    },
  ],
  totalIncoming: 580090.2600000004,
  totalExpense: -131429.39999999997,
  expenses: [
    {
      time: "2021-01-08",
      amount: -615.31,
    },
    {
      time: "2021-01-09",
      amount: -782,
    },
    {
      time: "2021-01-16",
      amount: -420.65,
    },
    {
      time: "2021-01-17",
      amount: -337.12,
    },
    {
      time: "2021-01-19",
      amount: -225.76,
    },
    {
      time: "2021-01-21",
      amount: -650.38,
    },
    {
      time: "2021-01-22",
      amount: -767.12,
    },
    {
      time: "2021-02-03",
      amount: -811.66,
    },
    {
      time: "2021-02-05",
      amount: -779.58,
    },
    {
      time: "2021-02-06",
      amount: -762.46,
    },
    {
      time: "2021-02-07",
      amount: -904.27,
    },
    {
      time: "2021-02-09",
      amount: -197.52,
    },
    {
      time: "2021-02-14",
      amount: -901.26,
    },
    {
      time: "2021-02-15",
      amount: -629.5,
    },
    {
      time: "2021-02-18",
      amount: -231.45,
    },
    {
      time: "2021-02-21",
      amount: -256.35,
    },
    {
      time: "2021-02-22",
      amount: -487.54,
    },
    {
      time: "2021-02-25",
      amount: -934.73,
    },
    {
      time: "2021-02-27",
      amount: -780.84,
    },
    {
      time: "2021-03-01",
      amount: -901.13,
    },
    {
      time: "2021-03-02",
      amount: -633.59,
    },
    {
      time: "2021-03-04",
      amount: -210.06,
    },
    {
      time: "2021-03-05",
      amount: -793.77,
    },
    {
      time: "2021-03-11",
      amount: -806.06,
    },
    {
      time: "2021-03-13",
      amount: -267.89,
    },
    {
      time: "2021-03-14",
      amount: -240.93,
    },
    {
      time: "2021-03-15",
      amount: -54.54,
    },
    {
      time: "2021-03-30",
      amount: -162.81,
    },
    {
      time: "2021-03-31",
      amount: -955.11,
    },
    {
      time: "2021-04-04",
      amount: -80.17,
    },
    {
      time: "2021-04-05",
      amount: -123.27,
    },
    {
      time: "2021-04-06",
      amount: -847.37,
    },
    {
      time: "2021-04-08",
      amount: -296.04,
    },
    {
      time: "2021-04-10",
      amount: -142.65,
    },
    {
      time: "2021-04-15",
      amount: -302.66,
    },
    {
      time: "2021-04-21",
      amount: -1801.1,
    },
    {
      time: "2021-04-22",
      amount: -982.39,
    },
    {
      time: "2021-04-23",
      amount: -676.73,
    },
    {
      time: "2021-04-25",
      amount: -267.5,
    },
    {
      time: "2021-05-01",
      amount: -50.5,
    },
    {
      time: "2021-05-04",
      amount: -658.31,
    },
    {
      time: "2021-05-11",
      amount: -1652.54,
    },
    {
      time: "2021-05-12",
      amount: -651.3,
    },
    {
      time: "2021-05-16",
      amount: -685.49,
    },
    {
      time: "2021-05-20",
      amount: -327.53,
    },
    {
      time: "2021-05-21",
      amount: -366.64,
    },
    {
      time: "2021-05-23",
      amount: -1109.79,
    },
    {
      time: "2021-05-27",
      amount: -750.25,
    },
    {
      time: "2021-05-29",
      amount: -682.67,
    },
    {
      time: "2021-05-31",
      amount: -539.55,
    },
    {
      time: "2021-06-01",
      amount: -799.56,
    },
    {
      time: "2021-06-03",
      amount: -769.68,
    },
    {
      time: "2021-06-06",
      amount: -122.52,
    },
    {
      time: "2021-06-10",
      amount: -285.27,
    },
    {
      time: "2021-06-13",
      amount: -383.31,
    },
    {
      time: "2021-06-16",
      amount: -584.76,
    },
    {
      time: "2021-06-18",
      amount: -1663.22,
    },
    {
      time: "2021-06-28",
      amount: -143.49,
    },
    {
      time: "2021-07-03",
      amount: -363.95,
    },
    {
      time: "2021-07-04",
      amount: -928.03,
    },
    {
      time: "2021-07-08",
      amount: -952.53,
    },
    {
      time: "2021-07-09",
      amount: -193.07,
    },
    {
      time: "2021-07-11",
      amount: -1012.02,
    },
    {
      time: "2021-07-12",
      amount: -917.42,
    },
    {
      time: "2021-07-16",
      amount: -230.25,
    },
    {
      time: "2021-07-19",
      amount: -605.3,
    },
    {
      time: "2021-07-22",
      amount: -720.42,
    },
    {
      time: "2021-08-01",
      amount: -546.39,
    },
    {
      time: "2021-08-02",
      amount: -111.97,
    },
    {
      time: "2021-08-06",
      amount: -478.96,
    },
    {
      time: "2021-08-12",
      amount: -61.17,
    },
    {
      time: "2021-08-19",
      amount: -334.95,
    },
    {
      time: "2021-08-21",
      amount: -925.03,
    },
    {
      time: "2021-08-22",
      amount: -1331.87,
    },
    {
      time: "2021-08-25",
      amount: -273.7,
    },
    {
      time: "2021-08-26",
      amount: -1130.01,
    },
    {
      time: "2021-09-02",
      amount: -860.48,
    },
    {
      time: "2021-09-13",
      amount: -850.2,
    },
    {
      time: "2021-09-14",
      amount: -855.65,
    },
    {
      time: "2021-09-15",
      amount: -860.4,
    },
    {
      time: "2021-09-17",
      amount: -1046.5900000000001,
    },
    {
      time: "2021-09-22",
      amount: -501.07,
    },
    {
      time: "2021-09-25",
      amount: -964.43,
    },
    {
      time: "2021-09-27",
      amount: -616.55,
    },
    {
      time: "2021-09-29",
      amount: -431.05,
    },
    {
      time: "2021-10-01",
      amount: -911.36,
    },
    {
      time: "2021-10-03",
      amount: -626.92,
    },
    {
      time: "2021-10-05",
      amount: -922.15,
    },
    {
      time: "2021-10-08",
      amount: -487.83,
    },
    {
      time: "2021-10-09",
      amount: -757.25,
    },
    {
      time: "2021-10-10",
      amount: -105.32,
    },
    {
      time: "2021-10-22",
      amount: -399.69,
    },
    {
      time: "2021-10-28",
      amount: -695.23,
    },
    {
      time: "2021-10-29",
      amount: -438.24,
    },
    {
      time: "2021-11-03",
      amount: -591.22,
    },
    {
      time: "2021-11-12",
      amount: -1061.84,
    },
    {
      time: "2021-11-13",
      amount: -82.3,
    },
    {
      time: "2021-11-16",
      amount: -779.29,
    },
    {
      time: "2021-11-29",
      amount: -983.54,
    },
    {
      time: "2021-12-01",
      amount: -68.12,
    },
    {
      time: "2021-12-05",
      amount: -805.64,
    },
    {
      time: "2021-12-07",
      amount: -64.32,
    },
    {
      time: "2021-12-08",
      amount: -648.87,
    },
    {
      time: "2021-12-15",
      amount: -556.97,
    },
    {
      time: "2021-12-16",
      amount: -551.62,
    },
    {
      time: "2021-12-23",
      amount: -936.78,
    },
    {
      time: "2022-01-01",
      amount: -942.15,
    },
    {
      time: "2022-01-02",
      amount: -854.64,
    },
    {
      time: "2022-01-04",
      amount: -550.12,
    },
    {
      time: "2022-01-07",
      amount: -927.61,
    },
    {
      time: "2022-01-09",
      amount: -118.7,
    },
    {
      time: "2022-01-12",
      amount: -738.34,
    },
    {
      time: "2022-01-16",
      amount: -919.28,
    },
    {
      time: "2022-01-20",
      amount: -74.08,
    },
    {
      time: "2022-01-23",
      amount: -813.75,
    },
    {
      time: "2022-01-24",
      amount: -450.52,
    },
    {
      time: "2022-01-29",
      amount: -173.57,
    },
    {
      time: "2022-01-31",
      amount: -93.09,
    },
    {
      time: "2022-02-01",
      amount: -930.95,
    },
    {
      time: "2022-02-04",
      amount: -743.68,
    },
    {
      time: "2022-02-05",
      amount: -264.49,
    },
    {
      time: "2022-02-08",
      amount: -909.35,
    },
    {
      time: "2022-02-10",
      amount: -1210,
    },
    {
      time: "2022-02-12",
      amount: -311.1,
    },
    {
      time: "2022-02-13",
      amount: -989.85,
    },
    {
      time: "2022-02-17",
      amount: -615.17,
    },
    {
      time: "2022-02-20",
      amount: -206.26,
    },
    {
      time: "2022-02-23",
      amount: -846.88,
    },
    {
      time: "2022-02-26",
      amount: -901.9,
    },
    {
      time: "2022-02-27",
      amount: -425.96,
    },
    {
      time: "2022-03-05",
      amount: -1916.35,
    },
    {
      time: "2022-03-07",
      amount: -373.95,
    },
    {
      time: "2022-03-10",
      amount: -445.72,
    },
    {
      time: "2022-03-11",
      amount: -888.29,
    },
    {
      time: "2022-03-17",
      amount: -347.05,
    },
    {
      time: "2022-03-18",
      amount: -123.33,
    },
    {
      time: "2022-03-19",
      amount: -964.49,
    },
    {
      time: "2022-03-20",
      amount: -555.14,
    },
    {
      time: "2022-03-26",
      amount: -868.94,
    },
    {
      time: "2022-03-31",
      amount: -883.0300000000001,
    },
    {
      time: "2022-04-11",
      amount: -462.8,
    },
    {
      time: "2022-04-12",
      amount: -391.31,
    },
    {
      time: "2022-04-15",
      amount: -134.56,
    },
    {
      time: "2022-04-18",
      amount: -703.05,
    },
    {
      time: "2022-04-19",
      amount: -133.78,
    },
    {
      time: "2022-04-23",
      amount: -598.34,
    },
    {
      time: "2022-04-25",
      amount: -792.86,
    },
    {
      time: "2022-04-26",
      amount: -155.58,
    },
    {
      time: "2022-05-04",
      amount: -736.94,
    },
    {
      time: "2022-05-05",
      amount: -182.49,
    },
    {
      time: "2022-05-09",
      amount: -171.58,
    },
    {
      time: "2022-05-13",
      amount: -892.79,
    },
    {
      time: "2022-05-14",
      amount: -418.56,
    },
    {
      time: "2022-05-23",
      amount: -1111.1399999999999,
    },
    {
      time: "2022-05-25",
      amount: -236.88,
    },
    {
      time: "2022-06-01",
      amount: -432.09,
    },
    {
      time: "2022-06-02",
      amount: -932.63,
    },
    {
      time: "2022-06-07",
      amount: -97.91,
    },
    {
      time: "2022-06-09",
      amount: -585.99,
    },
    {
      time: "2022-06-13",
      amount: -496.08,
    },
    {
      time: "2022-06-14",
      amount: -876.74,
    },
    {
      time: "2022-06-19",
      amount: -328.78,
    },
    {
      time: "2022-06-20",
      amount: -64.24,
    },
    {
      time: "2022-06-25",
      amount: -580.15,
    },
    {
      time: "2022-06-27",
      amount: -1079.73,
    },
    {
      time: "2022-07-06",
      amount: -903.39,
    },
    {
      time: "2022-07-09",
      amount: -575.31,
    },
    {
      time: "2022-07-11",
      amount: -992.59,
    },
    {
      time: "2022-07-15",
      amount: -352.02,
    },
    {
      time: "2022-07-16",
      amount: -265.72,
    },
    {
      time: "2022-07-17",
      amount: -543.31,
    },
    {
      time: "2022-07-19",
      amount: -762.65,
    },
    {
      time: "2022-07-21",
      amount: -807.28,
    },
    {
      time: "2022-07-22",
      amount: -1261.1299999999999,
    },
    {
      time: "2022-07-25",
      amount: -706.56,
    },
    {
      time: "2022-08-01",
      amount: -741.97,
    },
    {
      time: "2022-08-03",
      amount: -875.15,
    },
    {
      time: "2022-08-06",
      amount: -786.18,
    },
    {
      time: "2022-08-11",
      amount: -970.61,
    },
    {
      time: "2022-08-16",
      amount: -390.07,
    },
    {
      time: "2022-08-17",
      amount: -384.63,
    },
    {
      time: "2022-08-19",
      amount: -959.17,
    },
    {
      time: "2022-08-21",
      amount: -335.28,
    },
    {
      time: "2022-08-23",
      amount: -904,
    },
    {
      time: "2022-09-01",
      amount: -239.58,
    },
    {
      time: "2022-09-03",
      amount: -941.38,
    },
    {
      time: "2022-09-04",
      amount: -338.11,
    },
    {
      time: "2022-09-08",
      amount: -149.86,
    },
    {
      time: "2022-09-10",
      amount: -222.18,
    },
    {
      time: "2022-09-13",
      amount: -91.55,
    },
    {
      time: "2022-09-14",
      amount: -822.9,
    },
    {
      time: "2022-09-15",
      amount: -819.78,
    },
    {
      time: "2022-09-16",
      amount: -73.01,
    },
    {
      time: "2022-09-17",
      amount: -993,
    },
    {
      time: "2022-09-19",
      amount: -1044.99,
    },
    {
      time: "2022-09-21",
      amount: -419.78,
    },
    {
      time: "2022-09-22",
      amount: -841.01,
    },
    {
      time: "2022-10-10",
      amount: -537.36,
    },
    {
      time: "2022-10-11",
      amount: -261.29,
    },
    {
      time: "2022-10-14",
      amount: -501.24,
    },
    {
      time: "2022-10-16",
      amount: -2102.76,
    },
    {
      time: "2022-10-17",
      amount: -1375.44,
    },
    {
      time: "2022-10-20",
      amount: -537.45,
    },
    {
      time: "2022-10-26",
      amount: -746.39,
    },
    {
      time: "2022-11-03",
      amount: -785.71,
    },
    {
      time: "2022-11-04",
      amount: -957.65,
    },
    {
      time: "2022-11-11",
      amount: -838.4,
    },
    {
      time: "2022-11-13",
      amount: -855.7,
    },
    {
      time: "2022-11-14",
      amount: -1615.52,
    },
  ],
  expenseCategories: [
    {
      category: "Gifts",
      amount: -6013.969999999999,
    },
    {
      category: "Entertainment",
      amount: -8060.380000000001,
    },
    {
      category: "Printing",
      amount: -6049.339999999999,
    },
    {
      category: "Postage And Shipping",
      amount: -3896.14,
    },
    {
      category: "Dues And Subscriptions",
      amount: -6490.489999999999,
    },
    {
      category: "Home Improvement",
      amount: -9236.37,
    },
    {
      category: "Rent",
      amount: -7064.15,
    },
    {
      category: "Office Maintenance",
      amount: -2815.9,
    },
    {
      category: "Hobbies",
      amount: -3800.89,
    },
    {
      category: "Restaurants Dining",
      amount: -7719.57,
    },
    {
      category: "Clothing Shoes",
      amount: -12735.98,
    },
    {
      category: "Loans",
      amount: -3773.8600000000006,
    },
    {
      category: "Pets Pet Care",
      amount: -3029.4400000000005,
    },
    {
      category: "General Merchandise",
      amount: -1879.41,
    },
    {
      category: "Cable Satellite Services",
      amount: -5186.44,
    },
    {
      category: "Gasoline Fuel",
      amount: -2850.4,
    },
    {
      category: "Office Supplies",
      amount: -5015.1900000000005,
    },
    {
      category: "Automotive Expenses",
      amount: -5443.08,
    },
    {
      category: "Insurance",
      amount: -5129.54,
    },
    {
      category: "Groceries",
      amount: -5356.49,
    },
    {
      category: "Utilities",
      amount: -4750.96,
    },
    {
      category: "Online Services",
      amount: -1251.75,
    },
    {
      category: "Personal Care",
      amount: -4929.889999999999,
    },
    {
      category: "Healthcare Medical",
      amount: -3328.06,
    },
    {
      category: "Business Miscellaneous",
      amount: -2253.1400000000003,
    },
    {
      category: "Charitable Giving",
      amount: -1583.1100000000001,
    },
    {
      category: "Advertising",
      amount: -1785.46,
    },
  ],
  totalOutgoing: -131429.39999999997,
  livingExpenseCategories: [
    {
      category: "Gifts",
      amount: -6013.969999999999,
    },
    {
      category: "Entertainment",
      amount: -8060.380000000001,
    },
    {
      category: "Printing",
      amount: -6049.339999999999,
    },
    {
      category: "Postage And Shipping",
      amount: -3896.14,
    },
    {
      category: "Dues And Subscriptions",
      amount: -6490.489999999999,
    },
    {
      category: "Home Improvement",
      amount: -9236.37,
    },
    {
      category: "Rent",
      amount: -7064.15,
    },
    {
      category: "Office Maintenance",
      amount: -2815.9,
    },
    {
      category: "Hobbies",
      amount: -3800.89,
    },
    {
      category: "Restaurants Dining",
      amount: -7719.57,
    },
    {
      category: "Clothing Shoes",
      amount: -12735.98,
    },
    {
      category: "Loans",
      amount: -3773.8600000000006,
    },
    {
      category: "Pets Pet Care",
      amount: -3029.4400000000005,
    },
    {
      category: "General Merchandise",
      amount: -1879.41,
    },
    {
      category: "Cable Satellite Services",
      amount: -5186.44,
    },
    {
      category: "Gasoline Fuel",
      amount: -2850.4,
    },
    {
      category: "Office Supplies",
      amount: -5015.1900000000005,
    },
    {
      category: "Automotive Expenses",
      amount: -5443.08,
    },
    {
      category: "Insurance",
      amount: -5129.54,
    },
    {
      category: "Groceries",
      amount: -5356.49,
    },
    {
      category: "Utilities",
      amount: -4750.96,
    },
    {
      category: "Online Services",
      amount: -1251.75,
    },
    {
      category: "Personal Care",
      amount: -4929.889999999999,
    },
    {
      category: "Healthcare Medical",
      amount: -3328.06,
    },
    {
      category: "Business Miscellaneous",
      amount: -2253.1400000000003,
    },
    {
      category: "Charitable Giving",
      amount: -1583.1100000000001,
    },
    {
      category: "Advertising",
      amount: -1785.46,
    },
  ],
};

export const HISTORY_LOAN_30_DAYS_MOCKUP_DATA = {
  cashBalances: [],
  transactions: [
    {
      id: "17601619",
      status: "posted",
      description:
        "ONLINE FXXXXXX3837 fixed repayments MCCAFFERTY T TRANSFER CREDIT",
      amount: 700,
      account: "11118987",
      balance: 301984.67000000004,
      direction: "credit",
      categoryId: "28",
      category: "Transfers",
      categoryType: "TRANSFER",
      detailCategoryId: "undefined",
      date: "2022-10-27",
      transactionDate: "2022-10-27",
      postDate: "2022-10-27",
    },
    {
      id: "17643262",
      status: "posted",
      description: "INTEREST CHARGED INTEREST CHARGED",
      amount: -653.49,
      account: "11118987",
      balance: 302638.16000000003,
      direction: "debit",
      categoryId: "24",
      category: "Service Charges/Fees",
      categoryType: "EXPENSE",
      detailCategoryId: "undefined",
      date: "2022-10-31",
      transactionDate: "2022-10-31",
      postDate: "2022-10-31",
    },
    {
      id: "17643261",
      status: "posted",
      description: "VXXXX3242 X2460 MCCAFFERTY,TRENT INTER-BANK CREDIT",
      amount: 424.52,
      account: "11118987",
      balance: 302213.64,
      direction: "credit",
      categoryId: "32",
      category: "Other Income",
      categoryType: "INCOME",
      detailCategoryId: "undefined",
      date: "2022-11-01",
      transactionDate: "2022-11-01",
      postDate: "2022-11-01",
    },
    {
      id: "17686847",
      status: "posted",
      description:
        "ONLINE  PXXXXXX1145 fixed repayments    MCCAFFERTY T TRANSFER CREDIT",
      amount: 700,
      account: "11118987",
      balance: 301513.64,
      direction: "credit",
      categoryId: "28",
      category: "Transfers",
      categoryType: "TRANSFER",
      detailCategoryId: "undefined",
      date: "2022-11-10",
      transactionDate: "2022-11-10",
      postDate: "2022-11-10",
    },
  ],
  totalIncoming: 0,
  totalOutgoing: 0,
  totalExpense: 0,
  expenseCategories: [],
  livingExpenseCategories: [],
};

export const HISTORY_LOAN_60_DAYS_MOCKUP_DATA = {
  cashBalances: [],
  transactions: [],
  totalIncoming: 0,
  totalOutgoing: 0,
  totalExpense: 0,
  expenseCategories: [],
  livingExpenseCategories: [],
};

export const HISTORY_LOAN_120_DAYS_MOCKUP_DATA = {
  cashBalances: [],
  transactions: [],
  totalIncoming: 0,
  totalOutgoing: 0,
  totalExpense: 0,
  expenseCategories: [],
  livingExpenseCategories: [],
};

export const HISTORY_LOAN_ALL_DAYS_MOCKUP_DATA = {
  cashBalances: [],
  transactions: [],
  totalIncoming: 0,
  totalOutgoing: 0,
  totalExpense: 0,
  expenseCategories: [],
  livingExpenseCategories: [],
};
