import { format } from "date-fns";
import { useContext } from "react";
import { useQueries } from "react-query";
import { getTransactions } from "../api/finance-views";
import { FINANCE_HISTORY_DAY_FORMAT } from "../commons/enum";
import JointAccountContext from "../context/joint-account-context";
import { useSelectedJointAccountDebtTransaction } from "./joint-account.hooks";

export const useIndividualTransactions = ({
  accountIds = [],
  direction,
  limit,
  keyword,
  from,
  to,
}) => {
  const { selectedJointAccount = "" } = useContext(JointAccountContext);
  return useQueries(
    accountIds.map((accountId) => ({
      queryKey: [
        "transactions",
        { accountId, direction, limit, keyword, from, to },
      ],
      queryFn: () =>
        getTransactions({ accountId, direction, limit, keyword, from, to }),
      select: (response) => ({
        accountId: accountId.toString(),
        transactions: response.data,
      }),
      enabled: !selectedJointAccount,
    }))
  );
};
export const useTransactions = (debtTransactionParams) => {
  const formattedDebtTransactionParams = {
    ...debtTransactionParams,
    from: format(debtTransactionParams.from, FINANCE_HISTORY_DAY_FORMAT),
    to: format(debtTransactionParams.to, FINANCE_HISTORY_DAY_FORMAT),
  };
  const jointAccountDebtTransaction = useSelectedJointAccountDebtTransaction(
    formattedDebtTransactionParams
  );
  const balanceHistories = useIndividualTransactions(
    formattedDebtTransactionParams
  );
  if (jointAccountDebtTransaction.data) {
    return {
      ...jointAccountDebtTransaction,
      data: jointAccountDebtTransaction?.data,
    };
  }
  return balanceHistories;
};
