import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import { navigate } from "gatsby";
import React from "react";
import { useCompanyLogo } from "../../../hooks";
import { Logout } from "../../../svgs";
import MenuLeftItemWValue from "../menuLeftItem";
import MenuLeftItem from "../menu_left_item";
import SwitchAccount from "./switch-account";

const MobileMenu = ({
  sideBarMenuItem,
  name,
  isExpanded = false,
  handleIsExpanded = () => {},
  handleLogout,
  data = {},
  gitVersion = {},
  checkedItem = "",
}) => {
  const { darkLogo } = useCompanyLogo();
  const { income = 0, cashDeposit = 0, expenses = 0 } = data;
  const { gitTagName = "", gitDate = 0 } = gitVersion;
  return (
    <div className="mobile-menu">
      <div className="ml-items-group top">
        <div className="header-container">
          <div className="header-logo">
            {darkLogo && <img src={darkLogo} alt="Experity Icon" />}
          </div>

          <div className="d-flex align-items-center">
            <div className="user-name-header">{name}</div>

            <div
              className={classNames("arrow-down", {
                expanded: isExpanded,
              })}
              onClick={handleIsExpanded}
            >
              <ExpandMoreIcon />
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames("expanded-logout-mobile", {
          "d-none": !isExpanded,
        })}
      >
        <div className="expanded-logout-account-value">
          <MenuLeftItemWValue name="Income" value={income} />
          <MenuLeftItemWValue name="Expenses" value={expenses} />
          <MenuLeftItemWValue name="Cash Deposit" value={cashDeposit} />
        </div>
        <div className="expanded-logout__switch-account">
          <SwitchAccount />
        </div>
        <div
          className="expanded-logout-feature-groups logout-button-group"
          onClick={handleLogout}
        >
          <MenuLeftItem
            icon={<Logout />}
            title="Logout"
            checked={false}
            href=""
          />
        </div>
      </div>
      <div className="ml-items-group bottom">
        <div className="ml-items-container">
          {sideBarMenuItem.map((sideBarItem) => (
            <MenuLeftItem
              key={sideBarItem.title}
              icon={sideBarItem.icon}
              title={sideBarItem.title}
              checked={checkedItem === sideBarItem.title}
              onClick={() => navigate(sideBarItem.href)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

MobileMenu.propTypes = {};

export default MobileMenu;
