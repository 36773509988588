import { AppBar, Box, Button } from "@material-ui/core";
import classNames from "classnames";
import {
  useCompanyInfo,
  useCompanyTheme,
  useIsTablet,
} from "../../../hooks/common.hooks";

const navLinks = [
  {
    title: "Login",
    url: `/login`,
  },
];

const NavigationBar = ({ signupUrl = "" }) => {
  const companyTheme = useCompanyTheme();
  const { lightLogo } = useCompanyInfo();
  const isTablet = useIsTablet();

  return (
    <>
      <AppBar position="fixed">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="sharing-navigation__container"
        >
          <Box
            display="flex"
            className="sharing-navigation__home"
            alignItems="center"
          >
            {lightLogo && (
              <div className="sharing-navigation-icon__container">
                <img src={lightLogo} alt="experity-icon" />
              </div>
            )}
            {!isTablet && companyTheme?.companyName}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classNames("sharing-navigation-button__container")}
            justifyContent="space-between"
          >
            {navLinks.map(({ title, url }) => (
              <a target="_blank" href={url} key={title} rel="noreferrer">
                {title}
              </a>
            ))}
            <Button
              button
              className="sharing-navigation__button"
              target="_blank"
              href={signupUrl}
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </AppBar>
    </>
  );
};

export default NavigationBar;
