export const FINANCE_HISTORY_DAY_FORMAT = "yyyy-MM-dd";

export const INITIAL_APP_STATE = {
  isNotConnectingToAnyBank: false,
  isInEmbedMode: false,
  shouldPauseAuthenticatedRedirect: false,
  shouldCreatePendingScenario: false,
  pendingToCreateScenarios: [],
};

export const PROPERTY_PURPOSE = {
  INVEST: "INVEST",
  OCCUPY: "OCCUPY",
};

export const JOINT_ACCOUNT_STATUS_LIST = {
  WAITING_VERIFY: "WAITING_VERIFY",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const JOINT_ACCOUNT_STATUS = {
  WAITING_VERIFY: "Pending",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const PROPERTY_TYPE = {
  HOUSE: "House",
  UNIT: "Unit",
};

const loanTypes = {
  STANDARD_VARIABLE: "standard-variable",
  FIXED_RATE: "fixed-rate",
};

const scenarioTypes = {
  BUY_A_HOUSE: "buy-a-house",
  REFINANCE_HOME_LOAN: "refinance-home-loan",
  BUY_AN_INVESTMENT: "buy-an-investment",
};

const scenarioRefinanceOption = {
  ADD_MANUALLY: "ADD_MANUALLY",
  SELECT_LOAN: "SELECT_LOAN",
};

export const TIME_FILTER_INDICES = {
  ONE_YEAR: 0,
  THREE_YEAR: 1,
  FIVE_YEAR: 2,
  ALL: 3,
};

export const MAXIMUM_CATEGORIES_PERCENT = 5;
export const MAXIMUM_CATEGORIES = 5;

export const ESTIMATION_TYPES = {
  SUBURB_AVERAGE: 1,
  PROPERTY_PORTFOLIO: 0,
  COMBINED: 2,
};

export const LINE_CHART_DASH = [5, 10];

const CASH_TOWARD_DEFAULT_RATIO = 0.8;
const ESTIMATE_PROPERTY_DEFAULT_RATIO = 3.2;

export const AUTHENTICATED_REDIRECT_PAGES = [
  "login",
  "signup",
  "forgot-password",
  "recover-password",
];
export const NOT_DASHBOARD_ROUTES = [
  "login",
  "recover-password",
  "signup",
  "forgot-password",
  "referral",
  "staff",
  "confirmation",
  "accept-invitation",
];

const SORT_DIRECTION = {
  ASCENDING: 1,
  DESCENDING: -1,
};

const DEFAULT_SIGNUP_BUYING_GOAL = 750000;
const DEFAULT_MONTHLY_RENT = 650;

const ACCOUNT_TYPE = {
  TRANSACTION: "transaction",
  SAVINGS: "savings",
  CREDIT_CARD: "credit-card",
  MORTGAGE: "mortgage",
  LOAN: "loan",
  INVESTMENT: "investment",
  SHARE: "share",
  TERMDEPOSIT: "term-deposit",
  INSURANCE: "insurance",
  OTHER: "other",
  UNKNOWN: "unknown",
  SUPERANNUATION: "superannuation",
};

const ACCOUNT_CATEGORY = {
  ASSETS: "assets",
  ASSETS_NOT_SHARES_AND_SUPERS: "assetsNotSharesAndSupers",
  SHARE: "share",
  CREDIT: "credit",
  PROPERTY_LOAN: "propertyLoan",
  PERSONAL_LOAN: "personalLoan",
  LOAN: "loan",
  SUPER: "super",
  OTHERS: "others",
};

const LIABILITY_ACCOUNT_TYPES = [
  ACCOUNT_TYPE.CREDIT_CARD,
  ACCOUNT_TYPE.MORTGAGE,
  ACCOUNT_TYPE.LOAN,
];

const NON_LIABILITY_ACCOUNT_TYPES = Object.values(ACCOUNT_TYPE).filter(
  (type) => !LIABILITY_ACCOUNT_TYPES.includes(type)
);

const ACCOUNT_CATEGORIES_BY_ACCOUNT_TYPE = {
  [ACCOUNT_TYPE.CREDIT_CARD]: [ACCOUNT_CATEGORY.CREDIT],
  [ACCOUNT_TYPE.MORTGAGE]: [
    ACCOUNT_CATEGORY.PROPERTY_LOAN,
    ACCOUNT_CATEGORY.LOAN,
  ],
  [ACCOUNT_TYPE.LOAN]: [ACCOUNT_CATEGORY.PERSONAL_LOAN, ACCOUNT_CATEGORY.LOAN],
  [ACCOUNT_TYPE.SHARE]: [ACCOUNT_CATEGORY.SHARE],
  [ACCOUNT_TYPE.SUPERANNUATION]: [ACCOUNT_CATEGORY.SUPER],
};

const FASTLINK_PARAMS_GETTERS = {
  AGGREGATION: () => ({}),
  REFRESH_ACCOUNT: (institutionAccountId) => ({
    providerAccountId: institutionAccountId,
    flow: "refresh",
  }),
};

const INSTITUTION_ACCOUNT_ADDITIONAL_STATUS = {
  LOGIN_IN_PROGRESS: "LOGIN_IN_PROGRESS",
  DATA_RETRIEVAL_IN_PROGRESS: "DATA_RETRIEVAL_IN_PROGRESS",
  ACCT_SUMMARY_RECEIVED: "ACCT_SUMMARY_RECEIVED",
  AVAILABLE_DATA_RETRIEVED: "AVAILABLE_DATA_RETRIEVED",
  PARTIAL_DATA_RETRIEVED: "PARTIAL_DATA_RETRIEVED",
};

const LANDING_PAGE_URL = "https://wealthx.au";

export const EVENT_TRACKING = {
  SIGN_UP: "sign_up",
  LINK_ACCOUNT: "add_payment_info",
  ASSESS_WEALTH_DEBTS: "generate_lead",
  CALCULATE_LOAN: "loan_calculate",
  APPLY_LOAN: "loan_apply",
  PROPERTY_VALUATION: "property_valuation",
  SCHEDULE_CALL: "schedule_call",
};

export {
  loanTypes,
  scenarioTypes,
  scenarioRefinanceOption,
  CASH_TOWARD_DEFAULT_RATIO,
  DEFAULT_SIGNUP_BUYING_GOAL,
  DEFAULT_MONTHLY_RENT,
  ESTIMATE_PROPERTY_DEFAULT_RATIO,
  SORT_DIRECTION,
  ACCOUNT_TYPE,
  ACCOUNT_CATEGORY,
  NON_LIABILITY_ACCOUNT_TYPES,
  LIABILITY_ACCOUNT_TYPES,
  ACCOUNT_CATEGORIES_BY_ACCOUNT_TYPE,
  LANDING_PAGE_URL,
  FASTLINK_PARAMS_GETTERS,
  INSTITUTION_ACCOUNT_ADDITIONAL_STATUS,
};

export const USER_ROLE = {
  STAFF: "STAFF",
  USER: "USER",
  ADMIN: "ADMIN",
};

export const ConfirmationStatus = {
  PENDING: "PENDING",
  ACCEPT: "ACCEPT",
  REJECT: "REJECT",
};

export const ConfirmationType = {
  JOINT_ACCOUNT: "jointAccount",
  SHARE_DATA: "shareData",
};
