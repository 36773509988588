const titleCase = (str) => {
  str.toLowerCase();
  const strAr = str.split(" ");
  for (let i = 0; i < strAr.length; i += 1) {
    strAr[i] =
      strAr[i].charAt(0).toUpperCase() + strAr[i].substring(1).toLowerCase();
  }
  const strResult = strAr.join(" ");
  return strResult;
};

export const convertTextToKebabCase = (text) => {
  return text
    .trim()
    .replace(/\s+|\/|&/g, "-")
    .replace(/-+/g, "-")
    .toLowerCase();
};

export { titleCase };
