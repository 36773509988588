import { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import DataLoadingContext from "../context/data-loading-context";
import { mobileMessageBridge, MobileMessageType } from "../utils";

export const useIsInEmbedMode = () => {
  const { isInEmbedMode } = useContext(DataLoadingContext);
  return isInEmbedMode;
};

/**
 * Perform query invalidation and emit invalidation messages to parent window (used in embed mode)
 * @returns invalidation function
 */
export const useQueryInvalidationWithNotification = () => {
  const queryClient = useQueryClient();
  const isInEmbedMode = useIsInEmbedMode();
  const invalidateQueries = (...args) => {
    if (isInEmbedMode) {
      mobileMessageBridge.serializeAndEmit({
        type: MobileMessageType.INVALIDATE_QUERY,
        data: {
          queryKey: args[0],
        },
      });
    }
    return queryClient.invalidateQueries(...args);
  };
  return invalidateQueries;
};

export const useIncrementalLimits = (step = 5) => {
  const [limit, setLimit] = useState(step);
  const increment = () => {
    setLimit((prevLimit) => prevLimit + step);
  };
  const reset = () => {
    setLimit(step);
  };
  return {
    limit,
    increment,
    reset,
  };
};
