import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function SvgComponent(props, { fill = primaryColor }) {
  return (
    <svg width={17.781} height={17.258} viewBox="0 0 17.781 17.258" {...props}>
      <g data-name="Group 3999">
        <path
          data-name="Path 1372"
          d="M1.975 17.218s-.807-15.338 0-16.043 13.54-.412 14.093 0 0 16.043 0 16.043"
          fill="none"
          stroke="#323a45"
          strokeWidth={1.5}
        />
        <path
          data-name="Path 1373"
          d="M16.992 15.515L.756 15.343a7.267 7.267 0 012.086-4.916c2.059-1.775 10.019-1.767 12.24 0s1.91 5.088 1.91 5.088z"
          fill="none"
          stroke="#323a45"
          strokeWidth={1.5}
        />
        <path
          data-name="Path 1374"
          d="M1.874 12.574h14.775"
          fill="none"
          stroke="rgba(50,58,69,0.5)"
          strokeWidth={1.5}
        />
        <path
          data-name="Path 1378"
          d="M12.168 5.175a2.613 2.613 0 012.612 2.613c0 1.443-1.17 1.831-2.613 1.831s-2.612-.388-2.612-1.831a2.613 2.613 0 012.613-2.613z"
          fill={fill}
        />
        <path
          data-name="Path 1377"
          d="M5.897 5.175a2.613 2.613 0 012.612 2.613c0 1.443-1.17 1.831-2.613 1.831s-2.612-.388-2.612-1.831a2.613 2.613 0 012.613-2.613z"
          fill={fill}
        />
        <g data-name="Path 1375" fill="none">
          <path d="M12.168 5.175a2.613 2.613 0 012.612 2.613c0 1.443-1.17 1.831-2.613 1.831s-2.612-.388-2.612-1.831a2.613 2.613 0 012.613-2.613z" />
          <path
            d="M12.168 6.675c-.614 0-1.113.5-1.113 1.113 0 .086.01.135.015.158.058.037.323.173 1.098.173.774 0 1.04-.136 1.097-.173a.641.641 0 00.015-.158c0-.614-.499-1.113-1.112-1.113m0-1.5a2.613 2.613 0 012.612 2.613c0 1.443-1.17 1.831-2.612 1.831-1.443 0-2.613-.388-2.613-1.83a2.613 2.613 0 012.613-2.614z"
            fill="#323a45"
          />
        </g>
        <g data-name="Path 1376" fill="none">
          <path d="M5.897 5.175a2.613 2.613 0 012.612 2.613c0 1.443-1.17 1.831-2.613 1.831s-2.612-.388-2.612-1.831a2.613 2.613 0 012.613-2.613z" />
          <path
            d="M5.897 6.675c-.614 0-1.113.5-1.113 1.113 0 .086.01.135.015.158.058.037.323.173 1.098.173.774 0 1.04-.136 1.097-.173a.641.641 0 00.015-.158c0-.614-.499-1.113-1.112-1.113m0-1.5a2.613 2.613 0 012.612 2.613c0 1.443-1.17 1.831-2.612 1.831-1.443 0-2.613-.388-2.613-1.83a2.613 2.613 0 012.613-2.614z"
            fill="#323a45"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
