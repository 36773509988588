/* eslint-disable react/display-name */
const ReactQuery = require("react-query");
const React = require("react");
const { ThemeProvider, Fade } = require("@material-ui/core");
const { SnackbarProvider } = require("notistack");
const { Layout } = require("./src/components/Layout");
const { theme } = require("./src/utils/theme");
const {
  default: SnackbarInAxios,
} = require("./src/components/snackbar-in-axios");
const { queryClient } = require("./src/utils/react-query-util");

const { QueryClientProvider } = ReactQuery;

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  window.analytics &&
    window.analytics.page(location.href, {
      path: location.pathname,
      url: location.href,
      referrer: prevLocation ? prevLocation.href : null,
    });
};

exports.wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient} contextSharing>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={1}
          preventDuplicate
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          TransitionComponent={Fade}
        >
          <SnackbarInAxios>{element}</SnackbarInAxios>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
