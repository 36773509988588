export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const isBrowser = () => typeof window !== "undefined";

export function getItemFromLocalStorage(key) {
  return isBrowser() ? window.localStorage.getItem(key) : undefined;
}

export function setItemLocalStorage(key, value) {
  return isBrowser() ? window.localStorage.setItem(key, value) : undefined;
}
