const convertToInt = (value) => {
  if (!Number.isNaN(value)) {
    return Math.round(parseFloat(value));
  }
  return 0;
};

const convertToFloat = (value, toFix = -1) => {
  if (!Number.isNaN(parseFloat(value))) {
    if (value.toString().indexOf(".") >= 0) {
      if (toFix < 0) {
        return parseFloat(value);
      }
      return parseFloat(value).toFixed(toFix);
    }
    return convertToInt(value);
  }
  return 0;
};

export { convertToFloat, convertToInt };
