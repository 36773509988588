import * as React from "react";

function Download(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 15.6 18"
      xmlSpace="preserve"
      {...props}
    >
      <g id="prefix__Group_6311" transform="translate(-1296.745 -915.338)">
        <g id="prefix__Group_6306" transform="translate(6 -1)" opacity={0.4}>
          <path
            className="icon-main"
            d="M1307.2 934.3h-13.1c-.4 0-.8-.3-.8-.8v-4.8c0-.4.3-.8.8-.8s.8.3.8.8v4h11.6v-4c0-.4.3-.8.8-.8s.8.3.8.8v4.8c-.1.5-.4.8-.9.8z"
            id="prefix__Path_2387"
          />
          <path
            className="icon-main"
            d="M1300.5 929.1c-.4 0-.8-.3-.8-.8v-10.2c0-.4.3-.8.8-.8s.8.3.8.8v10.2c-.1.4-.4.8-.8.8z"
            id="prefix__Path_2388"
          />
          <path
            className="icon-main"
            d="M1300.6 929.5c-.2 0-.4-.1-.5-.2l-3.5-3.2c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l2.9 2.7 2.7-2.7c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1l-3.2 3.2c-.3.2-.4.2-.6.2z"
            id="prefix__Path_2389"
          />
        </g>
        <g id="prefix__Group_6307" transform="translate(4 -2)">
          <path
            className="icon-main"
            d="M1306.6 934.3h-13.1c-.4 0-.8-.3-.8-.8v-4.8c0-.4.3-.8.8-.8s.8.3.8.8v4h11.6v-4c0-.4.3-.8.8-.8s.8.3.8.8v4.8c-.1.5-.4.8-.9.8z"
            id="prefix__Path_2387-2"
          />
          <path
            className="icon-main"
            d="M1299.9 929.1c-.4 0-.8-.3-.8-.8v-10.2c0-.4.3-.8.8-.8s.8.3.8.8v10.2c-.1.4-.4.8-.8.8z"
            id="prefix__Path_2388-2"
          />
          <path
            className="icon-main"
            d="M1300 929.5c-.2 0-.4-.1-.5-.2l-3.5-3.2c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l2.9 2.7 2.7-2.7c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-3.2 3.2c-.3.2-.5.2-.6.2z"
            id="prefix__Path_2389-2"
          />
        </g>
      </g>
    </svg>
  );
}

export default Download;
