import * as React from "react";

function SvgTransactions(props) {
  return (
    <svg
      id="transactions_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 47.8 47.8"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.transactions_svg__st1{fill:none;stroke:#fff;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="transactions_svg__Group_3662" transform="translate(0 28.926)">
        <path
          id="transactions_svg__Path_1198"
          d="M17.8-18.7H0V8.7h6.4l11.4-27.4z"
          fillRule="evenodd"
          clipRule="evenodd"
          className="icon-fill"
        />
        <path
          id="transactions_svg__Path_1199"
          className="transactions_svg__st1"
          d="M3.4 8.7H47v-27.4H3.4V8.7z"
        />
        <path
          id="transactions_svg__Path_1200"
          className="transactions_svg__st1"
          d="M30.7-6.7c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5-5.5-2.5-5.5-5.5c0-3.1 2.5-5.5 5.5-5.5z"
        />
        <path
          id="transactions_svg__Path_1201"
          className="transactions_svg__st1"
          d="M36.2-6.7c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5-5.5-2.5-5.5-5.5c0-3.1 2.5-5.5 5.5-5.5z"
        />
      </g>
    </svg>
  );
}

export default SvgTransactions;
