import axiosClient from "./axiosClient";

const searchProperty = (params) => {
  const url = `/properties/search`;
  return axiosClient.get(url, params);
};
const postProperty = (params) => {
  const url = "/properties";
  return axiosClient.post(url, params);
};
const putProperty = (params, id) => {
  const url = `/properties/${id}`;
  return axiosClient.put(url, params);
};
const getProperty = () => {
  const url = `/properties`;
  return axiosClient.get(url);
};

const getPropertyIncides = (params) => {
  const url = `/properties/indices`;
  return axiosClient.get(url, params);
};
const getPropertyPriceHistory = () => {
  const url = `/properties/priceHistory`;
  return axiosClient.get(url);
};
const getPropertyNearbyDetail = () => {
  const url = `/properties/nearbyDetail`;
  return axiosClient.get(url);
};
const deleteProperty = (id) => {
  const url = `/properties/${id}`;
  return axiosClient.delete(url);
};

export {
  searchProperty,
  getPropertyPriceHistory,
  getPropertyNearbyDetail,
  postProperty,
  putProperty,
  getProperty,
  getPropertyIncides,
  deleteProperty,
};
