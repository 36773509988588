export const PRODUCT_LIST_MOCKUP_DATA = [
  {
    uniqueId:
      "4AB8E775-5313-E519-5CA6-D3528D3DDDAB:55386e0e-c144-49ec-bbf4-4c92e115a965",
    lenderId: "AMP",
    baseRate: 5.17,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 349,
    maxTerm: 30,
    setUpFee: 295,
  },
  {
    uniqueId:
      "1BC3AF21-01F8-0C28-6A92-40E716811770:e971c802-429d-412e-816c-54cbe5beb512",
    lenderId: "AMP",
    baseRate: 6.35,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 349,
    maxTerm: 30,
    setUpFee: 295,
  },
  {
    uniqueId:
      "C4661A2E-A78B-1362-6419-4A339A55C5AC:a2812996-6ada-44b7-88af-6e2d34d323fe",
    lenderId: "AMP",
    baseRate: 6.45,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 644,
  },
  {
    uniqueId:
      "11AA618F-F24E-7750-A8C5-33FB89BA146A:fbd0c13a-c503-43d2-bc68-8f8f94e90f61",
    lenderId: "AMP",
    baseRate: 6.65,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 349,
    maxTerm: 30,
    setUpFee: 295,
  },
  {
    uniqueId:
      "BDCADE59-B8F7-5B36-0002-409DC744755D:75947308-2553-4239-a14b-b7ddd45411da",
    lenderId: "AMP",
    baseRate: 6.65,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 644,
  },
  {
    uniqueId:
      "0159C788-744E-BA1A-A543-8FD6750D4ECB:672593a1-d1ad-4aec-b63c-887fbcc2908b",
    lenderId: "ANZ",
    baseRate: 7.14,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 120,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "AC92474D-6C7E-9F13-7164-8FD010008A3A:45120796-d9f4-4249-a841-3c4f73db4465",
    lenderId: "ANZ",
    baseRate: 7.14,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 120,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId: "CMEL011407:29e9da25-5a92-4e04-8b94-6e83f359925d",
    lenderId: "ANZ",
    baseRate: 7.69,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 120,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "A648458A-ED7A-071B-21F9-8FD6368D6CF8:f8ee06f3-5723-4803-b816-92cd08d21481",
    lenderId: "ANZ",
    baseRate: 7.69,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 120,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "5CD7FB68-ABC7-5790-ACA5-525DAB83525A:806e7182-2568-4fee-9927-cf422267310d",
    lenderId: "BLB",
    baseRate: 5.01,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 5.33,
    monthlyRepaymentList: [948.7065724745926],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 849,
  },
  {
    uniqueId:
      "6121A7A3-504C-65FC-FB45-539B136D700E:50b31149-c1b1-42c0-9fef-a2ca34feabbe",
    lenderId: "BLB",
    baseRate: 5.01,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 5.33,
    monthlyRepaymentList: [948.7065724745926],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 849,
  },
  {
    uniqueId:
      "AD894187-C482-C207-467C-1952E0B546EF:496ba213-d175-401c-84b9-cec97454ed29",
    lenderId: "BLB",
    baseRate: 5.39,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 5.4,
    monthlyRepaymentList: [990.1438020133321],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 286,
  },
  {
    uniqueId:
      "0BC991DD-5A36-8CF8-D8F7-53A4C39B6331:cbeb7570-306c-422c-bf96-da97528b787c",
    lenderId: "BLB",
    baseRate: 5.41,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 5.73,
    monthlyRepaymentList: [992.3476262540861],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 849,
  },
  {
    uniqueId:
      "167538FD-2DD2-763D-1921-526F1E3C6676:b472f7f5-b438-40e2-8f40-6e53a1eeecba",
    lenderId: "BLB",
    baseRate: 5.41,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 5.73,
    monthlyRepaymentList: [992.3476262540861],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 849,
  },
  {
    uniqueId:
      "59A30E4F-51B5-9756-3C1C-52467FDFF483:2ba13d1d-bbbb-43a1-9973-2a29ee880e30",
    lenderId: "BLB",
    baseRate: 5.7,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 6.01,
    monthlyRepaymentList: [1024.5551630432208],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 849,
  },
  {
    uniqueId:
      "E5E69F82-B693-BC74-1321-53AB37DB3E74:101f0910-1ae7-4650-a537-b0cb82f52523",
    lenderId: "BLB",
    baseRate: 5.7,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 6.01,
    monthlyRepaymentList: [1024.5551630432208],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 849,
  },
  {
    uniqueId:
      "FDF59E9A-6512-4518-D54D-1952A0AC96AE:753ca41b-2b8d-466b-8ff7-8c5849442242",
    lenderId: "BLB",
    baseRate: 5.94,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 5.95,
    monthlyRepaymentList: [1051.5601110053879],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 286,
  },
  {
    uniqueId:
      "9FE78DCE-2025-4D42-0B99-A17615FCB24C:b52387a1-1965-4888-9371-d42bf0be0167",
    lenderId: "BLB",
    baseRate: 6,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 6.38,
    monthlyRepaymentList: [1058.3599819638976],
    annualFee: 495,
    maxTerm: 30,
    setUpFee: 849,
  },
  {
    uniqueId:
      "E99BAC2B-9E01-9D62-AFC4-886D5B79F913:4b1ca46f-93d3-4043-abba-c78c23604ded",
    lenderId: "BLB",
    baseRate: 6,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 6.38,
    monthlyRepaymentList: [1058.3599819638976],
    annualFee: 495,
    maxTerm: 30,
    setUpFee: 849,
  },
  {
    uniqueId:
      "C76363B6-7963-B04E-A5F2-77A4B7CD73DE:3c04424d-a348-49cc-b135-f648fd7d2411",
    lenderId: "BLB",
    baseRate: 6.09,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 6.39,
    monthlyRepaymentList: [1068.5957859118103],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 605,
  },
  {
    uniqueId:
      "4A50C195-F622-B613-E9D5-FC1A681BF7CC:f798abbb-f5ef-4b88-a969-0a1cf6e23d95",
    lenderId: "BLB",
    baseRate: 6.14,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 6.15,
    monthlyRepaymentList: [1074.3008813268884],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 286,
  },
  {
    uniqueId:
      "5108CE83-36C7-1C67-11E8-105369ED27F0:957cc12a-7465-4933-af57-c03575204a35",
    lenderId: "BLB",
    baseRate: 6.24,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 6.25,
    monthlyRepaymentList: [1085.7504663668005],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 286,
  },
  {
    uniqueId:
      "858FA7BF-C65B-3C82-CC0B-A17D31582EF0:c41a4a37-fdb2-4f9f-9cc5-209d9620086b",
    lenderId: "BLB",
    baseRate: 6.4,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 6.77,
    monthlyRepaymentList: [1104.1778717065793],
    annualFee: 495,
    maxTerm: 30,
    setUpFee: 849,
  },
  {
    uniqueId:
      "E5FB755E-E0D0-986F-76A2-A16024A875C4:3f084852-4333-4fc9-b5d8-5f923ea83bd6",
    lenderId: "BLB",
    baseRate: 6.4,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 6.77,
    monthlyRepaymentList: [1104.1778717065793],
    annualFee: 495,
    maxTerm: 30,
    setUpFee: 849,
  },
  {
    uniqueId:
      "1E4D8958-86AD-A123-93CE-77B53DC6F901:568e7ebc-220a-4764-afca-4e11e44a19f9",
    lenderId: "BLB",
    baseRate: 6.49,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 6.79,
    monthlyRepaymentList: [1114.6010128808196],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 605,
  },
  {
    uniqueId:
      "01011416-84F1-6E29-A84C-75DEB6292912:aeaf0933-c4ef-4c89-8dc5-4c9483963b0f",
    lenderId: "BLB",
    baseRate: 6.59,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 6.96,
    monthlyRepaymentList: [1126.2304126788956],
    annualFee: 495,
    maxTerm: 30,
    setUpFee: 605,
  },
  {
    uniqueId:
      "C803D9BA-F761-1F18-C55C-7743A1D389C5:0c0b9686-71fa-4134-beda-d2994a8d7c3c",
    lenderId: "BLB",
    baseRate: 6.99,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 7.35,
    monthlyRepaymentList: [1173.2437213715275],
    annualFee: 495,
    maxTerm: 30,
    setUpFee: 605,
  },
  {
    uniqueId:
      "E42A9FA2-E98D-D681-504A-77BAE743616F:c3c8bcc7-7864-48ff-a1ab-0f50fef61ab8",
    lenderId: "BLB",
    baseRate: 7.09,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 7.38,
    monthlyRepaymentList: [1185.1181877828956],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 605,
  },
  {
    uniqueId:
      "317C496F-0475-00E9-33A1-77C093BDD599:d0d2f749-0b41-40f3-b869-03d556913fc7",
    lenderId: "BLB",
    baseRate: 7.49,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 7.77,
    monthlyRepaymentList: [1233.0838594332743],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 605,
  },
  {
    uniqueId:
      "DAD31755-F3E0-6E59-09D2-774AC862303F:8b5b2aca-af9a-4302-ac6c-a0642ee26ece",
    lenderId: "BLB",
    baseRate: 7.59,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 7.94,
    monthlyRepaymentList: [1245.189438847208],
    annualFee: 495,
    maxTerm: 30,
    setUpFee: 605,
  },
  {
    uniqueId:
      "D679E3A6-43D1-25D1-C24F-774FBB407E7D:371d58e9-91d6-4ff7-9367-4b7130cc8960",
    lenderId: "BLB",
    baseRate: 7.99,
    maxBorrowCapacity: 176525.57,
    rates: [],
    maxBuyingPower: 1447915.57,
    comparisonRate: 5.75,
    monthlyRepaymentList: [1294.0517135512105],
    annualFee: 495,
    maxTerm: 30,
    setUpFee: 605,
  },
  {
    uniqueId:
      "54B4FCC0-4424-35C1-3A10-A00C97C10BA8:183e1a72-0339-4b90-ba1d-ec7dc47c00b0",
    lenderId: "BOC",
    baseRate: 4.58,
    maxBorrowCapacity: 150443.45,
    rates: [],
    maxBuyingPower: 1421833.45,
    comparisonRate: 4.94,
    monthlyRepaymentList: [769.442719667413],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "46917636-78AE-750F-1E20-77AF8AC79043:eefbdab6-65d6-4f69-81c8-90523444d078",
    lenderId: "BOC",
    baseRate: 5.18,
    maxBorrowCapacity: 150443.45,
    rates: [],
    maxBuyingPower: 1421833.45,
    comparisonRate: 5.53,
    monthlyRepaymentList: [824.2436116900724],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "7B53EE25-9247-E27F-DB2F-9FE79CD61FDC:d21b57d1-a1b3-4053-9840-1899b475dca4",
    lenderId: "BOC",
    baseRate: 5.48,
    maxBorrowCapacity: 150443.45,
    rates: [],
    maxBuyingPower: 1421833.45,
    comparisonRate: 5.65,
    monthlyRepaymentList: [852.3145109528368],
    annualFee: 190,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "016BFB7F-9CA4-D3AC-2CD2-2216C762976B:f69448dc-ba88-407c-9e3f-0b50259153c9",
    lenderId: "BOC",
    baseRate: 6.19,
    maxBorrowCapacity: 150443.45,
    rates: [],
    maxBuyingPower: 1421833.45,
    comparisonRate: 6.35,
    monthlyRepaymentList: [920.4434470446213],
    annualFee: 190,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "01E90C6C-B9D7-CBDC-123E-76B5CAF6ED66:a24e71cf-eac2-4f88-8cea-22452a6b2c69",
    lenderId: "BOC",
    baseRate: 6.89,
    maxBorrowCapacity: 150443.45,
    rates: [],
    maxBuyingPower: 1421833.45,
    comparisonRate: 6.9,
    monthlyRepaymentList: [989.8146954538657],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 350,
  },
  {
    uniqueId:
      "347767CE-1C82-0DBE-D8A8-E1E30B5671A7:5229fa56-9117-40d2-9316-2089db3bba70",
    lenderId: "BOC",
    baseRate: 6.89,
    maxBorrowCapacity: 150443.45,
    rates: [],
    maxBuyingPower: 1421833.45,
    comparisonRate: 6.9,
    monthlyRepaymentList: [989.8146954538657],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 350,
  },
  {
    uniqueId:
      "922E3DB4-C9E1-061E-609B-B065A04E4F61:300aaab8-e939-46cb-b963-5e94cc7bffdd",
    lenderId: "BOM",
    baseRate: 7.59,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 100,
  },
  {
    uniqueId:
      "D57A1A4F-470F-6692-FCA2-B047F267D446:5293db44-79ca-4d12-b046-8d9b9ec0ea72",
    lenderId: "BOM",
    baseRate: 7.59,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 700,
  },
  {
    uniqueId:
      "5BB24BE9-B288-EC52-2C6B-892255249828:bc6b01d7-8a79-4782-ae8c-317e895946c1",
    lenderId: "BOM",
    baseRate: 7.88,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 100,
  },
  {
    uniqueId:
      "7C779DC9-DC42-758F-EBD8-693157A23CFE:ff07210b-ad8e-4a92-9bed-50c34bce66f2",
    lenderId: "BOM",
    baseRate: 7.88,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 700,
  },
  {
    uniqueId:
      "84FBE983-54E9-7EC1-8EA3-20AF7455F20A:ee555cfe-c561-497a-ba23-a96b85c79407",
    lenderId: "BQL",
    baseRate: 5.04,
    maxBorrowCapacity: 154064.7,
    rates: [],
    maxBuyingPower: 1425454.7,
    comparisonRate: 5.04,
    monthlyRepaymentList: [830.8230278205592],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 150,
  },
  {
    uniqueId:
      "CC47C89E-343F-ABD6-04D6-666B21126FC1:6dda9042-6b56-40b8-99b0-55a80035c9fc",
    lenderId: "BQL",
    baseRate: 5.04,
    maxBorrowCapacity: 154064.7,
    rates: [],
    maxBuyingPower: 1425454.7,
    comparisonRate: 5.04,
    monthlyRepaymentList: [830.8230278205592],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 150,
  },
  {
    uniqueId:
      "6AE0BBEA-6817-76E6-778F-92FEB22F955C:c679bc31-cde8-42e4-9dea-18d07e759a57",
    lenderId: "BQL",
    baseRate: 6.19,
    maxBorrowCapacity: 154064.7,
    rates: [],
    maxBuyingPower: 1425454.7,
    comparisonRate: 6.19,
    monthlyRepaymentList: [942.5989867680878],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 150,
  },
  {
    uniqueId:
      "5D1B7229-BAB2-F17F-46FE-5DC001B643A1:b817281e-54d0-4fba-87fd-5feea82db3ca",
    lenderId: "BQL",
    baseRate: 6.74,
    maxBorrowCapacity: 154064.7,
    rates: [],
    maxBuyingPower: 1425454.7,
    comparisonRate: 6.74,
    monthlyRepaymentList: [998.2368241643215],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 150,
  },
  {
    uniqueId:
      "3233559A-E565-1AF2-27E6-5DC1045FE322:292e7a48-39e8-4a55-bfe8-12fb3f552c38",
    lenderId: "BQL",
    baseRate: 7.13,
    maxBorrowCapacity: 154064.7,
    rates: [],
    maxBuyingPower: 1425454.7,
    comparisonRate: 7.13,
    monthlyRepaymentList: [1038.482410457716],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 150,
  },
  {
    uniqueId:
      "DA37FF03-2912-3D43-A8AD-C6B4B6C4B767:b7481e8d-84e3-4561-9c4d-390eaa4238b8",
    lenderId: "BSA",
    baseRate: 7.6,
    maxBorrowCapacity: 122913.42,
    rates: [],
    maxBuyingPower: 1394303.42,
    comparisonRate: 7.99,
    monthlyRepaymentList: [867.8606084857536],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 100,
  },
  {
    uniqueId:
      "FEAEECF3-714D-E35C-7ED5-AF9D7F474FA6:49dc3436-10f0-444e-ad7d-9ab238905bde",
    lenderId: "BSA",
    baseRate: 7.6,
    maxBorrowCapacity: 122913.42,
    rates: [],
    maxBuyingPower: 1394303.42,
    comparisonRate: 7.62,
    monthlyRepaymentList: [867.8606084857536],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 700,
  },
  {
    uniqueId:
      "81713304-B71A-3D45-BD09-EFC2FD6751B1:502ec154-a52a-45e9-82cc-6e0a65f6b7cc",
    lenderId: "BSA",
    baseRate: 7.89,
    maxBorrowCapacity: 122913.42,
    rates: [],
    maxBuyingPower: 1394303.42,
    comparisonRate: 7.91,
    monthlyRepaymentList: [892.487639097082],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 700,
  },
  {
    uniqueId:
      "8A95C77B-729D-3181-F60C-AFF62CD3A4FE:d0c869ed-0c77-47ac-b157-e4cdfed1cea1",
    lenderId: "BSA",
    baseRate: 7.89,
    maxBorrowCapacity: 122913.42,
    rates: [],
    maxBuyingPower: 1394303.42,
    comparisonRate: 8.28,
    monthlyRepaymentList: [892.487639097082],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 100,
  },
  {
    uniqueId:
      "B463EEE1-9AE6-13AE-DFD5-E1DEA1131960:f0ce54aa-8ddf-48d6-9ac9-025c1dc5a5f1",
    lenderId: "BWS",
    baseRate: 7.37,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 250,
  },
  {
    uniqueId: "C01015:805d8686-c005-421f-8247-cc6cfbbfb1e0",
    lenderId: "BWS",
    baseRate: 7.5,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 250,
  },
  {
    uniqueId:
      "1EE1A954-8AB7-DED2-9545-009DA13F7B12:5697641b-9640-4a69-9145-91c0ce398616",
    lenderId: "BWS",
    baseRate: 7.5,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 250,
  },
  {
    uniqueId:
      "30A759CD-CDAE-B694-A0E3-B7E3F1B6A951:2dafefdb-d162-47fe-a680-fae6f935a27d",
    lenderId: "BWS",
    baseRate: 7.85,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 250,
  },
  {
    uniqueId:
      "66142A0A-EE53-75DE-D097-AB2EC1CD9965:70650f02-1382-4117-a20e-52ff5e936f0b",
    lenderId: "BWS",
    baseRate: 7.85,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 250,
  },
  {
    uniqueId:
      "D682A4DB-F0A4-B49D-F80F-A9E4E2C6C832:de63ef3f-4982-41d4-ad6c-327010e1cf11",
    lenderId: "BWS",
    baseRate: 7.85,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 250,
  },
  {
    uniqueId:
      "12A213AD-809D-073E-E1EC-AA8A863999B1:cf67eb9a-2bd1-41ee-af3c-e9a4568d317d",
    lenderId: "CBA",
    baseRate: 7.3,
    maxBorrowCapacity: 131441.21,
    rates: [],
    maxBuyingPower: 1402831.21,
    comparisonRate: 7.67,
    monthlyRepaymentList: [901.1227236516564],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 200,
  },
  {
    uniqueId:
      "278F4896-08BD-294F-1C25-8E3FC491D4D8:8642ae1a-5ec9-49e2-b561-9e40addd3c6f",
    lenderId: "CBA",
    baseRate: 7.3,
    maxBorrowCapacity: 131441.21,
    rates: [],
    maxBuyingPower: 1402831.21,
    comparisonRate: 7.32,
    monthlyRepaymentList: [901.1227236516564],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 800,
  },
  {
    uniqueId:
      "A7946A71-764B-734C-2F39-2F01649A279E:cb6b51a7-1548-4bd7-bcce-90a3b19d20ff",
    lenderId: "CBA",
    baseRate: 7.3,
    maxBorrowCapacity: 131441.21,
    rates: [],
    maxBuyingPower: 1402831.21,
    comparisonRate: 7.67,
    monthlyRepaymentList: [901.1227236516564],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 200,
  },
  {
    uniqueId:
      "C02F47E9-D04D-DB7E-1BCE-0D3FA7F68312:a42bdbc6-3205-4668-8748-5c610ca56bd1",
    lenderId: "CBA",
    baseRate: 7.3,
    maxBorrowCapacity: 131441.21,
    rates: [],
    maxBuyingPower: 1402831.21,
    comparisonRate: 7.32,
    monthlyRepaymentList: [901.1227236516564],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 800,
  },
  {
    uniqueId:
      "C23E580D-6330-2B4D-F9C7-0D40692AA3A7:40d3f43f-3ca6-4a93-a84f-b60aeb7f810e",
    lenderId: "CBA",
    baseRate: 7.3,
    maxBorrowCapacity: 131441.21,
    rates: [],
    maxBuyingPower: 1402831.21,
    comparisonRate: 7.67,
    monthlyRepaymentList: [901.1227236516564],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 200,
  },
  {
    uniqueId:
      "EB107242-3F90-1F32-503D-D86C56F6F84E:fdb6dce7-f369-4048-9bde-02643891af26",
    lenderId: "CBA",
    baseRate: 7.3,
    maxBorrowCapacity: 131441.21,
    rates: [],
    maxBuyingPower: 1402831.21,
    comparisonRate: 7.67,
    monthlyRepaymentList: [901.1227236516564],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 200,
  },
  {
    uniqueId: "C10041:23260d44-3461-44da-a86f-ef1f942e6dbe",
    lenderId: "CBA",
    baseRate: 7.79,
    maxBorrowCapacity: 131441.21,
    rates: [],
    maxBuyingPower: 1402831.21,
    comparisonRate: 7.81,
    monthlyRepaymentList: [945.2967344758133],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 800,
  },
  {
    uniqueId:
      "0AE940ED-4579-4795-074E-8E47AD1566FA:c4c391f8-8962-4541-b1db-d7dd52045684",
    lenderId: "CBA",
    baseRate: 7.79,
    maxBorrowCapacity: 131441.21,
    rates: [],
    maxBuyingPower: 1402831.21,
    comparisonRate: 8.16,
    monthlyRepaymentList: [945.2967344758133],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 200,
  },
  {
    uniqueId:
      "BFBAE57B-C6DE-681B-8E96-AA952DAA6A07:c359740e-d248-4ce5-b45e-a8d0bb177d0f",
    lenderId: "CBA",
    baseRate: 7.79,
    maxBorrowCapacity: 131441.21,
    rates: [],
    maxBuyingPower: 1402831.21,
    comparisonRate: 8.16,
    monthlyRepaymentList: [945.2967344758133],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 200,
  },
  {
    uniqueId:
      "6E1CA16A-D860-850F-3574-187B1EA4C10E:02de599c-60d4-4d61-81fa-669097c3efd3",
    lenderId: "CHLA",
    baseRate: 5.5,
    maxBorrowCapacity: 138804.94,
    rates: [],
    maxBuyingPower: 1410194.94,
    comparisonRate: 5.51,
    monthlyRepaymentList: [788.1191826463032],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 395,
  },
  {
    uniqueId:
      "D615520E-08B5-0391-B948-DA0D0FCDD9A0:128b98c0-2587-46eb-a885-f65ecb34d9f6",
    lenderId: "CHLA",
    baseRate: 5.92,
    maxBorrowCapacity: 138804.94,
    rates: [],
    maxBuyingPower: 1410194.94,
    comparisonRate: 5.93,
    monthlyRepaymentList: [825.079988037548],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 395,
  },
  {
    uniqueId:
      "2C49CAF2-7FB0-F568-EDB6-72A9FF31F69A:f1aa12b5-8688-4cff-a033-ebcbe65d24fc",
    lenderId: "CHLA",
    baseRate: 6.05,
    maxBorrowCapacity: 138804.94,
    rates: [],
    maxBuyingPower: 1410194.94,
    comparisonRate: 6.06,
    monthlyRepaymentList: [836.6730023491089],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 395,
  },
  {
    uniqueId:
      "3D33DD07-5D1E-DB0A-118E-9E569940F8EB:0bbfc2c4-3b52-4075-b85f-7dc507dbf96c",
    lenderId: "CHLAB",
    baseRate: 4.83,
    maxBorrowCapacity: 126786.8,
    rates: [],
    maxBuyingPower: 1398176.8,
    comparisonRate: 4.89,
    monthlyRepaymentList: [667.5074310570295],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1699,
  },
  {
    uniqueId:
      "015BF939-A046-9A0E-0E07-3EE453A8B93E:7a97f037-cca6-4332-8b67-ab75ffb242b3",
    lenderId: "CHLAB",
    baseRate: 5.49,
    maxBorrowCapacity: 126786.8,
    rates: [],
    maxBuyingPower: 1398176.8,
    comparisonRate: 5.52,
    monthlyRepaymentList: [719.0862283090903],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 974,
  },
  {
    uniqueId:
      "4BF08571-FD70-9B4C-37A6-3EDD9A39B505:7ab07ab8-bbeb-4076-a70c-15c5de915636",
    lenderId: "CHLAB",
    baseRate: 5.49,
    maxBorrowCapacity: 126786.8,
    rates: [],
    maxBuyingPower: 1398176.8,
    comparisonRate: 5.52,
    monthlyRepaymentList: [719.0862283090903],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 974,
  },
  {
    uniqueId:
      "4E934524-7AC9-6DB4-29F0-A3A6C8116CA8:fd222532-1e92-4e82-95e9-c717769dc510",
    lenderId: "CHLAB",
    baseRate: 5.84,
    maxBorrowCapacity: 126786.8,
    rates: [],
    maxBuyingPower: 1398176.8,
    comparisonRate: 5.88,
    monthlyRepaymentList: [747.1580859023456],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1124,
  },
  {
    uniqueId:
      "B74BDB1A-E351-1AA4-C206-C48A3F72974D:e3c854ca-e754-444b-9cb0-66c66d1f321f",
    lenderId: "CHLAB",
    baseRate: 5.84,
    maxBorrowCapacity: 126786.8,
    rates: [],
    maxBuyingPower: 1398176.8,
    comparisonRate: 5.86,
    monthlyRepaymentList: [747.1580859023456],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 648,
  },
  {
    uniqueId:
      "D92DED70-7039-F40A-53A8-011AE0E8E474:e1612279-bbc2-431c-a311-e9ef1699ed66",
    lenderId: "CHLAB",
    baseRate: 5.84,
    maxBorrowCapacity: 126786.8,
    rates: [],
    maxBuyingPower: 1398176.8,
    comparisonRate: 5.86,
    monthlyRepaymentList: [747.1580859023456],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 648,
  },
  {
    uniqueId:
      "ECC36037-055A-B085-7754-A3A27BF74EBB:0f144860-0a0f-4ca9-a704-e2bd1626b89c",
    lenderId: "CHLAB",
    baseRate: 5.84,
    maxBorrowCapacity: 126786.8,
    rates: [],
    maxBuyingPower: 1398176.8,
    comparisonRate: 5.88,
    monthlyRepaymentList: [747.1580859023456],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1124,
  },
  {
    uniqueId:
      "2EADB6D4-B054-779F-5D5E-BEC6F8A89322:262041a2-cbee-4b91-bec8-0fc3007ec3cd",
    lenderId: "CHLAB",
    baseRate: 6.18,
    maxBorrowCapacity: 126786.8,
    rates: [],
    maxBuyingPower: 1398176.8,
    comparisonRate: 6.22,
    monthlyRepaymentList: [774.8851163216663],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1124,
  },
  {
    uniqueId:
      "38BD6D3F-4D30-8E25-DF3F-C45653888941:624f907c-82e6-4d39-9fa0-e1d0ea3f3d9c",
    lenderId: "CHLAB",
    baseRate: 6.18,
    maxBorrowCapacity: 126786.8,
    rates: [],
    maxBuyingPower: 1398176.8,
    comparisonRate: 6.2,
    monthlyRepaymentList: [774.8851163216663],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 648,
  },
  {
    uniqueId:
      "8550A46B-977C-91B8-3304-BEC87C63DDF5:1ba236a9-3b05-4921-a2a4-ffdfe2d95994",
    lenderId: "CHLAB",
    baseRate: 6.18,
    maxBorrowCapacity: 126786.8,
    rates: [],
    maxBuyingPower: 1398176.8,
    comparisonRate: 6.22,
    monthlyRepaymentList: [774.8851163216663],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1124,
  },
  {
    uniqueId:
      "A438EDFF-FDAA-790E-12F3-C453E06088CB:4e465a74-9cc2-4126-a0a4-1578091f85b4",
    lenderId: "CHLAB",
    baseRate: 6.18,
    maxBorrowCapacity: 126786.8,
    rates: [],
    maxBuyingPower: 1398176.8,
    comparisonRate: 6.2,
    monthlyRepaymentList: [774.8851163216663],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 648,
  },
  {
    uniqueId:
      "ABB4074F-D9C0-1FDC-C62D-B05BB22180D9:76dc10b2-b012-4095-8019-fa5e6ed35405",
    lenderId: "CHLE",
    baseRate: 5.79,
    maxBorrowCapacity: 154948.57,
    rates: [],
    maxBuyingPower: 1426338.57,
    comparisonRate: 5.82,
    monthlyRepaymentList: [908.1789545559708],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1110,
  },
  {
    uniqueId:
      "F192FE82-91F0-A727-BC15-B04C24C101C6:3ea43f7d-e821-45c3-9a9e-9ad3d18f668f",
    lenderId: "CHLE",
    baseRate: 6.54,
    maxBorrowCapacity: 154948.57,
    rates: [],
    maxBuyingPower: 1426338.57,
    comparisonRate: 6.57,
    monthlyRepaymentList: [983.4599951106646],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1110,
  },
  {
    uniqueId: "C00905:b9860dfa-ae85-49fa-8d9d-280fcaeb8bb1",
    lenderId: "CIT",
    baseRate: 7.85,
    maxBorrowCapacity: 151282.1,
    rates: [],
    maxBuyingPower: 1422672.1,
    comparisonRate: 7.87,
    monthlyRepaymentList: [1094.27622745289],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 649,
  },
  {
    uniqueId:
      "6C399C63-6C7F-AB60-DE96-89F0F59216E1:adb4a8d4-2957-49ba-8f57-a8ced340f40d",
    lenderId: "CIT",
    baseRate: 7.85,
    maxBorrowCapacity: 151282.1,
    rates: [],
    maxBuyingPower: 1422672.1,
    comparisonRate: 8.13,
    monthlyRepaymentList: [1094.27622745289],
    annualFee: 350,
    maxTerm: 30,
    setUpFee: 250,
  },
  {
    uniqueId:
      "6D4062BA-A016-341F-C275-959B1B67B806:6d3f15f1-309b-49ab-acf4-e9c0749914c0",
    lenderId: "CIT",
    baseRate: 7.85,
    maxBorrowCapacity: 151282.1,
    rates: [],
    maxBuyingPower: 1422672.1,
    comparisonRate: 8.13,
    monthlyRepaymentList: [1094.27622745289],
    annualFee: 350,
    maxTerm: 30,
    setUpFee: 250,
  },
  {
    uniqueId:
      "CFDD3481-AFA4-B2AA-6A03-7DD337E2B78E:0c5225aa-1f47-4bef-b256-d880ffb039a1",
    lenderId: "CIT",
    baseRate: 7.85,
    maxBorrowCapacity: 151282.1,
    rates: [],
    maxBuyingPower: 1422672.1,
    comparisonRate: 7.87,
    monthlyRepaymentList: [1094.27622745289],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 649,
  },
  {
    uniqueId:
      "F5338F68-4E33-214D-DEA2-AEDD5429347C:cc8f36cd-0f8b-40c1-9fc2-8a781f0b706a",
    lenderId: "FMB",
    baseRate: 4.54,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.56,
    monthlyRepaymentList: [771.1140349392077],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "40EE7EF3-EB84-7356-1976-945B1A3BE3E3:11ad71f1-095a-4bc0-9682-2d7cb83a436c",
    lenderId: "FMB",
    baseRate: 4.94,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.97,
    monthlyRepaymentList: [807.6136604786916],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1050,
  },
  {
    uniqueId:
      "BC034DCB-C02B-1B50-A841-AEDE2A163C27:2b1819e1-5555-4b7d-98ec-c364acc4baf5",
    lenderId: "FMB",
    baseRate: 4.94,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.96,
    monthlyRepaymentList: [807.6136604786916],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "99CBDC5B-D7A5-7B17-42FF-5027063C1B32:ca72b5e6-f6ce-40a8-b58a-2f46e3f9c2f5",
    lenderId: "FMB",
    baseRate: 5.69,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 5.96,
    monthlyRepaymentList: [878.2111509056562],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "319D19F8-9391-2617-9A56-379F43E48D44:0216fa6c-0b61-4935-a09e-22c96bee4807",
    lenderId: "FMB",
    baseRate: 5.74,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.01,
    monthlyRepaymentList: [883.0142826424238],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "1AE8510B-0C2B-19F2-A705-4132B8032D80:9ece1256-5a9e-488d-9ace-79f35ff72e47",
    lenderId: "FMB",
    baseRate: 5.79,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.05,
    monthlyRepaymentList: [887.8291695605385],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "763A592A-9A31-6439-6890-3788BF018EC6:ddd37eaa-b1e0-42c9-8365-9f49dcdca9b1",
    lenderId: "FMB",
    baseRate: 5.84,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.1,
    monthlyRepaymentList: [892.6557537140717],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "797E9CA6-0345-8833-A3F0-4133F7848786:50cb7b2f-e6fc-441b-95f2-90450bb6531a",
    lenderId: "FMB",
    baseRate: 6.19,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.44,
    monthlyRepaymentList: [926.7644676494675],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "E7B69D4E-E8AD-B4CE-2527-378AD2801360:389caeaa-40b4-43cb-b649-c3c4e2a0106c",
    lenderId: "FMB",
    baseRate: 6.24,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.49,
    monthlyRepaymentList: [931.6825267504153],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "A1ADDEB9-FF70-FF0C-3577-37A676DF3EC6:6455805b-a553-4278-94ee-56ec143d6f29",
    lenderId: "FMB",
    baseRate: 6.69,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.71,
    monthlyRepaymentList: [976.4405739209036],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "C9DE3AFF-492B-60A5-C9A1-37AAEE5E63D0:d5ef25c8-6cae-419e-aeb6-2c164f9ba3a5",
    lenderId: "FMB",
    baseRate: 7.09,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 7.11,
    monthlyRepaymentList: [1016.9499732164273],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "EBCE9BE9-3A96-E6AC-F74A-37AC5A26C99C:be71504e-9419-4945-a433-1675a6eb7e15",
    lenderId: "FMB",
    baseRate: 7.09,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 7.12,
    monthlyRepaymentList: [1016.9499732164273],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1050,
  },
  {
    uniqueId:
      "7A803299-C710-814E-99BF-87FDB03B1143:72798cc6-d51e-4111-9c45-156f05c48b62",
    lenderId: "GCU",
    baseRate: 4.24,
    maxBorrowCapacity: 125650.13,
    rates: [],
    maxBuyingPower: 1397040.13,
    comparisonRate: 4.59,
    monthlyRepaymentList: [617.3877437625999],
    annualFee: 299,
    maxTerm: 30,
    setUpFee: 475,
  },
  {
    uniqueId:
      "090A1FBA-4E84-05FB-68E0-8BED2253F1AA:e83e6772-e3d2-4fee-a7b4-8d8ca68805a7",
    lenderId: "GCU",
    baseRate: 4.39,
    maxBorrowCapacity: 125650.13,
    rates: [],
    maxBuyingPower: 1397040.13,
    comparisonRate: 4.74,
    monthlyRepaymentList: [628.4647414748412],
    annualFee: 299,
    maxTerm: 30,
    setUpFee: 475,
  },
  {
    uniqueId:
      "61711FBB-C16E-31A1-2EAF-B91C4176792F:c46a6c39-a143-497b-aa7e-f3b934b45c1b",
    lenderId: "GCU",
    baseRate: 4.44,
    maxBorrowCapacity: 125650.13,
    rates: [],
    maxBuyingPower: 1397040.13,
    comparisonRate: 4.79,
    monthlyRepaymentList: [632.1790931228959],
    annualFee: 299,
    maxTerm: 30,
    setUpFee: 475,
  },
  {
    uniqueId:
      "26076041-DC95-732D-95D4-B91D4E2F05BF:b3c3cbf1-1d30-4ab0-bbb9-94b26768c017",
    lenderId: "GCU",
    baseRate: 4.59,
    maxBorrowCapacity: 125650.13,
    rates: [],
    maxBuyingPower: 1397040.13,
    comparisonRate: 4.94,
    monthlyRepaymentList: [643.3876087856263],
    annualFee: 299,
    maxTerm: 30,
    setUpFee: 475,
  },
  {
    uniqueId:
      "A830A6E3-A121-9DEE-0888-C01676490507:5b3aecb4-3b8b-482a-a419-3e7e9e89f8ef",
    lenderId: "GCU",
    baseRate: 6.72,
    maxBorrowCapacity: 125650.13,
    rates: [],
    maxBuyingPower: 1397040.13,
    comparisonRate: 7.02,
    monthlyRepaymentList: [812.4602569926916],
    annualFee: 299,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "0EF16CDE-5468-4BD0-58BA-CE25EAB7510C:f8d20828-e0b6-4a2d-ab01-7aa22d9f51d5",
    lenderId: "GCU",
    baseRate: 7.18,
    maxBorrowCapacity: 125650.13,
    rates: [],
    maxBuyingPower: 1397040.13,
    comparisonRate: 7.21,
    monthlyRepaymentList: [851.1977468845361],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1000,
  },
  {
    uniqueId:
      "9A2ED5C7-059E-2A40-341C-CE25057C1DE3:89ac1eb5-0b50-4a11-b977-5ec625f4ab32",
    lenderId: "GCU",
    baseRate: 7.18,
    maxBorrowCapacity: 125650.13,
    rates: [],
    maxBuyingPower: 1397040.13,
    comparisonRate: 7.21,
    monthlyRepaymentList: [851.1977468845361],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1000,
  },
  {
    uniqueId:
      "FDE42994-3FE1-9BE3-BC28-F2F8BFEDD95D:1914cf87-25ee-4dd4-a36f-9b63b41f1c07",
    lenderId: "GCU",
    baseRate: 7.18,
    maxBorrowCapacity: 125650.13,
    rates: [],
    maxBuyingPower: 1397040.13,
    comparisonRate: 7.21,
    monthlyRepaymentList: [851.1977468845361],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1000,
  },
  {
    uniqueId:
      "AB6319F6-1D37-A99F-7B72-C01715933C67:88af22ab-a5b1-4fe8-be4c-5cd4b61fc2d1",
    lenderId: "GCU",
    baseRate: 7.54,
    maxBorrowCapacity: 125650.13,
    rates: [],
    maxBuyingPower: 1397040.13,
    comparisonRate: 7.83,
    monthlyRepaymentList: [882.0080680738979],
    annualFee: 299,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "AD8C0039-A57C-CA0C-27F8-B600CD8381F0:9b240a95-1cba-4d56-90ca-8f07680ef48d",
    lenderId: "GCU",
    baseRate: 8,
    maxBorrowCapacity: 125650.13,
    rates: [],
    maxBuyingPower: 1397040.13,
    comparisonRate: 8.03,
    monthlyRepaymentList: [921.9761409733844],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1000,
  },
  {
    uniqueId:
      "D6059C36-2B22-14B8-A917-B5F4BAF87865:884981fe-0459-4188-8e76-52200aa3a7b1",
    lenderId: "GCU",
    baseRate: 8,
    maxBorrowCapacity: 125650.13,
    rates: [],
    maxBuyingPower: 1397040.13,
    comparisonRate: 8.03,
    monthlyRepaymentList: [921.9761409733844],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1000,
  },
  {
    uniqueId:
      "66D395DE-D680-6A8B-9C43-DCAE805B3E96:a2161bb1-8ef8-4847-b134-d365c576a67d",
    lenderId: "HBS",
    baseRate: 7.24,
    maxBorrowCapacity: 145982.85,
    rates: [],
    maxBuyingPower: 1417372.85,
    comparisonRate: 7.25,
    monthlyRepaymentList: [994.8704052146686],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 510,
  },
  {
    uniqueId:
      "CAA73571-91A1-7D9E-159D-9D776F5C4048:76ad5302-eeb0-466b-8228-1c17f7484e90",
    lenderId: "HBS",
    baseRate: 7.24,
    maxBorrowCapacity: 145982.85,
    rates: [],
    maxBuyingPower: 1417372.85,
    comparisonRate: 7.55,
    monthlyRepaymentList: [994.8704052146686],
    annualFee: 350,
    maxTerm: 30,
    setUpFee: 510,
  },
  {
    uniqueId:
      "9649426C-B88A-3D2E-8198-E3C3BFFDD73C:7ec74259-026f-4b3a-b1b6-2f1e1037cc18",
    lenderId: "HBS",
    baseRate: 8.04,
    maxBorrowCapacity: 145982.85,
    rates: [],
    maxBuyingPower: 1417372.85,
    comparisonRate: 10.18,
    monthlyRepaymentList: [12701.518852526136],
    annualFee: 0,
    maxTerm: 1,
    setUpFee: 1740,
  },
  {
    uniqueId:
      "156BD9BE-FBB2-D4AF-2C0F-CB09F1A39309:9d90f158-d3d9-428d-9599-f784c2045b79",
    lenderId: "HLL",
    baseRate: 4.89,
    maxBorrowCapacity: 155565.4,
    rates: [],
    maxBuyingPower: 1426955.4,
    comparisonRate: 5.16,
    monthlyRepaymentList: [824.6818519397639],
    annualFee: 299,
    maxTerm: 30,
    setUpFee: 330,
  },
  {
    uniqueId:
      "B6F81E2E-8099-7399-7F32-CB11F3D935C9:1d4217e7-185e-4790-9f71-0701b286ee0e",
    lenderId: "HLL",
    baseRate: 5.19,
    maxBorrowCapacity: 155565.4,
    rates: [],
    maxBuyingPower: 1426955.4,
    comparisonRate: 5.2,
    monthlyRepaymentList: [853.2657891051929],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 330,
  },
  {
    uniqueId:
      "E29E4023-2F1A-75AB-00C0-630F92443D9B:31cd69f1-cbac-4c94-860a-268c9a49aba5",
    lenderId: "HLL",
    baseRate: 5.91,
    maxBorrowCapacity: 155565.4,
    rates: [],
    maxBuyingPower: 1426955.4,
    comparisonRate: 6.17,
    monthlyRepaymentList: [923.7108473480778],
    annualFee: 299,
    maxTerm: 30,
    setUpFee: 330,
  },
  {
    uniqueId:
      "D53C08B6-B046-58B3-DDE9-632F1686A97C:ad0b6424-4e8c-49cd-a400-fce037cf217a",
    lenderId: "HLL",
    baseRate: 5.94,
    maxBorrowCapacity: 155565.4,
    rates: [],
    maxBuyingPower: 1426955.4,
    comparisonRate: 5.95,
    monthlyRepaymentList: [926.7007000322816],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 330,
  },
  {
    uniqueId:
      "60DD8865-C984-3DB7-F2AD-689ED1D86C26:a36c0114-8cd6-41e6-87e7-3a6bb47a4452",
    lenderId: "HLL",
    baseRate: 6.24,
    maxBorrowCapacity: 155565.4,
    rates: [],
    maxBuyingPower: 1426955.4,
    comparisonRate: 6.25,
    monthlyRepaymentList: [956.8313848273533],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 330,
  },
  {
    uniqueId:
      "04445E03-DDA6-A67D-253E-AEE5CD3133EA:9c631ad3-017c-4387-96b7-1f1a61ed4527",
    lenderId: "HPB",
    baseRate: 4.54,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.56,
    monthlyRepaymentList: [771.1140349392077],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "12A342E1-AD23-8E91-8B3F-AEEF9EE73374:9b8ed5c5-9b27-43c4-8f98-45de4a60f68b",
    lenderId: "HPB",
    baseRate: 4.94,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.96,
    monthlyRepaymentList: [807.6136604786916],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "9868BED3-8606-7C5F-E74B-945D41E72050:7fcc1a27-522d-4cb4-827f-027db86b3cfc",
    lenderId: "HPB",
    baseRate: 4.94,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.97,
    monthlyRepaymentList: [807.6136604786916],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1050,
  },
  {
    uniqueId:
      "4C5D9E7F-FA81-2413-0F85-502ABC8041FF:04828bca-cf44-4477-973a-8a0723ced6bc",
    lenderId: "HPB",
    baseRate: 5.69,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 5.96,
    monthlyRepaymentList: [878.2111509056562],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "BDB75534-086F-98C9-8AF3-37BC8E2EA4B3:fc1b35ad-aeaf-41d0-adc6-53bcf97c2001",
    lenderId: "HPB",
    baseRate: 5.74,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.01,
    monthlyRepaymentList: [883.0142826424238],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "E4F9D672-4920-9CBD-7710-40D3C5B71576:515ebf81-cb4a-41ca-ad7d-e63f57c8b5f6",
    lenderId: "HPB",
    baseRate: 5.79,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.05,
    monthlyRepaymentList: [887.8291695605385],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "F4B71EB6-D594-0FCA-9F80-37B2FF305364:56484b2a-5f1f-4168-9a07-6e498defd09b",
    lenderId: "HPB",
    baseRate: 5.84,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.1,
    monthlyRepaymentList: [892.6557537140717],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "0C43B3AA-EF28-B69A-B7F9-40E39F9F8263:aa8444b6-8fe2-456d-afe3-128d4de2f80b",
    lenderId: "HPB",
    baseRate: 6.19,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.44,
    monthlyRepaymentList: [926.7644676494675],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "71BEDBE7-C5E8-02A6-CC26-37B51FF64029:4f40eba1-1bb5-44ba-8c5f-4309d09879a5",
    lenderId: "HPB",
    baseRate: 6.24,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.49,
    monthlyRepaymentList: [931.6825267504153],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "2230E91A-5F0B-E2F0-2EAC-37C2E1BEAA9D:9ac28cc6-6a51-489d-8b2f-1ac2c0c541c7",
    lenderId: "HPB",
    baseRate: 6.69,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.71,
    monthlyRepaymentList: [976.4405739209036],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "4ED84E50-69EC-D40E-92F0-37C4E1B01A7C:2b2ab2df-da14-4c72-91b7-c903cc8affea",
    lenderId: "HPB",
    baseRate: 7.09,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 7.11,
    monthlyRepaymentList: [1016.9499732164273],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "67818D30-AB63-1C6D-E669-37C96AC353F2:2dfd3e96-2939-47cb-9365-de149b9c9ef3",
    lenderId: "HPB",
    baseRate: 7.09,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 7.12,
    monthlyRepaymentList: [1016.9499732164273],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1050,
  },
  {
    uniqueId: "CMEL11154:baf5411f-0469-4276-8b2e-7ac1d8a89b36",
    lenderId: "HSL",
    baseRate: 7.27,
    maxBorrowCapacity: 124882.54,
    rates: [],
    maxBuyingPower: 1396272.54,
    comparisonRate: 7.66,
    monthlyRepaymentList: [853.6138120583504],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "F175D099-F7F1-12EE-FE61-0088C418F0B5:2749f148-00e3-4ab3-b99b-bb0e8b0dd686",
    lenderId: "HSL",
    baseRate: 7.27,
    maxBorrowCapacity: 124882.54,
    rates: [],
    maxBuyingPower: 1396272.54,
    comparisonRate: 7.27,
    monthlyRepaymentList: [853.6138120583504],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "1345A3BC-3865-3F2C-C20B-15586794CA95:89b7080a-d063-4903-9c45-39498db1ae04",
    lenderId: "HSL",
    baseRate: 7.74,
    maxBorrowCapacity: 124882.54,
    rates: [],
    maxBuyingPower: 1396272.54,
    comparisonRate: 8.12,
    monthlyRepaymentList: [893.8109828126678],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "3C9EC230-19B0-68AB-5073-66DB7E34E362:4072a7d6-f117-4213-aa46-00d5e9732a75",
    lenderId: "HSL",
    baseRate: 7.74,
    maxBorrowCapacity: 124882.54,
    rates: [],
    maxBuyingPower: 1396272.54,
    comparisonRate: 7.74,
    monthlyRepaymentList: [893.8109828126678],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "69896C94-FAE5-4CBF-5AB6-396D64F2123A:3ee02730-5d8a-4510-863d-7c9c0cf2eb4e",
    lenderId: "HSL",
    baseRate: 7.74,
    maxBorrowCapacity: 124882.54,
    rates: [],
    maxBuyingPower: 1396272.54,
    comparisonRate: 8.12,
    monthlyRepaymentList: [893.8109828126678],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "B2241C3C-45B1-BC38-6F1A-008C89720D6A:c8674c35-f029-484b-a90d-ea7695279e23",
    lenderId: "HSL",
    baseRate: 7.74,
    maxBorrowCapacity: 124882.54,
    rates: [],
    maxBuyingPower: 1396272.54,
    comparisonRate: 7.74,
    monthlyRepaymentList: [893.8109828126678],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "953421F5-C9F1-DE0B-E043-F73475F0464D:7d047ab3-5148-432d-8624-ebd1cf07f379",
    lenderId: "IDE",
    baseRate: 4.46,
    maxBorrowCapacity: 167112.23,
    rates: [],
    maxBuyingPower: 1438502.23,
    comparisonRate: 4.47,
    monthlyRepaymentList: [842.7659858857043],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 275,
  },
  {
    uniqueId:
      "FA5B37AF-4BF2-202B-C6C9-8C6FE03B6FC8:e8d50266-54ab-48dc-99d6-a305fc077380",
    lenderId: "IDE",
    baseRate: 4.89,
    maxBorrowCapacity: 167112.23,
    rates: [],
    maxBuyingPower: 1438502.23,
    comparisonRate: 4.9,
    monthlyRepaymentList: [885.8937997664246],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 275,
  },
  {
    uniqueId:
      "FCBD8809-4640-5B07-931E-16C45D1E59F9:9c9932e2-f81a-44e7-b6d9-6600f3feeb65",
    lenderId: "IDE",
    baseRate: 5.44,
    maxBorrowCapacity: 167112.23,
    rates: [],
    maxBuyingPower: 1438502.23,
    comparisonRate: 5.78,
    monthlyRepaymentList: [942.5635186631332],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 900,
  },
  {
    uniqueId:
      "71F673A4-53F7-9396-5527-16D295FE4D40:32192aa9-d27f-4c08-bf37-8302c36bf582",
    lenderId: "IDE",
    baseRate: 5.54,
    maxBorrowCapacity: 167112.23,
    rates: [],
    maxBuyingPower: 1438502.23,
    comparisonRate: 5.87,
    monthlyRepaymentList: [953.043048969057],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 900,
  },
  {
    uniqueId:
      "2C3F9986-EB09-5B46-D132-E53AAE4B1152:58fb448b-757a-4bf0-903b-7ae834371890",
    lenderId: "IDE",
    baseRate: 5.69,
    maxBorrowCapacity: 167112.23,
    rates: [],
    maxBuyingPower: 1438502.23,
    comparisonRate: 5.7,
    monthlyRepaymentList: [968.8613544185093],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 275,
  },
  {
    uniqueId: "CMEL11516:f3e3ff2b-c7c5-44cc-b15d-5956d62931a4",
    lenderId: "ING",
    baseRate: 6.9,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 299,
    maxTerm: 30,
    setUpFee: 299,
  },
  {
    uniqueId:
      "ACA88EC4-51DA-CB8F-9A6D-CF1A7BEB2C13:f3b50765-35bd-488e-8100-dd414a103247",
    lenderId: "ING",
    baseRate: 7.3,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 299,
    maxTerm: 30,
    setUpFee: 299,
  },
  {
    uniqueId:
      "F3919EF8-9552-D9E0-4515-11A45DC0E67E:f5b7cfbc-1fc9-4366-a8d0-873a3f5db07b",
    lenderId: "LHL",
    baseRate: 4.9,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 5.3,
    monthlyRepaymentList: [2250.158516318643],
    annualFee: 0,
    maxTerm: 7,
    setUpFee: 2500,
  },
  {
    uniqueId:
      "6203D886-2E90-AD49-A43A-11B4864D0F2B:6231201a-37aa-4564-890d-865f74879433",
    lenderId: "LHL",
    baseRate: 5.59,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 5.59,
    monthlyRepaymentList: [915.9885407333809],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "BF570E66-B5FF-11BC-D326-11C7128C7E2F:34fb5d4d-4537-494e-ba44-3f290e976b6d",
    lenderId: "LHL",
    baseRate: 5.59,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 5.59,
    monthlyRepaymentList: [915.9885407333809],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "DD914F98-2D51-0C02-21E8-D5FE48AA0E62:5c620985-dc2f-4b4e-b7db-145ca2d3c326",
    lenderId: "LHL",
    baseRate: 5.79,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 5.82,
    monthlyRepaymentList: [936.2232754321864],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 995,
  },
  {
    uniqueId:
      "E2BE2AED-B9F6-2EF9-B318-435E18137A7F:20921c3d-0497-46de-b158-2109a58c59a6",
    lenderId: "LHL",
    baseRate: 5.79,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 5.82,
    monthlyRepaymentList: [936.2232754321864],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 995,
  },
  {
    uniqueId:
      "3CB24875-14A7-142C-CF6A-D5A9840D48D0:a0612db0-6266-4ec5-baf6-08b821c63af0",
    lenderId: "LHL",
    baseRate: 6.09,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.09,
    monthlyRepaymentList: [966.9441882760577],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "D4E3C0CC-2371-1D72-22F2-7B203BB5B435:8a3c6a46-7682-4858-b171-7621a7cb5ff3",
    lenderId: "LHL",
    baseRate: 6.09,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.09,
    monthlyRepaymentList: [966.9441882760577],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "82AD0F5F-10D2-FFB4-406D-E4F75153A54A:cecb737d-fe74-4652-830a-67bbedaa7ae1",
    lenderId: "LHL",
    baseRate: 6.19,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.22,
    monthlyRepaymentList: [977.2808724976093],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 995,
  },
  {
    uniqueId: "CMEL011431:47188369-9812-4fea-9659-0d99fd3edeac",
    lenderId: "LHL",
    baseRate: 6.29,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.29,
    monthlyRepaymentList: [987.6649197930896],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "DFD8A21F-7249-2E12-6BC5-D601A3A0AA05:c3a52482-91ba-4ed9-b369-a5f8ce693ed4",
    lenderId: "LHL",
    baseRate: 6.29,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.32,
    monthlyRepaymentList: [987.6649197930896],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 995,
  },
  {
    uniqueId:
      "FA861DC7-8E02-A1BB-6A31-8592643D5C10:ce576295-13ce-441f-9842-261c99341b71",
    lenderId: "LHL",
    baseRate: 6.29,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.32,
    monthlyRepaymentList: [987.6649197930896],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 995,
  },
  {
    uniqueId:
      "1EA557B4-AEF1-7F3E-E4C1-D61A2783FCA9:53315221-0ef1-4709-8f37-983953dda326",
    lenderId: "LHL",
    baseRate: 6.39,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.39,
    monthlyRepaymentList: [998.0958313251044],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "496BED0F-77AE-010A-C3D0-FCC2CD4D597A:81bc1edb-e22d-43ec-b0af-31fa1110289a",
    lenderId: "LHL",
    baseRate: 6.39,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.39,
    monthlyRepaymentList: [998.0958313251044],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "66BDFF7C-C981-4C69-2FA5-46F05240B2E3:7684ab5c-8ae9-47f3-bcca-083961310c30",
    lenderId: "LHL",
    baseRate: 6.39,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.39,
    monthlyRepaymentList: [998.0958313251044],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "B1638DEC-8899-198C-407D-3404752299ED:d41ec65d-f8df-47f0-b05e-a00ea8337986",
    lenderId: "LHL",
    baseRate: 6.39,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.39,
    monthlyRepaymentList: [998.0958313251044],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "E953D6C4-6A9B-5472-B973-02D844EE24DC:07f0ab7a-26c1-4393-8b13-8875451a3ad3",
    lenderId: "LHL",
    baseRate: 6.39,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.39,
    monthlyRepaymentList: [998.0958313251044],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "DA48F4B3-A2BC-5B53-8DE5-0CBE99E5DBBA:0b6954e6-acf4-4360-8538-0e0ae90cf216",
    lenderId: "LHL",
    baseRate: 6.49,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.49,
    monthlyRepaymentList: [1008.5731067450246],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "1A9BF366-1F44-9106-3EBA-0848A041CE28:8b557a25-bf7c-4d6f-b152-f2962a396846",
    lenderId: "LHL",
    baseRate: 6.59,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.59,
    monthlyRepaymentList: [1019.0962443955193],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "1D03A7EA-4839-BF1A-C3E3-0CC247D118C7:6474c59f-6ec2-4ab9-aae5-0ee2acb413e5",
    lenderId: "LHL",
    baseRate: 6.59,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.59,
    monthlyRepaymentList: [1019.0962443955193],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "17F25386-DE9F-1669-5A8A-E4F826D38778:6ca9c57a-668c-428b-b6a6-12f214fbf81b",
    lenderId: "LHL",
    baseRate: 6.69,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.72,
    monthlyRepaymentList: [1029.6647415105883],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 995,
  },
  {
    uniqueId:
      "4391C0C3-316B-0662-0566-D7585E19683D:b6e9b743-0875-463f-b3ea-dbf30d921356",
    lenderId: "LHL",
    baseRate: 6.79,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.79,
    monthlyRepaymentList: [1040.2780944126364],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "4478F2C5-0E2B-FAF1-DD68-FCC0D243260E:b2fb2af9-53de-4e4f-873f-9505aa056429",
    lenderId: "LHL",
    baseRate: 6.79,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.79,
    monthlyRepaymentList: [1040.2780944126364],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "B34275D6-E3AA-1DDB-3694-0302E2945A2B:31ea250a-444f-42b2-9c2f-4c66644b4042",
    lenderId: "LHL",
    baseRate: 6.79,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.79,
    monthlyRepaymentList: [1040.2780944126364],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "ECC1D999-84F7-7B50-97C9-D755E6B3010D:cdd14783-d519-4337-bd4b-a67c5e279e4d",
    lenderId: "LHL",
    baseRate: 6.79,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.79,
    monthlyRepaymentList: [1040.2780944126364],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "A3713D83-3B78-C54F-BCFD-3409E341DFF8:2a463e84-0e63-471b-a409-d0619090963f",
    lenderId: "LHL",
    baseRate: 6.84,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.84,
    monthlyRepaymentList: [1045.6014341830978],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "66EA47E6-79A9-9D71-EB85-0CC22321AE25:7c87dc90-8272-4945-8e26-a7b929ee0d22",
    lenderId: "LHL",
    baseRate: 6.99,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.99,
    monthlyRepaymentList: [1061.6373494712604],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "9D7C550F-C11A-D2D6-72A2-0845FB0110D8:981b279f-722a-418e-b20e-e3efdd242a80",
    lenderId: "LHL",
    baseRate: 6.99,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 6.99,
    monthlyRepaymentList: [1061.6373494712604],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "E90FC7C0-47C5-2D58-6B71-0CA599738043:cffdb2f5-6d99-4252-9454-a597b8e2cca9",
    lenderId: "LHL",
    baseRate: 7.14,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 7.14,
    monthlyRepaymentList: [1077.7707824484844],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "155AAD89-F765-F2A3-EECC-D7591C39E458:5223447c-33ca-41bd-b450-414a5f62d1c8",
    lenderId: "LHL",
    baseRate: 7.19,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 7.19,
    monthlyRepaymentList: [1083.169969220831],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "067B2CED-C596-8CBD-B3BC-0C9DA2DE31DE:d7919b9b-2c66-4eab-9bb7-d119669452f4",
    lenderId: "LHL",
    baseRate: 7.49,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 7.49,
    monthlyRepaymentList: [1115.7851146854669],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "86B4E867-1E4D-ACFC-155A-0816E1FDA329:062009dc-522b-46c8-a826-4ee42b66c5a2",
    lenderId: "LHL",
    baseRate: 7.49,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 7.49,
    monthlyRepaymentList: [1115.7851146854669],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "9187E5FF-AB5F-DE85-5132-203FCDF226F3:7cee752f-889b-41a1-83e9-62002a545168",
    lenderId: "LHL",
    baseRate: 7.64,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 7.64,
    monthlyRepaymentList: [1132.2312934406448],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "F2878D9F-A555-8565-40DA-081C51E05036:c5d89b9b-719c-480d-abf7-9b0f82a3832a",
    lenderId: "LHL",
    baseRate: 7.99,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 7.99,
    monthlyRepaymentList: [1170.9533204637612],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "03E6B9D7-8E87-B2F0-E210-0CC95F83B5CD:6fb2217c-9170-45c1-b826-d57c457dcd08",
    lenderId: "LHL",
    baseRate: 8.44,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: 5.85,
    monthlyRepaymentList: [1221.4253919693801],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "7FFEF6CB-9D8C-9112-8604-0CC97E61009F:7a532b3a-ca13-4a8f-8fb2-8c509c22f86a",
    lenderId: "LHL",
    baseRate: 8.84,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: -1202.65,
    monthlyRepaymentList: [1266.9043211634703],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "7CECBC50-8D8A-8E89-EEEE-081D25CC2C2A:5eb85c87-3b66-4128-a4b2-c5bbf794cb49",
    lenderId: "LHL",
    baseRate: 8.99,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: -1459.38,
    monthlyRepaymentList: [1284.1014157651146],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "4462022F-1666-9FB4-924A-20436D6E1800:17978dbc-0571-40eb-9972-8f046a13fcca",
    lenderId: "LHL",
    baseRate: 9.14,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: -1686.9,
    monthlyRepaymentList: [1301.3737141213826],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "26A67FDD-11DB-415C-D6FF-0CCDD36A5377:4f64d606-af34-4096-8602-12076c2e3e6a",
    lenderId: "LHL",
    baseRate: 9.44,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: -1676.34,
    monthlyRepaymentList: [1336.1375311664924],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "37704B75-D327-0646-08D3-0CD0A58BCBE1:c5894dcb-20ae-465a-a418-c90c2a03f224",
    lenderId: "LHL",
    baseRate: 9.84,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: -1769.19,
    monthlyRepaymentList: [1382.9254890420614],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "37BADDF5-13F9-F07E-2FF0-0CCEA89D81ED:ea0cbada-7be2-4eea-9a1c-245acba61be8",
    lenderId: "LHL",
    baseRate: 9.84,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: -1769.19,
    monthlyRepaymentList: [1382.9254890420614],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "E34E1025-79B4-AACE-9A33-0CD0C3FABF18:79f0883e-7e21-4ba2-b2e3-3d78b27dac7d",
    lenderId: "LHL",
    baseRate: 10.24,
    maxBorrowCapacity: 159733.34,
    rates: [],
    maxBuyingPower: 1431123.34,
    comparisonRate: -1371.19,
    monthlyRepaymentList: [1430.1852383148655],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "65DFBD28-3732-DB86-6BEB-B22C4436B6D6:d97c5871-510c-45cb-a35e-3aa162010c50",
    lenderId: "MCM",
    baseRate: 7.32,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 248,
    maxTerm: 30,
    setUpFee: 350,
  },
  {
    uniqueId:
      "7100689D-7E42-9DC4-0008-0111F9857817:5e9a6dfb-3c46-4d33-bc09-e812abe07548",
    lenderId: "MCM",
    baseRate: 7.32,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 248,
    maxTerm: 30,
    setUpFee: 1850,
  },
  {
    uniqueId:
      "7FE38375-656B-E6E2-1B5B-BD3D9035BCB2:af2145c9-dbd4-4cbb-98ca-27221cd7b11a",
    lenderId: "MCM",
    baseRate: 7.32,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 248,
    maxTerm: 30,
    setUpFee: 1850,
  },
  {
    uniqueId:
      "17F29C5A-BCEF-0C48-F0BE-9D48C5623586:04af2449-2f39-4e4b-a0bc-b31dec07f156",
    lenderId: "MCM",
    baseRate: 7.81,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 248,
    maxTerm: 30,
    setUpFee: 350,
  },
  {
    uniqueId:
      "C9D1B6EF-0AC5-1EB8-773A-452AB016FDEA:5bf6a96c-6279-4b8b-9cd6-fe55626503e8",
    lenderId: "MEB",
    baseRate: 7.21,
    maxBorrowCapacity: 125622.36,
    rates: [],
    maxBuyingPower: 1397012.36,
    comparisonRate: 7.6,
    monthlyRepaymentList: [853.5603395502834],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 250,
  },
  {
    uniqueId:
      "BFD2D99B-E769-0533-89AE-4504AB51454D:9e6c9724-f4e8-4f7e-87e5-64e601237efa",
    lenderId: "MEB",
    baseRate: 7.36,
    maxBorrowCapacity: 125622.36,
    rates: [],
    maxBuyingPower: 1397012.36,
    comparisonRate: 7.37,
    monthlyRepaymentList: [866.3585418360933],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "C4CE9582-86A0-477E-E52A-7EA337384B2D:7779b2d6-864b-4130-b6b5-9203e0eae184",
    lenderId: "MEB",
    baseRate: 7.46,
    maxBorrowCapacity: 125622.36,
    rates: [],
    maxBuyingPower: 1397012.36,
    comparisonRate: 7.85,
    monthlyRepaymentList: [874.9315288355691],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 250,
  },
  {
    uniqueId:
      "5DB9155E-474E-01DE-E1BA-7EA43CEA4A62:6253fff4-bb36-4bdf-9727-647b52e3f325",
    lenderId: "MEB",
    baseRate: 7.86,
    maxBorrowCapacity: 125622.36,
    rates: [],
    maxBuyingPower: 1397012.36,
    comparisonRate: 7.87,
    monthlyRepaymentList: [909.5417066860789],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "CC353F6D-BF25-1ED3-E758-471E1CE34AEF:c2438081-c67a-4f2b-853e-31dabb25d149",
    lenderId: "MKM",
    baseRate: 6.95,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: 7.22,
    monthlyRepaymentList: [1143.5208996070396],
    annualFee: 295,
    maxTerm: 30,
    setUpFee: 2430,
  },
  {
    uniqueId:
      "C838A452-3C81-4E95-2616-46CE2A9B1277:c46858b6-a0d5-4cab-9de9-bb4f6de9323a",
    lenderId: "MKM",
    baseRate: 7.15,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: 7.32,
    monthlyRepaymentList: [1166.7711823463696],
    annualFee: 195,
    maxTerm: 30,
    setUpFee: 1294,
  },
  {
    uniqueId:
      "77C44B54-1C45-91B4-93D1-481094837451:4f15e11e-4d9c-4d0b-8577-ffe52ed33160",
    lenderId: "MKM",
    baseRate: 7.2,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: 7.47,
    monthlyRepaymentList: [1172.6126730701092],
    annualFee: 295,
    maxTerm: 30,
    setUpFee: 2430,
  },
  {
    uniqueId:
      "CBFA78E6-5E08-C50B-7A75-46DC98794071:7483d698-9e2d-4337-a072-5daebb9073b7",
    lenderId: "MKM",
    baseRate: 7.4,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: 7.57,
    monthlyRepaymentList: [1196.0922667822904],
    annualFee: 195,
    maxTerm: 30,
    setUpFee: 1294,
  },
  {
    uniqueId:
      "BFE1EF5D-9218-30CE-97E3-46E5D139C7EC:5ef27cf6-96a3-4d5b-bd35-b92c6428d0ab",
    lenderId: "MKM",
    baseRate: 7.59,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: 7.76,
    monthlyRepaymentList: [1218.5633151019185],
    annualFee: 195,
    maxTerm: 30,
    setUpFee: 1394,
  },
  {
    uniqueId:
      "AEBCDA3B-DB6D-7623-8C9B-46EC426E3B50:9bea761d-863d-41e8-8250-2fd52a41eae9",
    lenderId: "MKM",
    baseRate: 7.84,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: 8.01,
    monthlyRepaymentList: [1248.3696760911675],
    annualFee: 195,
    maxTerm: 30,
    setUpFee: 1394,
  },
  {
    uniqueId:
      "1EF437B5-97A2-15DD-B058-471C506CA985:ea39d5a6-96c3-48aa-9464-5e9a52f1efcc",
    lenderId: "MKM",
    baseRate: 8.25,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: 5.93,
    monthlyRepaymentList: [1297.8197447707487],
    annualFee: 295,
    maxTerm: 30,
    setUpFee: 3645,
  },
  {
    uniqueId:
      "AFB9D2A3-9C09-6958-116F-46F4E46E7280:dd02a750-7e75-4e70-bf00-fd05c175969a",
    lenderId: "MKM",
    baseRate: 8.29,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: 5.91,
    monthlyRepaymentList: [1302.6807688286237],
    annualFee: 295,
    maxTerm: 30,
    setUpFee: 1394,
  },
  {
    uniqueId:
      "4CF70506-081A-E959-8912-480945D7C241:17127872-4b44-4982-b44c-2944a2f55258",
    lenderId: "MKM",
    baseRate: 8.5,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: -1447.95,
    monthlyRepaymentList: [1328.3048862219403],
    annualFee: 295,
    maxTerm: 30,
    setUpFee: 3645,
  },
  {
    uniqueId:
      "52E9E1DA-3ACC-EEC0-FD6F-46F71E656AE1:2c7e0ef9-0b7f-4b79-a4b4-abeebd85bbb2",
    lenderId: "MKM",
    baseRate: 8.54,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: -1400.41,
    monthlyRepaymentList: [1333.2051739989774],
    annualFee: 295,
    maxTerm: 30,
    setUpFee: 1394,
  },
  {
    uniqueId:
      "F3E346C5-64AD-A661-4308-4719FE07FCCD:83441612-a52a-448b-b114-5a88a63ec9c9",
    lenderId: "MKM",
    baseRate: 9.75,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: -1571.65,
    monthlyRepaymentList: [1484.1968933057613],
    annualFee: 295,
    maxTerm: 30,
    setUpFee: 2145,
  },
  {
    uniqueId:
      "D1E79A43-2004-F6AB-F859-480CB14DE4F3:d07a0998-fa2b-49a7-be00-a4a31d585d0e",
    lenderId: "MKM",
    baseRate: 10,
    maxBorrowCapacity: 172750.89,
    rates: [],
    maxBuyingPower: 1444140.8900000001,
    comparisonRate: -1360.09,
    monthlyRepaymentList: [1516.0126977153745],
    annualFee: 295,
    maxTerm: 30,
    setUpFee: 2145,
  },
  {
    uniqueId:
      "82DAD066-AE24-19FB-BB64-9D34E0229DDB:ac408299-b911-4393-add1-965846b9eba7",
    lenderId: "MSB",
    baseRate: 6.09,
    maxBorrowCapacity: 153101.55,
    rates: [],
    maxBuyingPower: 1424491.55,
    comparisonRate: 6.11,
    monthlyRepaymentList: [926.798713334087],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 800,
  },
  {
    uniqueId:
      "A7BBF4F2-325D-6DC1-2E68-B969B1B1F8BE:cff1c812-c37f-4271-be4a-62fb52e5f17a",
    lenderId: "MSB",
    baseRate: 6.09,
    maxBorrowCapacity: 153101.55,
    rates: [],
    maxBuyingPower: 1424491.55,
    comparisonRate: 6.11,
    monthlyRepaymentList: [926.798713334087],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 800,
  },
  {
    uniqueId:
      "9743FE8F-4E1F-5548-2281-B9697D6A4C14:cdbf2777-066b-4fde-8bb8-14d18dc090f1",
    lenderId: "MSB",
    baseRate: 6.13,
    maxBorrowCapacity: 153101.55,
    rates: [],
    maxBuyingPower: 1424491.55,
    comparisonRate: 6.15,
    monthlyRepaymentList: [930.7562459943031],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 800,
  },
  {
    uniqueId:
      "9BB149E1-0037-40EE-7C13-81861D1EA328:3c1cc487-b713-4c9c-b34c-bacc26dce61f",
    lenderId: "MSB",
    baseRate: 6.13,
    maxBorrowCapacity: 153101.55,
    rates: [],
    maxBuyingPower: 1424491.55,
    comparisonRate: 6.15,
    monthlyRepaymentList: [930.7562459943031],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 800,
  },
  {
    uniqueId:
      "220BA5FF-F9EF-A1F8-7F31-9D25B284AF6B:f96f5ae4-3e4f-43e7-b23a-d200b70a376d",
    lenderId: "MSB",
    baseRate: 6.97,
    maxBorrowCapacity: 153101.55,
    rates: [],
    maxBuyingPower: 1424491.55,
    comparisonRate: 7,
    monthlyRepaymentList: [1015.5056271634342],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 950,
  },
  {
    uniqueId:
      "0048A6D8-E45B-FB3C-C27D-818B5F0005F9:9670153d-6c0f-4219-919b-6cc80783eead",
    lenderId: "MSB",
    baseRate: 7.37,
    maxBorrowCapacity: 153101.55,
    rates: [],
    maxBuyingPower: 1424491.55,
    comparisonRate: 7.4,
    monthlyRepaymentList: [1056.912683906793],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 950,
  },
  {
    uniqueId:
      "9FA04FE3-5D35-4652-D1C8-D9EB2A25E418:7aac2f82-1cd1-461d-b59b-f8211d330533",
    lenderId: "NPBS",
    baseRate: 6.32,
    maxBorrowCapacity: 131542.42,
    rates: [],
    maxBuyingPower: 1402932.42,
    comparisonRate: 6.7,
    monthlyRepaymentList: [815.9274801791089],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "B9D944A7-04B8-0027-FFDA-3C5121DD9ABE:7c9bfaab-0830-4a8d-b94f-ee1bda760c2b",
    lenderId: "NPBS",
    baseRate: 6.32,
    maxBorrowCapacity: 131542.42,
    rates: [],
    maxBuyingPower: 1402932.42,
    comparisonRate: 6.7,
    monthlyRepaymentList: [815.9274801791089],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "2A91A6ED-2C0D-7758-D436-D9EB337AA592:f17e605d-81ba-4b86-8c48-ece4a4f7d880",
    lenderId: "NPBS",
    baseRate: 6.37,
    maxBorrowCapacity: 131542.42,
    rates: [],
    maxBuyingPower: 1402932.42,
    comparisonRate: 6.75,
    monthlyRepaymentList: [820.2234396465834],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "1EB26CD3-A452-E2BD-D30B-D9EB1F448788:ee162d1d-08ea-4b94-a0e9-332426a6205f",
    lenderId: "NPBS",
    baseRate: 6.47,
    maxBorrowCapacity: 131542.42,
    rates: [],
    maxBuyingPower: 1402932.42,
    comparisonRate: 6.85,
    monthlyRepaymentList: [828.8440048595062],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "35EC6F0B-D7EA-4DE0-7BF1-3C4FAB1061D8:21c75d78-d8ed-4aaf-b780-cd2e5e649030",
    lenderId: "NPBS",
    baseRate: 6.52,
    maxBorrowCapacity: 131542.42,
    rates: [],
    maxBuyingPower: 1402932.42,
    comparisonRate: 6.9,
    monthlyRepaymentList: [833.1685073726802],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "1F00D9EA-A760-E465-E74A-D9F045752961:8eb55693-9918-4619-9e0e-62fb418c8e5c",
    lenderId: "NPBS",
    baseRate: 6.81,
    maxBorrowCapacity: 131542.42,
    rates: [],
    maxBuyingPower: 1402932.42,
    comparisonRate: 7.18,
    monthlyRepaymentList: [858.4345725965662],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "BA8A02B4-0DA4-65A4-0545-C2BF7443C4E4:c7d60678-22d2-4a09-badd-c0a1a880e4a2",
    lenderId: "NPBS",
    baseRate: 6.81,
    maxBorrowCapacity: 131542.42,
    rates: [],
    maxBuyingPower: 1402932.42,
    comparisonRate: 7.18,
    monthlyRepaymentList: [858.4345725965662],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "42B977EA-3466-DB49-FC05-D9F05240B52E:5bdab582-5579-4749-8503-b6ed2e7f3e9a",
    lenderId: "NPBS",
    baseRate: 6.86,
    maxBorrowCapacity: 131542.42,
    rates: [],
    maxBuyingPower: 1402932.42,
    comparisonRate: 7.23,
    monthlyRepaymentList: [862.8220478805239],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "F70A1595-4452-2B84-51BB-D9F04D172661:fdc12eed-3404-4328-bdf2-2169aeb92d10",
    lenderId: "NPBS",
    baseRate: 6.96,
    maxBorrowCapacity: 131542.42,
    rates: [],
    maxBuyingPower: 1402932.42,
    comparisonRate: 7.33,
    monthlyRepaymentList: [871.6241211430388],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "AB190F8A-D67A-D86F-C9A3-32EE4B847C54:90d5d68a-4481-489b-8037-92b3f8f3b1fc",
    lenderId: "NPBS",
    baseRate: 7.01,
    maxBorrowCapacity: 131542.42,
    rates: [],
    maxBuyingPower: 1402932.42,
    comparisonRate: 7.38,
    monthlyRepaymentList: [876.0386151141805],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "0F488E7D-0011-D219-748D-4E930A7690BA:7cdb2180-ba48-4a42-810a-bd5d09704cb3",
    lenderId: "SCM",
    baseRate: 7.73,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.73,
    monthlyRepaymentList: [1142.5786871588368],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "12625F32-6AB8-A60F-A9CA-D4676EE281AA:004c4da2-1d5f-4fea-802d-4c5454c49651",
    lenderId: "SCM",
    baseRate: 7.73,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.73,
    monthlyRepaymentList: [1142.5786871588368],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "429812D1-6591-E223-544F-D661574477D7:dc6129e3-8818-400b-abe5-a666c7b33adb",
    lenderId: "SCM",
    baseRate: 7.73,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.73,
    monthlyRepaymentList: [1142.5786871588368],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "56D81293-1F1A-BA18-67BA-4E902D266B78:4ec51c07-353c-4c81-8d12-f70ed93e2600",
    lenderId: "SCM",
    baseRate: 7.73,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.73,
    monthlyRepaymentList: [1142.5786871588368],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "65D4828C-52EB-2234-A423-4BA1434650D7:6dc20ef5-f38b-4e37-8794-47bc3052914e",
    lenderId: "SCM",
    baseRate: 7.73,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.75,
    monthlyRepaymentList: [1142.5786871588368],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "84E8680A-1816-9AFC-0E73-DF7912778C7E:f5cd6722-daab-4a00-8c3d-864c2e91524e",
    lenderId: "SCM",
    baseRate: 7.73,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.75,
    monthlyRepaymentList: [1142.5786871588368],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "E99E8BB1-FCCF-A819-E18E-6D916F113F0B:d7419b1f-2e48-4550-8b8a-9a18347ba6a9",
    lenderId: "SCM",
    baseRate: 7.73,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.73,
    monthlyRepaymentList: [1142.5786871588368],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "9040F4E1-B984-2755-8759-71D1FEFD8E82:670ad2d9-5524-4c31-ac98-3dc5c73a43dd",
    lenderId: "SCM",
    baseRate: 7.9,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.92,
    monthlyRepaymentList: [1161.3940457658498],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "911FAFC9-9013-A9CE-3840-71DCDCE1DFE7:148aa602-c8c4-403b-95f8-b67c32513e6e",
    lenderId: "SCM",
    baseRate: 7.9,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.9,
    monthlyRepaymentList: [1161.3940457658498],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "ADCD71D0-D5D8-2E42-BD44-71EAF1931547:5f0b83f7-926b-4683-8e45-7801ffd386cf",
    lenderId: "SCM",
    baseRate: 7.9,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.9,
    monthlyRepaymentList: [1161.3940457658498],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "DE765C87-8934-AF6B-B0C9-4E95A0539027:ec980a6f-3dcd-4ae9-9922-11ddcab853d9",
    lenderId: "SCM",
    baseRate: 7.9,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.9,
    monthlyRepaymentList: [1161.3940457658498],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "E8D86610-9E81-757F-44CE-4BAA45305DCB:31c04eb4-d839-4619-ab62-e5b8f11b7760",
    lenderId: "SCM",
    baseRate: 7.9,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.92,
    monthlyRepaymentList: [1161.3940457658498],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "FC1B2E4F-18A7-1E89-62BB-4E90E7655C35:9e2e93e8-f456-4b28-8511-8d8a51437f79",
    lenderId: "SCM",
    baseRate: 7.9,
    maxBorrowCapacity: 159794.36,
    rates: [],
    maxBuyingPower: 1431184.3599999999,
    comparisonRate: 7.9,
    monthlyRepaymentList: [1161.3940457658498],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "0945294A-6E49-2075-A50C-B0C0A5578D5D:9d9ea1f5-2b22-44a3-8ed7-7fdd1e4b84be",
    lenderId: "SGB",
    baseRate: 7.61,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 700,
  },
  {
    uniqueId:
      "7B583202-6FBB-0919-3BD5-B0D499F16F37:4b66023d-12c5-44ca-b9bd-9fe6a994468c",
    lenderId: "SGB",
    baseRate: 7.61,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 100,
  },
  {
    uniqueId: "C10159:f0abdd7d-6d81-4582-acfe-cebec90f7f25",
    lenderId: "SGB",
    baseRate: 8.2,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 700,
  },
  {
    uniqueId:
      "C6CC5DC3-6C6A-4718-2E45-122DF64D8A17:0ac1cd52-225b-4325-a254-e00c2a78f619",
    lenderId: "SGB",
    baseRate: 8.2,
    maxBorrowCapacity: 0,
    rates: [],
    maxBuyingPower: 1271390,
    comparisonRate: 0,
    monthlyRepaymentList: [0],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 100,
  },
  {
    uniqueId:
      "A119FC66-962B-64EB-E1CF-AEF8E8A4EAC8:17eadaba-eedd-4c05-a047-99f2e7c45c16",
    lenderId: "TMB",
    baseRate: 4.54,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.56,
    monthlyRepaymentList: [771.1140349392077],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "3DDD0FB3-1099-1E89-F028-AEF7BF342B76:32f099a7-986b-4e97-8cd8-bed39d372379",
    lenderId: "TMB",
    baseRate: 4.94,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.96,
    monthlyRepaymentList: [807.6136604786916],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "F3FC19A6-09B3-CFCB-4855-945EA8792F22:8f788f05-f3dc-457f-9f1f-abc7c5aeecb3",
    lenderId: "TMB",
    baseRate: 4.94,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.97,
    monthlyRepaymentList: [807.6136604786916],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1050,
  },
  {
    uniqueId:
      "33F63C2A-F7EF-C696-66E1-501FEC58F0FB:9096a583-68cd-4920-8bc2-e345aed8d246",
    lenderId: "TMB",
    baseRate: 5.69,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 5.96,
    monthlyRepaymentList: [878.2111509056562],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "440C5B03-E6C5-2530-7445-32824C76483D:fbd00615-17eb-42da-9ad8-3b6bc08d1503",
    lenderId: "TMB",
    baseRate: 5.74,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.01,
    monthlyRepaymentList: [883.0142826424238],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "AB7F8048-3820-1F2C-E108-4130A04FDD62:8c5af2ac-47e3-483c-bbdd-9b0200ec507b",
    lenderId: "TMB",
    baseRate: 5.79,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.05,
    monthlyRepaymentList: [887.8291695605385],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "E8FCCC8F-DFAE-87AB-A6B4-12E2C64AA179:a132c5a8-b91a-4417-9440-447545cd3a66",
    lenderId: "TMB",
    baseRate: 5.84,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.1,
    monthlyRepaymentList: [892.6557537140717],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "76DF9B40-00FD-4C4A-1FBB-41316C6DA6AE:55d75a31-ecf3-4385-969a-f0fa5e107c86",
    lenderId: "TMB",
    baseRate: 6.19,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.44,
    monthlyRepaymentList: [926.7644676494675],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "CBEAABA2-C1CC-90E9-2E88-17ACBA463FD1:ab01fcf4-5785-42e9-be11-20a4b75fb329",
    lenderId: "TMB",
    baseRate: 6.24,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.49,
    monthlyRepaymentList: [931.6825267504153],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "6A10E69F-E525-4873-C7BE-E55F2E2F434F:f888c6bb-7cc3-4997-ac57-75a662fa5c4e",
    lenderId: "TMB",
    baseRate: 6.69,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.71,
    monthlyRepaymentList: [976.4405739209036],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "73063AE1-49F4-3602-9DBC-E56C5E0D04BC:729dd994-09e3-428b-a87e-8ae978a6bc10",
    lenderId: "TMB",
    baseRate: 7.09,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 7.11,
    monthlyRepaymentList: [1016.9499732164273],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "A7C20883-230D-4C1D-BB1D-327DFA955860:cdae51a0-7047-4a84-880b-e6b3f1511a5d",
    lenderId: "TMB",
    baseRate: 7.09,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 7.12,
    monthlyRepaymentList: [1016.9499732164273],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1050,
  },
  {
    uniqueId:
      "3DD50FF1-180F-A2F6-B705-18889DDED820:99215542-37ed-4dd3-8618-52dcc4778685",
    lenderId: "TNT",
    baseRate: 4.59,
    maxBorrowCapacity: 186834.37,
    rates: [],
    maxBuyingPower: 1458224.37,
    comparisonRate: 4.61,
    monthlyRepaymentList: [956.6796194581648],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 720,
  },
  {
    uniqueId:
      "4F671D8A-230E-D41C-1F1A-E2CD37461B10:9fdad8ce-d0f1-4303-b276-6cf79d92581d",
    lenderId: "TNT",
    baseRate: 5.24,
    maxBorrowCapacity: 186834.37,
    rates: [],
    maxBuyingPower: 1458224.37,
    comparisonRate: 5.26,
    monthlyRepaymentList: [1030.549400182024],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 720,
  },
  {
    uniqueId:
      "386C0FA4-4A16-9C50-DCC5-E2CE1AD63B96:e1d4ea0c-9fdb-4594-a737-ecd764c3ed0f",
    lenderId: "TNT",
    baseRate: 5.34,
    maxBorrowCapacity: 186834.37,
    rates: [],
    maxBuyingPower: 1458224.37,
    comparisonRate: 5.36,
    monthlyRepaymentList: [1042.1457504040561],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 720,
  },
  {
    uniqueId:
      "40A8CF42-F687-6146-7F70-7E943571E59A:d3849bf7-240b-4f94-be3a-ca06588870d0",
    lenderId: "TNT",
    baseRate: 5.44,
    maxBorrowCapacity: 186834.37,
    rates: [
      {
        uniqueId: "EBC32953-9E8F-6668-6EB6-822C0768252F",
        company: null,
        isDeleted: null,
        deletedBy: null,
        deletedByDisplay: null,
        deletedOn: null,
        createdOn: null,
        createdBy: null,
        isNew: null,
        productId: "40A8CF42-F687-6146-7F70-7E943571E59A",
        rateNumber: 1,
        rate: 4.84,
        duration: 5,
        type: "Intro",
        noDiscount: false,
        rateChangeDate: "2022-11-03",
        previousRate: 4.59,
        repayment: null,
        discountOverride: 0,
        hasOverriddenDiscount: false,
        discountOverrideIO: 0,
        hasOverriddenDiscountIO: false,
        discountApplied: 0,
      },
    ],
    maxBuyingPower: 1458224.37,
    comparisonRate: 5.47,
    monthlyRepaymentList: [984.7775425240741, 1137.232231498399],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1390,
  },
  {
    uniqueId:
      "1C7F4C44-C85C-34C2-49DF-790FA09065D3:42392abc-e75a-40f2-a31e-a531951fa0a6",
    lenderId: "TNT",
    baseRate: 5.58,
    maxBorrowCapacity: 186834.37,
    rates: [],
    maxBuyingPower: 1458224.37,
    comparisonRate: 5.6,
    monthlyRepaymentList: [1070.2217566397478],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 720,
  },
  {
    uniqueId:
      "9CEF27E1-8A90-FF2D-9DE4-7E74E315ECA4:2cb9d4a7-b04e-420d-bf83-a1f16f98fe4f",
    lenderId: "TNT",
    baseRate: 5.59,
    maxBorrowCapacity: 186834.37,
    rates: [
      {
        uniqueId: "3F2F1D86-9DEA-E463-716D-822ABA397ADD",
        company: null,
        isDeleted: null,
        deletedBy: null,
        deletedByDisplay: null,
        deletedOn: null,
        createdOn: null,
        createdBy: null,
        isNew: null,
        productId: "9CEF27E1-8A90-FF2D-9DE4-7E74E315ECA4",
        rateNumber: 1,
        rate: 4.99,
        duration: 5,
        type: "Intro",
        noDiscount: false,
        rateChangeDate: "2022-11-03",
        previousRate: 4.74,
        repayment: null,
        discountOverride: 0,
        hasOverriddenDiscount: false,
        discountOverrideIO: 0,
        hasOverriddenDiscountIO: false,
        discountApplied: 0,
      },
    ],
    maxBuyingPower: 1458224.37,
    comparisonRate: 5.61,
    monthlyRepaymentList: [1001.8257558672941, 1157.1772188873883],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 720,
  },
  {
    uniqueId:
      "B896C1CD-A0F3-75CD-EE22-5C8F3383D2F9:e9634939-ff7e-4fe9-ab78-54288d60397e",
    lenderId: "TNT",
    baseRate: 5.59,
    maxBorrowCapacity: 186834.37,
    rates: [],
    maxBuyingPower: 1458224.37,
    comparisonRate: 5.61,
    monthlyRepaymentList: [1071.3990074654455],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 720,
  },
  {
    uniqueId:
      "18BED764-03A6-D481-D090-32735031E6D6:89366eaf-84db-4947-9dcc-e99c426a38d9",
    lenderId: "TNT",
    baseRate: 5.74,
    maxBorrowCapacity: 186834.37,
    rates: [],
    maxBuyingPower: 1458224.37,
    comparisonRate: 5.76,
    monthlyRepaymentList: [1089.128071256545],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 720,
  },
  {
    uniqueId:
      "4D8FB36B-9411-415E-DBFD-3B15616E9062:0b251b79-d3f4-4849-9823-a38320b8dd90",
    lenderId: "TNT",
    baseRate: 5.94,
    maxBorrowCapacity: 186834.37,
    rates: [],
    maxBuyingPower: 1458224.37,
    comparisonRate: 5.96,
    monthlyRepaymentList: [1112.969474375988],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 720,
  },
  {
    uniqueId:
      "4F7C56AA-22D6-13E0-FDCC-5C91F758183F:caa416d3-3084-4ce3-9752-1b671df6f368",
    lenderId: "TNT",
    baseRate: 5.94,
    maxBorrowCapacity: 186834.37,
    rates: [],
    maxBuyingPower: 1458224.37,
    comparisonRate: 5.96,
    monthlyRepaymentList: [1112.969474375988],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 720,
  },
  {
    uniqueId:
      "6384E9A2-E191-5323-C64C-7E8A3B28AA1C:09220628-d71a-4bea-8ee9-e4244ac79bed",
    lenderId: "TNT",
    baseRate: 5.94,
    maxBorrowCapacity: 186834.37,
    rates: [
      {
        uniqueId: "A488C402-6CEC-0CDB-05F1-822A2034473E",
        company: null,
        isDeleted: null,
        deletedBy: null,
        deletedByDisplay: null,
        deletedOn: null,
        createdOn: null,
        createdBy: null,
        isNew: null,
        productId: "6384E9A2-E191-5323-C64C-7E8A3B28AA1C",
        rateNumber: 1,
        rate: 5.34,
        duration: 5,
        type: "Intro",
        noDiscount: false,
        rateChangeDate: "2022-11-03",
        previousRate: 5.09,
        repayment: null,
        discountOverride: 0,
        hasOverriddenDiscount: false,
        discountOverrideIO: 0,
        hasOverriddenDiscountIO: false,
        discountApplied: 0,
      },
    ],
    maxBuyingPower: 1458224.37,
    comparisonRate: 5.96,
    monthlyRepaymentList: [1042.1457504040561, 1204.1431141669502],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 720,
  },
  {
    uniqueId:
      "20997308-6DC7-7970-6DD0-1C7189B701FF:89d95bf6-3b0e-463a-9597-1b96c57a6454",
    lenderId: "TNT",
    baseRate: 6.42,
    maxBorrowCapacity: 186834.37,
    rates: [],
    maxBuyingPower: 1458224.37,
    comparisonRate: 6.45,
    monthlyRepaymentList: [1171.1077668727835],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1390,
  },
  {
    uniqueId:
      "847836D0-00BA-DA79-B0F0-AF013F692393:148e5239-97a7-49b3-9f87-ad3859f90a89",
    lenderId: "UNI",
    baseRate: 4.54,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.56,
    monthlyRepaymentList: [771.1140349392077],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "D150E2B6-08CD-CFFF-A7F0-945F787F9755:dd32c267-ff1c-4027-bd76-2273e1c16726",
    lenderId: "UNI",
    baseRate: 4.94,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.97,
    monthlyRepaymentList: [807.6136604786916],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1050,
  },
  {
    uniqueId:
      "F8B0B9C1-AF24-949A-937F-AF01DDA77607:ab49ec57-ca70-4e4a-9125-b3fdfed9bc72",
    lenderId: "UNI",
    baseRate: 4.94,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 4.96,
    monthlyRepaymentList: [807.6136604786916],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "83DEED47-8DCC-EB8D-CA61-5023E2B7A544:70477b14-0808-48f3-ba6e-439eb6352b90",
    lenderId: "UNI",
    baseRate: 5.69,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 5.96,
    monthlyRepaymentList: [878.2111509056562],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "4AF6F7EA-716F-E679-2603-37DB2ABA2E43:802bd7a6-733b-4569-8fe4-7571d39d2896",
    lenderId: "UNI",
    baseRate: 5.74,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.01,
    monthlyRepaymentList: [883.0142826424238],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "33224F72-2507-3839-84E5-412DA974DCE3:42e57416-71ae-4196-9435-3b95a000bcec",
    lenderId: "UNI",
    baseRate: 5.79,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.05,
    monthlyRepaymentList: [887.8291695605385],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "B4BA46D6-800F-BD20-A936-37D4E2B0517D:f80070df-6eb3-48c7-bd13-844b696277a6",
    lenderId: "UNI",
    baseRate: 5.84,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.1,
    monthlyRepaymentList: [892.6557537140717],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "F9C3468A-78C4-5E3B-9327-412EE8121A81:41a9645f-231e-4c21-9649-fb5054de14bb",
    lenderId: "UNI",
    baseRate: 6.19,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.44,
    monthlyRepaymentList: [926.7644676494675],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "410F552C-480C-AFDB-62CA-37D75B4CAEEE:feaf3c22-36ac-440f-a216-1ec0b505affb",
    lenderId: "UNI",
    baseRate: 6.24,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.49,
    monthlyRepaymentList: [931.6825267504153],
    annualFee: 300,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "47197655-ED4D-FC19-5BD9-37DE61DABA6D:2af1bbe2-7857-4015-b714-31ff7e4a05f7",
    lenderId: "UNI",
    baseRate: 6.69,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 6.71,
    monthlyRepaymentList: [976.4405739209036],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "8638F21B-218E-393A-0224-37E3251E51D8:edbcf0fb-aaaf-45e2-b7df-2c75cbb463e0",
    lenderId: "UNI",
    baseRate: 7.09,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 7.12,
    monthlyRepaymentList: [1016.9499732164273],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 1050,
  },
  {
    uniqueId:
      "D90842FD-3CAC-6FA4-AAB1-37E0E6666AC4:e841f8cb-63db-4386-9785-b33fa2b24021",
    lenderId: "UNI",
    baseRate: 7.09,
    maxBorrowCapacity: 151476.6,
    rates: [],
    maxBuyingPower: 1422866.6,
    comparisonRate: 7.11,
    monthlyRepaymentList: [1016.9499732164273],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId:
      "B4DA629B-CCB8-E051-E559-E302DC4E87AA:3414e999-f9f3-4178-8ac0-6c97cf6dbadc",
    lenderId: "VMY",
    baseRate: 6.82,
    maxBorrowCapacity: 157844.75,
    rates: [],
    maxBuyingPower: 1429234.75,
    comparisonRate: 6.83,
    monthlyRepaymentList: [1031.1334071043639],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "87098988-6A3B-53EC-80D9-7AC93849CAD5:20c53a2c-bbea-46b7-bb6b-a600b6c038ce",
    lenderId: "VMY",
    baseRate: 7.07,
    maxBorrowCapacity: 157844.75,
    rates: [],
    maxBuyingPower: 1429234.75,
    comparisonRate: 7.08,
    monthlyRepaymentList: [1057.5760726136675],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "70429FB6-8FA4-5D3E-0C22-E2FEF25F9632:a1c43b9d-0267-4bf3-8ec1-a9f498bdc2d6",
    lenderId: "VMY",
    baseRate: 7.32,
    maxBorrowCapacity: 157844.75,
    rates: [],
    maxBuyingPower: 1429234.75,
    comparisonRate: 7.33,
    monthlyRepaymentList: [1084.283920754115],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "CA4A178B-5D96-4BF5-9F62-F4B762D2261C:e33fd5f5-5350-4960-8055-290ac0e5e52a",
    lenderId: "VMY",
    baseRate: 7.37,
    maxBorrowCapacity: 157844.75,
    rates: [],
    maxBuyingPower: 1429234.75,
    comparisonRate: 7.38,
    monthlyRepaymentList: [1089.656625704291],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 450,
  },
  {
    uniqueId:
      "2D8C0E9E-E309-5798-ABE7-FA10A2FB9CE2:4ab02e63-1a1b-4c15-bcae-578698664d84",
    lenderId: "WBA",
    baseRate: 4.56,
    maxBorrowCapacity: 133117.63,
    rates: [],
    maxBuyingPower: 1404507.63,
    comparisonRate: 4.98,
    monthlyRepaymentList: [679.2414973500896],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 303,
  },
  {
    uniqueId:
      "45615AE7-E914-D237-1092-1DB04EE4025D:799030ed-97e4-4627-86f2-a97f99ad79af",
    lenderId: "WBA",
    baseRate: 6.21,
    maxBorrowCapacity: 133117.63,
    rates: [],
    maxBuyingPower: 1404507.63,
    comparisonRate: 6.6,
    monthlyRepaymentList: [816.1681917962302],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 303,
  },
  {
    uniqueId:
      "3F3D28B5-B305-A01A-140F-58E9C9ADBFF5:28f9bb6a-d6ea-44b0-a357-a71cc351de5e",
    lenderId: "WBA",
    baseRate: 6.48,
    maxBorrowCapacity: 133117.63,
    rates: [],
    maxBuyingPower: 1404507.63,
    comparisonRate: 6.86,
    monthlyRepaymentList: [839.6438389946551],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 303,
  },
  {
    uniqueId:
      "03703BF6-C259-239C-881D-B1FE3C6B4C93:41800c6e-b9ba-4566-9211-3887a5987d7e",
    lenderId: "WBA",
    baseRate: 6.51,
    maxBorrowCapacity: 133117.63,
    rates: [],
    maxBuyingPower: 1404507.63,
    comparisonRate: 6.89,
    monthlyRepaymentList: [842.2696126836444],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 303,
  },
  {
    uniqueId:
      "709603C0-77B3-4458-D809-C83C4484B294:c4215fec-edde-42ac-bd48-f585021096dc",
    lenderId: "WBA",
    baseRate: 6.76,
    maxBorrowCapacity: 133117.63,
    rates: [],
    maxBuyingPower: 1404507.63,
    comparisonRate: 7.14,
    monthlyRepaymentList: [864.2834625640413],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 303,
  },
  {
    uniqueId:
      "72F75EAD-D259-BDCE-FD8C-AE096879630E:49668ec0-7545-479c-867f-3e878e9691bd",
    lenderId: "WBA",
    baseRate: 7.76,
    maxBorrowCapacity: 133117.63,
    rates: [],
    maxBuyingPower: 1404507.63,
    comparisonRate: 7.79,
    monthlyRepaymentList: [954.5910549705762],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 903,
  },
  {
    uniqueId: "C10119:ed61bfa3-5031-49e9-a24d-b29f4d091bd9",
    lenderId: "WPC",
    baseRate: 7.63,
    maxBorrowCapacity: 126570.36,
    rates: [],
    maxBuyingPower: 1397960.36,
    comparisonRate: 7.65,
    monthlyRepaymentList: [896.2924825051747],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
  {
    uniqueId: "CMEL011133:6e930597-8667-4dca-ba81-df4d9cf5a05a",
    lenderId: "WPC",
    baseRate: 7.63,
    maxBorrowCapacity: 126570.36,
    rates: [],
    maxBuyingPower: 1397960.36,
    comparisonRate: 8.01,
    monthlyRepaymentList: [896.2924825051747],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "A1081594-9A0E-6160-7E38-B0A6A2BBE1FB:e675abe8-ff28-44e4-8621-3280199c479b",
    lenderId: "WPC",
    baseRate: 8.22,
    maxBorrowCapacity: 126570.36,
    rates: [],
    maxBuyingPower: 1397960.36,
    comparisonRate: 5.96,
    monthlyRepaymentList: [948.2127586618193],
    annualFee: 395,
    maxTerm: 30,
    setUpFee: 0,
  },
  {
    uniqueId:
      "DFD211DB-B5A6-A3AA-D981-B09C4E4BF83C:6b2e72c7-2384-42f1-a57c-e72fff37ef0a",
    lenderId: "WPC",
    baseRate: 8.22,
    maxBorrowCapacity: 126570.36,
    rates: [],
    maxBuyingPower: 1397960.36,
    comparisonRate: 8.24,
    monthlyRepaymentList: [948.2127586618193],
    annualFee: 0,
    maxTerm: 30,
    setUpFee: 600,
  },
];
