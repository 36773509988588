import * as React from "react";

const Surfing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M21 22.8c-1 0-2.1-.2-3-.8-1.9 1-4.1 1-6 0-1.9 1-4.1 1-6 0-.9.5-2 .8-3 .8H2v-2h1c1 0 2.1-.4 3-1 1.8 1.3 4.2 1.3 6 0 1.8 1.3 4.2 1.3 6 0 .9.6 2 1 3 1h1v2h-1zM17 1.2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-2.6 7-2.2 1.5 3.8 3v3.8l1.5 1.2c-.7.6-1.6 1-2.5 1-1.2 0-2.3-.7-3-1.5-.7.8-1.8 1.5-3 1.5-.3 0-.6 0-1-.1-2.8-2-5-4.1-5-5.6 0-1 1-1.3 1.8-1.3 1 0 2.3.3 3.7.8l-.5-3c-.1-.7.2-1.4.8-1.8L11 6.2l-2-.3-2.9 1.9L5 6.2l3.5-2.4 5.6 1c.4.1.9.4 1.2.9l.9 1.5c.9 1.5 2.5 2.5 4.4 2.5v2c-2.7.1-5-1.4-6.2-3.5zm-4.1 2.7.4 2.6c.9.4 2.5 1.3 3.3 1.8v-1.5l-3.7-2.9z" />
  </svg>
);

export default Surfing;
