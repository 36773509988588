import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function Call(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.813"
      height="33.995"
      viewBox="0 0 35.813 33.995"
      {...props}
    >
      <g
        id="Group_6193"
        data-name="Group 6193"
        transform="translate(-1271.093 -1062.002)"
      >
        <path
          id="Path_2324"
          data-name="Path 2324"
          d="M9316.156,8484.667l-6.323,6.146s2.555,9.055,8.924,15.517,16.554,10.332,16.554,10.332l6.336-6.751-7.419-7.5-4.756,4.761a26.59,26.59,0,0,1-6.19-4.587,24.756,24.756,0,0,1-4.132-6.309l4.291-4.406Z"
          transform="translate(-8034.74 -7422.665)"
          fill={primaryColor}
          opacity="0.4"
        />
        <path
          id="Path_2323"
          data-name="Path 2323"
          d="M9316.156,8484.667l-6.323,6.146s2.555,9.055,8.924,15.517,16.554,10.332,16.554,10.332l6.336-6.751-7.419-7.5-4.756,4.761a26.59,26.59,0,0,1-6.19-4.587,24.756,24.756,0,0,1-4.132-6.309l4.291-4.406Z"
          transform="translate(-8037.74 -7421.665)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default Call;
