import * as React from "react";

function MagnifyingGlass(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 16.8 15.6"
      xmlSpace="preserve"
      {...props}
    >
      <g id="prefix__Group_6124" transform="translate(-1294.179 -836.973)">
        <g id="prefix__Group_6122" transform="translate(0 2.973)" opacity={0.4}>
          <path
            className="icon-main"
            d="M5 10c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8C3.3 2 2 3.3 2 5s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"
            transform="translate(1301 835)"
            id="prefix__Ellipse_291"
          />
          <path
            className="icon-main"
            d="M1298.6 849.2c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4.7-4.6c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.7 4.6c-.2.2-.5.3-.7.3z"
            id="prefix__Path_2288"
          />
        </g>
        <g id="prefix__Group_6123" transform="translate(-3 1.973)">
          <path
            className="icon-main"
            d="M5 10c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8C3.3 2 2 3.3 2 5s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"
            transform="translate(1301 835)"
            id="prefix__Ellipse_291-2"
          />
          <path
            className="icon-main"
            d="M1298.6 848.9c-.3 0-.5-.1-.7-.3-.4-.4-.3-1 .1-1.4l4.7-4.3c.4-.4 1-.3 1.4.1.4.4.3 1-.1 1.4l-4.7 4.3c-.2.2-.5.2-.7.2z"
            id="prefix__Path_2288-2"
          />
        </g>
      </g>
    </svg>
  );
}

export default MagnifyingGlass;
