import { ACCOUNT_TYPE, SORT_DIRECTION } from "../commons/enum";

export const isNonZeroDataPoint = (dataPoint) => !!dataPoint.y;

export const buildChartDataFromObject = (chartDataObject = {}) => {
  const result = Object.keys(chartDataObject).map((key) => {
    return {
      x: key,
      y: chartDataObject[key],
    };
  });
  return result;
};

export const generateReduceData = (mainChart) => {
  return mainChart.map((item) => {
    const { y = 0 } = item;
    return { ...item, y: (y * 80) / 100 };
  });
};

export const generateBuyingGoalData = (mainChart, estimateProperty) => {
  return mainChart.map((item) => {
    return { ...item, y: estimateProperty };
  });
};

export const dateComparator = (direction = SORT_DIRECTION.ASCENDING) => (
  date1,
  date2
) => {
  if (date1 === date2) {
    return 0;
  }
  return (new Date(date1).getTime() - new Date(date2).getTime()) * direction;
};

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
/**
 * Given a `startTime` and `groupDuration`, return the group that the `time` belongs to
 * @param {*} time
 * @param {*} startTime
 * @param {milliseconds} groupDurationInMilliseconds
 * @returns {miliiseconds} timeGroupStart
 */
export const getTimeGroup = (
  time,
  startTime,
  groupDurationInMilliseconds = DAY_IN_MILLISECONDS
) => {
  if (!startTime || !time) {
    throw new Error("Please provide time and startTime");
  }

  const timeInMilliseconds = new Date(time).valueOf();
  const startTimeInMilliseconds = new Date(startTime).valueOf();
  const timeDiffFromStartTime = timeInMilliseconds - startTimeInMilliseconds;
  const timeGroupIndex = Math.floor(
    timeDiffFromStartTime / groupDurationInMilliseconds
  );

  return new Date(
    startTimeInMilliseconds + timeGroupIndex * groupDurationInMilliseconds
  ).valueOf();
};

export const tooltipsBaseOption = {
  mode: "nearest",
  intersect: false,
  backgroundColor: "#fff",
  titleFontFamily: "ProximaNovaRegular",
  titleFontStyle: "normal",
  titleFontSize: 14,
  titleFontColor: "#000",
  bodyFontFamily: "ProximaNovaRegular",
  bodyFontStyle: "bold",
  bodyFontColor: "#000",
  bodyFontSize: 13,
  displayColors: false,
  borderColor: "#000",
  borderWidth: 1,
};

export const getChartBalanceTitle = (accountType) => {
  switch (accountType) {
    case ACCOUNT_TYPE.CREDIT_CARD:
      return "Credit Card Balance";
    case ACCOUNT_TYPE.LOAN:
    case ACCOUNT_TYPE.MORTGAGE:
      return "Loan Balance";
    case ACCOUNT_TYPE.SHARE:
      return "Share Portfolio";
    case ACCOUNT_TYPE.SUPERANNUATION:
      return "Superannuation Balance";
    default:
      return "Cash Balance";
  }
};
