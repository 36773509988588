/* eslint-disable class-methods-use-this */

import { isBrowser } from "./miscellaneous-util";

export const MobileMessageType = {
  INVALIDATE_QUERY: "INVALIDATE_QUERY",
  OPEN_FASTLINK: "OPEN_FASTLINK",
};

/**
 * Some screens of the mobile app are rendered in webviews instead of native screens
 *
 * This MobileMessageBridge provides a communication bridge between the embedded webview screens and native app
 * - Emit invalidation requests from the web app to native app then the app will proceed further (broadcast to native screens and other webview screens)
 * - Listen for invalidation request from native app and apply it
 * - Important notes: we should be using 'useQueryInvalidationWithNotification' instead of 'queryClient.invalidateQueries' if we would like to emit the changes to native app
 * - Delegate Fastlink handling to mobile app, see BUILD-1069
 */
export class MobileMessageBridge {
  constructor() {
    this.listeners = [];
  }

  static createListener(callback, interestedMessageTypes) {
    const interestedIn = interestedMessageTypes.reduce((result, type) => {
      return { ...result, [type]: true };
    }, {});
    return {
      id: new Date().valueOf(),
      callback,
      interestedIn,
    };
  }

  register(listener) {
    this.listeners.push(listener);
  }

  unregister(id) {
    this.listeners = this.listeners.filter((listener) => listener.id !== id);
  }

  serializeMessage(message) {
    return JSON.stringify(message);
  }

  on(message) {
    this.listeners.forEach((listener) => {
      if (
        (message.type && listener.interestedIn[message.type]) ||
        listener.interestedIn["*"]
      ) {
        listener.callback(message);
      }
    });
  }

  serializeAndEmit(message) {
    const serializedMessage = this.serializeMessage(message);
    this.emit(serializedMessage);
  }

  emit(serializedMessage) {
    window?.ReactNativeWebView.postMessage(serializedMessage);
  }
}

const mobileMessageBridge = new MobileMessageBridge();

if (isBrowser()) {
  window.mobileMessageBridge = mobileMessageBridge;
}

export { mobileMessageBridge };
