export const BANK_ACCOUNT_MOCKUP_DATA = {
  data: [
    {
      id: "11409642",
      name: "WealthX Loan (xxxx9415)",
      currency: "USD",
      balance: 885721.36,
      institution: "16441",
      isAsset: false,
      class: {
        product: "WealthX Loan (xxxx9415)",
        type: "loan",
      },
    },
    {
      id: "11409640",
      name: "WealthX Bank (xxxx1335)",
      currency: "USD",
      balance: 1014511.81,
      institution: "16441",
      isAsset: true,
      class: {
        product: "WealthX Bank (xxxx1335)",
        type: "transaction",
      },
    },
    {
      id: "11081704",
      name: "CMA -Joint Brokerage - 3547",
      currency: "USD",
      balance: 330101.25,
      institution: "16441",
      isAsset: true,
      class: {
        product: "CMA -Joint Brokerage - 3547",
        type: "share",
      },
    },
  ],
};

export const INCOME_EXPENSE_SUMMARY_BY_MONTH_3_IN_12_MONTHS_MOCKUP_DATA = {
  incomes: {
    "2021-10": [
      {
        source: "Refunds/Adjustments",
        amount: 1033.81,
      },
      {
        source: "Rewards",
        amount: 1041.74,
      },
      {
        source: "Consulting",
        amount: 1027.64,
      },
      {
        source: "Sales",
        amount: 729.01,
      },
      {
        source: "Expense Reimbursement",
        amount: 1207.47,
      },
      {
        source: "Retirement Income",
        amount: 633.28,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2021-11": [
      {
        source: "Other Income",
        amount: 3288.26,
      },
      {
        source: "Consulting",
        amount: 1713.41,
      },
      {
        source: "Services",
        amount: 1933.8,
      },
      {
        source: "Interest",
        amount: 1359.6799999999998,
      },
      {
        source: "Sales",
        amount: 1798.47,
      },
      {
        source: "Retirement Income",
        amount: 702.23,
      },
      {
        source: "Deposits",
        amount: 1894.89,
      },
      {
        source: "Investment Income",
        amount: 2915.95,
      },
      {
        source: "Refunds/Adjustments",
        amount: 808.04,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2021-12": [
      {
        source: "Retirement Income",
        amount: 1267.8,
      },
      {
        source: "Other Income",
        amount: 762.1,
      },
      {
        source: "Expense Reimbursement",
        amount: 1171.04,
      },
      {
        source: "Interest",
        amount: 2749.5299999999997,
      },
      {
        source: "Investment Income",
        amount: 283.34,
      },
      {
        source: "Deposits",
        amount: 1491.17,
      },
      {
        source: "Rewards",
        amount: 1419.99,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-01": [
      {
        source: "Expense Reimbursement",
        amount: 449.91,
      },
      {
        source: "Consulting",
        amount: 789.22,
      },
      {
        source: "Sales",
        amount: 1124.12,
      },
      {
        source: "Rewards",
        amount: 1312.37,
      },
      {
        source: "Retirement Income",
        amount: 1449.92,
      },
      {
        source: "Services",
        amount: 3503.96,
      },
      {
        source: "Other Income",
        amount: 194.19,
      },
      {
        source: "Deposits",
        amount: 1819.55,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-02": [
      {
        source: "Interest",
        amount: 983.93,
      },
      {
        source: "Investment Income",
        amount: 1607.24,
      },
      {
        source: "Retirement Income",
        amount: 1558.81,
      },
      {
        source: "Consulting",
        amount: 1408.93,
      },
      {
        source: "Services",
        amount: 1341.59,
      },
      {
        source: "Rewards",
        amount: 2589.65,
      },
      {
        source: "Salary",
        amount: 15364.33,
      },
      {
        source: "Refunds/Adjustments",
        amount: 1043.14,
      },
    ],
    "2022-03": [
      {
        source: "Sales",
        amount: 2433.48,
      },
      {
        source: "Expense Reimbursement",
        amount: 2021.79,
      },
      {
        source: "Services",
        amount: 131.32,
      },
      {
        source: "Retirement Income",
        amount: 2055.5,
      },
      {
        source: "Refunds/Adjustments",
        amount: 641.39,
      },
      {
        source: "Deposits",
        amount: 282,
      },
      {
        source: "Investment Income",
        amount: 2512.3199999999997,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-04": [
      {
        source: "Services",
        amount: 2961.67,
      },
      {
        source: "Other Income",
        amount: 663.8699999999999,
      },
      {
        source: "Retirement Income",
        amount: 3455.81,
      },
      {
        source: "Sales",
        amount: 2814.08,
      },
      {
        source: "Interest",
        amount: 408.69,
      },
      {
        source: "Rewards",
        amount: 1687.28,
      },
      {
        source: "Investment Income",
        amount: 3354.59,
      },
      {
        source: "Refunds/Adjustments",
        amount: 1062.14,
      },
      {
        source: "Consulting",
        amount: 2883.16,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-05": [
      {
        source: "Services",
        amount: 981.48,
      },
      {
        source: "Retirement Income",
        amount: 1566.57,
      },
      {
        source: "Sales",
        amount: 747.88,
      },
      {
        source: "Deposits",
        amount: 812.8,
      },
      {
        source: "Refunds/Adjustments",
        amount: 785.3199999999999,
      },
      {
        source: "Investment Income",
        amount: 1804.13,
      },
      {
        source: "Expense Reimbursement",
        amount: 373.57,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-06": [
      {
        source: "Interest",
        amount: 831.1600000000001,
      },
      {
        source: "Retirement Income",
        amount: 73.54,
      },
      {
        source: "Consulting",
        amount: 78.24,
      },
      {
        source: "Other Income",
        amount: 2510.55,
      },
      {
        source: "Deposits",
        amount: 1838.33,
      },
      {
        source: "Rewards",
        amount: 2035.3,
      },
      {
        source: "Services",
        amount: 1749.47,
      },
      {
        source: "Refunds/Adjustments",
        amount: 1134,
      },
      {
        source: "Investment Income",
        amount: 897.1,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-07": [
      {
        source: "Deposits",
        amount: 3939.05,
      },
      {
        source: "Consulting",
        amount: 1068.68,
      },
      {
        source: "Sales",
        amount: 477.17,
      },
      {
        source: "Expense Reimbursement",
        amount: 1399.9099999999999,
      },
      {
        source: "Interest",
        amount: 3369.0699999999997,
      },
      {
        source: "Services",
        amount: 1683.31,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-08": [
      {
        source: "Expense Reimbursement",
        amount: 1651.96,
      },
      {
        source: "Refunds/Adjustments",
        amount: 7332.4,
      },
      {
        source: "Investment Income",
        amount: 172.75,
      },
      {
        source: "Sales",
        amount: 1518.17,
      },
      {
        source: "Services",
        amount: 954.88,
      },
      {
        source: "Deposits",
        amount: 366.3,
      },
      {
        source: "Rewards",
        amount: 962.94,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-09": [
      {
        source: "Services",
        amount: 1541.1399999999999,
      },
      {
        source: "Rewards",
        amount: 663.56,
      },
      {
        source: "Interest",
        amount: 752.06,
      },
      {
        source: "Refunds/Adjustments",
        amount: 1661.54,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-10": [
      {
        source: "Refunds/Adjustments",
        amount: 1773.29,
      },
      {
        source: "Rewards",
        amount: 1506.46,
      },
      {
        source: "Salary",
        amount: 16879.66,
      },
      {
        source: "Retirement Income",
        amount: 1581.62,
      },
      {
        source: "Sales",
        amount: 1775.94,
      },
      {
        source: "Deposits",
        amount: 2364.0699999999997,
      },
      {
        source: "Consulting",
        amount: 1538.52,
      },
    ],
  },
  incomesByLastXMonths: {
    "2022-01": {
      regular: [
        {
          name: "Rewards",
          noOccurrences: 4,
          amount: 3774.1,
          totalAmount: 3774.1,
          avgAmount: 943.525,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 6,
          amount: 4053.2299999999996,
          totalAmount: 4053.2299999999996,
          avgAmount: 1013.3074999999999,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 4,
          amount: 60000,
          totalAmount: 60000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Other Income",
          noOccurrences: 4,
          amount: 4244.55,
          totalAmount: 4244.55,
          avgAmount: 1061.1375,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 4,
          amount: 5437.76,
          totalAmount: 5437.76,
          avgAmount: 1359.44,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Interest",
          noOccurrences: 4,
          amount: 4109.21,
          totalAmount: 4109.21,
          avgAmount: 1027.3025,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 3,
          amount: 3199.29,
          totalAmount: 3199.29,
          avgAmount: 799.8225,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Refunds/Adjustments",
          noOccurrences: 2,
          amount: 1841.85,
          avgAmount: 460.4625,
        },
        {
          name: "Consulting",
          noOccurrences: 3,
          amount: 3530.2700000000004,
          avgAmount: 882.5675000000001,
        },
        {
          name: "Sales",
          noOccurrences: 3,
          amount: 3651.6,
          avgAmount: 912.9,
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 2828.42,
          avgAmount: 707.105,
        },
        {
          name: "Deposits",
          noOccurrences: 3,
          amount: 5205.610000000001,
          avgAmount: 1301.4025000000001,
        },
      ],
    },
    "2022-02": {
      regular: [
        {
          name: "Other Income",
          noOccurrences: 4,
          amount: 4244.55,
          totalAmount: 4244.55,
          avgAmount: 1061.1375,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Consulting",
          noOccurrences: 4,
          amount: 3911.5600000000004,
          totalAmount: 3911.5600000000004,
          avgAmount: 977.8900000000001,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 5,
          amount: 6779.35,
          totalAmount: 6779.35,
          avgAmount: 1694.8375,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Interest",
          noOccurrences: 5,
          amount: 5093.14,
          totalAmount: 5093.14,
          avgAmount: 1273.285,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 6,
          amount: 4978.76,
          totalAmount: 4978.76,
          avgAmount: 1244.69,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 4,
          amount: 4806.53,
          totalAmount: 4806.53,
          avgAmount: 1201.6325,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 5,
          amount: 60364.33,
          totalAmount: 60364.33,
          avgAmount: 15091.0825,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 4,
          amount: 5322.01,
          totalAmount: 5322.01,
          avgAmount: 1330.5025,
          type: "Other",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Sales",
          noOccurrences: 2,
          amount: 2922.59,
          avgAmount: 730.6475,
        },
        {
          name: "Deposits",
          noOccurrences: 3,
          amount: 5205.610000000001,
          avgAmount: 1301.4025000000001,
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 2,
          amount: 1851.18,
          avgAmount: 462.795,
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 2,
          amount: 1620.95,
          avgAmount: 405.2375,
        },
      ],
    },
    "2022-03": {
      regular: [
        {
          name: "Retirement Income",
          noOccurrences: 7,
          amount: 6332.03,
          totalAmount: 6332.03,
          avgAmount: 1583.0075,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 4,
          amount: 3642.7400000000002,
          totalAmount: 3642.7400000000002,
          avgAmount: 910.6850000000001,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 5,
          amount: 4402.9,
          totalAmount: 4402.9,
          avgAmount: 1100.725,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 4,
          amount: 5322.01,
          totalAmount: 5322.01,
          avgAmount: 1330.5025,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 5,
          amount: 60364.33,
          totalAmount: 60364.33,
          avgAmount: 15091.0825,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 5,
          amount: 4976.87,
          totalAmount: 4976.87,
          avgAmount: 1244.2175,
          type: "Other",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Other Income",
          noOccurrences: 2,
          amount: 956.29,
          avgAmount: 239.0725,
        },
        {
          name: "Interest",
          noOccurrences: 3,
          amount: 3733.4599999999996,
          avgAmount: 933.3649999999999,
        },
        {
          name: "Deposits",
          noOccurrences: 3,
          amount: 3592.7200000000003,
          avgAmount: 898.1800000000001,
        },
        {
          name: "Consulting",
          noOccurrences: 3,
          amount: 2198.15,
          avgAmount: 549.5375,
        },
        {
          name: "Sales",
          noOccurrences: 3,
          amount: 3557.5999999999995,
          avgAmount: 889.3999999999999,
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 2,
          amount: 1684.5300000000002,
          avgAmount: 421.13250000000005,
        },
      ],
    },
    "2022-04": {
      regular: [
        {
          name: "Consulting",
          noOccurrences: 5,
          amount: 5081.31,
          totalAmount: 5081.31,
          avgAmount: 1270.3275,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Sales",
          noOccurrences: 5,
          amount: 6371.68,
          totalAmount: 6371.68,
          avgAmount: 1592.92,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 4,
          amount: 5589.3,
          totalAmount: 5589.3,
          avgAmount: 1397.325,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 8,
          amount: 8520.039999999999,
          totalAmount: 8520.039999999999,
          avgAmount: 2130.0099999999998,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 9,
          amount: 7938.54,
          totalAmount: 7938.54,
          avgAmount: 1984.635,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 5,
          amount: 60364.33,
          totalAmount: 60364.33,
          avgAmount: 15091.0825,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 6,
          amount: 7474.15,
          totalAmount: 7474.15,
          avgAmount: 1868.5375,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Other Income",
          noOccurrences: 3,
          amount: 858.06,
          totalAmount: 858.06,
          avgAmount: 214.515,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 2471.7,
          avgAmount: 617.925,
        },
        {
          name: "Deposits",
          noOccurrences: 2,
          amount: 2101.55,
          avgAmount: 525.3875,
        },
        {
          name: "Interest",
          noOccurrences: 2,
          amount: 1392.62,
          avgAmount: 348.155,
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 3,
          amount: 2746.67,
          avgAmount: 686.6675,
        },
      ],
    },
    "2022-05": {
      regular: [
        {
          name: "Investment Income",
          noOccurrences: 8,
          amount: 9278.28,
          totalAmount: 9278.28,
          avgAmount: 2319.57,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 9,
          amount: 8636.689999999999,
          totalAmount: 8636.689999999999,
          avgAmount: 2159.1724999999997,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Consulting",
          noOccurrences: 4,
          amount: 4292.09,
          totalAmount: 4292.09,
          avgAmount: 1073.0225,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 9,
          amount: 5416.0599999999995,
          totalAmount: 5416.0599999999995,
          avgAmount: 1354.0149999999999,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 5,
          amount: 60364.33,
          totalAmount: 60364.33,
          avgAmount: 15091.0825,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 5,
          amount: 3531.9900000000002,
          totalAmount: 3531.9900000000002,
          avgAmount: 882.9975000000001,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Sales",
          noOccurrences: 5,
          amount: 5995.44,
          totalAmount: 5995.44,
          avgAmount: 1498.86,
          type: "Other",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Interest",
          noOccurrences: 2,
          amount: 1392.62,
          avgAmount: 348.155,
        },
        {
          name: "Rewards",
          noOccurrences: 3,
          amount: 4276.93,
          avgAmount: 1069.2325,
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 2395.36,
          avgAmount: 598.84,
        },
        {
          name: "Deposits",
          noOccurrences: 3,
          amount: 1094.8,
          avgAmount: 273.7,
        },
        {
          name: "Other Income",
          noOccurrences: 2,
          amount: 663.8699999999999,
          avgAmount: 165.96749999999997,
        },
      ],
    },
    "2022-06": {
      regular: [
        {
          name: "Sales",
          noOccurrences: 5,
          amount: 5995.44,
          totalAmount: 5995.44,
          avgAmount: 1498.86,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 10,
          amount: 5823.9400000000005,
          totalAmount: 5823.9400000000005,
          avgAmount: 1455.9850000000001,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 9,
          amount: 7151.419999999999,
          totalAmount: 7151.419999999999,
          avgAmount: 1787.8549999999998,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 5,
          amount: 3622.8500000000004,
          totalAmount: 3622.8500000000004,
          avgAmount: 905.7125000000001,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Deposits",
          noOccurrences: 5,
          amount: 2933.13,
          totalAmount: 2933.13,
          avgAmount: 733.2825,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 8,
          amount: 8568.14,
          totalAmount: 8568.14,
          avgAmount: 2142.035,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 4,
          amount: 60000,
          totalAmount: 60000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Other Income",
          noOccurrences: 4,
          amount: 3174.42,
          totalAmount: 3174.42,
          avgAmount: 793.605,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 4,
          amount: 3722.58,
          totalAmount: 3722.58,
          avgAmount: 930.645,
          type: "Other",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 2395.36,
          avgAmount: 598.84,
        },
        {
          name: "Interest",
          noOccurrences: 3,
          amount: 1239.85,
          avgAmount: 309.9625,
        },
        {
          name: "Consulting",
          noOccurrences: 3,
          amount: 2961.3999999999996,
          avgAmount: 740.3499999999999,
        },
      ],
    },
    "2022-07": {
      regular: [
        {
          name: "Services",
          noOccurrences: 10,
          amount: 7375.93,
          totalAmount: 7375.93,
          avgAmount: 1843.9825,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Other Income",
          noOccurrences: 4,
          amount: 3174.42,
          totalAmount: 3174.42,
          avgAmount: 793.605,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 7,
          amount: 5095.919999999999,
          totalAmount: 5095.919999999999,
          avgAmount: 1273.9799999999998,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Sales",
          noOccurrences: 4,
          amount: 4039.13,
          totalAmount: 4039.13,
          avgAmount: 1009.7825,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Interest",
          noOccurrences: 5,
          amount: 4608.92,
          totalAmount: 4608.92,
          avgAmount: 1152.23,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 4,
          amount: 3722.58,
          totalAmount: 3722.58,
          avgAmount: 930.645,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 5,
          amount: 6055.820000000001,
          totalAmount: 6055.820000000001,
          avgAmount: 1513.9550000000002,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 4,
          amount: 2981.46,
          totalAmount: 2981.46,
          avgAmount: 745.365,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Consulting",
          noOccurrences: 4,
          amount: 4030.08,
          totalAmount: 4030.08,
          avgAmount: 1007.52,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 4,
          amount: 60000,
          totalAmount: 60000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Deposits",
          noOccurrences: 7,
          amount: 6590.179999999999,
          totalAmount: 6590.179999999999,
          avgAmount: 1647.5449999999998,
          type: "Other",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 1773.48,
          avgAmount: 443.37,
        },
      ],
    },
    "2022-08": {
      regular: [
        {
          name: "Services",
          noOccurrences: 7,
          amount: 5369.14,
          totalAmount: 5369.14,
          avgAmount: 1342.285,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 4,
          amount: 1640.11,
          totalAmount: 1640.11,
          avgAmount: 410.0275,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Deposits",
          noOccurrences: 8,
          amount: 6956.48,
          totalAmount: 6956.48,
          avgAmount: 1739.12,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 8,
          amount: 9251.72,
          totalAmount: 9251.72,
          avgAmount: 2312.93,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 4,
          amount: 2873.98,
          totalAmount: 2873.98,
          avgAmount: 718.495,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 4,
          amount: 3425.44,
          totalAmount: 3425.44,
          avgAmount: 856.36,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 4,
          amount: 60000,
          totalAmount: 60000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Interest",
          noOccurrences: 4,
          amount: 4200.23,
          totalAmount: 4200.23,
          avgAmount: 1050.0575,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 5,
          amount: 2998.2400000000002,
          totalAmount: 2998.2400000000002,
          avgAmount: 749.5600000000001,
          type: "Other",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Sales",
          noOccurrences: 3,
          amount: 2743.2200000000003,
          avgAmount: 685.8050000000001,
        },
        {
          name: "Consulting",
          noOccurrences: 2,
          amount: 1146.92,
          avgAmount: 286.73,
        },
        {
          name: "Other Income",
          noOccurrences: 2,
          amount: 2510.55,
          avgAmount: 627.6375,
        },
      ],
    },
    "2022-09": {
      regular: [
        {
          name: "Interest",
          noOccurrences: 5,
          amount: 4952.289999999999,
          totalAmount: 4952.289999999999,
          avgAmount: 1238.0724999999998,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Deposits",
          noOccurrences: 6,
          amount: 6143.68,
          totalAmount: 6143.68,
          avgAmount: 1535.92,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 7,
          amount: 3661.8,
          totalAmount: 3661.8,
          avgAmount: 915.45,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 7,
          amount: 5928.8,
          totalAmount: 5928.8,
          avgAmount: 1482.2,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 7,
          amount: 10127.939999999999,
          totalAmount: 10127.939999999999,
          avgAmount: 2531.9849999999997,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 4,
          amount: 60000,
          totalAmount: 60000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Retirement Income",
          noOccurrences: 1,
          amount: 73.54,
          avgAmount: 18.385,
        },
        {
          name: "Consulting",
          noOccurrences: 2,
          amount: 1146.92,
          avgAmount: 286.73,
        },
        {
          name: "Other Income",
          noOccurrences: 2,
          amount: 2510.55,
          avgAmount: 627.6375,
        },
        {
          name: "Investment Income",
          noOccurrences: 2,
          amount: 1069.85,
          avgAmount: 267.4625,
        },
        {
          name: "Sales",
          noOccurrences: 2,
          amount: 1995.3400000000001,
          avgAmount: 498.83500000000004,
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 3051.87,
          avgAmount: 762.9675,
        },
      ],
    },
    "2022-10": {
      regular: [
        {
          name: "Deposits",
          noOccurrences: 6,
          amount: 6669.42,
          totalAmount: 6669.42,
          avgAmount: 1667.355,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Sales",
          noOccurrences: 5,
          amount: 3771.28,
          totalAmount: 3771.28,
          avgAmount: 942.82,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 5,
          amount: 4179.33,
          totalAmount: 4179.33,
          avgAmount: 1044.8325,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 5,
          amount: 61879.66,
          totalAmount: 61879.66,
          avgAmount: 15469.915,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 8,
          amount: 10767.23,
          totalAmount: 10767.23,
          avgAmount: 2691.8075,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 6,
          amount: 3132.96,
          totalAmount: 3132.96,
          avgAmount: 783.24,
          type: "Other",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Consulting",
          noOccurrences: 2,
          amount: 2607.2,
          avgAmount: 651.8,
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 3051.87,
          avgAmount: 762.9675,
        },
        {
          name: "Interest",
          noOccurrences: 3,
          amount: 4121.129999999999,
          avgAmount: 1030.2824999999998,
        },
        {
          name: "Investment Income",
          noOccurrences: 1,
          amount: 172.75,
          avgAmount: 43.1875,
        },
        {
          name: "Retirement Income",
          noOccurrences: 1,
          amount: 1581.62,
          avgAmount: 395.405,
        },
      ],
    },
  },
  expenses: {
    "2021-10": [
      {
        category: "Insurance",
        amount: -911.36,
      },
      {
        category: "Utilities",
        amount: -626.92,
      },
      {
        category: "Dues And Subscriptions",
        amount: -922.15,
      },
      {
        category: "Printing",
        amount: -487.83,
      },
      {
        category: "Loans",
        amount: -757.25,
      },
      {
        category: "Pets Pet Care",
        amount: -105.32,
      },
      {
        category: "Gifts",
        amount: -399.69,
      },
      {
        category: "Hobbies",
        amount: -695.23,
      },
      {
        category: "Rent",
        amount: -438.24,
      },
    ],
    "2021-11": [
      {
        category: "Business Miscellaneous",
        amount: -591.22,
      },
      {
        category: "Rent",
        amount: -338.28,
      },
      {
        category: "Personal Care",
        amount: -1707.1,
      },
      {
        category: "Restaurants Dining",
        amount: -82.3,
      },
      {
        category: "Loans",
        amount: -779.29,
      },
    ],
    "2021-12": [
      {
        category: "Office Maintenance",
        amount: -68.12,
      },
      {
        category: "Automotive Expenses",
        amount: -805.64,
      },
      {
        category: "Printing",
        amount: -713.19,
      },
      {
        category: "Online Services",
        amount: -166.87,
      },
      {
        category: "Insurance",
        amount: -390.1,
      },
      {
        category: "Gasoline Fuel",
        amount: -551.62,
      },
      {
        category: "Clothing Shoes",
        amount: -936.78,
      },
    ],
    "2022-01": [
      {
        category: "Restaurants Dining",
        amount: -942.15,
      },
      {
        category: "Business Miscellaneous",
        amount: -854.64,
      },
      {
        category: "Postage And Shipping",
        amount: -550.12,
      },
      {
        category: "Clothing Shoes",
        amount: -927.61,
      },
      {
        category: "Groceries",
        amount: -118.7,
      },
      {
        category: "Dues And Subscriptions",
        amount: -812.4200000000001,
      },
      {
        category: "Automotive Expenses",
        amount: -919.28,
      },
      {
        category: "Home Improvement",
        amount: -813.75,
      },
      {
        category: "Printing",
        amount: -450.52,
      },
      {
        category: "Pets Pet Care",
        amount: -173.57,
      },
      {
        category: "Personal Care",
        amount: -93.09,
      },
    ],
    "2022-02": [
      {
        category: "Automotive Expenses",
        amount: -606.46,
      },
      {
        category: "Rent",
        amount: -530.75,
      },
      {
        category: "Dues And Subscriptions",
        amount: -1008.17,
      },
      {
        category: "Utilities",
        amount: -909.35,
      },
      {
        category: "Hobbies",
        amount: -651.56,
      },
      {
        category: "Groceries",
        amount: -1405.3200000000002,
      },
      {
        category: "Clothing Shoes",
        amount: -1638.96,
      },
      {
        category: "Gifts",
        amount: -989.85,
      },
      {
        category: "Charitable Giving",
        amount: -615.17,
      },
    ],
    "2022-03": [
      {
        category: "Clothing Shoes",
        amount: -993.1,
      },
      {
        category: "Home Improvement",
        amount: -1368.97,
      },
      {
        category: "Entertainment",
        amount: -1081.9,
      },
      {
        category: "Printing",
        amount: -888.29,
      },
      {
        category: "Rent",
        amount: -470.38,
      },
      {
        category: "Utilities",
        amount: -964.49,
      },
      {
        category: "Office Supplies",
        amount: -555.14,
      },
      {
        category: "Healthcare Medical",
        amount: -868.94,
      },
      {
        category: "Charitable Giving",
        amount: -175.08,
      },
    ],
    "2022-04": [
      {
        category: "Entertainment",
        amount: -1557.1599999999999,
      },
      {
        category: "Cable Satellite Services",
        amount: -134.56,
      },
      {
        category: "Loans",
        amount: -133.78,
      },
      {
        category: "Groceries",
        amount: -598.34,
      },
      {
        category: "Charitable Giving",
        amount: -792.86,
      },
      {
        category: "Home Improvement",
        amount: -155.58,
      },
    ],
    "2022-05": [
      {
        category: "Office Supplies",
        amount: -736.94,
      },
      {
        category: "Online Services",
        amount: -182.49,
      },
      {
        category: "Home Improvement",
        amount: -171.58,
      },
      {
        category: "Insurance",
        amount: -892.79,
      },
      {
        category: "Cable Satellite Services",
        amount: -418.56,
      },
      {
        category: "Clothing Shoes",
        amount: -821.91,
      },
      {
        category: "Dues And Subscriptions",
        amount: -526.11,
      },
    ],
    "2022-06": [
      {
        category: "Hobbies",
        amount: -530,
      },
      {
        category: "Automotive Expenses",
        amount: -932.63,
      },
      {
        category: "Cable Satellite Services",
        amount: -1166.1399999999999,
      },
      {
        category: "Insurance",
        amount: -496.08,
      },
      {
        category: "Postage And Shipping",
        amount: -876.74,
      },
      {
        category: "General Merchandise",
        amount: -328.78,
      },
      {
        category: "Home Improvement",
        amount: -64.24,
      },
      {
        category: "Rent",
        amount: -386.21,
      },
      {
        category: "Clothing Shoes",
        amount: -693.52,
      },
    ],
    "2022-07": [
      {
        category: "Pets Pet Care",
        amount: -441.8,
      },
      {
        category: "Entertainment",
        amount: -461.59,
      },
      {
        category: "Groceries",
        amount: -1567.9,
      },
      {
        category: "Hobbies",
        amount: -352.02,
      },
      {
        category: "Home Improvement",
        amount: -265.72,
      },
      {
        category: "Clothing Shoes",
        amount: -282.33,
      },
      {
        category: "Advertising",
        amount: -967.54,
      },
      {
        category: "Postage And Shipping",
        amount: -762.65,
      },
      {
        category: "Business Miscellaneous",
        amount: -807.28,
      },
      {
        category: "Insurance",
        amount: -864.06,
      },
      {
        category: "Office Supplies",
        amount: -233.56,
      },
      {
        category: "Office Maintenance",
        amount: -163.51,
      },
    ],
    "2022-08": [
      {
        category: "Home Improvement",
        amount: -741.97,
      },
      {
        category: "Entertainment",
        amount: -875.15,
      },
      {
        category: "Cable Satellite Services",
        amount: -786.18,
      },
      {
        category: "Online Services",
        amount: -276.73,
      },
      {
        category: "Pets Pet Care",
        amount: -693.88,
      },
      {
        category: "Dues And Subscriptions",
        amount: -390.07,
      },
      {
        category: "Office Supplies",
        amount: -384.63,
      },
      {
        category: "Automotive Expenses",
        amount: -959.17,
      },
      {
        category: "Insurance",
        amount: -335.28,
      },
      {
        category: "Gasoline Fuel",
        amount: -904,
      },
    ],
    "2022-09": [
      {
        category: "Utilities",
        amount: -1232.58,
      },
      {
        category: "Restaurants Dining",
        amount: -2560.84,
      },
      {
        category: "Loans",
        amount: -338.11,
      },
      {
        category: "Office Supplies",
        amount: -149.86,
      },
      {
        category: "Entertainment",
        amount: -222.18,
      },
      {
        category: "Office Maintenance",
        amount: -819.78,
      },
      {
        category: "Automotive Expenses",
        amount: -73.01,
      },
      {
        category: "Hobbies",
        amount: -280.43,
      },
      {
        category: "Pets Pet Care",
        amount: -59.55,
      },
      {
        category: "Home Improvement",
        amount: -419.78,
      },
      {
        category: "Healthcare Medical",
        amount: -841.01,
      },
    ],
    "2022-10": [
      {
        category: "Home Improvement",
        amount: -537.36,
      },
      {
        category: "Gasoline Fuel",
        amount: -261.29,
      },
      {
        category: "Personal Care",
        amount: -501.24,
      },
      {
        category: "Gifts",
        amount: -436.19,
      },
      {
        category: "General Merchandise",
        amount: -754.23,
      },
      {
        category: "Insurance",
        amount: -912.34,
      },
      {
        category: "Entertainment",
        amount: -221.73,
      },
      {
        category: "Postage And Shipping",
        amount: -311.59,
      },
      {
        category: "Restaurants Dining",
        amount: -842.12,
      },
      {
        category: "Office Supplies",
        amount: -537.45,
      },
      {
        category: "Rent",
        amount: -746.39,
      },
    ],
  },
  connectiveExpenses: {
    "2021-10": [
      {
        category: "General Insurance",
        amount: -911.36,
      },
      {
        category: "Other Regular And Recurring Expenses",
        amount: -3237.14,
      },
      {
        category: "Other",
        amount: -757.25,
      },
      {
        category: "Rent",
        amount: -438.24,
      },
    ],
    "2021-11": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -2380.62,
      },
      {
        category: "Rent",
        amount: -338.28,
      },
      {
        category: "Other",
        amount: -779.29,
      },
    ],
    "2021-12": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -2305.4399999999996,
      },
      {
        category: "General Insurance",
        amount: -390.1,
      },
      {
        category: "Clothing And Personal Care",
        amount: -936.78,
      },
    ],
    "2022-01": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -4795.789999999999,
      },
      {
        category: "Clothing And Personal Care",
        amount: -927.61,
      },
      {
        category: "Groceries",
        amount: -118.7,
      },
      {
        category: "Primary Residence Costs",
        amount: -813.75,
      },
    ],
    "2022-02": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -4780.56,
      },
      {
        category: "Rent",
        amount: -530.75,
      },
      {
        category: "Groceries",
        amount: -1405.3200000000002,
      },
      {
        category: "Clothing And Personal Care",
        amount: -1638.96,
      },
    ],
    "2022-03": [
      {
        category: "Clothing And Personal Care",
        amount: -993.1,
      },
      {
        category: "Primary Residence Costs",
        amount: -1368.97,
      },
      {
        category: "Recreation Entertainment",
        amount: -1081.9,
      },
      {
        category: "Other Regular And Recurring Expenses",
        amount: -2583,
      },
      {
        category: "Rent",
        amount: -470.38,
      },
      {
        category: "Medical Health",
        amount: -868.94,
      },
    ],
    "2022-04": [
      {
        category: "Recreation Entertainment",
        amount: -1557.1599999999999,
      },
      {
        category: "Telephone Internet Pay TV Media Streaming Subscriptions",
        amount: -134.56,
      },
      {
        category: "Other",
        amount: -133.78,
      },
      {
        category: "Groceries",
        amount: -598.34,
      },
      {
        category: "Other Regular And Recurring Expenses",
        amount: -792.86,
      },
      {
        category: "Primary Residence Costs",
        amount: -155.58,
      },
    ],
    "2022-05": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -1445.54,
      },
      {
        category: "Primary Residence Costs",
        amount: -171.58,
      },
      {
        category: "General Insurance",
        amount: -892.79,
      },
      {
        category: "Telephone Internet Pay TV Media Streaming Subscriptions",
        amount: -418.56,
      },
      {
        category: "Clothing And Personal Care",
        amount: -821.91,
      },
    ],
    "2022-06": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -2668.1499999999996,
      },
      {
        category: "Telephone Internet Pay TV Media Streaming Subscriptions",
        amount: -1166.1399999999999,
      },
      {
        category: "General Insurance",
        amount: -496.08,
      },
      {
        category: "Primary Residence Costs",
        amount: -64.24,
      },
      {
        category: "Rent",
        amount: -386.21,
      },
      {
        category: "Clothing And Personal Care",
        amount: -693.52,
      },
    ],
    "2022-07": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -3728.359999999999,
      },
      {
        category: "Recreation Entertainment",
        amount: -461.59,
      },
      {
        category: "Groceries",
        amount: -1567.9,
      },
      {
        category: "Primary Residence Costs",
        amount: -265.72,
      },
      {
        category: "Clothing And Personal Care",
        amount: -282.33,
      },
      {
        category: "General Insurance",
        amount: -864.06,
      },
    ],
    "2022-08": [
      {
        category: "Primary Residence Costs",
        amount: -741.97,
      },
      {
        category: "Recreation Entertainment",
        amount: -875.15,
      },
      {
        category: "Telephone Internet Pay TV Media Streaming Subscriptions",
        amount: -786.18,
      },
      {
        category: "Other Regular And Recurring Expenses",
        amount: -3608.48,
      },
      {
        category: "General Insurance",
        amount: -335.28,
      },
    ],
    "2022-09": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -5176.050000000001,
      },
      {
        category: "Other",
        amount: -338.11,
      },
      {
        category: "Recreation Entertainment",
        amount: -222.18,
      },
      {
        category: "Primary Residence Costs",
        amount: -419.78,
      },
      {
        category: "Medical Health",
        amount: -841.01,
      },
    ],
    "2022-10": [
      {
        category: "Primary Residence Costs",
        amount: -537.36,
      },
      {
        category: "Other Regular And Recurring Expenses",
        amount: -3644.1099999999997,
      },
      {
        category: "General Insurance",
        amount: -912.34,
      },
      {
        category: "Recreation Entertainment",
        amount: -221.73,
      },
      {
        category: "Rent",
        amount: -746.39,
      },
    ],
  },
  connectiveExpensesByLastXMonths: {
    "2022-01": [
      {
        category: "Insurance",
        amount: -1301.46,
      },
      {
        category: "Utilities",
        amount: -626.92,
      },
      {
        category: "Dues And Subscriptions",
        amount: -1734.57,
      },
      {
        category: "Printing",
        amount: -1651.54,
      },
      {
        category: "Loans",
        amount: -1536.54,
      },
      {
        category: "Pets Pet Care",
        amount: -278.89,
      },
      {
        category: "Gifts",
        amount: -399.69,
      },
      {
        category: "Hobbies",
        amount: -695.23,
      },
      {
        category: "Rent",
        amount: -776.52,
      },
      {
        category: "Business Miscellaneous",
        amount: -1445.8600000000001,
      },
      {
        category: "Personal Care",
        amount: -1800.1899999999998,
      },
      {
        category: "Restaurants Dining",
        amount: -1024.45,
      },
      {
        category: "Office Maintenance",
        amount: -68.12,
      },
      {
        category: "Automotive Expenses",
        amount: -1724.92,
      },
      {
        category: "Online Services",
        amount: -166.87,
      },
      {
        category: "Gasoline Fuel",
        amount: -551.62,
      },
      {
        category: "Clothing Shoes",
        amount: -1864.3899999999999,
      },
      {
        category: "Postage And Shipping",
        amount: -550.12,
      },
      {
        category: "Groceries",
        amount: -118.7,
      },
      {
        category: "Home Improvement",
        amount: -813.75,
      },
    ],
    "2022-02": [
      {
        category: "Business Miscellaneous",
        amount: -1445.8600000000001,
      },
      {
        category: "Rent",
        amount: -869.03,
      },
      {
        category: "Personal Care",
        amount: -1800.1899999999998,
      },
      {
        category: "Restaurants Dining",
        amount: -1024.45,
      },
      {
        category: "Loans",
        amount: -779.29,
      },
      {
        category: "Office Maintenance",
        amount: -68.12,
      },
      {
        category: "Automotive Expenses",
        amount: -2331.38,
      },
      {
        category: "Printing",
        amount: -1163.71,
      },
      {
        category: "Online Services",
        amount: -166.87,
      },
      {
        category: "Insurance",
        amount: -390.1,
      },
      {
        category: "Gasoline Fuel",
        amount: -551.62,
      },
      {
        category: "Clothing Shoes",
        amount: -3503.35,
      },
      {
        category: "Postage And Shipping",
        amount: -550.12,
      },
      {
        category: "Groceries",
        amount: -1524.02,
      },
      {
        category: "Dues And Subscriptions",
        amount: -1820.59,
      },
      {
        category: "Home Improvement",
        amount: -813.75,
      },
      {
        category: "Pets Pet Care",
        amount: -173.57,
      },
      {
        category: "Utilities",
        amount: -909.35,
      },
      {
        category: "Hobbies",
        amount: -651.56,
      },
      {
        category: "Gifts",
        amount: -989.85,
      },
      {
        category: "Charitable Giving",
        amount: -615.17,
      },
    ],
    "2022-03": [
      {
        category: "Office Maintenance",
        amount: -68.12,
      },
      {
        category: "Automotive Expenses",
        amount: -2331.38,
      },
      {
        category: "Printing",
        amount: -2052,
      },
      {
        category: "Online Services",
        amount: -166.87,
      },
      {
        category: "Insurance",
        amount: -390.1,
      },
      {
        category: "Gasoline Fuel",
        amount: -551.62,
      },
      {
        category: "Clothing Shoes",
        amount: -4496.45,
      },
      {
        category: "Restaurants Dining",
        amount: -942.15,
      },
      {
        category: "Business Miscellaneous",
        amount: -854.64,
      },
      {
        category: "Postage And Shipping",
        amount: -550.12,
      },
      {
        category: "Groceries",
        amount: -1524.02,
      },
      {
        category: "Dues And Subscriptions",
        amount: -1820.59,
      },
      {
        category: "Home Improvement",
        amount: -2182.7200000000003,
      },
      {
        category: "Pets Pet Care",
        amount: -173.57,
      },
      {
        category: "Personal Care",
        amount: -93.09,
      },
      {
        category: "Rent",
        amount: -1001.13,
      },
      {
        category: "Utilities",
        amount: -1873.8400000000001,
      },
      {
        category: "Hobbies",
        amount: -651.56,
      },
      {
        category: "Gifts",
        amount: -989.85,
      },
      {
        category: "Charitable Giving",
        amount: -790.25,
      },
      {
        category: "Entertainment",
        amount: -1081.9,
      },
      {
        category: "Office Supplies",
        amount: -555.14,
      },
      {
        category: "Healthcare Medical",
        amount: -868.94,
      },
    ],
    "2022-04": [
      {
        category: "Restaurants Dining",
        amount: -942.15,
      },
      {
        category: "Business Miscellaneous",
        amount: -854.64,
      },
      {
        category: "Postage And Shipping",
        amount: -550.12,
      },
      {
        category: "Clothing Shoes",
        amount: -3559.67,
      },
      {
        category: "Groceries",
        amount: -2122.36,
      },
      {
        category: "Dues And Subscriptions",
        amount: -1820.59,
      },
      {
        category: "Automotive Expenses",
        amount: -1525.74,
      },
      {
        category: "Home Improvement",
        amount: -2338.3,
      },
      {
        category: "Printing",
        amount: -1338.81,
      },
      {
        category: "Pets Pet Care",
        amount: -173.57,
      },
      {
        category: "Personal Care",
        amount: -93.09,
      },
      {
        category: "Rent",
        amount: -1001.13,
      },
      {
        category: "Utilities",
        amount: -1873.8400000000001,
      },
      {
        category: "Hobbies",
        amount: -651.56,
      },
      {
        category: "Gifts",
        amount: -989.85,
      },
      {
        category: "Charitable Giving",
        amount: -1583.1100000000001,
      },
      {
        category: "Entertainment",
        amount: -2639.06,
      },
      {
        category: "Office Supplies",
        amount: -555.14,
      },
      {
        category: "Healthcare Medical",
        amount: -868.94,
      },
      {
        category: "Cable Satellite Services",
        amount: -134.56,
      },
      {
        category: "Loans",
        amount: -133.78,
      },
    ],
    "2022-05": [
      {
        category: "Automotive Expenses",
        amount: -606.46,
      },
      {
        category: "Rent",
        amount: -1001.13,
      },
      {
        category: "Dues And Subscriptions",
        amount: -1534.28,
      },
      {
        category: "Utilities",
        amount: -1873.8400000000001,
      },
      {
        category: "Hobbies",
        amount: -651.56,
      },
      {
        category: "Groceries",
        amount: -2003.6600000000003,
      },
      {
        category: "Clothing Shoes",
        amount: -3453.9700000000003,
      },
      {
        category: "Gifts",
        amount: -989.85,
      },
      {
        category: "Charitable Giving",
        amount: -1583.1100000000001,
      },
      {
        category: "Home Improvement",
        amount: -1696.1299999999999,
      },
      {
        category: "Entertainment",
        amount: -2639.06,
      },
      {
        category: "Printing",
        amount: -888.29,
      },
      {
        category: "Office Supplies",
        amount: -1292.08,
      },
      {
        category: "Healthcare Medical",
        amount: -868.94,
      },
      {
        category: "Cable Satellite Services",
        amount: -553.12,
      },
      {
        category: "Loans",
        amount: -133.78,
      },
      {
        category: "Online Services",
        amount: -182.49,
      },
      {
        category: "Insurance",
        amount: -892.79,
      },
    ],
    "2022-06": [
      {
        category: "Clothing Shoes",
        amount: -2508.53,
      },
      {
        category: "Home Improvement",
        amount: -1760.37,
      },
      {
        category: "Entertainment",
        amount: -2639.06,
      },
      {
        category: "Printing",
        amount: -888.29,
      },
      {
        category: "Rent",
        amount: -856.5899999999999,
      },
      {
        category: "Utilities",
        amount: -964.49,
      },
      {
        category: "Office Supplies",
        amount: -1292.08,
      },
      {
        category: "Healthcare Medical",
        amount: -868.94,
      },
      {
        category: "Charitable Giving",
        amount: -967.94,
      },
      {
        category: "Cable Satellite Services",
        amount: -1719.2600000000002,
      },
      {
        category: "Loans",
        amount: -133.78,
      },
      {
        category: "Groceries",
        amount: -598.34,
      },
      {
        category: "Online Services",
        amount: -182.49,
      },
      {
        category: "Insurance",
        amount: -1388.87,
      },
      {
        category: "Dues And Subscriptions",
        amount: -526.11,
      },
      {
        category: "Hobbies",
        amount: -530,
      },
      {
        category: "Automotive Expenses",
        amount: -932.63,
      },
      {
        category: "Postage And Shipping",
        amount: -876.74,
      },
      {
        category: "General Merchandise",
        amount: -328.78,
      },
    ],
    "2022-07": [
      {
        category: "Entertainment",
        amount: -2018.7499999999998,
      },
      {
        category: "Cable Satellite Services",
        amount: -1719.2600000000002,
      },
      {
        category: "Loans",
        amount: -133.78,
      },
      {
        category: "Groceries",
        amount: -2166.2400000000002,
      },
      {
        category: "Charitable Giving",
        amount: -792.86,
      },
      {
        category: "Home Improvement",
        amount: -657.1200000000001,
      },
      {
        category: "Office Supplies",
        amount: -970.5,
      },
      {
        category: "Online Services",
        amount: -182.49,
      },
      {
        category: "Insurance",
        amount: -2252.93,
      },
      {
        category: "Clothing Shoes",
        amount: -1797.7599999999998,
      },
      {
        category: "Dues And Subscriptions",
        amount: -526.11,
      },
      {
        category: "Hobbies",
        amount: -882.02,
      },
      {
        category: "Automotive Expenses",
        amount: -932.63,
      },
      {
        category: "Postage And Shipping",
        amount: -1639.3899999999999,
      },
      {
        category: "General Merchandise",
        amount: -328.78,
      },
      {
        category: "Rent",
        amount: -386.21,
      },
      {
        category: "Pets Pet Care",
        amount: -441.8,
      },
      {
        category: "Advertising",
        amount: -967.54,
      },
      {
        category: "Business Miscellaneous",
        amount: -807.28,
      },
      {
        category: "Office Maintenance",
        amount: -163.51,
      },
    ],
    "2022-08": [
      {
        category: "Office Supplies",
        amount: -1355.13,
      },
      {
        category: "Online Services",
        amount: -459.22,
      },
      {
        category: "Home Improvement",
        amount: -1243.51,
      },
      {
        category: "Insurance",
        amount: -2588.21,
      },
      {
        category: "Cable Satellite Services",
        amount: -2370.8799999999997,
      },
      {
        category: "Clothing Shoes",
        amount: -1797.7599999999998,
      },
      {
        category: "Dues And Subscriptions",
        amount: -916.1800000000001,
      },
      {
        category: "Hobbies",
        amount: -882.02,
      },
      {
        category: "Automotive Expenses",
        amount: -1891.8,
      },
      {
        category: "Postage And Shipping",
        amount: -1639.3899999999999,
      },
      {
        category: "General Merchandise",
        amount: -328.78,
      },
      {
        category: "Rent",
        amount: -386.21,
      },
      {
        category: "Pets Pet Care",
        amount: -1135.68,
      },
      {
        category: "Entertainment",
        amount: -1336.74,
      },
      {
        category: "Groceries",
        amount: -1567.9,
      },
      {
        category: "Advertising",
        amount: -967.54,
      },
      {
        category: "Business Miscellaneous",
        amount: -807.28,
      },
      {
        category: "Office Maintenance",
        amount: -163.51,
      },
      {
        category: "Gasoline Fuel",
        amount: -904,
      },
    ],
    "2022-09": [
      {
        category: "Hobbies",
        amount: -1162.45,
      },
      {
        category: "Automotive Expenses",
        amount: -1964.81,
      },
      {
        category: "Cable Satellite Services",
        amount: -1952.3199999999997,
      },
      {
        category: "Insurance",
        amount: -1695.4199999999998,
      },
      {
        category: "Postage And Shipping",
        amount: -1639.3899999999999,
      },
      {
        category: "General Merchandise",
        amount: -328.78,
      },
      {
        category: "Home Improvement",
        amount: -1491.71,
      },
      {
        category: "Rent",
        amount: -386.21,
      },
      {
        category: "Clothing Shoes",
        amount: -975.8499999999999,
      },
      {
        category: "Pets Pet Care",
        amount: -1195.23,
      },
      {
        category: "Entertainment",
        amount: -1558.92,
      },
      {
        category: "Groceries",
        amount: -1567.9,
      },
      {
        category: "Advertising",
        amount: -967.54,
      },
      {
        category: "Business Miscellaneous",
        amount: -807.28,
      },
      {
        category: "Office Supplies",
        amount: -768.0500000000001,
      },
      {
        category: "Office Maintenance",
        amount: -983.29,
      },
      {
        category: "Online Services",
        amount: -276.73,
      },
      {
        category: "Dues And Subscriptions",
        amount: -390.07,
      },
      {
        category: "Gasoline Fuel",
        amount: -904,
      },
      {
        category: "Utilities",
        amount: -1232.58,
      },
      {
        category: "Restaurants Dining",
        amount: -2560.84,
      },
      {
        category: "Loans",
        amount: -338.11,
      },
      {
        category: "Healthcare Medical",
        amount: -841.01,
      },
    ],
    "2022-10": [
      {
        category: "Pets Pet Care",
        amount: -1195.23,
      },
      {
        category: "Entertainment",
        amount: -1780.65,
      },
      {
        category: "Groceries",
        amount: -1567.9,
      },
      {
        category: "Hobbies",
        amount: -632.45,
      },
      {
        category: "Home Improvement",
        amount: -1964.83,
      },
      {
        category: "Clothing Shoes",
        amount: -282.33,
      },
      {
        category: "Advertising",
        amount: -967.54,
      },
      {
        category: "Postage And Shipping",
        amount: -1074.24,
      },
      {
        category: "Business Miscellaneous",
        amount: -807.28,
      },
      {
        category: "Insurance",
        amount: -2111.68,
      },
      {
        category: "Office Supplies",
        amount: -1305.5,
      },
      {
        category: "Office Maintenance",
        amount: -983.29,
      },
      {
        category: "Cable Satellite Services",
        amount: -786.18,
      },
      {
        category: "Online Services",
        amount: -276.73,
      },
      {
        category: "Dues And Subscriptions",
        amount: -390.07,
      },
      {
        category: "Automotive Expenses",
        amount: -1032.18,
      },
      {
        category: "Gasoline Fuel",
        amount: -1165.29,
      },
      {
        category: "Utilities",
        amount: -1232.58,
      },
      {
        category: "Restaurants Dining",
        amount: -3402.96,
      },
      {
        category: "Loans",
        amount: -338.11,
      },
      {
        category: "Healthcare Medical",
        amount: -841.01,
      },
      {
        category: "Personal Care",
        amount: -501.24,
      },
      {
        category: "Gifts",
        amount: -436.19,
      },
      {
        category: "General Merchandise",
        amount: -754.23,
      },
      {
        category: "Rent",
        amount: -746.39,
      },
    ],
  },
};

export const INCOME_EXPENSE_SUMMARY_BY_MONTH_2_IN_12_MONTHS_MOCKUP_DATA = {
  incomes: {
    "2021-11": [
      {
        source: "Other Income",
        amount: 3288.26,
      },
      {
        source: "Consulting",
        amount: 1713.41,
      },
      {
        source: "Services",
        amount: 1933.8,
      },
      {
        source: "Interest",
        amount: 1359.6799999999998,
      },
      {
        source: "Sales",
        amount: 1798.47,
      },
      {
        source: "Retirement Income",
        amount: 702.23,
      },
      {
        source: "Deposits",
        amount: 1894.89,
      },
      {
        source: "Investment Income",
        amount: 2915.95,
      },
      {
        source: "Refunds/Adjustments",
        amount: 808.04,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2021-12": [
      {
        source: "Retirement Income",
        amount: 1267.8,
      },
      {
        source: "Other Income",
        amount: 762.1,
      },
      {
        source: "Expense Reimbursement",
        amount: 1171.04,
      },
      {
        source: "Interest",
        amount: 2749.5299999999997,
      },
      {
        source: "Investment Income",
        amount: 283.34,
      },
      {
        source: "Deposits",
        amount: 1491.17,
      },
      {
        source: "Rewards",
        amount: 1419.99,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-01": [
      {
        source: "Expense Reimbursement",
        amount: 449.91,
      },
      {
        source: "Consulting",
        amount: 789.22,
      },
      {
        source: "Sales",
        amount: 1124.12,
      },
      {
        source: "Rewards",
        amount: 1312.37,
      },
      {
        source: "Retirement Income",
        amount: 1449.92,
      },
      {
        source: "Services",
        amount: 3503.96,
      },
      {
        source: "Other Income",
        amount: 194.19,
      },
      {
        source: "Deposits",
        amount: 1819.55,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-02": [
      {
        source: "Interest",
        amount: 983.93,
      },
      {
        source: "Investment Income",
        amount: 1607.24,
      },
      {
        source: "Retirement Income",
        amount: 1558.81,
      },
      {
        source: "Consulting",
        amount: 1408.93,
      },
      {
        source: "Services",
        amount: 1341.59,
      },
      {
        source: "Rewards",
        amount: 2589.65,
      },
      {
        source: "Salary",
        amount: 15364.33,
      },
      {
        source: "Refunds/Adjustments",
        amount: 1043.14,
      },
    ],
    "2022-03": [
      {
        source: "Sales",
        amount: 2433.48,
      },
      {
        source: "Expense Reimbursement",
        amount: 2021.79,
      },
      {
        source: "Services",
        amount: 131.32,
      },
      {
        source: "Retirement Income",
        amount: 2055.5,
      },
      {
        source: "Refunds/Adjustments",
        amount: 641.39,
      },
      {
        source: "Deposits",
        amount: 282,
      },
      {
        source: "Investment Income",
        amount: 2512.3199999999997,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-04": [
      {
        source: "Services",
        amount: 2961.67,
      },
      {
        source: "Other Income",
        amount: 663.8699999999999,
      },
      {
        source: "Retirement Income",
        amount: 3455.81,
      },
      {
        source: "Sales",
        amount: 2814.08,
      },
      {
        source: "Interest",
        amount: 408.69,
      },
      {
        source: "Rewards",
        amount: 1687.28,
      },
      {
        source: "Investment Income",
        amount: 3354.59,
      },
      {
        source: "Refunds/Adjustments",
        amount: 1062.14,
      },
      {
        source: "Consulting",
        amount: 2883.16,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-05": [
      {
        source: "Services",
        amount: 981.48,
      },
      {
        source: "Retirement Income",
        amount: 1566.57,
      },
      {
        source: "Sales",
        amount: 747.88,
      },
      {
        source: "Deposits",
        amount: 812.8,
      },
      {
        source: "Refunds/Adjustments",
        amount: 785.3199999999999,
      },
      {
        source: "Investment Income",
        amount: 1804.13,
      },
      {
        source: "Expense Reimbursement",
        amount: 373.57,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-06": [
      {
        source: "Interest",
        amount: 831.1600000000001,
      },
      {
        source: "Retirement Income",
        amount: 73.54,
      },
      {
        source: "Consulting",
        amount: 78.24,
      },
      {
        source: "Other Income",
        amount: 2510.55,
      },
      {
        source: "Deposits",
        amount: 1838.33,
      },
      {
        source: "Rewards",
        amount: 2035.3,
      },
      {
        source: "Services",
        amount: 1749.47,
      },
      {
        source: "Refunds/Adjustments",
        amount: 1134,
      },
      {
        source: "Investment Income",
        amount: 897.1,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-07": [
      {
        source: "Deposits",
        amount: 3939.05,
      },
      {
        source: "Consulting",
        amount: 1068.68,
      },
      {
        source: "Sales",
        amount: 477.17,
      },
      {
        source: "Expense Reimbursement",
        amount: 1399.9099999999999,
      },
      {
        source: "Interest",
        amount: 3369.0699999999997,
      },
      {
        source: "Services",
        amount: 1683.31,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-08": [
      {
        source: "Expense Reimbursement",
        amount: 1651.96,
      },
      {
        source: "Refunds/Adjustments",
        amount: 7332.4,
      },
      {
        source: "Investment Income",
        amount: 172.75,
      },
      {
        source: "Sales",
        amount: 1518.17,
      },
      {
        source: "Services",
        amount: 954.88,
      },
      {
        source: "Deposits",
        amount: 366.3,
      },
      {
        source: "Rewards",
        amount: 962.94,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-09": [
      {
        source: "Services",
        amount: 1541.1399999999999,
      },
      {
        source: "Rewards",
        amount: 663.56,
      },
      {
        source: "Interest",
        amount: 752.06,
      },
      {
        source: "Refunds/Adjustments",
        amount: 1661.54,
      },
      {
        source: "Salary",
        amount: 15000,
      },
    ],
    "2022-10": [
      {
        source: "Refunds/Adjustments",
        amount: 1773.29,
      },
      {
        source: "Rewards",
        amount: 1506.46,
      },
      {
        source: "Salary",
        amount: 16879.66,
      },
      {
        source: "Retirement Income",
        amount: 1581.62,
      },
      {
        source: "Sales",
        amount: 1775.94,
      },
      {
        source: "Deposits",
        amount: 2364.0699999999997,
      },
      {
        source: "Consulting",
        amount: 1538.52,
      },
    ],
  },
  incomesByLastXMonths: {
    "2022-01": {
      regular: [
        {
          name: "Other Income",
          noOccurrences: 4,
          amount: 4244.55,
          totalAmount: 4244.55,
          avgAmount: 1414.8500000000001,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 4,
          amount: 5437.76,
          totalAmount: 5437.76,
          avgAmount: 1812.5866666666668,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Interest",
          noOccurrences: 4,
          amount: 4109.21,
          totalAmount: 4109.21,
          avgAmount: 1369.7366666666667,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 5,
          amount: 3419.9500000000003,
          totalAmount: 3419.9500000000003,
          avgAmount: 1139.9833333333333,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Deposits",
          noOccurrences: 3,
          amount: 5205.610000000001,
          totalAmount: 5205.610000000001,
          avgAmount: 1735.2033333333336,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 3,
          amount: 3199.29,
          totalAmount: 3199.29,
          avgAmount: 1066.43,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 3,
          amount: 45000,
          totalAmount: 45000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Consulting",
          noOccurrences: 2,
          amount: 2502.63,
          avgAmount: 834.21,
        },
        {
          name: "Sales",
          noOccurrences: 2,
          amount: 2922.59,
          avgAmount: 974.1966666666667,
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 1,
          amount: 808.04,
          avgAmount: 269.34666666666664,
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 2,
          amount: 1620.95,
          avgAmount: 540.3166666666667,
        },
        {
          name: "Rewards",
          noOccurrences: 2,
          amount: 2732.3599999999997,
          avgAmount: 910.7866666666665,
        },
      ],
    },
    "2022-02": {
      regular: [
        {
          name: "Retirement Income",
          noOccurrences: 5,
          amount: 4276.53,
          totalAmount: 4276.53,
          avgAmount: 1425.51,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Interest",
          noOccurrences: 3,
          amount: 3733.4599999999996,
          totalAmount: 3733.4599999999996,
          avgAmount: 1244.4866666666665,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 4,
          amount: 5322.01,
          totalAmount: 5322.01,
          avgAmount: 1774.0033333333333,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 4,
          amount: 45364.33,
          totalAmount: 45364.33,
          avgAmount: 15121.443333333335,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Consulting",
          noOccurrences: 3,
          amount: 2198.15,
          totalAmount: 2198.15,
          avgAmount: 732.7166666666667,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 4,
          amount: 4845.55,
          totalAmount: 4845.55,
          avgAmount: 1615.1833333333334,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Other Income",
          noOccurrences: 2,
          amount: 956.29,
          totalAmount: 956.29,
          avgAmount: 318.7633333333333,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 2,
          amount: 1890.58,
          totalAmount: 1890.58,
          avgAmount: 630.1933333333333,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Expense Reimbursement",
          noOccurrences: 2,
          amount: 1620.95,
          avgAmount: 540.3166666666667,
        },
        {
          name: "Deposits",
          noOccurrences: 2,
          amount: 3310.7200000000003,
          avgAmount: 1103.5733333333335,
        },
        {
          name: "Sales",
          noOccurrences: 1,
          amount: 1124.12,
          avgAmount: 374.70666666666665,
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 1,
          amount: 1043.14,
          avgAmount: 347.71333333333337,
        },
      ],
    },
    "2022-03": {
      regular: [
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 2471.7,
          totalAmount: 2471.7,
          avgAmount: 823.9,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Consulting",
          noOccurrences: 3,
          amount: 2198.15,
          totalAmount: 2198.15,
          avgAmount: 732.7166666666667,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Sales",
          noOccurrences: 3,
          amount: 3557.5999999999995,
          totalAmount: 3557.5999999999995,
          avgAmount: 1185.8666666666666,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 3,
          amount: 3902.02,
          totalAmount: 3902.02,
          avgAmount: 1300.6733333333334,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 5,
          amount: 5064.23,
          totalAmount: 5064.23,
          avgAmount: 1688.0766666666666,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 5,
          amount: 4976.87,
          totalAmount: 4976.87,
          avgAmount: 1658.9566666666667,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 4,
          amount: 45364.33,
          totalAmount: 45364.33,
          avgAmount: 15121.443333333335,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 4,
          amount: 4119.5599999999995,
          totalAmount: 4119.5599999999995,
          avgAmount: 1373.1866666666665,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Other Income",
          noOccurrences: 1,
          amount: 194.19,
          avgAmount: 64.73,
        },
        {
          name: "Deposits",
          noOccurrences: 2,
          amount: 2101.55,
          avgAmount: 700.5166666666668,
        },
        {
          name: "Interest",
          noOccurrences: 1,
          amount: 983.93,
          avgAmount: 327.97666666666663,
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 2,
          amount: 1684.5300000000002,
          avgAmount: 561.5100000000001,
        },
      ],
    },
    "2022-04": {
      regular: [
        {
          name: "Investment Income",
          noOccurrences: 6,
          amount: 7474.15,
          totalAmount: 7474.15,
          avgAmount: 2491.383333333333,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 6,
          amount: 7070.119999999999,
          totalAmount: 7070.119999999999,
          avgAmount: 2356.7066666666665,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Consulting",
          noOccurrences: 4,
          amount: 4292.09,
          totalAmount: 4292.09,
          avgAmount: 1430.6966666666667,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 6,
          amount: 4434.58,
          totalAmount: 4434.58,
          avgAmount: 1478.1933333333334,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 3,
          amount: 4276.93,
          totalAmount: 4276.93,
          avgAmount: 1425.6433333333334,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 4,
          amount: 45364.33,
          totalAmount: 45364.33,
          avgAmount: 15121.443333333335,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 3,
          amount: 2746.67,
          totalAmount: 2746.67,
          avgAmount: 915.5566666666667,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Sales",
          noOccurrences: 4,
          amount: 5247.5599999999995,
          totalAmount: 5247.5599999999995,
          avgAmount: 1749.1866666666665,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Other Income",
          noOccurrences: 2,
          amount: 663.8699999999999,
          totalAmount: 663.8699999999999,
          avgAmount: 221.28999999999996,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Interest",
          noOccurrences: 2,
          amount: 1392.62,
          avgAmount: 464.20666666666665,
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 2,
          amount: 2021.79,
          avgAmount: 673.93,
        },
        {
          name: "Deposits",
          noOccurrences: 1,
          amount: 282,
          avgAmount: 94,
        },
      ],
    },
    "2022-05": {
      regular: [
        {
          name: "Sales",
          noOccurrences: 5,
          amount: 5995.44,
          totalAmount: 5995.44,
          avgAmount: 1998.4799999999998,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 2395.36,
          totalAmount: 2395.36,
          avgAmount: 798.4533333333334,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 8,
          amount: 4074.4700000000003,
          totalAmount: 4074.4700000000003,
          avgAmount: 1358.1566666666668,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 8,
          amount: 7077.879999999999,
          totalAmount: 7077.879999999999,
          avgAmount: 2359.293333333333,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 4,
          amount: 2488.8500000000004,
          totalAmount: 2488.8500000000004,
          avgAmount: 829.6166666666668,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Deposits",
          noOccurrences: 3,
          amount: 1094.8,
          totalAmount: 1094.8,
          avgAmount: 364.93333333333334,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 7,
          amount: 7671.04,
          totalAmount: 7671.04,
          avgAmount: 2557.0133333333333,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 3,
          amount: 45000,
          totalAmount: 45000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Other Income",
          noOccurrences: 2,
          amount: 663.8699999999999,
          totalAmount: 663.8699999999999,
          avgAmount: 221.28999999999996,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Interest",
          noOccurrences: 1,
          amount: 408.69,
          avgAmount: 136.23,
        },
        {
          name: "Rewards",
          noOccurrences: 1,
          amount: 1687.28,
          avgAmount: 562.4266666666666,
        },
        {
          name: "Consulting",
          noOccurrences: 2,
          amount: 2883.16,
          avgAmount: 961.0533333333333,
        },
      ],
    },
    "2022-06": {
      regular: [
        {
          name: "Services",
          noOccurrences: 9,
          amount: 5692.620000000001,
          totalAmount: 5692.620000000001,
          avgAmount: 1897.5400000000002,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Other Income",
          noOccurrences: 4,
          amount: 3174.42,
          totalAmount: 3174.42,
          avgAmount: 1058.14,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 7,
          amount: 5095.919999999999,
          totalAmount: 5095.919999999999,
          avgAmount: 1698.6399999999996,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Sales",
          noOccurrences: 3,
          amount: 3561.96,
          totalAmount: 3561.96,
          avgAmount: 1187.32,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Interest",
          noOccurrences: 3,
          amount: 1239.85,
          totalAmount: 1239.85,
          avgAmount: 413.2833333333333,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 4,
          amount: 3722.58,
          totalAmount: 3722.58,
          avgAmount: 1240.86,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 5,
          amount: 6055.820000000001,
          totalAmount: 6055.820000000001,
          avgAmount: 2018.6066666666668,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 4,
          amount: 2981.46,
          totalAmount: 2981.46,
          avgAmount: 993.82,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Consulting",
          noOccurrences: 3,
          amount: 2961.3999999999996,
          totalAmount: 2961.3999999999996,
          avgAmount: 987.1333333333332,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 3,
          amount: 45000,
          totalAmount: 45000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Deposits",
          noOccurrences: 4,
          amount: 2651.13,
          totalAmount: 2651.13,
          avgAmount: 883.71,
          type: "Other",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Expense Reimbursement",
          noOccurrences: 1,
          amount: 373.57,
          avgAmount: 124.52333333333333,
        },
      ],
    },
    "2022-07": {
      regular: [
        {
          name: "Services",
          noOccurrences: 6,
          amount: 4414.26,
          totalAmount: 4414.26,
          avgAmount: 1471.42,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Retirement Income",
          noOccurrences: 4,
          amount: 1640.11,
          totalAmount: 1640.11,
          avgAmount: 546.7033333333333,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Deposits",
          noOccurrences: 7,
          amount: 6590.179999999999,
          totalAmount: 6590.179999999999,
          avgAmount: 2196.7266666666665,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 3,
          amount: 1919.32,
          totalAmount: 1919.32,
          avgAmount: 639.7733333333333,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 3,
          amount: 2701.23,
          totalAmount: 2701.23,
          avgAmount: 900.41,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 1773.48,
          totalAmount: 1773.48,
          avgAmount: 591.16,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 3,
          amount: 45000,
          totalAmount: 45000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Interest",
          noOccurrences: 4,
          amount: 4200.23,
          totalAmount: 4200.23,
          avgAmount: 1400.0766666666666,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 3,
          amount: 2035.3,
          totalAmount: 2035.3,
          avgAmount: 678.4333333333333,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Other Income",
          noOccurrences: 2,
          amount: 2510.55,
          totalAmount: 2510.55,
          avgAmount: 836.85,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Sales",
          noOccurrences: 2,
          amount: 1225.05,
          avgAmount: 408.34999999999997,
        },
        {
          name: "Consulting",
          noOccurrences: 2,
          amount: 1146.92,
          avgAmount: 382.3066666666667,
        },
      ],
    },
    "2022-08": {
      regular: [
        {
          name: "Interest",
          noOccurrences: 4,
          amount: 4200.23,
          totalAmount: 4200.23,
          avgAmount: 1400.0766666666666,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Deposits",
          noOccurrences: 6,
          amount: 6143.68,
          totalAmount: 6143.68,
          avgAmount: 2047.8933333333334,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 5,
          amount: 2998.2400000000002,
          totalAmount: 2998.2400000000002,
          avgAmount: 999.4133333333334,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 4,
          amount: 4387.66,
          totalAmount: 4387.66,
          avgAmount: 1462.5533333333333,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 6,
          amount: 8466.4,
          totalAmount: 8466.4,
          avgAmount: 2822.133333333333,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 3,
          amount: 45000,
          totalAmount: 45000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 3051.87,
          totalAmount: 3051.87,
          avgAmount: 1017.29,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Other Income",
          noOccurrences: 2,
          amount: 2510.55,
          totalAmount: 2510.55,
          avgAmount: 836.85,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Investment Income",
          noOccurrences: 2,
          amount: 1069.85,
          totalAmount: 1069.85,
          avgAmount: 356.6166666666666,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Retirement Income",
          noOccurrences: 1,
          amount: 73.54,
          avgAmount: 24.513333333333335,
        },
        {
          name: "Consulting",
          noOccurrences: 2,
          amount: 1146.92,
          avgAmount: 382.3066666666667,
        },
        {
          name: "Sales",
          noOccurrences: 2,
          amount: 1995.3400000000001,
          avgAmount: 665.1133333333333,
        },
      ],
    },
    "2022-09": {
      regular: [
        {
          name: "Deposits",
          noOccurrences: 4,
          amount: 4305.35,
          totalAmount: 4305.35,
          avgAmount: 1435.1166666666668,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 3,
          amount: 3051.87,
          totalAmount: 3051.87,
          avgAmount: 1017.29,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Interest",
          noOccurrences: 3,
          amount: 4121.129999999999,
          totalAmount: 4121.129999999999,
          avgAmount: 1373.7099999999998,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 5,
          amount: 4179.33,
          totalAmount: 4179.33,
          avgAmount: 1393.11,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 3,
          amount: 45000,
          totalAmount: 45000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
        {
          name: "Refunds/Adjustments",
          noOccurrences: 6,
          amount: 8993.939999999999,
          totalAmount: 8993.939999999999,
          avgAmount: 2997.9799999999996,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 4,
          amount: 1626.5,
          totalAmount: 1626.5,
          avgAmount: 542.1666666666666,
          type: "Other",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Consulting",
          noOccurrences: 1,
          amount: 1068.68,
          avgAmount: 356.2266666666667,
        },
        {
          name: "Sales",
          noOccurrences: 2,
          amount: 1995.3400000000001,
          avgAmount: 665.1133333333333,
        },
        {
          name: "Investment Income",
          noOccurrences: 1,
          amount: 172.75,
          avgAmount: 57.583333333333336,
        },
      ],
    },
    "2022-10": {
      regular: [
        {
          name: "Refunds/Adjustments",
          noOccurrences: 8,
          amount: 10767.23,
          totalAmount: 10767.23,
          avgAmount: 3589.0766666666664,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Sales",
          noOccurrences: 4,
          amount: 3294.11,
          totalAmount: 3294.11,
          avgAmount: 1098.0366666666666,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Services",
          noOccurrences: 4,
          amount: 2496.02,
          totalAmount: 2496.02,
          avgAmount: 832.0066666666667,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Deposits",
          noOccurrences: 3,
          amount: 2730.37,
          totalAmount: 2730.37,
          avgAmount: 910.1233333333333,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Rewards",
          noOccurrences: 6,
          amount: 3132.96,
          totalAmount: 3132.96,
          avgAmount: 1044.32,
          type: "Other",
          otherVariants: [],
        },
        {
          name: "Salary",
          noOccurrences: 4,
          amount: 46879.66,
          totalAmount: 46879.66,
          avgAmount: 15626.553333333335,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Expense Reimbursement",
          noOccurrences: 1,
          amount: 1651.96,
          avgAmount: 550.6533333333333,
        },
        {
          name: "Investment Income",
          noOccurrences: 1,
          amount: 172.75,
          avgAmount: 57.583333333333336,
        },
        {
          name: "Interest",
          noOccurrences: 1,
          amount: 752.06,
          avgAmount: 250.68666666666664,
        },
        {
          name: "Retirement Income",
          noOccurrences: 1,
          amount: 1581.62,
          avgAmount: 527.2066666666666,
        },
        {
          name: "Consulting",
          noOccurrences: 1,
          amount: 1538.52,
          avgAmount: 512.84,
        },
      ],
    },
  },
  expenses: {
    "2021-11": [
      {
        category: "Business Miscellaneous",
        amount: -591.22,
      },
      {
        category: "Rent",
        amount: -338.28,
      },
      {
        category: "Personal Care",
        amount: -1707.1,
      },
      {
        category: "Restaurants Dining",
        amount: -82.3,
      },
      {
        category: "Loans",
        amount: -779.29,
      },
    ],
    "2021-12": [
      {
        category: "Office Maintenance",
        amount: -68.12,
      },
      {
        category: "Automotive Expenses",
        amount: -805.64,
      },
      {
        category: "Printing",
        amount: -713.19,
      },
      {
        category: "Online Services",
        amount: -166.87,
      },
      {
        category: "Insurance",
        amount: -390.1,
      },
      {
        category: "Gasoline Fuel",
        amount: -551.62,
      },
      {
        category: "Clothing Shoes",
        amount: -936.78,
      },
    ],
    "2022-01": [
      {
        category: "Restaurants Dining",
        amount: -942.15,
      },
      {
        category: "Business Miscellaneous",
        amount: -854.64,
      },
      {
        category: "Postage And Shipping",
        amount: -550.12,
      },
      {
        category: "Clothing Shoes",
        amount: -927.61,
      },
      {
        category: "Groceries",
        amount: -118.7,
      },
      {
        category: "Dues And Subscriptions",
        amount: -812.4200000000001,
      },
      {
        category: "Automotive Expenses",
        amount: -919.28,
      },
      {
        category: "Home Improvement",
        amount: -813.75,
      },
      {
        category: "Printing",
        amount: -450.52,
      },
      {
        category: "Pets Pet Care",
        amount: -173.57,
      },
      {
        category: "Personal Care",
        amount: -93.09,
      },
    ],
    "2022-02": [
      {
        category: "Automotive Expenses",
        amount: -606.46,
      },
      {
        category: "Rent",
        amount: -530.75,
      },
      {
        category: "Dues And Subscriptions",
        amount: -1008.17,
      },
      {
        category: "Utilities",
        amount: -909.35,
      },
      {
        category: "Hobbies",
        amount: -651.56,
      },
      {
        category: "Groceries",
        amount: -1405.3200000000002,
      },
      {
        category: "Clothing Shoes",
        amount: -1638.96,
      },
      {
        category: "Gifts",
        amount: -989.85,
      },
      {
        category: "Charitable Giving",
        amount: -615.17,
      },
    ],
    "2022-03": [
      {
        category: "Clothing Shoes",
        amount: -993.1,
      },
      {
        category: "Home Improvement",
        amount: -1368.97,
      },
      {
        category: "Entertainment",
        amount: -1081.9,
      },
      {
        category: "Printing",
        amount: -888.29,
      },
      {
        category: "Rent",
        amount: -470.38,
      },
      {
        category: "Utilities",
        amount: -964.49,
      },
      {
        category: "Office Supplies",
        amount: -555.14,
      },
      {
        category: "Healthcare Medical",
        amount: -868.94,
      },
      {
        category: "Charitable Giving",
        amount: -175.08,
      },
    ],
    "2022-04": [
      {
        category: "Entertainment",
        amount: -1557.1599999999999,
      },
      {
        category: "Cable Satellite Services",
        amount: -134.56,
      },
      {
        category: "Loans",
        amount: -133.78,
      },
      {
        category: "Groceries",
        amount: -598.34,
      },
      {
        category: "Charitable Giving",
        amount: -792.86,
      },
      {
        category: "Home Improvement",
        amount: -155.58,
      },
    ],
    "2022-05": [
      {
        category: "Office Supplies",
        amount: -736.94,
      },
      {
        category: "Online Services",
        amount: -182.49,
      },
      {
        category: "Home Improvement",
        amount: -171.58,
      },
      {
        category: "Insurance",
        amount: -892.79,
      },
      {
        category: "Cable Satellite Services",
        amount: -418.56,
      },
      {
        category: "Clothing Shoes",
        amount: -821.91,
      },
      {
        category: "Dues And Subscriptions",
        amount: -526.11,
      },
    ],
    "2022-06": [
      {
        category: "Hobbies",
        amount: -530,
      },
      {
        category: "Automotive Expenses",
        amount: -932.63,
      },
      {
        category: "Cable Satellite Services",
        amount: -1166.1399999999999,
      },
      {
        category: "Insurance",
        amount: -496.08,
      },
      {
        category: "Postage And Shipping",
        amount: -876.74,
      },
      {
        category: "General Merchandise",
        amount: -328.78,
      },
      {
        category: "Home Improvement",
        amount: -64.24,
      },
      {
        category: "Rent",
        amount: -386.21,
      },
      {
        category: "Clothing Shoes",
        amount: -693.52,
      },
    ],
    "2022-07": [
      {
        category: "Pets Pet Care",
        amount: -441.8,
      },
      {
        category: "Entertainment",
        amount: -461.59,
      },
      {
        category: "Groceries",
        amount: -1567.9,
      },
      {
        category: "Hobbies",
        amount: -352.02,
      },
      {
        category: "Home Improvement",
        amount: -265.72,
      },
      {
        category: "Clothing Shoes",
        amount: -282.33,
      },
      {
        category: "Advertising",
        amount: -967.54,
      },
      {
        category: "Postage And Shipping",
        amount: -762.65,
      },
      {
        category: "Business Miscellaneous",
        amount: -807.28,
      },
      {
        category: "Insurance",
        amount: -864.06,
      },
      {
        category: "Office Supplies",
        amount: -233.56,
      },
      {
        category: "Office Maintenance",
        amount: -163.51,
      },
    ],
    "2022-08": [
      {
        category: "Home Improvement",
        amount: -741.97,
      },
      {
        category: "Entertainment",
        amount: -875.15,
      },
      {
        category: "Cable Satellite Services",
        amount: -786.18,
      },
      {
        category: "Online Services",
        amount: -276.73,
      },
      {
        category: "Pets Pet Care",
        amount: -693.88,
      },
      {
        category: "Dues And Subscriptions",
        amount: -390.07,
      },
      {
        category: "Office Supplies",
        amount: -384.63,
      },
      {
        category: "Automotive Expenses",
        amount: -959.17,
      },
      {
        category: "Insurance",
        amount: -335.28,
      },
      {
        category: "Gasoline Fuel",
        amount: -904,
      },
    ],
    "2022-09": [
      {
        category: "Utilities",
        amount: -1232.58,
      },
      {
        category: "Restaurants Dining",
        amount: -2560.84,
      },
      {
        category: "Loans",
        amount: -338.11,
      },
      {
        category: "Office Supplies",
        amount: -149.86,
      },
      {
        category: "Entertainment",
        amount: -222.18,
      },
      {
        category: "Office Maintenance",
        amount: -819.78,
      },
      {
        category: "Automotive Expenses",
        amount: -73.01,
      },
      {
        category: "Hobbies",
        amount: -280.43,
      },
      {
        category: "Pets Pet Care",
        amount: -59.55,
      },
      {
        category: "Home Improvement",
        amount: -419.78,
      },
      {
        category: "Healthcare Medical",
        amount: -841.01,
      },
    ],
    "2022-10": [
      {
        category: "Home Improvement",
        amount: -537.36,
      },
      {
        category: "Gasoline Fuel",
        amount: -261.29,
      },
      {
        category: "Personal Care",
        amount: -501.24,
      },
      {
        category: "Gifts",
        amount: -436.19,
      },
      {
        category: "General Merchandise",
        amount: -754.23,
      },
      {
        category: "Insurance",
        amount: -912.34,
      },
      {
        category: "Entertainment",
        amount: -221.73,
      },
      {
        category: "Postage And Shipping",
        amount: -311.59,
      },
      {
        category: "Restaurants Dining",
        amount: -842.12,
      },
      {
        category: "Office Supplies",
        amount: -537.45,
      },
      {
        category: "Rent",
        amount: -746.39,
      },
    ],
  },
  connectiveExpenses: {
    "2021-11": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -2380.62,
      },
      {
        category: "Rent",
        amount: -338.28,
      },
      {
        category: "Other",
        amount: -779.29,
      },
    ],
    "2021-12": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -2305.4399999999996,
      },
      {
        category: "General Insurance",
        amount: -390.1,
      },
      {
        category: "Clothing And Personal Care",
        amount: -936.78,
      },
    ],
    "2022-01": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -4795.789999999999,
      },
      {
        category: "Clothing And Personal Care",
        amount: -927.61,
      },
      {
        category: "Groceries",
        amount: -118.7,
      },
      {
        category: "Primary Residence Costs",
        amount: -813.75,
      },
    ],
    "2022-02": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -4780.56,
      },
      {
        category: "Rent",
        amount: -530.75,
      },
      {
        category: "Groceries",
        amount: -1405.3200000000002,
      },
      {
        category: "Clothing And Personal Care",
        amount: -1638.96,
      },
    ],
    "2022-03": [
      {
        category: "Clothing And Personal Care",
        amount: -993.1,
      },
      {
        category: "Primary Residence Costs",
        amount: -1368.97,
      },
      {
        category: "Recreation Entertainment",
        amount: -1081.9,
      },
      {
        category: "Other Regular And Recurring Expenses",
        amount: -2583,
      },
      {
        category: "Rent",
        amount: -470.38,
      },
      {
        category: "Medical Health",
        amount: -868.94,
      },
    ],
    "2022-04": [
      {
        category: "Recreation Entertainment",
        amount: -1557.1599999999999,
      },
      {
        category: "Telephone Internet Pay TV Media Streaming Subscriptions",
        amount: -134.56,
      },
      {
        category: "Other",
        amount: -133.78,
      },
      {
        category: "Groceries",
        amount: -598.34,
      },
      {
        category: "Other Regular And Recurring Expenses",
        amount: -792.86,
      },
      {
        category: "Primary Residence Costs",
        amount: -155.58,
      },
    ],
    "2022-05": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -1445.54,
      },
      {
        category: "Primary Residence Costs",
        amount: -171.58,
      },
      {
        category: "General Insurance",
        amount: -892.79,
      },
      {
        category: "Telephone Internet Pay TV Media Streaming Subscriptions",
        amount: -418.56,
      },
      {
        category: "Clothing And Personal Care",
        amount: -821.91,
      },
    ],
    "2022-06": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -2668.1499999999996,
      },
      {
        category: "Telephone Internet Pay TV Media Streaming Subscriptions",
        amount: -1166.1399999999999,
      },
      {
        category: "General Insurance",
        amount: -496.08,
      },
      {
        category: "Primary Residence Costs",
        amount: -64.24,
      },
      {
        category: "Rent",
        amount: -386.21,
      },
      {
        category: "Clothing And Personal Care",
        amount: -693.52,
      },
    ],
    "2022-07": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -3728.359999999999,
      },
      {
        category: "Recreation Entertainment",
        amount: -461.59,
      },
      {
        category: "Groceries",
        amount: -1567.9,
      },
      {
        category: "Primary Residence Costs",
        amount: -265.72,
      },
      {
        category: "Clothing And Personal Care",
        amount: -282.33,
      },
      {
        category: "General Insurance",
        amount: -864.06,
      },
    ],
    "2022-08": [
      {
        category: "Primary Residence Costs",
        amount: -741.97,
      },
      {
        category: "Recreation Entertainment",
        amount: -875.15,
      },
      {
        category: "Telephone Internet Pay TV Media Streaming Subscriptions",
        amount: -786.18,
      },
      {
        category: "Other Regular And Recurring Expenses",
        amount: -3608.48,
      },
      {
        category: "General Insurance",
        amount: -335.28,
      },
    ],
    "2022-09": [
      {
        category: "Other Regular And Recurring Expenses",
        amount: -5176.050000000001,
      },
      {
        category: "Other",
        amount: -338.11,
      },
      {
        category: "Recreation Entertainment",
        amount: -222.18,
      },
      {
        category: "Primary Residence Costs",
        amount: -419.78,
      },
      {
        category: "Medical Health",
        amount: -841.01,
      },
    ],
    "2022-10": [
      {
        category: "Primary Residence Costs",
        amount: -537.36,
      },
      {
        category: "Other Regular And Recurring Expenses",
        amount: -3644.1099999999997,
      },
      {
        category: "General Insurance",
        amount: -912.34,
      },
      {
        category: "Recreation Entertainment",
        amount: -221.73,
      },
      {
        category: "Rent",
        amount: -746.39,
      },
    ],
  },
  connectiveExpensesByLastXMonths: {
    "2022-01": [
      {
        category: "Business Miscellaneous",
        amount: -1445.8600000000001,
      },
      {
        category: "Rent",
        amount: -338.28,
      },
      {
        category: "Personal Care",
        amount: -1800.1899999999998,
      },
      {
        category: "Restaurants Dining",
        amount: -1024.45,
      },
      {
        category: "Loans",
        amount: -779.29,
      },
      {
        category: "Office Maintenance",
        amount: -68.12,
      },
      {
        category: "Automotive Expenses",
        amount: -1724.92,
      },
      {
        category: "Printing",
        amount: -1163.71,
      },
      {
        category: "Online Services",
        amount: -166.87,
      },
      {
        category: "Insurance",
        amount: -390.1,
      },
      {
        category: "Gasoline Fuel",
        amount: -551.62,
      },
      {
        category: "Clothing Shoes",
        amount: -1864.3899999999999,
      },
      {
        category: "Postage And Shipping",
        amount: -550.12,
      },
      {
        category: "Groceries",
        amount: -118.7,
      },
      {
        category: "Dues And Subscriptions",
        amount: -812.4200000000001,
      },
      {
        category: "Home Improvement",
        amount: -813.75,
      },
      {
        category: "Pets Pet Care",
        amount: -173.57,
      },
    ],
    "2022-02": [
      {
        category: "Office Maintenance",
        amount: -68.12,
      },
      {
        category: "Automotive Expenses",
        amount: -2331.38,
      },
      {
        category: "Printing",
        amount: -1163.71,
      },
      {
        category: "Online Services",
        amount: -166.87,
      },
      {
        category: "Insurance",
        amount: -390.1,
      },
      {
        category: "Gasoline Fuel",
        amount: -551.62,
      },
      {
        category: "Clothing Shoes",
        amount: -3503.35,
      },
      {
        category: "Restaurants Dining",
        amount: -942.15,
      },
      {
        category: "Business Miscellaneous",
        amount: -854.64,
      },
      {
        category: "Postage And Shipping",
        amount: -550.12,
      },
      {
        category: "Groceries",
        amount: -1524.02,
      },
      {
        category: "Dues And Subscriptions",
        amount: -1820.59,
      },
      {
        category: "Home Improvement",
        amount: -813.75,
      },
      {
        category: "Pets Pet Care",
        amount: -173.57,
      },
      {
        category: "Personal Care",
        amount: -93.09,
      },
      {
        category: "Rent",
        amount: -530.75,
      },
      {
        category: "Utilities",
        amount: -909.35,
      },
      {
        category: "Hobbies",
        amount: -651.56,
      },
      {
        category: "Gifts",
        amount: -989.85,
      },
      {
        category: "Charitable Giving",
        amount: -615.17,
      },
    ],
    "2022-03": [
      {
        category: "Restaurants Dining",
        amount: -942.15,
      },
      {
        category: "Business Miscellaneous",
        amount: -854.64,
      },
      {
        category: "Postage And Shipping",
        amount: -550.12,
      },
      {
        category: "Clothing Shoes",
        amount: -3559.67,
      },
      {
        category: "Groceries",
        amount: -1524.02,
      },
      {
        category: "Dues And Subscriptions",
        amount: -1820.59,
      },
      {
        category: "Automotive Expenses",
        amount: -1525.74,
      },
      {
        category: "Home Improvement",
        amount: -2182.7200000000003,
      },
      {
        category: "Printing",
        amount: -1338.81,
      },
      {
        category: "Pets Pet Care",
        amount: -173.57,
      },
      {
        category: "Personal Care",
        amount: -93.09,
      },
      {
        category: "Rent",
        amount: -1001.13,
      },
      {
        category: "Utilities",
        amount: -1873.8400000000001,
      },
      {
        category: "Hobbies",
        amount: -651.56,
      },
      {
        category: "Gifts",
        amount: -989.85,
      },
      {
        category: "Charitable Giving",
        amount: -790.25,
      },
      {
        category: "Entertainment",
        amount: -1081.9,
      },
      {
        category: "Office Supplies",
        amount: -555.14,
      },
      {
        category: "Healthcare Medical",
        amount: -868.94,
      },
    ],
    "2022-04": [
      {
        category: "Automotive Expenses",
        amount: -606.46,
      },
      {
        category: "Rent",
        amount: -1001.13,
      },
      {
        category: "Dues And Subscriptions",
        amount: -1008.17,
      },
      {
        category: "Utilities",
        amount: -1873.8400000000001,
      },
      {
        category: "Hobbies",
        amount: -651.56,
      },
      {
        category: "Groceries",
        amount: -2003.6600000000003,
      },
      {
        category: "Clothing Shoes",
        amount: -2632.06,
      },
      {
        category: "Gifts",
        amount: -989.85,
      },
      {
        category: "Charitable Giving",
        amount: -1583.1100000000001,
      },
      {
        category: "Home Improvement",
        amount: -1524.55,
      },
      {
        category: "Entertainment",
        amount: -2639.06,
      },
      {
        category: "Printing",
        amount: -888.29,
      },
      {
        category: "Office Supplies",
        amount: -555.14,
      },
      {
        category: "Healthcare Medical",
        amount: -868.94,
      },
      {
        category: "Cable Satellite Services",
        amount: -134.56,
      },
      {
        category: "Loans",
        amount: -133.78,
      },
    ],
    "2022-05": [
      {
        category: "Clothing Shoes",
        amount: -1815.0100000000002,
      },
      {
        category: "Home Improvement",
        amount: -1696.1299999999999,
      },
      {
        category: "Entertainment",
        amount: -2639.06,
      },
      {
        category: "Printing",
        amount: -888.29,
      },
      {
        category: "Rent",
        amount: -470.38,
      },
      {
        category: "Utilities",
        amount: -964.49,
      },
      {
        category: "Office Supplies",
        amount: -1292.08,
      },
      {
        category: "Healthcare Medical",
        amount: -868.94,
      },
      {
        category: "Charitable Giving",
        amount: -967.94,
      },
      {
        category: "Cable Satellite Services",
        amount: -553.12,
      },
      {
        category: "Loans",
        amount: -133.78,
      },
      {
        category: "Groceries",
        amount: -598.34,
      },
      {
        category: "Online Services",
        amount: -182.49,
      },
      {
        category: "Insurance",
        amount: -892.79,
      },
      {
        category: "Dues And Subscriptions",
        amount: -526.11,
      },
    ],
    "2022-06": [
      {
        category: "Entertainment",
        amount: -1557.1599999999999,
      },
      {
        category: "Cable Satellite Services",
        amount: -1719.2600000000002,
      },
      {
        category: "Loans",
        amount: -133.78,
      },
      {
        category: "Groceries",
        amount: -598.34,
      },
      {
        category: "Charitable Giving",
        amount: -792.86,
      },
      {
        category: "Home Improvement",
        amount: -391.40000000000003,
      },
      {
        category: "Office Supplies",
        amount: -736.94,
      },
      {
        category: "Online Services",
        amount: -182.49,
      },
      {
        category: "Insurance",
        amount: -1388.87,
      },
      {
        category: "Clothing Shoes",
        amount: -1515.4299999999998,
      },
      {
        category: "Dues And Subscriptions",
        amount: -526.11,
      },
      {
        category: "Hobbies",
        amount: -530,
      },
      {
        category: "Automotive Expenses",
        amount: -932.63,
      },
      {
        category: "Postage And Shipping",
        amount: -876.74,
      },
      {
        category: "General Merchandise",
        amount: -328.78,
      },
      {
        category: "Rent",
        amount: -386.21,
      },
    ],
    "2022-07": [
      {
        category: "Office Supplies",
        amount: -970.5,
      },
      {
        category: "Online Services",
        amount: -182.49,
      },
      {
        category: "Home Improvement",
        amount: -501.54,
      },
      {
        category: "Insurance",
        amount: -2252.93,
      },
      {
        category: "Cable Satellite Services",
        amount: -1584.6999999999998,
      },
      {
        category: "Clothing Shoes",
        amount: -1797.7599999999998,
      },
      {
        category: "Dues And Subscriptions",
        amount: -526.11,
      },
      {
        category: "Hobbies",
        amount: -882.02,
      },
      {
        category: "Automotive Expenses",
        amount: -932.63,
      },
      {
        category: "Postage And Shipping",
        amount: -1639.3899999999999,
      },
      {
        category: "General Merchandise",
        amount: -328.78,
      },
      {
        category: "Rent",
        amount: -386.21,
      },
      {
        category: "Pets Pet Care",
        amount: -441.8,
      },
      {
        category: "Entertainment",
        amount: -461.59,
      },
      {
        category: "Groceries",
        amount: -1567.9,
      },
      {
        category: "Advertising",
        amount: -967.54,
      },
      {
        category: "Business Miscellaneous",
        amount: -807.28,
      },
      {
        category: "Office Maintenance",
        amount: -163.51,
      },
    ],
    "2022-08": [
      {
        category: "Hobbies",
        amount: -882.02,
      },
      {
        category: "Automotive Expenses",
        amount: -1891.8,
      },
      {
        category: "Cable Satellite Services",
        amount: -1952.3199999999997,
      },
      {
        category: "Insurance",
        amount: -1695.4199999999998,
      },
      {
        category: "Postage And Shipping",
        amount: -1639.3899999999999,
      },
      {
        category: "General Merchandise",
        amount: -328.78,
      },
      {
        category: "Home Improvement",
        amount: -1071.93,
      },
      {
        category: "Rent",
        amount: -386.21,
      },
      {
        category: "Clothing Shoes",
        amount: -975.8499999999999,
      },
      {
        category: "Pets Pet Care",
        amount: -1135.68,
      },
      {
        category: "Entertainment",
        amount: -1336.74,
      },
      {
        category: "Groceries",
        amount: -1567.9,
      },
      {
        category: "Advertising",
        amount: -967.54,
      },
      {
        category: "Business Miscellaneous",
        amount: -807.28,
      },
      {
        category: "Office Supplies",
        amount: -618.19,
      },
      {
        category: "Office Maintenance",
        amount: -163.51,
      },
      {
        category: "Online Services",
        amount: -276.73,
      },
      {
        category: "Dues And Subscriptions",
        amount: -390.07,
      },
      {
        category: "Gasoline Fuel",
        amount: -904,
      },
    ],
    "2022-09": [
      {
        category: "Pets Pet Care",
        amount: -1195.23,
      },
      {
        category: "Entertainment",
        amount: -1558.92,
      },
      {
        category: "Groceries",
        amount: -1567.9,
      },
      {
        category: "Hobbies",
        amount: -632.45,
      },
      {
        category: "Home Improvement",
        amount: -1427.47,
      },
      {
        category: "Clothing Shoes",
        amount: -282.33,
      },
      {
        category: "Advertising",
        amount: -967.54,
      },
      {
        category: "Postage And Shipping",
        amount: -762.65,
      },
      {
        category: "Business Miscellaneous",
        amount: -807.28,
      },
      {
        category: "Insurance",
        amount: -1199.34,
      },
      {
        category: "Office Supplies",
        amount: -768.0500000000001,
      },
      {
        category: "Office Maintenance",
        amount: -983.29,
      },
      {
        category: "Cable Satellite Services",
        amount: -786.18,
      },
      {
        category: "Online Services",
        amount: -276.73,
      },
      {
        category: "Dues And Subscriptions",
        amount: -390.07,
      },
      {
        category: "Automotive Expenses",
        amount: -1032.18,
      },
      {
        category: "Gasoline Fuel",
        amount: -904,
      },
      {
        category: "Utilities",
        amount: -1232.58,
      },
      {
        category: "Restaurants Dining",
        amount: -2560.84,
      },
      {
        category: "Loans",
        amount: -338.11,
      },
      {
        category: "Healthcare Medical",
        amount: -841.01,
      },
    ],
    "2022-10": [
      {
        category: "Home Improvement",
        amount: -1699.1100000000001,
      },
      {
        category: "Entertainment",
        amount: -1319.06,
      },
      {
        category: "Cable Satellite Services",
        amount: -786.18,
      },
      {
        category: "Online Services",
        amount: -276.73,
      },
      {
        category: "Pets Pet Care",
        amount: -753.43,
      },
      {
        category: "Dues And Subscriptions",
        amount: -390.07,
      },
      {
        category: "Office Supplies",
        amount: -1071.94,
      },
      {
        category: "Automotive Expenses",
        amount: -1032.18,
      },
      {
        category: "Insurance",
        amount: -1247.62,
      },
      {
        category: "Gasoline Fuel",
        amount: -1165.29,
      },
      {
        category: "Utilities",
        amount: -1232.58,
      },
      {
        category: "Restaurants Dining",
        amount: -3402.96,
      },
      {
        category: "Loans",
        amount: -338.11,
      },
      {
        category: "Office Maintenance",
        amount: -819.78,
      },
      {
        category: "Hobbies",
        amount: -280.43,
      },
      {
        category: "Healthcare Medical",
        amount: -841.01,
      },
      {
        category: "Personal Care",
        amount: -501.24,
      },
      {
        category: "Gifts",
        amount: -436.19,
      },
      {
        category: "General Merchandise",
        amount: -754.23,
      },
      {
        category: "Postage And Shipping",
        amount: -311.59,
      },
      {
        category: "Rent",
        amount: -746.39,
      },
    ],
  },
};

export const INCOME_EXPENSE_SUMMARY_BY_MONTH_2_IN_3_MONTHS_MOCKUP_DATA = {
  incomes: {
    "2022-08": [],
    "2022-09": [],
    "2022-10": [
      { source: "Salary", amount: 45000 },
      { source: "Deposits", amount: 1910.17 },
      { source: "Investment Income", amount: 306.7 },
      { source: "Other Income", amount: 1465.88 },
      { source: "Expense Reimbursement", amount: 1333.94 },
    ],
  },
  incomesByLastXMonths: {
    "2022-10": {
      regular: [
        {
          name: "Salary",
          noOccurrences: 3,
          amount: 45000,
          totalAmount: 45000,
          avgAmount: 15000,
          type: "Salary",
          otherVariants: [],
        },
      ],
      irregular: [
        {
          name: "Deposits",
          noOccurrences: 1,
          amount: 1910.17,
          avgAmount: 636.7233333333334,
        },
        {
          name: "Investment Income",
          noOccurrences: 1,
          amount: 306.7,
          avgAmount: 102.23333333333333,
        },
        {
          name: "Other Income",
          noOccurrences: 1,
          amount: 1465.88,
          avgAmount: 488.6266666666667,
        },
        {
          name: "Expense Reimbursement",
          noOccurrences: 1,
          amount: 1333.94,
          avgAmount: 444.6466666666667,
        },
      ],
    },
  },
  expenses: {
    "2022-08": [],
    "2022-09": [],
    "2022-10": [
      { category: "Loans", amount: -462.04 },
      { category: "Printing", amount: -256.57 },
      { category: "Business Miscellaneous", amount: -654.82 },
      { category: "Restaurants Dining", amount: -266.46 },
      { category: "Office Maintenance", amount: -911.92 },
      { category: "Cable Satellite Services", amount: -66.97 },
      { category: "Healthcare Medical", amount: -129.85 },
      { category: "General Merchandise", amount: -824.49 },
    ],
  },
  connectiveExpenses: {
    "2022-08": [],
    "2022-09": [],
    "2022-10": [
      { category: "Other", amount: -462.04 },
      { category: "Other Regular And Recurring Expenses", amount: -2914.26 },
      {
        category: "Telephone Internet Pay TV Media Streaming Subscriptions",
        amount: -66.97,
      },
      { category: "Medical Health", amount: -129.85 },
    ],
  },
  connectiveExpensesByLastXMonths: {
    "2022-10": [
      { category: "Loans", amount: -462.04 },
      { category: "Printing", amount: -256.57 },
      { category: "Business Miscellaneous", amount: -654.82 },
      { category: "Restaurants Dining", amount: -266.46 },
      { category: "Office Maintenance", amount: -911.92 },
      { category: "Cable Satellite Services", amount: -66.97 },
      { category: "Healthcare Medical", amount: -129.85 },
      { category: "General Merchandise", amount: -824.49 },
    ],
  },
};

export const ACCOUNT_WITH_INSTITUTION_MOCKUP_DATA = {
  data: [
    {
      id: "11409642",
      name: "WealthX Loan (xxxx9415)",
      currency: "USD",
      balance: 885721.36,
      institution: "16441",
      isAsset: false,
      class: { product: "WealthX Loan (xxxx9415)", type: "loan" },
    },
    {
      id: "11409640",
      name: "WealthX Bank (xxxx1335)",
      currency: "USD",
      balance: 1014511.81,
      institution: "16441",
      isAsset: true,
      class: { product: "WealthX Bank (xxxx1335)", type: "transaction" },
    },
  ],
};

export const CATEGORIZED_ACCOUNTS = {
  assets: [
    {
      id: "11409640",
      name: "WealthX Bank (xxxx1335)",
      currency: "USD",
      balance: 1014511.81,
      institution: "16441",
      isAsset: true,
      class: {
        product: "WealthX Bank (xxxx1335)",
        type: "transaction",
      },
      institutionDetail: {
        id: 16441,
        name: "Dag Site",
        logo: "https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16441_1_2.SVG",
        favicon: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
        baseUrl: "http://64.14.28.129/dag/index.do",
      },
      accountId: "11409640",
      institutionLogo: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
      type: "transaction",
    },
    {
      id: "11081704",
      name: "CMA -Joint Brokerage - 3547",
      currency: "USD",
      balance: 330101.25,
      institution: "16441",
      isAsset: true,
      class: {
        product: "CMA -Joint Brokerage - 3547",
        type: "share",
      },
      institutionDetail: {
        id: 16441,
        name: "Dag Site",
        logo: "https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16441_1_2.SVG",
        favicon: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
        baseUrl: "http://64.14.28.129/dag/index.do",
      },
      accountId: "11081704",
      institutionLogo: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
      type: "share",
    },
  ],
  assetsNotSharesAndSupers: [
    {
      id: "11409640",
      name: "WealthX Bank (xxxx1335)",
      currency: "USD",
      balance: 1014511.81,
      institution: "16441",
      isAsset: true,
      class: {
        product: "WealthX Bank (xxxx1335)",
        type: "transaction",
      },
      institutionDetail: {
        id: 16441,
        name: "Dag Site",
        logo: "https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16441_1_2.SVG",
        favicon: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
        baseUrl: "http://64.14.28.129/dag/index.do",
      },
      accountId: "11409640",
      institutionLogo: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
      type: "transaction",
    },
  ],
  credit: [],
  loan: [
    {
      id: "11409642",
      name: "WealthX Loan (xxxx9415)",
      currency: "USD",
      balance: 885721.36,
      institution: "16441",
      isAsset: false,
      class: {
        product: "WealthX Loan (xxxx9415)",
        type: "loan",
      },
      institutionDetail: {
        id: 16441,
        name: "Dag Site",
        logo: "https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16441_1_2.SVG",
        favicon: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
        baseUrl: "http://64.14.28.129/dag/index.do",
      },
      accountId: "11409642",
      institutionLogo: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
      type: "loan",
    },
  ],
  propertyLoan: [],
  personalLoan: [
    {
      id: "11409642",
      name: "WealthX Loan (xxxx9415)",
      currency: "USD",
      balance: 885721.36,
      institution: "16441",
      isAsset: false,
      class: {
        product: "WealthX Loan (xxxx9415)",
        type: "loan",
      },
      institutionDetail: {
        id: 16441,
        name: "Dag Site",
        logo: "https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16441_1_2.SVG",
        favicon: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
        baseUrl: "http://64.14.28.129/dag/index.do",
      },
      accountId: "11409642",
      institutionLogo: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
      type: "loan",
    },
  ],
  share: [
    {
      id: "11081704",
      name: "CMA -Joint Brokerage - 3547",
      currency: "USD",
      balance: 330101.25,
      institution: "16441",
      isAsset: true,
      class: {
        product: "CMA -Joint Brokerage - 3547",
        type: "share",
      },
      institutionDetail: {
        id: 16441,
        name: "Dag Site",
        logo: "https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16441_1_2.SVG",
        favicon: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
        baseUrl: "http://64.14.28.129/dag/index.do",
      },
      accountId: "11081704",
      institutionLogo: "https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_16441.SVG",
      type: "share",
    },
  ],
  super: [],
  others: [],
};
