import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 39 39"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st2{fill:#f44336}"}</style>
      <g id="prefix__Group_6239" transform="translate(-1080 -467)">
        <g id="prefix__Ellipse_293" transform="translate(1080 467)" fill="none">
          <circle cx={19.5} cy={19.5} r={19.5} />
          <circle cx={19.5} cy={19.5} r={18} stroke="#f44336" strokeWidth={3} />
        </g>
        <path
          className="prefix__st2"
          d="M1105.3 494.9l-14.2-14.2c-.7-.7-.7-1.9 0-2.6.7-.7 1.9-.7 2.6 0l14.2 14.2c.7.7.7 1.9 0 2.6-.7.7-1.9.7-2.6 0z"
        />
        <path
          className="prefix__st2"
          d="M1108 480.7l-14.2 14.2c-.7.7-1.9.7-2.6 0-.7-.7-.7-1.9 0-2.6l14.2-14.2c.7-.7 1.9-.7 2.6 0 .7.7.7 1.9 0 2.6z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
