import React from "react";
import PropTypes from "prop-types";
import { Backdrop, CircularProgress } from "@material-ui/core";

const LoadingBackdrop = ({
  isOpen = true,
  handleClose = () => {},
  content = "Loading Data...",
}) => {
  return (
    <Backdrop className="loading-modal" open={isOpen} onClick={handleClose}>
      <div className="loading-modal__text-container">
        <CircularProgress color="inherit" />
        <div className="loading-modal__text">{content}</div>
      </div>
    </Backdrop>
  );
};

LoadingBackdrop.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default LoadingBackdrop;
export { LoadingBackdrop };
