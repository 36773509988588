import * as React from "react";

function SvgSuper(props) {
  return (
    <svg
      id="super_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 42.6 42.6"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.super_svg__st1{fill:none;stroke:#fff;stroke-width:2}`}</style>
      <g id="super_svg__Group_3744" transform="translate(-40.352 -752.888)">
        <circle
          id="super_svg__Ellipse_87"
          cx={74}
          cy={766.5}
          r={9}
          className="icon-fill"
        />
        <g id="super_svg__Group_3742" transform="translate(41.352 760.174)">
          <path
            id="super_svg__Path_1245"
            className="super_svg__st1"
            d="M18.1 7.6H0v22.2h31.3v-9"
          />
          <path
            id="super_svg__Path_1246"
            className="super_svg__st1"
            d="M4.5 23.7l8.4-8.5 6 6.1L34.2 5.2V17"
          />
          <path
            id="super_svg__Path_1247"
            className="super_svg__st1"
            d="M22.7 4.6h11.7"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSuper;
