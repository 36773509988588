import * as React from "react";

function Circle(props) {
  return (
    <svg
      data-name="Group 6146"
      xmlns="http://www.w3.org/2000/svg"
      width={17.412}
      height={17.412}
      viewBox="0 0 17.412 17.412"
      {...props}
    >
      <circle data-name="Ellipse 105" cx={8.706} cy={8.706} r={8.706} />
      <g data-name="Ellipse 104" transform="translate(1.451 1.451)">
        <circle cx={7.255} cy={7.255} r={7.255} stroke="none" />
        <circle cx={7.255} cy={7.255} r={6.755} fill="none" />
      </g>
    </svg>
  );
}

export default Circle;
