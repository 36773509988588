import { getByPathOrDefault } from "../../utils/expensesUtils";

const calculateTotalMarketIndices = (marketIndices = {}, propertyList = []) => {
  const { dates: marketDate = [] } = marketIndices;
  let addressList = propertyList.map((item) => {
    const { addressDetails = {}, propertyType = "" } = item;
    const { locality = "", postcode = "" } = addressDetails;
    return getByPathOrDefault(
      marketIndices,
      [
        propertyType.toLowerCase(),
        "series",
        `${locality}-${postcode}`,
        "price",
      ],
      []
    );
  });
  addressList = [...new Set(addressList)];
  const result = addressList.reduce((addressListResult, addressItem) => {
    addressItem.forEach((addressItemDetail, i) => {
      addressListResult[i] = (addressListResult[i] || 0) + addressItemDetail;
    });
    return addressListResult;
  }, []);
  const marketCount = addressList.length || 1;
  const lastResult = result.map((item) => item / marketCount);
  let saleHistoryChart = [];
  if (lastResult.length > 0 && marketDate.length > 0) {
    saleHistoryChart = lastResult.reduce((saleChart, item, index) => {
      if (item) {
        const chartData = { x: marketDate[index], y: item };
        saleChart.push(chartData);
      }
      return saleChart;
    }, []);
  }
  const returnData = [
    lastResult.length > 0 && marketDate.length > 0,
    saleHistoryChart,
  ];

  return returnData;
};

export { calculateTotalMarketIndices };
