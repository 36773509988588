export const USER_INFO_MOCKUP_DATA = {
  id: "xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
  email: "demo@wealthx.au",
  firstName: "Demo",
  lastName: "Data",
  fullName: "Demo Data",
  gender: null,
  birthday: null,
  phoneNumber: "+61000000000",
  postalAddress: "",
  isSubscribedMonthlyReport: true,
  userRole: "USER",
  state: "VERIFIED",
  companyId: "yyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy",
  referralCode: "AAAAAAA",
  referralBy: "",
  referralUrl: "demo-data",
  staffCode: "AAAAAA",
};
