import { useQuery } from "react-query";
import {
  getStaffInfo,
  getStaffInfoByReferralCode,
  getStaffList,
} from "../api/brokers.ts";
import { useCompanyInfo } from "./common.hooks";
import { useIsLoggedIn, useMyInfo } from "./user.hooks.ts";
import { extractWistiaIdFromWistiaURL } from "../utils/wistia-utils";

export const useBrokerInfo = (staffCode) => {
  return useQuery(["brokerInfo", staffCode], () => getStaffInfo(staffCode), {
    enabled: !!staffCode,
    retry: 1,
  });
};

export const useStaffList = () => {
  const isLoggedIn = useIsLoggedIn();
  return useQuery("staffList", () => getStaffList(), {
    enabled: isLoggedIn,
    retry: 1,
  });
};

export const useStaffInfoByReferralCode = (referralCode) => {
  return useQuery(
    ["staffInfoByReferralCode", referralCode],
    () => getStaffInfoByReferralCode(referralCode),
    {
      enabled: !!referralCode,
    }
  );
};

export const useDefaultBroker = () => {
  const { data: companyInfo } = useCompanyInfo();
  const adminDetails = companyInfo?.adminDetails;
  return {
    name: `${adminDetails?.user.firstName} ${adminDetails?.user.lastName}`,
    phone: adminDetails?.user.phoneNumber,
    email: adminDetails?.user.email,
    introductionVideoUrl: extractWistiaIdFromWistiaURL(
      adminDetails?.introductionVideoUrl
    ),
    calendlyUrl: adminDetails?.calendlyUrl,
    imageUrl: adminDetails?.imageUrl,
    staffRoles: adminDetails?.staffRoles,
  };
};

export const useMyBrokerInfo = () => {
  const { data: myInfo } = useMyInfo();
  const staffCode = myInfo?.staffCode || "";
  const defaultBrokerInfo = useDefaultBroker();
  const { data: brokerInfo, isLoading } = useBrokerInfo(staffCode);
  const formattedBrokerInfo = {
    ...brokerInfo,
    imageUrl: brokerInfo?.imageUrl || defaultBrokerInfo.imageUrl,
    introductionVideoUrl: brokerInfo?.introductionVideoUrl
      ? extractWistiaIdFromWistiaURL(brokerInfo?.introductionVideoUrl)
      : null,
  };
  return {
    data: brokerInfo ? formattedBrokerInfo : defaultBrokerInfo,
    isLoading,
  };
};
