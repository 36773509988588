import { Grid } from "@material-ui/core";
import React from "react";
import { useCompanyInfo } from "../../../hooks";

const SharingContentWithSignup = ({
  reverse = false,
  children,
  name = "",
  brokerName,
  isBrokerPage = false,
  brokerInfo,
}) => {
  const isBrokerRole = brokerInfo?.staffRoles?.some(
    (role) => role.role === "BROKER"
  );
  const { data: companyInfo } = useCompanyInfo();

  const renderReferralTitle = () => {
    return (
      <Grid item md={6} xs={12} className="sharing-heading__title">
        <span>{name || "Many users"}</span> Uses {companyInfo?.name} & Thinks
        You Should Too.
      </Grid>
    );
  };

  const renderBrokerTitle = () => {
    return (
      <Grid item md={6} xs={12} className="sharing-heading__title">
        Signup With <span>{brokerName}</span> <br />
        As Your In App {isBrokerRole ? "Finance Broker" : "Finance Advisor"}.
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction={reverse ? "row-reverse" : "row"}
      justify="space-between"
      alignItems="flex-start"
      className="sharing-heading__container"
    >
      {isBrokerPage ? renderBrokerTitle() : renderReferralTitle()}
      <Grid item md={6} xs={12} className="sharing-heading-login__container">
        <div className="sharing-heading-login__title">
          Lets Connect You To Our Team
        </div>
        {children}
      </Grid>
    </Grid>
  );
};

SharingContentWithSignup.propTypes = {};

export default SharingContentWithSignup;
