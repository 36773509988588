import { useSnackbar } from "notistack";
import { useEffect } from "react";
import axiosClient from "../api/axiosClient";

const SnackbarInAxios = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    axiosClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.data?.errorCode === "PERMISSION_DENIED") {
          enqueueSnackbar("Permission Denied", {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else if (error?.data?.statusCode === 429) {
          enqueueSnackbar(error?.data?.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
        throw error;
      }
    );
  }, [enqueueSnackbar]);

  return children;
};

export default SnackbarInAxios;
