import React from "react";

const NotFoundBroker = () => {
  return (
    <div className="error-page-container">
      <div className="h1 text-uppercase">Broker not found</div>
      <div className="h6 mt-3">
        Sorry, the broker you entered was not found.
      </div>
    </div>
  );
};

export default NotFoundBroker;
