import { useMutation, useQuery } from "react-query";
import { getNameByReferral, changeReferralURL } from "../api/users";

export const useInviterName = (referralPath = "", referralCode = "") => {
  return useQuery(
    "getNameByReferral",
    () => getNameByReferral(referralPath, referralCode),
    {
      enabled: !!referralCode,
      retry: 1,
    }
  );
};

export const ChangeReferralURLPath = (referralPath = "", referralCode = "") => {
  const queryParam = {
    params: {
      ref: referralPath,
    },
  };
  return useMutation(() => changeReferralURL(queryParam, referralCode), {
    retry: 1,
  });
};
