import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function SvgComponent({ fill = primaryColor }) {
  return (
    <svg viewBox="0 0 385 385">
      <path
        d="M376.5 168.5h-160V8.5c0-4.4-3.6-8-8-8h-32c-4.4 0-8 3.6-8 8v160H8.5c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h160v160c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8v-160h160c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgComponent;
