import axiosClient from "./axiosClient";

const createScenario = (params) => {
  const url = "/scenarios";
  return axiosClient.post(url, params);
};
const getScenario = () => {
  const url = "/scenarios/all";
  return axiosClient.get(url);
};
const deleteScenario = (id) => {
  const url = `/scenarios/${id}`;
  return axiosClient.delete(url);
};
const updateScenario = (id, params, isSaveToDB = true) => {
  const url = `/scenarios/${id}/${isSaveToDB ? "true" : "false"}`;
  return axiosClient.put(url, params);
};

const updateBorrowCapacity = () => {
  const url = `/scenarios/updateBorrowCapacity`;
  return axiosClient.post(url);
};

const getProductListByScenarioId = (id) => {
  const url = `/scenarios/${id}/getDataAndBuildProductListByScenarioId`;
  return axiosClient.get(url);
};

const getServiceProviders = () => {
  const url = `/scenarios/serviceProviders`;
  return axiosClient.get(url);
};

const getBuyingPowerHistoryByScenario = (scenarioId) => {
  const url = `/scenarios/${scenarioId}/buyingPowerHistory`;
  return axiosClient.get(url);
};

const updateUserContact = () => {
  const url = "/scenarios/contact";
  return axiosClient.post(url);
};

const updateUserEmployment = () => {
  const url = "/scenarios/employment";
  return axiosClient.post(url);
};

const uploadDocument = (id, params, options) => {
  const url = `/scenarios/${id}/upLoad`;
  return axiosClient.post(url, params, options);
};
const getDocumentList = (id) => {
  const url = `/scenarios/${id}/documentation`;
  return axiosClient.get(url);
};
const createLoanSubmission = (id) => {
  const url = `/scenarios/${id}/loanSubmission`;
  return axiosClient.post(url);
};
const getLoanSubmissionStatus = (id) => {
  const url = `/scenarios/${id}/loanSubmission/status`;
  return axiosClient.get(url);
};
const getDocumentURL = (id, isDownload) => {
  const url = `/scenarios/documentation/${id}/${isDownload}`;
  return axiosClient.get(url);
};
const deleteDocument = (scenarioId, documentId) => {
  const url = `/scenarios/${scenarioId}/documentation/${documentId}`;
  return axiosClient.delete(url);
};

const getNotification = (id) => {
  const url = `/scenarios/${id}/notification`;
  return axiosClient.get(url);
};

const getScenarioContact = () => {
  const url = "/scenarios/contact";
  return axiosClient.get(url);
};

const deleteScenarioContact = () => {
  const url = "/scenarios/contact";
  return axiosClient.delete(url);
};

const getOpportunity = (scenarioId) => {
  const url = `/scenarios/${scenarioId}/opportunity`;
  return axiosClient.get(url);
};

const inviteUserToScenario = (payload) => {
  const url = `/scenarios/send-invitation-email`;
  return axiosClient.post(url, payload);
};

const acceptInvitation = (token) => {
  const url = `/scenarios/accept-invitation`;
  return axiosClient.post(url, { token });
};

const validateToken = (token) => {
  const url = `/scenarios/validate-invite-token`;
  return axiosClient.post(url, { token });
};

const getConnectionByScenario = (scenarioId) => {
  const url = `/scenarios/${scenarioId}/invitation`;
  return axiosClient.get(url);
};

const removeUserFromScenario = (scenarioId, email) => {
  const url = `/scenarios/${scenarioId}/remove-member`;
  return axiosClient.post(url, { email });
};

export {
  updateBorrowCapacity,
  getServiceProviders,
  getLoanSubmissionStatus,
  getOpportunity,
  deleteDocument,
  createLoanSubmission,
  getNotification,
  getDocumentURL,
  getDocumentList,
  uploadDocument,
  updateUserEmployment,
  updateUserContact,
  deleteScenarioContact,
  getScenarioContact,
  createScenario,
  getScenario,
  deleteScenario,
  getProductListByScenarioId,
  updateScenario,
  getBuyingPowerHistoryByScenario,
  inviteUserToScenario,
  acceptInvitation,
  getConnectionByScenario,
  validateToken,
  removeUserFromScenario,
};
