import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 17.8 16.7"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".help-support-line{fill:#fff}"}</style>
      <g id="prefix__Group_6567" transform="translate(-21.5 -627.75)">
        <g id="prefix__Group_3955" transform="translate(20.5 749.842)">
          <path
            className="icon-fill"
            d="M10.2-105.7c-4.5 0-8.1-3.6-8.2-8.1.1-4.5 3.8-8.2 8.3-8.1h.1c4.5 0 8.1 3.6 8.2 8.1-.1 4.5-3.7 8.1-8.2 8.1h-.2zm0-15.1c-3.9 0-7.1 3.2-7.2 7.1.1 3.9 3.3 7.1 7.3 7.1s7.2-3.1 7.3-7.1c-.1-4-3.4-7.2-7.4-7.1z"
            id="prefix__Path_1305"
          />
          <path
            className="icon-fill"
            d="M10.3-109c-2.6 0-4.7-2.2-4.6-4.7 0-2.6 2-4.7 4.6-4.7 2.6 0 4.7 2.2 4.6 4.7 0 2.5-2 4.7-4.6 4.7zm0-8.5c-2 0-3.7 1.7-3.6 3.7 0 2 1.6 3.7 3.6 3.7s3.6-1.7 3.6-3.7-1.6-3.7-3.6-3.7z"
            id="prefix__Path_1306"
          />
          <path
            transform="rotate(-84.221 12.06 -119.469)"
            className="icon-fill"
            d="M10.6-120h3v1h-3z"
            id="prefix__Path_1307"
          />
          <path
            transform="rotate(-6.027 8.543 -119.467)"
            className="icon-fill"
            d="M8-121h1v3H8z"
            id="prefix__Path_1308"
          />
          <path
            transform="rotate(-82.16 4.638 -115.502)"
            className="icon-fill"
            d="M4.1-117h1v3.1h-1z"
            id="prefix__Path_1309"
          />
          <path
            transform="rotate(-7.753 4.637 -111.808)"
            className="icon-fill"
            d="M3.1-112.3h3.1v1H3.1z"
            id="prefix__Path_1310"
          />
          <path
            transform="rotate(-84.174 8.544 -107.987)"
            className="icon-fill"
            d="M7-108.5h3.1v1H7z"
            id="prefix__Path_1311"
          />
          <path
            transform="rotate(-6.832 12.123 -108.099)"
            className="icon-fill"
            d="M11.6-109.5h1v2.9h-1z"
            id="prefix__Path_1312"
          />
          <path
            transform="rotate(-82.432 15.915 -112.052)"
            className="icon-fill"
            d="M15.4-113.6h1v3h-1z"
            id="prefix__Path_1313"
          />
          <path
            transform="rotate(-5.826 15.92 -115.564)"
            className="icon-fill"
            d="M14.4-116h3v1h-3z"
            id="prefix__Path_1314"
          />
          <path
            className="icon-fill"
            d="M16.6-118.6l-.7-.7c.2-.2.2-.3.2-.4-.1-.1-.3 0-.5.2l-.7-.7c.4-.4 1-.6 1.4-.5.4.1.7.4.8.7.1.3.1.8-.5 1.4z"
            id="prefix__Path_1315"
          />
          <path
            className="icon-fill"
            d="M3.8-118.6c-.4-.4-.6-.9-.5-1.4.1-.4.4-.7.7-.8.3-.1.8-.1 1.4.5l-.7.7c-.2-.2-.4-.2-.4-.2-.1.1 0 .3.2.5l-.7.7z"
            id="prefix__Path_1316"
          />
          <path
            className="icon-fill"
            d="M4.4-106.7h-.3c-.4-.1-.7-.4-.8-.7-.1-.3-.1-.8.5-1.4l.7.7c-.2.2-.2.4-.2.4.1.1.3 0 .5-.2l.7.7c-.3.3-.7.5-1.1.5z"
            id="prefix__Path_1317"
          />
          <path
            className="icon-fill"
            d="M16.1-106.7c-.3 0-.7-.1-1.1-.6l.7-.7c.2.2.3.2.4.2.1-.1 0-.3-.2-.5l.7-.7c.4.4.6 1 .5 1.4-.1.4-.4.7-.8.7 0 .2-.1.2-.2.2z"
            id="prefix__Path_1318"
          />
        </g>
        <g id="prefix__Group_3945" transform="translate(19.5 749.842)">
          <path
            className="help-support-line"
            d="M10.2-105.7c-4.5 0-8.1-3.6-8.2-8.1.1-4.5 3.8-8.2 8.3-8.1h.1c4.5 0 8.1 3.6 8.2 8.1-.1 4.5-3.7 8.1-8.2 8.1h-.2zm0-15.1c-3.9 0-7.1 3.2-7.2 7.1.1 3.9 3.4 7.1 7.3 7.1 4 0 7.2-3.1 7.3-7.1-.1-4-3.3-7.2-7.4-7.1.1 0 .1 0 0 0z"
            id="prefix__Path_1305-2"
          />
          <path
            className="help-support-line"
            d="M10.3-109c-2.6 0-4.7-2.2-4.6-4.7 0-2.6 2-4.7 4.6-4.7 2.6 0 4.7 2.2 4.6 4.7 0 2.5-2 4.7-4.6 4.7zm0-8.5c-2 0-3.7 1.7-3.6 3.7 0 2 1.6 3.7 3.6 3.7s3.6-1.7 3.6-3.7-1.6-3.7-3.6-3.7z"
            id="prefix__Path_1306-2"
          />
          <path
            className="help-support-line"
            d="M11.9-117.5c-.3 0-.5-.3-.5-.6l.3-3c0-.3.3-.5.5-.4.3 0 .5.3.4.5l-.3 3c.1.3-.1.5-.4.5z"
            id="prefix__Path_1307-2"
          />
          <path
            className="help-support-line"
            d="M8.7-117.5c-.3 0-.5-.2-.5-.4l-.3-3c0-.3.2-.5.4-.5.3 0 .5.2.5.4l.3 3c.1.2-.1.5-.4.5z"
            id="prefix__Path_1308-2"
          />
          <path
            className="help-support-line"
            d="M6.2-114.8c-.1 0-.1 0 0 0l-3.2-.4c-.3 0-.5-.3-.4-.6 0-.3.3-.5.6-.4l3 .4c.3 0 .5.3.4.6 0 .2-.2.4-.4.4z"
            id="prefix__Path_1309-2"
          />
          <path
            className="help-support-line"
            d="M3.1-111.1c-.2 0-.5-.2-.5-.4 0-.3.2-.5.4-.6l3-.4c.3 0 .5.2.6.4s-.2.5-.4.6l-3.1.4c.1 0 0 0 0 0z"
            id="prefix__Path_1310-2"
          />
          <path
            className="help-support-line"
            d="M8.4-106c-.3 0-.5-.3-.5-.6l.3-3.1c0-.3.3-.5.5-.4.3 0 .5.3.4.5l-.3 3.1c.1.4-.2.5-.4.5z"
            id="prefix__Path_1311-2"
          />
          <path
            className="help-support-line"
            d="M12.3-106.2c-.2 0-.5-.2-.5-.4l-.3-2.9c0-.3.2-.5.4-.6.3 0 .5.2.6.4l.3 2.9c0 .4-.2.6-.5.6z"
            id="prefix__Path_1312-2"
          />
          <path
            className="help-support-line"
            d="M17.4-111.3s0-.1 0 0l-3.1-.4c-.3 0-.5-.3-.4-.6 0-.3.3-.5.6-.4l3 .4c.3 0 .5.3.4.6 0 .2-.2.4-.5.4z"
            id="prefix__Path_1313-2"
          />
          <path
            className="help-support-line"
            d="M14.4-114.9c-.3 0-.5-.2-.5-.4 0-.3.2-.5.4-.5l3-.3c.3 0 .5.2.5.4 0 .3-.2.5-.4.5l-3 .3z"
            id="prefix__Path_1314-2"
          />
          <path
            className="help-support-line"
            d="M16.3-118.5c-.1 0-.3-.1-.4-.2-.2-.2-.2-.5 0-.7.2-.2.2-.3.2-.4-.1-.1-.3 0-.5.2s-.5.2-.7 0c-.2-.2-.2-.5 0-.7.4-.4 1-.6 1.4-.5.4.1.7.4.8.7.1.3.1.8-.5 1.4-.1.2-.2.2-.3.2z"
            id="prefix__Path_1315-2"
          />
          <path
            className="help-support-line"
            d="M4.1-118.4c-.1 0-.2 0-.3-.1-.4-.4-.6-.9-.5-1.4.1-.4.4-.7.7-.8.3-.1.8-.1 1.4.5.2.2.2.5 0 .7-.2.2-.5.2-.7 0-.2-.2-.4-.2-.4-.2-.1.1 0 .3.2.5s.2.5 0 .7c-.1 0-.2.1-.4.1z"
            id="prefix__Path_1316-2"
          />
          <path
            className="help-support-line"
            d="M4.4-106.7h-.3c-.4-.1-.7-.4-.8-.7-.1-.3-.1-.8.5-1.4.2-.2.5-.2.7 0 .2.2.2.5 0 .7-.2.2-.2.4-.2.4.1.1.3 0 .5-.2s.5-.2.7 0c.2.2.2.5 0 .7-.3.3-.7.5-1.1.5z"
            id="prefix__Path_1317-2"
          />
          <path
            className="help-support-line"
            d="M16.1-106.7c-.3 0-.7-.1-1.1-.6-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0 .2.2.3.2.4.2.1-.1 0-.3-.2-.5s-.2-.5 0-.7c.2-.2.5-.2.7 0 .4.4.6 1 .5 1.4-.1.4-.4.7-.8.7 0 .2-.1.2-.2.2z"
            id="prefix__Path_1318-2"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
