import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37.82 49.83"
      {...props}
    >
      <defs>
        <style>
          {
            ".prefix__cls-1{fill:#fff;stroke:#fff;stroke-miterlimit:10;stroke-width:.01px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <g id="prefix__Layer_2-2" data-name="Layer 2">
            <g id="prefix__Layer_1-2-2" data-name="Layer 1-2">
              <path
                className="prefix__cls-1"
                d="M3.11 40.51a1.36 1.36 0 011.39-1.4h12.8a12.94 12.94 0 011.2-3.8h-14A1.62 1.62 0 013 34a1.32 1.32 0 011.31-1.5h16.3a11.29 11.29 0 017.8-3.1 10.7 10.7 0 014.3.9V13.81h-8.6a3.37 3.37 0 01-3.4-3.4V0H3.5A3.54 3.54 0 000 3.51V43a3.46 3.46 0 003.4 3.5H19a11.32 11.32 0 01-1.7-4.6H4.5a1.43 1.43 0 01-1.39-1.39zm0-20.7a1.36 1.36 0 011.39-1.4h23.71a1.37 1.37 0 011.4 1.4 1.37 1.37 0 01-1.4 1.4H4.5a1.5 1.5 0 01-1.39-1.4zm1.39 5.7h23.71a1.4 1.4 0 010 2.8H4.5a1.36 1.36 0 01-1.39-1.4 1.43 1.43 0 011.39-1.4z"
              />
              <path
                className="prefix__cls-1"
                d="M24.11 12.11h8.6L22.4 0v10.3a1.78 1.78 0 001.71 1.81zM28.41 31a9.59 9.59 0 00-7.9 4.2 9.69 9.69 0 00-1.5 6.6 9.46 9.46 0 0018.7-2.9 9.31 9.31 0 00-9.3-7.9zm4.89 13.1l-1.2 1.2-3.5-3.5-.1-.2-.2.2-3.5 3.5-1.2-1.2 2.2-2.2 1.41-1.4-1.4-1.4-2.2-2.2 1.2-1.2 3.5 3.5.2.2.2-.2 3.5-3.5 1.2 1.2-3.6 3.7z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
