import * as React from "react";

function StartLiveChat(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.281"
      height="29.895"
      viewBox="0 0 26.281 29.895"
      {...props}
    >
      <g
        id="Group_6189"
        data-name="Group 6189"
        transform="translate(-1533.896 -831.64)"
      >
        <path
          id="Path_2305"
          data-name="Path 2305"
          d="M9435.9,8626.223v25.133h17.209l7.072,2.762v-27.895Z"
          transform="translate(-7901 -7793.583)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_2306"
          data-name="Path 2306"
          d="M9440.588,8646.445a17.2,17.2,0,0,0,8,2.507c4.068,0,8.282-2.507,8.282-2.507"
          transform="translate(-7901.598 -7796.162)"
          fill="none"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default StartLiveChat;
