export const PROPERTIES_MOCKUP_DATA = [
  {
    salesHistory: [
      {
        saleId: "843289d3abbf0726618587ba4012caf53e907e88",
        propertyId: "7420c1f01ca22b0b",
        salePrice: 4840000,
        saleDate: "2010-03-23",
        saleReporter: "GOV",
        saleType: "N",
        isRelatedParty: false,
        relatedPartyDescription: null,
        bedrooms: null,
        bathrooms: null,
        landarea: 1205,
        carparks: 0,
        propertyType: "Secondary Production (Manufacturing)",
        images: [],
      },
    ],
    images: [
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w370-h256-2008286043_1_pi_150226_021427",
        date: "2010-03-25T02:52:38Z",
        rank: 1,
      },
    ],
    propertyId: "7420c1f01ca22b0b",
    address: "1 TURNER STREET ABBOTSFORD VIC 3067",
    location: {
      lat: -37.7993,
      long: 144.9954,
    },
    estimateDate: "2022-11-14",
    confidence: "Low",
    percentFsd: 32.1,
    estimate: 8596236,
    estimateHigh: 11227263,
    estimateLow: 5965209,
    coverageProbabilityPercent: 68.3,
    propertyType: "House",
    landarea: 120531,
    bedrooms: 1,
    bathrooms: 1,
    carparks: 1,
    lastSalePrice: null,
    lastSaleDate: null,
    isOnMarket: false,
    listPrice: null,
    listDate: null,
    weeklyRent: null,
    rentDate: null,
    weeklyRentEstimate: 2795,
    percentRentYieldEstimate: 1.71,
    last12MnthsLocalityDataSimilarProperties: {
      sales: 33,
      medianPrice: 1220000,
      highPrice: 1776000,
      lowPrice: 825000,
      numberSimilarProperties: 1097,
      meanPercentReturn: 1.7,
      meanPercentRentalYield: 2.4,
      meanDaysOnMarket: 27,
      percentVendorExpectationError: 7,
    },
    comparableSales: [
      {
        saleId: "4449e68ae726f92a816b6343fa09c93f02457541",
        address: "100 STUDLEY STREET ABBOTSFORD VIC 3067",
        location: {
          lat: -37.8015,
          long: 144.9968,
        },
        state: "VIC",
        propertyId: "15ced4ea80623409",
        saleDate: "2022-07-30",
        salePrice: 1565000,
        landarea: 224,
        bedrooms: 3,
        bathrooms: 1,
        carparks: 0,
        metresFromTarget: 275,
        daysOnMarket: 24,
        percentVendorExpectationError: 14.8,
        listPrice: 1350000,
        images: [],
      },
      {
        saleId: "34fb99ed0c258fa781c04913391567d6ec940423",
        address: "21 TURNER STREET ABBOTSFORD VIC 3067",
        location: {
          lat: -37.7994,
          long: 144.9962,
        },
        state: "VIC",
        propertyId: "336869a44a62d77f",
        saleDate: "2022-02-12",
        salePrice: 1815000,
        landarea: 215,
        bedrooms: 3,
        bathrooms: 1,
        carparks: 0,
        metresFromTarget: 73,
        daysOnMarket: 23,
        percentVendorExpectationError: 0.8,
        listPrice: 1800000,
        images: [],
      },
      {
        saleId: "f12826558c38922035743ee69ea1b35806f81126",
        address: "48 YARRA STREET ABBOTSFORD VIC 3067",
        location: {
          lat: -37.8019,
          long: 144.9949,
        },
        state: "VIC",
        propertyId: "e7fc933bcbdec37e",
        saleDate: "2022-06-23",
        salePrice: 1460000,
        landarea: 221,
        bedrooms: 3,
        bathrooms: 2,
        carparks: 2,
        metresFromTarget: 299,
        daysOnMarket: 21,
        percentVendorExpectationError: -2.7,
        listPrice: 1500000,
        images: [],
      },
      {
        saleId: "775444a48ac5e5ba75aba31fc1d5fc94f1fb983d",
        address: "60 STAFFORD STREET ABBOTSFORD VIC 3067",
        location: {
          lat: -37.8007,
          long: 144.9958,
        },
        state: "VIC",
        propertyId: "82d5ee41c43ea552",
        saleDate: "2022-02-02",
        salePrice: 1350000,
        landarea: 172,
        bedrooms: 2,
        bathrooms: 1,
        carparks: 1,
        metresFromTarget: 168,
        daysOnMarket: 132,
        percentVendorExpectationError: -6.1,
        listPrice: 1435000,
        images: [],
      },
      {
        saleId: "0cf15dbbffd4ce4a2dac812c8f756a2fc5a35b22",
        address: "27 TURNER STREET ABBOTSFORD VIC 3067",
        location: {
          lat: -37.7994,
          long: 144.9964,
        },
        state: "VIC",
        propertyId: "e74d7c806834b733",
        saleDate: "2021-12-12",
        salePrice: 2150000,
        landarea: 253,
        bedrooms: 4,
        bathrooms: 2,
        carparks: 0,
        metresFromTarget: 91,
        daysOnMarket: 25,
        percentVendorExpectationError: 7.2,
        listPrice: 2000000,
        images: [],
      },
      {
        saleId: "eaac9ece1650a4bc0c687f77b8faf7f4be7a5ab7",
        address: "304 JOHNSTON STREET ABBOTSFORD VIC 3067",
        location: {
          lat: -37.7999,
          long: 144.9956,
        },
        state: "VIC",
        propertyId: "406898cde94f0370",
        saleDate: "2022-04-30",
        salePrice: 1430000,
        landarea: 200,
        bedrooms: 3,
        bathrooms: 1,
        carparks: 1,
        metresFromTarget: 76,
        daysOnMarket: 52,
        percentVendorExpectationError: 2.1,
        listPrice: 1400000,
        images: [],
      },
      {
        saleId: "a7d96282206368043c0292f9d4e71d492107197f",
        address: "302 JOHNSTON STREET ABBOTSFORD VIC 3067",
        location: {
          lat: -37.7999,
          long: 144.9955,
        },
        state: "VIC",
        propertyId: "5833af71b7ebaa1e",
        saleDate: "2021-11-25",
        salePrice: 1101000,
        landarea: 201,
        bedrooms: 2,
        bathrooms: 1,
        carparks: 1,
        metresFromTarget: 73,
        daysOnMarket: 14,
        percentVendorExpectationError: 22.4,
        listPrice: 880000,
        images: [],
      },
      {
        saleId: "81623f1d42f5aed88d7b7214ffbc745299f2cf8d",
        address: "65 STAFFORD STREET ABBOTSFORD VIC 3067",
        location: {
          lat: -37.8009,
          long: 144.9956,
        },
        state: "VIC",
        propertyId: "1a72971f03b9be9c",
        saleDate: "2021-05-15",
        salePrice: 1481000,
        landarea: 221,
        bedrooms: 2,
        bathrooms: 1,
        carparks: 1,
        metresFromTarget: 186,
        daysOnMarket: 24,
        percentVendorExpectationError: 18.6,
        listPrice: 1230000,
        images: [],
      },
      {
        saleId: "d80db97cfafe12db26917233a8292312e0d6a6f3",
        address: "137 EASEY STREET COLLINGWOOD VIC 3066",
        location: {
          lat: -37.7986,
          long: 144.9922,
        },
        state: "VIC",
        propertyId: "3668448a2c499d12",
        saleDate: "2022-06-07",
        salePrice: 1536000,
        landarea: 164,
        bedrooms: 2,
        bathrooms: 2,
        carparks: 0,
        metresFromTarget: 291,
        daysOnMarket: 13,
        percentVendorExpectationError: 11.1,
        listPrice: 1375000,
        images: [],
      },
      {
        saleId: "694a7e7ed0dc1cc55117ec247e631c7ddcf13773",
        address: "34 STUDLEY STREET ABBOTSFORD VIC 3067",
        location: {
          lat: -37.8012,
          long: 144.9945,
        },
        state: "VIC",
        propertyId: "2123499bd6c928ab",
        saleDate: "2021-10-13",
        salePrice: 1275000,
        landarea: 156,
        bedrooms: 2,
        bathrooms: 1,
        carparks: 1,
        metresFromTarget: 232,
        daysOnMarket: 14,
        percentVendorExpectationError: 6.1,
        listPrice: 1200000,
        images: [],
      },
    ],
    comparableSaleListings: [
      {
        listingId: "dad28f387633e544dcb50ba4a5d442586ab2ff10",
        address: "82 LITTLE TURNER STREET ABBOTSFORD VIC 3067",
        state: "VIC",
        propertyId: "010e93a363381dd6",
        location: {
          lat: -37.7998,
          long: 144.9982,
        },
        listDate: "2022-08-18",
        listPrice: "$1,150,000 - $1,250,000",
        isAuction: "0",
        landarea: 200,
        bedrooms: 2,
        bathrooms: 2,
        carparks: 1,
        metresFromTarget: 261,
        images: [],
      },
      {
        listingId: "f4e21626d53b3e3b00ffc65daf80b5a4d22dbfc3",
        address: "73 TURNER STREET ABBOTSFORD VIC 3067",
        state: "VIC",
        propertyId: "d61965c5a3077306",
        location: {
          lat: -37.7996,
          long: 144.9981,
        },
        listDate: "2022-07-21",
        listPrice: "$2,750,000",
        isAuction: "0",
        landarea: 809,
        bedrooms: 5,
        bathrooms: 2,
        carparks: 1,
        metresFromTarget: 243,
        images: [],
      },
      {
        listingId: "9f09524c53d39be79d01a7bf765f2f61740cd9c7",
        address: "53 STUDLEY STREET ABBOTSFORD VIC 3067",
        state: "VIC",
        propertyId: "85f472dc659ba313",
        location: {
          lat: -37.8016,
          long: 144.9959,
        },
        listDate: "2022-04-09",
        listPrice: "$1,595,000",
        isAuction: "0",
        landarea: 296,
        bedrooms: 2,
        bathrooms: 2,
        carparks: 1,
        metresFromTarget: 263,
        images: [],
      },
      {
        listingId: "b3b0992ae0559940c46af9c856de9767c558d6e5",
        address: "32 LITTLE TURNER STREET ABBOTSFORD VIC 3067",
        state: "VIC",
        propertyId: "754cdf650d7979bf",
        location: {
          lat: -37.7996,
          long: 144.9962,
        },
        listDate: "2022-03-28",
        listPrice: "$1,895,000",
        isAuction: "0",
        landarea: 405,
        bedrooms: 4,
        bathrooms: 2,
        carparks: 2,
        metresFromTarget: 82,
        images: [],
      },
      {
        listingId: "2f46c86bcd94a7eea8e5f1871b97faf1d90fa1ec",
        address: "11 / 82 TRENERRY CRESCENT ABBOTSFORD VIC 3067",
        state: "VIC",
        propertyId: "f393e2434da1bd9c",
        location: {
          lat: -37.7979,
          long: 144.9982,
        },
        listDate: "2021-11-29",
        listPrice: "$870,000 to $930,000",
        isAuction: "0",
        landarea: 12680,
        bedrooms: 2,
        bathrooms: 1,
        carparks: 1,
        metresFromTarget: 291,
        images: [],
      },
      {
        listingId: "9562beb45cc2c5dea57582b3ff6c420ca131c6da",
        address: "92 STAFFORD STREET ABBOTSFORD VIC 3067",
        state: "VIC",
        propertyId: "3eb6f05f9d516a45",
        location: {
          lat: -37.8008,
          long: 144.997,
        },
        listDate: "2022-08-11",
        listPrice: "$1,799,000",
        isAuction: "0",
        landarea: 167,
        bedrooms: 4,
        bathrooms: 2,
        carparks: 1,
        metresFromTarget: 227,
        images: [],
      },
    ],
    comparableRentalListings: [
      {
        listing_id: "4b7651c557d03d6bb0196f50fffd7d538ed2d9af",
        address: "11 TURNER STREET ABBOTSFORD VIC 3067",
        state: "VIC",
        property_id: "1bacfb83d56daa51",
        location: {
          lat: -37.7993,
          long: 144.9958,
        },
        list_date: "2022-09-09",
        rental_price: 550,
        rental_period: "WEEK",
        landarea: 202,
        bedrooms: 2,
        bathrooms: 1,
        carparks: 2,
        metres_from_target: 38,
      },
      {
        listing_id: "02285864ce92b854d61a6aece4581e2ffe30a735",
        address: "38 LITTLE TURNER STREET ABBOTSFORD VIC 3067",
        state: "VIC",
        property_id: "6b82e805ae9942dc",
        location: {
          lat: -37.7996,
          long: 144.9965,
        },
        list_date: "2022-01-21",
        rental_price: 715,
        rental_period: "WEEK",
        landarea: 200,
        bedrooms: 2,
        bathrooms: 2,
        carparks: 1,
        metres_from_target: 106,
      },
      {
        listing_id: "53ba6b37f82aaece6641c7415c879060ed67a612",
        address: "56 STAFFORD STREET ABBOTSFORD VIC 3067",
        state: "VIC",
        property_id: "92e0f080dea21d83",
        location: {
          lat: -37.8007,
          long: 144.9957,
        },
        list_date: "2022-05-20",
        rental_price: 620,
        rental_period: "WEEK",
        landarea: 167,
        bedrooms: 2,
        bathrooms: 1,
        carparks: 0,
        metres_from_target: 164,
      },
      {
        listing_id: "a44b59404c5859c67540e252ece6bea0f0d8f66f",
        address: "58 STAFFORD STREET ABBOTSFORD VIC 3067",
        state: "VIC",
        property_id: "98a45ae5ff5b5194",
        location: {
          lat: -37.8007,
          long: 144.9958,
        },
        list_date: "2021-11-05",
        rental_price: 540,
        rental_period: "WEEK",
        landarea: 164,
        bedrooms: 2,
        bathrooms: 1,
        carparks: 1,
        metres_from_target: 165,
      },
      {
        listing_id: "37b90f2918d4a1add8ea6b5b5fcf8f911554e34f",
        address: "36 LITTLE TURNER STREET ABBOTSFORD VIC 3067",
        state: "VIC",
        property_id: "72ee2b929492799a",
        location: {
          lat: -37.7996,
          long: 144.9964,
        },
        list_date: "2021-05-06",
        rental_price: 720,
        rental_period: "WEEK",
        landarea: 201,
        bedrooms: 2,
        bathrooms: 2,
        carparks: 1,
        metres_from_target: 101,
      },
      {
        listing_id: "780fe6d5dda01efd1127f5b0d06fb792d1d4620d",
        address: "304 JOHNSTON STREET ABBOTSFORD VIC 3067",
        state: "VIC",
        property_id: "406898cde94f0370",
        location: {
          lat: -37.7999,
          long: 144.9956,
        },
        list_date: "2022-02-04",
        rental_price: 1000,
        rental_period: "WEEK",
        landarea: 192,
        bedrooms: 3,
        bathrooms: 1,
        carparks: 1,
        metres_from_target: 76,
      },
    ],
    state: "VIC",
    addressDetails: {
      address: "1 TURNER STREET ABBOTSFORD VIC 3067",
      location: {
        point: {
          lat: -37.79927435,
          lon: 144.99535735,
        },
      },
      unitNumber: null,
      streetNumber: "1",
      streetNumberHigh: null,
      street: "TURNER",
      streetType: "ST",
      streetSuffix: null,
      locality: "ABBOTSFORD",
      state: "VIC",
      postcode: "3067",
      lga: "Yarra",
      lgaCode: "27350",
      meshblock: 21301460000,
      sa1: 20607113906,
      sa2: 206071139,
      sa3: 20607,
      sa4: 206,
    },
    legalParcel: {
      full_legal_parcel: "CM1\\PS803857",
      lot: "CM1",
      plan: "PS803857",
    },
    dwellingCount: 44,
    parentId: null,
    monthsOccupancy: 151,
    occupancyType: "owner occupied",
    monthlyRent: null,
    accountIds: "11409642",
    purpose: "INVEST",
  },
];

export const PROPERTIES_PRICE_HISTORY_MOCKUP_DATA = [
  {
    "7420c1f01ca22b0b": {
      "1": {
        time: [
          "2022-10-01",
          "2022-09-01",
          "2022-08-01",
          "2022-07-01",
          "2022-06-01",
          "2022-05-01",
          "2022-04-01",
          "2022-03-01",
          "2022-02-01",
          "2022-01-01",
          "2021-12-01",
          "2021-11-01",
          "2021-10-01",
        ],
        value: [
          8596236,
          8417257,
          8363419,
          8290779,
          8422910,
          8313069,
          8005349,
          7804887,
          7586903,
          7456000,
          7406210,
          7663084,
          7327677,
        ],
      },
      "3": {
        time: [
          "2022-10-01",
          "2022-07-01",
          "2022-04-01",
          "2022-01-01",
          "2021-10-01",
          "2021-07-01",
          "2021-04-01",
          "2021-01-01",
          "2020-10-01",
          "2020-07-01",
          "2020-04-01",
          "2020-01-01",
          "2019-10-01",
        ],
        value: [
          8596236,
          8290779,
          8005349,
          7456000,
          7327677,
          6771412,
          6726267,
          7043875,
          6607155,
          5722323,
          4544284,
          3398955,
          3132796,
        ],
      },
      "5": {
        time: [
          "2022-10-01",
          "2022-05-01",
          "2021-12-01",
          "2021-07-01",
          "2021-02-01",
          "2020-09-01",
          "2020-04-01",
          "2019-11-01",
          "2019-06-01",
          "2019-01-01",
          "2018-08-01",
          "2018-03-01",
          "2017-10-01",
        ],
        value: [
          8596236,
          8313069,
          7406210,
          6771412,
          6970605,
          6299705,
          4544284,
          3200628,
          2306405,
          1595609,
          1620769,
          1832952,
          1960963,
        ],
      },
    },
  },
];

export const PROPERTIES_NEARBY_DETAILS_MOCKUP_DATA = [
  {
    b4613cae1c6d13ce: [
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_1_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_2_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_3_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_4_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_5_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_6_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_7_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_8_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_9_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_10_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_11_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_12_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_13_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 13,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_14_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 14,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_15_1_220406_052152-w1107-h1600",
        date: "2022-05-09T00:56:13.187Z",
        rank: 15,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_16_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 16,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_17_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 17,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_18_1_220406_052152-w1107-h1600",
        date: "2022-05-09T00:56:13.187Z",
        rank: 18,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_19_1_220406_052152-w1107-h1600",
        date: "2022-05-09T00:56:13.187Z",
        rank: 19,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_20_1_220406_052152-w1600-h1067",
        date: "2022-05-09T00:56:13.187Z",
        rank: 20,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017719424_21_3_220406_052152-w326-h1200",
        date: "2022-05-09T00:56:13.187Z",
        rank: 21,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w5184-h3456-11061308_1_pi_161220_024435",
        date: "2017-02-13T05:38:46.597Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w5184-h3456-11061308_2_pi_161220_024443",
        date: "2017-02-13T05:38:46.597Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w5184-h3456-11061308_3_pi_161220_024435",
        date: "2017-02-13T05:38:46.597Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w5184-h3456-11061308_4_pi_161220_024449",
        date: "2017-02-13T05:38:46.597Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w5184-h3456-11061308_5_pi_161220_024435",
        date: "2017-02-13T05:38:46.597Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w5184-h3456-11061308_6_pi_161220_024442",
        date: "2017-02-13T05:38:46.597Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w5184-h3456-11061308_7_pi_161220_024435",
        date: "2017-02-13T05:38:46.597Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w5184-h3456-11061308_8_pi_161220_024451",
        date: "2017-02-13T05:38:46.597Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w2254-h1336-11061308_9_pi_161220_024456",
        date: "2017-02-13T05:38:46.597Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1180-2012978397_1_pi_160809_021546",
        date: "2016-09-05T04:05:00.247Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1067-2012978397_2_pi_160809_021548",
        date: "2016-09-05T04:05:00.247Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1067-2012978397_3_pi_160809_021546",
        date: "2016-09-05T04:05:00.247Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1067-2012978397_4_pi_160809_021550",
        date: "2016-09-05T04:05:00.247Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1067-2012978397_5_pi_160809_021546",
        date: "2016-09-05T04:05:00.247Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1067-2012978397_6_pi_160809_021550",
        date: "2016-09-05T04:05:00.247Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1067-2012978397_7_pi_160809_021546",
        date: "2016-09-05T04:05:00.247Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1067-h1600-2012978397_8_pi_160809_021548",
        date: "2016-09-05T04:05:00.247Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1132-h1600-2012978397_9_fi_160809_021552",
        date: "2016-09-05T04:05:00.247Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h532-2010446703_1_pi_150316_073436",
        date: "2013-06-17T02:56:24Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h532-2010446703_2_pi_150316_073436",
        date: "2013-06-17T02:56:24Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-2010446703_3_pi_150426_095130",
        date: "2013-06-17T02:56:24Z",
        rank: 3,
      },
    ],
  },
  {
    "82d5ee41c43ea552": [
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017275328_1_1_210923_054447-w1600-h1067",
        date: "2022-02-02T21:49:23.59Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017275328_2_1_210923_054447-w1600-h1067",
        date: "2022-02-02T21:49:23.59Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017275328_3_1_210923_054447-w1600-h1067",
        date: "2022-02-02T21:49:23.59Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017275328_4_1_210923_054447-w1600-h1067",
        date: "2022-02-02T21:49:23.59Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017275328_5_1_210923_054447-w1600-h1067",
        date: "2022-02-02T21:49:23.59Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017275328_6_1_210923_054447-w1600-h1067",
        date: "2022-02-02T21:49:23.59Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017275328_7_1_210923_054447-w1600-h1067",
        date: "2022-02-02T21:49:23.59Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017275328_8_1_210923_054447-w1600-h1067",
        date: "2022-02-02T21:49:23.59Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017275328_9_1_210923_054447-w1600-h1067",
        date: "2022-02-02T21:49:23.59Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017275328_10_1_210923_054447-w1600-h1067",
        date: "2022-02-02T21:49:23.59Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017345867_13_1_211027_084012-w1600-h1163",
        date: "2022-02-02T21:49:23.59Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017275328_12_3_210923_054447-w1600-h1131",
        date: "2022-02-02T21:49:23.59Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/14329970_1_1_200622_051751-w1920-h1280",
        date: "2020-07-06T00:34:50.093Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/14329970_2_1_200622_051751-w1920-h1280",
        date: "2020-07-06T00:34:50.093Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/14329970_3_1_200622_051751-w1920-h1280",
        date: "2020-07-06T00:34:50.093Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/14329970_4_1_200622_051751-w1920-h1280",
        date: "2020-07-06T00:34:50.093Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/14329970_5_1_200622_051751-w1920-h1280",
        date: "2020-07-06T00:34:50.093Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/14329970_6_3_200622_051751-w2480-h3507",
        date: "2020-07-06T00:34:50.093Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-7747142_1_pi_150310_014815",
        date: "2012-08-01T11:43:30.797Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-7747142_2_pi_150310_014815",
        date: "2012-08-01T11:43:30.797Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-7747142_3_pi_150310_014815",
        date: "2012-08-01T11:43:30.797Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-7747142_4_pi_150310_014815",
        date: "2012-08-01T11:43:30.797Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-7747142_5_pi_150310_014815",
        date: "2012-08-01T11:43:30.797Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-7747142_6_fi_150310_014815",
        date: "2012-08-01T11:43:30.797Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w440-h293-6451850_1_pi_150302_120116",
        date: "2011-02-04T04:53:59.667Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w440-h293-6451850_2_pi_150302_120116",
        date: "2011-02-04T04:53:59.667Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w440-h293-6451850_3_pi_150302_120116",
        date: "2011-02-04T04:53:59.667Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w440-h293-6451850_4_pi_150302_120116",
        date: "2011-02-04T04:53:59.667Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w440-h293-6451850_5_pi_150302_120116",
        date: "2011-02-04T04:53:59.667Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-6451850_6_fi_150302_120116",
        date: "2011-02-04T04:53:59.667Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h532-2008077562_1_pi_150225_042806",
        date: "2009-11-30T00:08:01Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h532-2008077562_2_pi_150225_042806",
        date: "2009-11-30T00:08:01Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h532-2008077562_3_pi_150225_042807",
        date: "2009-11-30T00:08:01Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h532-2008077562_4_pi_150225_042807",
        date: "2009-11-30T00:08:01Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h532-2008077562_5_pi_150225_042807",
        date: "2009-11-30T00:08:01Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w640-h2763-2008077562_6_fi_150225_042807",
        date: "2009-11-30T00:08:01Z",
        rank: 6,
      },
    ],
  },
  {
    b6c9fc982e79853f: [
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_1_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_2_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_3_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_4_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_5_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_6_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_7_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_8_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_9_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_10_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_11_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_12_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_13_1_220316_034929-w1600-h1067",
        date: "2022-04-06T01:05:37.88Z",
        rank: 13,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_14_1_220316_034929-w1600-h1070",
        date: "2022-04-06T01:05:37.88Z",
        rank: 14,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_15_1_220316_034929-w1600-h1065",
        date: "2022-04-06T01:05:37.88Z",
        rank: 15,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_16_1_220316_034929-w798-h1200",
        date: "2022-04-06T01:05:37.88Z",
        rank: 16,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017666830_17_3_220316_043446-w772-h1200",
        date: "2022-04-06T01:05:37.88Z",
        rank: 17,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-2011296531_1_pi_150327_112221",
        date: "2014-08-04T06:22:22Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-2011296531_2_pi_150327_112221",
        date: "2014-08-04T06:22:22Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-2011296531_3_pi_150327_112221",
        date: "2014-08-04T06:22:22Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-2011296531_4_pi_150327_112221",
        date: "2014-08-04T06:22:22Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-2011296531_5_pi_150327_112221",
        date: "2014-08-04T06:22:22Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1240-h1752-2011296531_6_fi_150327_112221",
        date: "2014-08-04T06:22:22Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5202677_1_pi_150301_042311",
        date: "2010-11-04T07:17:42.35Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5202677_2_pi_150301_042311",
        date: "2010-11-04T07:17:42.35Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5202677_3_pi_150301_042311",
        date: "2010-11-04T07:17:42.35Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w450-h600-5202677_4_pi_150301_042311",
        date: "2010-11-04T07:17:42.35Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5202677_5_pi_150301_042311",
        date: "2010-11-04T07:17:42.35Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w450-h600-5202677_6_pi_150301_042311",
        date: "2010-11-04T07:17:42.35Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5202677_7_pi_150301_042311",
        date: "2010-11-04T07:17:42.35Z",
        rank: 7,
      },
    ],
  },
  {
    "15ced4ea80623409": [
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_1_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_2_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_3_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_4_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_5_1_220706_073348-w1067-h1600",
        date: "2022-08-04T04:20:16.117Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_6_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_7_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_8_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_9_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_10_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_11_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_12_1_220706_073348-w1067-h1600",
        date: "2022-08-04T04:20:16.117Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_13_1_220706_073348-w1067-h1600",
        date: "2022-08-04T04:20:16.117Z",
        rank: 13,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_14_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 14,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_15_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 15,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_16_1_220706_073348-w1600-h1067",
        date: "2022-08-04T04:20:16.117Z",
        rank: 16,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_17_1_220706_073348-w1067-h1600",
        date: "2022-08-04T04:20:16.117Z",
        rank: 17,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017918845_18_3_220706_073348-w1131-h1600",
        date: "2022-08-04T04:20:16.117Z",
        rank: 18,
      },
    ],
  },
  {
    "336869a44a62d77f": [
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_1_1_220120_025141-w1600-h1067",
        date: "2022-02-13T03:05:56.873Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_2_1_220120_025141-w1600-h1067",
        date: "2022-02-13T03:05:56.873Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_3_1_220120_025141-w1600-h1067",
        date: "2022-02-13T03:05:56.873Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_4_1_220120_025141-w1600-h1067",
        date: "2022-02-13T03:05:56.873Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_5_1_220120_025141-w1600-h1067",
        date: "2022-02-13T03:05:56.873Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_6_1_220120_025141-w1600-h1067",
        date: "2022-02-13T03:05:56.873Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_7_1_220120_025141-w1600-h1067",
        date: "2022-02-13T03:05:56.873Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_8_1_220120_025141-w1600-h1067",
        date: "2022-02-13T03:05:56.873Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_9_1_220120_025141-w1600-h1067",
        date: "2022-02-13T03:05:56.873Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_10_1_220120_025141-w1600-h1067",
        date: "2022-02-13T03:05:56.873Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_11_1_220120_025141-w1600-h1067",
        date: "2022-02-13T03:05:56.873Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_12_1_220120_025141-w1600-h1149",
        date: "2022-02-13T03:05:56.873Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017537460_13_3_220120_025141-w1600-h1131",
        date: "2022-02-13T03:05:56.873Z",
        rank: 13,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5546096_1_pi_150227_083111",
        date: "2010-09-06T02:17:12.687Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5546096_2_pi_150227_083111",
        date: "2010-09-06T02:17:12.687Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5546096_3_pi_150227_083111",
        date: "2010-09-06T02:17:12.687Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5546096_4_pi_150227_083111",
        date: "2010-09-06T02:17:12.687Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5546096_5_pi_150227_083111",
        date: "2010-09-06T02:17:12.687Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5546096_6_pi_150227_083111",
        date: "2010-09-06T02:17:12.687Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5546096_7_pi_150227_083111",
        date: "2010-09-06T02:17:12.687Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-5546096_8_pi_150227_083112",
        date: "2010-09-06T02:17:12.687Z",
        rank: 8,
      },
    ],
  },
  {
    e7fc933bcbdec37e: [
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/16069226_1_1_220901_083714-w2100-h1400",
        date: "2022-09-16T00:01:17.42Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/16069226_2_1_220901_083714-w2100-h1400",
        date: "2022-09-16T00:01:17.42Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/16069226_3_1_220901_083714-w2100-h1400",
        date: "2022-09-16T00:01:17.42Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/16069226_4_1_220901_083714-w2100-h1400",
        date: "2022-09-16T00:01:17.42Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/16069226_5_1_220901_083714-w2100-h1400",
        date: "2022-09-16T00:01:17.42Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/15797729_1_1_220322_095028-w4500-h3000",
        date: "2022-09-16T00:01:17.42Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/16069226_7_1_220901_083714-w2100-h1400",
        date: "2022-09-16T00:01:17.42Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/16069226_8_1_220901_083714-w2100-h1400",
        date: "2022-09-16T00:01:17.42Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/16069226_9_1_220901_083714-w2100-h1400",
        date: "2022-09-16T00:01:17.42Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/16069226_10_1_220901_083714-w2100-h1400",
        date: "2022-09-16T00:01:17.42Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/15797729_4_1_220322_095028-w4500-h3000",
        date: "2022-09-16T00:01:17.42Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/16069226_12_1_220901_083714-w2100-h1400",
        date: "2022-09-16T00:01:17.42Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/16069226_13_3_220901_083714-w3508-h2480",
        date: "2022-09-16T00:01:17.42Z",
        rank: 13,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_1_1_220602_065917-w1600-h1067",
        date: "2022-06-30T01:04:26.183Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_2_1_220602_065917-w1600-h1067",
        date: "2022-06-30T01:04:26.183Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_3_1_220602_065917-w1600-h1067",
        date: "2022-06-30T01:04:26.183Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_4_1_220602_065917-w1600-h1067",
        date: "2022-06-30T01:04:26.183Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_5_1_220602_065917-w1600-h1067",
        date: "2022-06-30T01:04:26.183Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_6_1_220602_065917-w1600-h1067",
        date: "2022-06-30T01:04:26.183Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_7_1_220602_065917-w1600-h1067",
        date: "2022-06-30T01:04:26.183Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_8_1_220602_065917-w1600-h1067",
        date: "2022-06-30T01:04:26.183Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_9_1_220602_065917-w1600-h1067",
        date: "2022-06-30T01:04:26.183Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_10_1_220602_065917-w1600-h1067",
        date: "2022-06-30T01:04:26.183Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_11_1_220602_065917-w1600-h957",
        date: "2022-06-30T01:04:26.183Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017847300_12_3_220602_065917-w1600-h1131",
        date: "2022-06-30T01:04:26.183Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/15797729_1_1_220322_095028-w4500-h3000",
        date: "2022-05-13T02:06:06.233Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/15797729_2_1_220322_095028-w4500-h3000",
        date: "2022-05-13T02:06:06.233Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/15797729_3_1_220322_095028-w4500-h3000",
        date: "2022-05-13T02:06:06.233Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/15797729_4_1_220322_095028-w4500-h3000",
        date: "2022-05-13T02:06:06.233Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/15797729_5_1_220322_095028-w4500-h3000",
        date: "2022-05-13T02:06:06.233Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/15797729_6_1_220322_095028-w4500-h3000",
        date: "2022-05-13T02:06:06.233Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-2010009652_1_pi_150313_094149",
        date: "2013-03-04T06:45:23.663Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-2010009652_2_pi_150313_094149",
        date: "2013-03-04T06:45:23.663Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-2010009652_3_pi_150313_094149",
        date: "2013-03-04T06:45:23.663Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-2010009652_4_pi_150313_094149",
        date: "2013-03-04T06:45:23.663Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-2010009652_5_pi_150313_094149",
        date: "2013-03-04T06:45:23.663Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1240-h1752-2010009652_6_fi_150313_094149",
        date: "2013-03-04T06:45:23.663Z",
        rank: 6,
      },
    ],
  },
  {
    "2123499bd6c928ab": [
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_1_1_210929_065028-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_2_1_210929_065028-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_3_1_210929_065028-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_4_1_210929_065028-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_5_1_210929_065028-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_6_1_210929_065028-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_7_1_210929_065028-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_8_1_210929_065028-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_9_1_210929_065028-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_10_1_210929_065028-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_11_1_210929_065028-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_12_1_210929_065028-w1067-h1600",
        date: "2021-10-18T09:14:18.417Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016620152_18_1_201111_025441-w1600-h1067",
        date: "2021-10-18T09:14:18.417Z",
        rank: 13,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017287995_14_3_210929_065028-w1132-h1600",
        date: "2021-10-18T09:14:18.417Z",
        rank: 14,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1067-2012299189_1_pi_151021_035856",
        date: "2015-10-24T02:20:32.217Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1068-2012299189_2_pi_151021_035900",
        date: "2015-10-24T02:20:32.217Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1068-2012299189_3_pi_151021_035856",
        date: "2015-10-24T02:20:32.217Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1068-2012299189_4_pi_151021_035902",
        date: "2015-10-24T02:20:32.217Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1068-2012299189_5_pi_151021_035856",
        date: "2015-10-24T02:20:32.217Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1068-2012299189_6_pi_151021_035859",
        date: "2015-10-24T02:20:32.217Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1068-2012299189_7_pi_151021_035856",
        date: "2015-10-24T02:20:32.217Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1600-h1068-2012299189_8_pi_151021_035900",
        date: "2015-10-24T02:20:32.217Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w587-h1600-2012299189_9_fi_151021_035903",
        date: "2015-10-24T02:20:32.217Z",
        rank: 9,
      },
      {
        url: "https://www.youtube.com/watch?v=PdrY2Y2K5cc&feature=youtu.be",
        date: "2015-10-24T02:20:32.217Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h533-2009159768_1_pi_150305_014655",
        date: "2011-10-19T13:00:00Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h533-2009159768_2_pi_150305_014655",
        date: "2011-10-19T13:00:00Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h509-2009159768_3_pi_150305_014655",
        date: "2011-10-19T13:00:00Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h533-2009159768_4_pi_150305_014655",
        date: "2011-10-19T13:00:00Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h533-2009159768_5_pi_150305_014655",
        date: "2011-10-19T13:00:00Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w707-h1000-2009159768_6_fi_150305_014655",
        date: "2011-10-19T13:00:00Z",
        rank: 6,
      },
    ],
  },
  {
    "3668448a2c499d12": [
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_3_1_220525_052410-w1600-h1067",
        date: "2022-07-14T08:25:10.427Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_2_1_220525_052410-w1600-h1067",
        date: "2022-07-14T08:25:10.427Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_4_1_220525_052410-w1600-h1067",
        date: "2022-07-14T08:25:10.427Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_5_1_220525_052410-w1600-h1067",
        date: "2022-07-14T08:25:10.427Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_6_1_220525_052410-w1600-h1067",
        date: "2022-07-14T08:25:10.427Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_7_1_220525_052410-w1600-h1067",
        date: "2022-07-14T08:25:10.427Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_8_1_220526_121021-w1600-h1067",
        date: "2022-07-14T08:25:10.427Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_9_1_220525_052410-w1600-h1067",
        date: "2022-07-14T08:25:10.427Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_10_1_220525_052410-w1600-h1067",
        date: "2022-07-14T08:25:10.427Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_11_1_220525_052410-w1067-h1600",
        date: "2022-07-14T08:25:10.427Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_12_1_220525_052410-w1600-h1067",
        date: "2022-07-14T08:25:10.427Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_13_1_220525_052410-w1600-h1067",
        date: "2022-07-14T08:25:10.427Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017828646_14_3_220525_052410-w1132-h1600",
        date: "2022-07-14T08:25:10.427Z",
        rank: 13,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_1_1_181109_114206-w1600-h1067",
        date: "2018-11-09T11:48:35.573Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_2_1_181109_114210-w1600-h1067",
        date: "2018-11-09T11:48:35.573Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_3_1_181109_114213-w1600-h1067",
        date: "2018-11-09T11:48:35.573Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_4_1_181109_114206-w1600-h1072",
        date: "2018-11-09T11:48:35.573Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_5_1_181109_114210-w1600-h1067",
        date: "2018-11-09T11:48:35.573Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_6_1_181109_114213-w1600-h1067",
        date: "2018-11-09T11:48:35.573Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_7_1_181109_114206-w1067-h1600",
        date: "2018-11-09T11:48:35.573Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_8_1_181109_114208-w1600-h1067",
        date: "2018-11-09T11:48:35.573Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_9_1_181109_114211-w1600-h1067",
        date: "2018-11-09T11:48:35.573Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_10_1_181109_114206-w1067-h1600",
        date: "2018-11-09T11:48:35.573Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_11_1_181109_114208-w1067-h1600",
        date: "2018-11-09T11:48:35.573Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2014713808_12_3_181109_114212-w1131-h1600",
        date: "2018-11-09T11:48:35.573Z",
        rank: 12,
      },
    ],
  },
  {
    f3c6c61721d49787: [
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_1_1_211109_020526-w1600-h1067",
        date: "2022-02-02T23:02:43.103Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_2_1_211109_020526-w1600-h1067",
        date: "2022-02-02T23:02:43.103Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_3_1_211109_020526-w1600-h1067",
        date: "2022-02-02T23:02:43.103Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_4_1_211109_020526-w1600-h1067",
        date: "2022-02-02T23:02:43.103Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_5_1_211109_020526-w1600-h1067",
        date: "2022-02-02T23:02:43.103Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_6_1_211109_020526-w1600-h1067",
        date: "2022-02-02T23:02:43.103Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_7_1_211109_020526-w1600-h1067",
        date: "2022-02-02T23:02:43.103Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_8_1_211109_020526-w1600-h1067",
        date: "2022-02-02T23:02:43.103Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_9_1_211109_020526-w1600-h1067",
        date: "2022-02-02T23:02:43.103Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_10_1_211109_020526-w1600-h1067",
        date: "2022-02-02T23:02:43.103Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_11_1_211109_020526-w1600-h1067",
        date: "2022-02-02T23:02:43.103Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017394381_13_3_211109_020526-w1132-h1600",
        date: "2022-02-02T23:02:43.103Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2015310619_1_1_190528_071328-w1600-h1067",
        date: "2019-06-13T23:47:19.907Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2015310619_2_1_190528_071328-w1600-h1067",
        date: "2019-06-13T23:47:19.907Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2015310619_3_1_190528_071328-w1600-h1067",
        date: "2019-06-13T23:47:19.907Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2015310619_4_1_190528_071328-w1600-h1067",
        date: "2019-06-13T23:47:19.907Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2015310619_5_1_190528_071328-w1600-h1067",
        date: "2019-06-13T23:47:19.907Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2015310619_6_1_190528_071328-w1600-h1067",
        date: "2019-06-13T23:47:19.907Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2015310619_7_1_190528_071328-w1600-h1067",
        date: "2019-06-13T23:47:19.907Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2015310619_8_1_190528_071328-w1600-h1067",
        date: "2019-06-13T23:47:19.907Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2015310619_9_3_190528_071328-w927-h1200",
        date: "2019-06-13T23:47:19.907Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w640-h480-9966684_1_pi_150902_051404",
        date: "2018-04-12T04:56:51.677Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w640-h480-9966684_2_pi_150902_051404",
        date: "2018-04-12T04:56:51.677Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1024-h613-9966684_3_pi_150902_051404",
        date: "2018-04-12T04:56:51.677Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w572-h429-9966684_4_pi_150902_051404",
        date: "2018-04-12T04:56:51.677Z",
        rank: 4,
      },
    ],
  },
  {
    c8a7b5e0876602bc: [
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_1_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_2_1_220302_103516-w1067-h1600",
        date: "2022-04-13T05:45:18.353Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_3_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_4_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_5_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_6_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_7_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_8_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_9_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_10_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_11_1_220302_103516-w1067-h1600",
        date: "2022-04-13T05:45:18.353Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_12_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_13_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 13,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_14_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 14,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_15_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 15,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_16_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 16,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_17_1_220302_103516-w1600-h1067",
        date: "2022-04-13T05:45:18.353Z",
        rank: 17,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2017635473_18_3_220302_103516-w1132-h1600",
        date: "2022-04-13T05:45:18.353Z",
        rank: 18,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_1_1_200611_010203-w1067-h1600",
        date: "2020-07-24T04:27:19.533Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_2_1_200611_010203-w1600-h1067",
        date: "2020-07-24T04:27:19.533Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_3_1_200611_010203-w1600-h1067",
        date: "2020-07-24T04:27:19.533Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_4_1_200611_010139-w1600-h1067",
        date: "2020-07-24T04:27:19.533Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_5_1_200611_010139-w1600-h1067",
        date: "2020-07-24T04:27:19.533Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_6_1_200611_010139-w1600-h1067",
        date: "2020-07-24T04:27:19.533Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_7_1_200611_010139-w1600-h1067",
        date: "2020-07-24T04:27:19.533Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_8_1_200611_010139-w1600-h1067",
        date: "2020-07-24T04:27:19.533Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_9_1_200611_010139-w1600-h1067",
        date: "2020-07-24T04:27:19.533Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_10_1_200611_010139-w1600-h1067",
        date: "2020-07-24T04:27:19.533Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_11_1_200611_010139-w1600-h1067",
        date: "2020-07-24T04:27:19.533Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_12_1_200611_010139-w1600-h1067",
        date: "2020-07-24T04:27:19.533Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2015171770_10_1_200513_075101-w1600-h1153",
        date: "2020-07-24T04:27:19.533Z",
        rank: 13,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/2016306984_14_3_200611_010139-w1600-h1131",
        date: "2020-07-24T04:27:19.533Z",
        rank: 14,
      },
      {
        url: "https://portal.diakrit.com/product/social-video/9939986",
        date: "2020-07-24T04:27:19.533Z",
        rank: 15,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h534-9558467_1_pi_150210_040154",
        date: "2015-02-25T04:10:20Z",
        rank: 1,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h600-9558467_2_pi_150210_040157",
        date: "2015-02-25T04:10:20Z",
        rank: 2,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w482-h298-9558467_3_pi_150210_040159",
        date: "2015-02-25T04:10:20Z",
        rank: 3,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w456-h342-9558467_4_pi_150210_040200",
        date: "2015-02-25T04:10:20Z",
        rank: 4,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h534-9558467_5_pi_150210_040154",
        date: "2015-02-25T04:10:20Z",
        rank: 5,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h534-9558467_6_pi_150210_040156",
        date: "2015-02-25T04:10:20Z",
        rank: 6,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h534-9558467_7_pi_150210_040157",
        date: "2015-02-25T04:10:20Z",
        rank: 7,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h534-9558467_8_pi_150210_040159",
        date: "2015-02-25T04:10:20Z",
        rank: 8,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h534-9558467_9_pi_150210_040154",
        date: "2015-02-25T04:10:20Z",
        rank: 9,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h534-9558467_10_pi_150210_040156",
        date: "2015-02-25T04:10:20Z",
        rank: 10,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h534-9558467_11_pi_150210_040158",
        date: "2015-02-25T04:10:20Z",
        rank: 11,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h534-9558467_12_pi_150210_040159",
        date: "2015-02-25T04:10:20Z",
        rank: 12,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w800-h534-9558467_13_pi_150210_040154",
        date: "2015-02-25T04:10:20Z",
        rank: 13,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w1213-h2383-9558467_14_pi_150210_040155",
        date: "2015-02-25T04:10:20Z",
        rank: 14,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w439-h907-9558467_15_fi_150210_040158",
        date: "2015-02-25T04:10:20Z",
        rank: 15,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w738-h1066-9558467_16_fi_150210_040159",
        date: "2015-02-25T04:10:20Z",
        rank: 16,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w439-h907-9558467_5_fi_150204_112645",
        date: "2015-02-25T04:10:20Z",
        rank: 17,
      },
      {
        url:
          "https://bucket-api.domain.com.au/v1/bucket/image/w738-h1066-9558467_6_fi_150204_112646",
        date: "2015-02-25T04:10:20Z",
        rank: 18,
      },
    ],
  },
];

export const PROPERTIES_INDICES_MOCKUP_DATA = {
  interval: "monthly",
  dates: [
    "2007-11-01",
    "2007-12-01",
    "2008-01-01",
    "2008-02-01",
    "2008-03-01",
    "2008-04-01",
    "2008-05-01",
    "2008-06-01",
    "2008-07-01",
    "2008-08-01",
    "2008-09-01",
    "2008-10-01",
    "2008-11-01",
    "2008-12-01",
    "2009-01-01",
    "2009-02-01",
    "2009-03-01",
    "2009-04-01",
    "2009-05-01",
    "2009-06-01",
    "2009-07-01",
    "2009-08-01",
    "2009-09-01",
    "2009-10-01",
    "2009-11-01",
    "2009-12-01",
    "2010-01-01",
    "2010-02-01",
    "2010-03-01",
    "2010-04-01",
    "2010-05-01",
    "2010-06-01",
    "2010-07-01",
    "2010-08-01",
    "2010-09-01",
    "2010-10-01",
    "2010-11-01",
    "2010-12-01",
    "2011-01-01",
    "2011-02-01",
    "2011-03-01",
    "2011-04-01",
    "2011-05-01",
    "2011-06-01",
    "2011-07-01",
    "2011-08-01",
    "2011-09-01",
    "2011-10-01",
    "2011-11-01",
    "2011-12-01",
    "2012-01-01",
    "2012-02-01",
    "2012-03-01",
    "2012-04-01",
    "2012-05-01",
    "2012-06-01",
    "2012-07-01",
    "2012-08-01",
    "2012-09-01",
    "2012-10-01",
    "2012-11-01",
    "2012-12-01",
    "2013-01-01",
    "2013-02-01",
    "2013-03-01",
    "2013-04-01",
    "2013-05-01",
    "2013-06-01",
    "2013-07-01",
    "2013-08-01",
    "2013-09-01",
    "2013-10-01",
    "2013-11-01",
    "2013-12-01",
    "2014-01-01",
    "2014-02-01",
    "2014-03-01",
    "2014-04-01",
    "2014-05-01",
    "2014-06-01",
    "2014-07-01",
    "2014-08-01",
    "2014-09-01",
    "2014-10-01",
    "2014-11-01",
    "2014-12-01",
    "2015-01-01",
    "2015-02-01",
    "2015-03-01",
    "2015-04-01",
    "2015-05-01",
    "2015-06-01",
    "2015-07-01",
    "2015-08-01",
    "2015-09-01",
    "2015-10-01",
    "2015-11-01",
    "2015-12-01",
    "2016-01-01",
    "2016-02-01",
    "2016-03-01",
    "2016-04-01",
    "2016-05-01",
    "2016-06-01",
    "2016-07-01",
    "2016-08-01",
    "2016-09-01",
    "2016-10-01",
    "2016-11-01",
    "2016-12-01",
    "2017-01-01",
    "2017-02-01",
    "2017-03-01",
    "2017-04-01",
    "2017-05-01",
    "2017-06-01",
    "2017-07-01",
    "2017-08-01",
    "2017-09-01",
    "2017-10-01",
    "2017-11-01",
    "2017-12-01",
    "2018-01-01",
    "2018-02-01",
    "2018-03-01",
    "2018-04-01",
    "2018-05-01",
    "2018-06-01",
    "2018-07-01",
    "2018-08-01",
    "2018-09-01",
    "2018-10-01",
    "2018-11-01",
    "2018-12-01",
    "2019-01-01",
    "2019-02-01",
    "2019-03-01",
    "2019-04-01",
    "2019-05-01",
    "2019-06-01",
    "2019-07-01",
    "2019-08-01",
    "2019-09-01",
    "2019-10-01",
    "2019-11-01",
    "2019-12-01",
    "2020-01-01",
    "2020-02-01",
    "2020-03-01",
    "2020-04-01",
    "2020-05-01",
    "2020-06-01",
    "2020-07-01",
    "2020-08-01",
    "2020-09-01",
    "2020-10-01",
    "2020-11-01",
    "2020-12-01",
    "2021-01-01",
    "2021-02-01",
    "2021-03-01",
    "2021-04-01",
    "2021-05-01",
    "2021-06-01",
    "2021-07-01",
    "2021-08-01",
    "2021-09-01",
    "2021-10-01",
    "2021-11-01",
    "2021-12-01",
    "2022-01-01",
    "2022-02-01",
    "2022-03-01",
    "2022-04-01",
    "2022-05-01",
    "2022-06-01",
    "2022-07-01",
    "2022-08-01",
    "2022-09-01",
    "2022-10-01",
    "2022-11-01",
  ],
  house: {
    series: {
      "ABBOTSFORD-3067": {
        marketscore: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          66.58299,
          66.47056,
          65.66961,
          65.6135,
          65.76633,
          65.77721,
          65.91784,
          66.29758,
          66.75429,
          67.21678,
          67.71541,
          67.53477,
          67.20497,
          66.79083,
          66.24075,
          65.93192,
          65.98205,
          66.26907,
          66.7331,
          67.38859,
          67.98587,
          68.45493,
          68.82115,
          69.0749,
          69.34418,
          69.76298,
          70.23855,
          70.66685,
          71.10672,
          71.55093,
          71.60083,
          71.4528,
          70.96408,
          70.13358,
          69.06953,
          67.61668,
          66.02814,
          64.17911,
          62.03795,
          59.50558,
          56.7273,
          53.9162,
          51.36616,
          49.30896,
          47.57133,
          46.04122,
          44.57242,
          43.12492,
          41.77709,
          40.68375,
          39.72367,
          39.06219,
          38.61063,
          38.3255,
          38.47574,
          39.08115,
          39.91816,
          41.15965,
          42.61411,
          44.37508,
          46.67499,
          49.86725,
          53.91016,
          58.15867,
          61.78059,
          63.93417,
          64.98694,
          65.10372,
          64.66877,
          64.22702,
          63.79582,
          63.75322,
          63.89996,
          64.13267,
          64.95787,
          65.86483,
          66.56499,
          67.4097,
          68.69141,
          69.83563,
          70.56366,
          71.18614,
          71.29,
          71.06962,
          70.59129,
          69.83855,
          69.26973,
          68.96351,
          68.51699,
          68.40415,
          67.81776,
          65.93008,
          64.11141,
          61.63405,
          58.12887,
          53.68951,
          49.03401,
          44.7235,
          41.20298,
          38.92087,
          39.61506,
          40.35527,
          41.77737,
          39.48543,
          39.56929,
          39.7864,
          null,
          null,
        ],
        price: [
          730928,
          737391,
          741764,
          744309,
          745350,
          745167,
          743920,
          741765,
          738801,
          735469,
          732159,
          729147,
          726793,
          725318,
          724724,
          724998,
          726179,
          728377,
          731814,
          736927,
          744083,
          753141,
          763956,
          776396,
          789705,
          803101,
          815937,
          827829,
          838516,
          847803,
          855543,
          861703,
          866269,
          869378,
          871103,
          871529,
          870924,
          869510,
          867449,
          864801,
          861707,
          858309,
          854379,
          849949,
          845030,
          840024,
          835300,
          831225,
          827798,
          824773,
          821918,
          819339,
          817162,
          815612,
          814745,
          814659,
          815367,
          816756,
          818610,
          820649,
          823047,
          825971,
          829550,
          833842,
          838884,
          844630,
          850847,
          857466,
          864446,
          871715,
          879042,
          886254,
          893318,
          900263,
          907259,
          914531,
          922308,
          930802,
          939519,
          947904,
          955439,
          962318,
          968704,
          974760,
          980784,
          987112,
          993999,
          1001479,
          1009302,
          1017305,
          1025391,
          1033628,
          1042239,
          1051435,
          1061545,
          1072905,
          1085079,
          1097596,
          1110075,
          1122712,
          1135546,
          1148662,
          1162427,
          1177286,
          1193739,
          1211181,
          1228954,
          1246525,
          1263579,
          1279863,
          1295130,
          1309591,
          1323227,
          1335975,
          1347185,
          1356348,
          1363025,
          1367042,
          1368216,
          1366633,
          1362505,
          1356046,
          1347516,
          1337466,
          1326055,
          1313561,
          1300004,
          1285678,
          1270945,
          1256319,
          1242467,
          1229852,
          1218773,
          1209197,
          1200962,
          1194025,
          1188539,
          1184899,
          1183737,
          1185732,
          1191267,
          1199449,
          1209108,
          1219107,
          1229138,
          1239197,
          1249429,
          1260060,
          1271083,
          1282617,
          1294508,
          1306691,
          1319155,
          1332297,
          1346388,
          1361270,
          1376180,
          1391699,
          1408488,
          1426237,
          1444546,
          1463094,
          1480943,
          1497249,
          1511053,
          1523172,
          1534852,
          1547072,
          1559748,
          1571483,
          1581664,
          1589412,
          1593312,
          1592137,
          1585553,
          1574442,
          1560444,
          1543279,
          1526987,
          null,
          null,
        ],
      },
    },
  },
};
