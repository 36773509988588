import { createIntl, createIntlCache } from "react-intl";
import { convertToInt } from "./convert-number";

const cache = createIntlCache();
const intl = createIntl({ locale: "en-AU" }, cache);

function formatNumberWithText(num, digits) {
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

const formatNumber = (inputNumber) => {
  const formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");

  return formetedNumber;
};
const formatNumberWithDollar = (inputNumber, digit = 0) => {
  return intl.formatNumber(convertToInt(inputNumber), {
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
    currencyDisplay: "narrowSymbol",
  });
};

const formatNumberWithPercent = (inputNumber) => {
  const formetedNumber = `${inputNumber}%`;
  return formetedNumber;
};

export {
  formatNumberWithText,
  formatNumber,
  formatNumberWithDollar,
  formatNumberWithPercent,
};
