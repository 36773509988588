import * as React from "react";
import { primaryColor } from "../scss/colors.scss";

function SvgComponent(props, { fill = primaryColor }) {
  return (
    <svg width={16.252} height={14.893} viewBox="0 0 16.252 14.893" {...props}>
      <g
        data-name="Group 4521"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path
          data-name="Path 1437"
          d="M4.124 1.774V11.84h11.128z"
          fill={fill}
          stroke={fill}
        />
        <path
          data-name="Path 1436"
          d="M1 1v12.893h14.252z"
          fill="none"
          stroke="#040d13"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
